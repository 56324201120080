import styled from 'styled-components'
import { 
  darkBlue,
  white,
  electricBlue,
  coolGray50
} from '../../../constants'
import { Paragraph } from '../Text'
import { IRadioButtonProps } from './'

const HiddenRadioButton = styled('input')`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`

const StyledRadioButton = styled('label')<{
  mr?: string
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  cursor: pointer;
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: ${props => (props.mr ? props.mr : 0)};
  padding: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;

  input:focus-visible ~ .radiomark {
    outline: 2px solid ${darkBlue};
    outline-offset: 2px;
  }
`

const StyledUnselected = styled('div')`
  width: 20px;
  height: 20px;
  border: 1px solid ${coolGray50};
  border-radius: 50%;
  background-color: ${white};
  margin-right: 16px;
  cursor: pointer;
`

const StyledSelected = styled(StyledUnselected)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${electricBlue};
  background-color: ${electricBlue};
  margin-right: 16px;
  cursor: pointer;
`

const StyledMark = styled('div')`
  width: 12px;
  height: 12px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${white};
  background-color: ${electricBlue};
`

export const RadioButton: React.FC<IRadioButtonProps> = ({
  label,
  name,
  value,
  selected,
  toggleSelected,
  marginRight='0'
}) => {
  return (
    <StyledRadioButton mr={marginRight}>
      <HiddenRadioButton 
        type='radio'
        name={name}
        value={value}
        checked={selected}
        onKeyDown={(e) => e.key === 'Enter' ? toggleSelected(value) : null}
        onChange={() => toggleSelected(value)}
      />
      {selected ? (
        <StyledSelected className="radiomark">
          <StyledMark />
        </StyledSelected>
      ) : (
        <StyledUnselected className="radiomark" />
      )}
      <Paragraph
        as='span'
        color={darkBlue}
      >
        {label}
      </Paragraph>
    </StyledRadioButton>
  )
}
