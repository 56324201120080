import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { AgentInfo } from '../common/AgentInfo'
import { Header, StyledModalBackground, StyledModalContainer } from '../common/Modal'
import { AgentInfoForm } from './AgentInfoForm'
import { LoadingSpinner } from '../common/LoadingSpinner'
import { OrangeButton, SecondaryButton } from '../common/Button'
import { Flex } from '../common/Flex'
import { CheckCircleIcon } from '../icons/CheckCircleIcon'
import { requestData } from '../../utils/requestData'
import {
  BREAKPOINT_M,
  coolGray40,
  coolGray50,
  coolGray60,
  CREATE_PENDING_AGENT,
  electricBlue,
  HAPI_AUTH_URL,
  white,
  whiteHover,
  PROVIDER_AGENT_UPDATE_STAGE
} from '../../constants'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import AgentConfirmationHeaderMobile from './AgentConfirmationHeaderMobile'

const ModalContainer = styled(StyledModalContainer)`
  height: 100%;
  border-radius: 0;
  position: relative;

  .loading-spinner {
    height: 100%;
    display: flex;
    align-items: center;
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    height: ${props => (props.inContractScreen ? '356px' : '512px')};
    border-radius: 8px;
  }
`

const ModalBody = styled(StyledModalContainer)<{ submitted?: boolean }>`
  padding: 0 32px 48px;
  height: calc(100% - 81px);
  ${({ submitted }) =>
    submitted &&
    css`
      margin-top: 105px;
    `}
`

const Title = styled.h3`
  margin-top: 0;
  font-size: 20px;
  text-align: ${props => (props.center ? 'center' : '')};

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 24px;
  }
`

const AgentList = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: calc(100% - 137px);
  align-items: stretch;
  margin-bottom: 64px;
  overflow: auto;

  @media (min-width: ${BREAKPOINT_M}px) {
    height: calc(100% - 63px);
  }
`

const DifferentAgent = styled.div`
  text-align: center;
  color: ${electricBlue};
  cursor: pointer;
  text-decoration-line: underline;
  margin-top: 8px;
  margin-bottom: 24px;
`

const AgentWrapper = styled.div<{
  selected?: boolean
}>`
  padding: 16px 24px;
  border-radius: 12px;
  border: 1px solid;
  border-color: ${props => (props.selected ? electricBlue : coolGray50)};
  cursor: pointer;

  .agent-info-wrapper {
    margin-bottom: 8px;
  }
`

const ButtonSection = styled(Flex)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  padding: 16px;
  border-top: solid 1px ${coolGray60};
  gap: 16px;

  @media (min-width: ${BREAKPOINT_M}px) {
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 24px;
    gap: 8px;
  }
`

const StyledOrangeButton = styled(OrangeButton)`
  width: 100%;
  @media (min-width: ${BREAKPOINT_M}px) {
    order: 2;
    width: unset;
  }
`

const StyledSecondaryButton = styled(SecondaryButton)`
  width: 100%;
  height: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background-color: ${white};
  border-color: ${coolGray40};
  cursor: pointer;
  &:hover {
    background-color: ${whiteHover};
  }
  @media (min-width: ${BREAKPOINT_M}px) {
    order: 1;
    width: unset;
  }
`

const AgentSuccessImageWrapper = styled(Flex)`
  justify-content: center;
`

const SuccessImage = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #cef0e4;
  border-radius: 50%;
  margin-bottom: 24px;

  svg {
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: 28px;
    height: 28px;
  }
`

const AgentImage = styled.img`
  position: absolute;
  width: 90px;
  height: 90px;
  left: 5px;
  top: 5px;
  border-radius: 50%;
`

const StyledCheckIcon = styled(CheckCircleIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
`

const SuccessMessage = styled.p`
  text-align: center;
  margin-bottom: 64px;
`

const NoAgents = styled.p`
  text-align: center;
  margin-bottom: 64px;
`

export const AgentConfirmation = ({ onClose, currentUser, pendingRequestUuid }) => {
  const [agents, setAgents] = useState([])
  const [selectedId, setSelectedId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [agentFormActive, setAgentFormActive] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [inContractAgent, setInContractAgent] = useState({})
  const [inContractScreen, setInContractScreen] = useState(true)
  const { mobile: isMobile } = useBreakpoint()

  const selectedAgent = agents.find(agent => agent.id === selectedId)
  const submitDisabled = selectedId === null

  const getAgentData = () => {
    setLoading(true)
    return requestData({
      url: HAPI_AUTH_URL,
      method: 'get',
      includeBearerToken: false,
      fields: [
        {
          user: ['current_lead_id', 'current_lead_agents', 'in_contract_agent,']
        }
      ]
    }).then(data => {
      if (!data.data?.inContractAgent?.id) {
        setInContractScreen(false)
      }

      setLoading(false)
      setAgents(data.data?.currentLeadAgents || [])
      setInContractAgent(data.data?.inContractAgent || {})
    })
  }

  useEffect(() => {
    getAgentData()
  }, [])

  const submitAgent = (agentData?: { name: string; email: string; phone: string }) => {
    const agent = agentData || {
      name: selectedAgent?.full_name,
      email: selectedAgent?.email,
      phone: selectedAgent?.phone
    }

    return requestData({
      url: CREATE_PENDING_AGENT,
      method: 'post',
      body: {
        user_id: currentUser?.id,
        name: agent.name,
        email: agent.email,
        phone: agent.phone
      },
      includeBearerToken: true,
      ignoreJsonFormat: true
    })
  }

  const handlePendingAgentSubmit = agentData => {
    submitAgent(agentData).then(({ data: agentData }) => {
      setSubmitted(true)
    })
  }

  const handleSelectAgent = () => {
    const url = PROVIDER_AGENT_UPDATE_STAGE.replace(':lead_id', currentUser?.currentLeadId).replace(
      ':id',
      selectedAgent?.provider_lead_id
    )
    requestData({
      url: url,
      method: 'put',
      body: {
        disposition: 'in_contract',
        note: 'Client indicated they are now under contract with the agent.',
        user_id: currentUser?.id
      },
      includeBearerToken: true
    }).then(({ data }) => {
      setSubmitted(true)
    })
  }

  const renderAgents = () => {
    let displayAgents

    if (inContractScreen) {
      displayAgents = [inContractAgent]
    } else {
      displayAgents = agents
    }

    if (displayAgents.length === 0) {
      return <NoAgents>You currently do not have any agents.</NoAgents>
    }

    return displayAgents.map(agent => (
      <AgentWrapper
        key={agent.id}
        selected={selectedId === agent.id}
        onClick={() => !inContractScreen && setSelectedId(agent.id)}
      >
        <AgentInfo
          fullName={agent.full_name || agent.fullName}
          brokerage={agent.brokerage_name || agent.brokerageName}
          image={agent.image_url || agent.imageUrl}
          email={agent.email}
        />
      </AgentWrapper>
    ))
  }

  if (loading) {
    return (
      <StyledModalBackground>
        <ModalContainer>
          <Header closeModal={onClose} hideBorder />
          <LoadingSpinner color={electricBlue} />
        </ModalContainer>
      </StyledModalBackground>
    )
  }

  return (
    <StyledModalBackground>
      <ModalContainer inContractScreen={inContractScreen}>
        {isMobile && !submitted ? (
          <AgentConfirmationHeaderMobile onClose={onClose} />
        ) : (
          <Header closeModal={onClose} hideBorder />
        )}
        {submitted ? (
          <>
            <ModalBody submitted={submitted}>
              <AgentSuccessImageWrapper>
                <SuccessImage>
                  <AgentImage src={selectedAgent?.image_url} />
                  <StyledCheckIcon bgFill="#38BF8F" />
                </SuccessImage>
              </AgentSuccessImageWrapper>
              <Title center={true}>Thank you!</Title>
              <SuccessMessage>
                We will share all of your interested properties with{' '}
                <strong>{selectedAgent?.full_name}</strong> going forward.
              </SuccessMessage>
            </ModalBody>
            <ButtonSection>
              <StyledOrangeButton onClick={onClose}>Back to search</StyledOrangeButton>
            </ButtonSection>
          </>
        ) : (
          <>
            <ModalBody>
              <Title>{inContractScreen ? 'My agent' : 'Which agent are you working with?'}</Title>
              <AgentList>
                {renderAgents()}
                {inContractScreen && (
                  <DifferentAgent onClick={() => setInContractScreen(false)}>
                    This is not my agent
                  </DifferentAgent>
                )}
              </AgentList>
            </ModalBody>
            {!inContractScreen && (
              <ButtonSection>
                <StyledOrangeButton
                  disabled={submitDisabled}
                  onClick={submitDisabled ? () => {} : handleSelectAgent}
                >
                  {isMobile ? 'Share with Agent' : 'Submit'}
                </StyledOrangeButton>
                <StyledSecondaryButton onClick={onClose}>I'm still deciding</StyledSecondaryButton>
              </ButtonSection>
            )}
          </>
        )}
      </ModalContainer>
      {agentFormActive && (
        <AgentInfoForm
          submitAgent={handlePendingAgentSubmit}
          onClose={() => setAgentFormActive(false)}
        />
      )}
    </StyledModalBackground>
  )
}
