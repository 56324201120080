import React from 'react'
import styled from 'styled-components'
import { Body, Header } from '../common/Modal'
import { darkBlue } from '../../constants'
import { CadmiumRedButton } from '../common/Button'
import { Heading3, Text } from '../common/Text'
import { LoadingSpinner } from '../common/LoadingSpinner'

const StyledHeading = styled(Heading3)`
  padding-bottom: 13px;
`
const StyledText = styled(Text)<{color: string}>`
  padding-bottom: 28px;
`

const StyledRedButton = styled(CadmiumRedButton)`
  margin-bottom: 16px;
  float: right;
`

declare interface IConfirmDeleteModal {
  cityName: string,
  closeModal: () => void,
  deleteSearch: () => void,
  isLoading: boolean
}

export const ConfirmDeleteModal: React.FC<IConfirmDeleteModal> = ({ closeModal, cityName, deleteSearch, isLoading }) => {
  return (
    <>
      <Header closeModal={closeModal} />
      <Body>
        <StyledHeading as='h3'>
          Delete search
        </StyledHeading>
        <StyledText as='p' color={darkBlue}>
          {`Are you sure you want to delete ${cityName}? This will remove your saved search criteria`}
        </StyledText>
        <StyledRedButton
          width='120px'
          onClick={deleteSearch}
          disabled={isLoading}
          className='delete-search-button'
          data-testid='delete-search-button'
        >
          {(isLoading ?
            <LoadingSpinner width='28px' /> :
            `Yes, delete`
          )}
        </StyledRedButton>
      </Body>
    </>
  )
}
