import styled from 'styled-components'
import { useEffect, useState, lazy, Suspense } from 'react'
import { DEFAULT_LISTING_STATUS_FILTER_VALUE, BREAKPOINT_M } from '../../../constants'
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll'
import { FallbackLoadingSpinner } from '../../common/FallbackLoadingSpinner'
import { PopoverContainer } from '../../common/Popover'
import { Trigger } from '../FilterPopover/Trigger'
import { IMobileFiltersProps } from './'
import { StyledModalBackground } from '../../common/Modal'
import { Sort } from '../../Sort'

const MobileFiltersBody = lazy(() => import('./MobileFiltersBody/MobileFiltersBody'))

const StyledPopOverContainer = styled(PopoverContainer)`
  @media (max-width: ${BREAKPOINT_M}px) {
    display: flex;
    max-width: calc(100vw - 147px);
  }
`

export const MobileFilters: React.FC<IMobileFiltersProps> = ({
  showMap,
  priceMin,
  selectPriceMin,
  priceMinOptions,
  priceMax,
  selectPriceMax,
  priceMaxOptions,
  bedsMin,
  selectBedsMin,
  bedsMax,
  selectBedsMax,
  bathsMin,
  selectBathsMin,
  propertyTypes,
  selectPropertyTypes,
  forSaleOrSold,
  selectForSaleOrSold,
  listingStatus,
  selectListingStatus,
  saleDate,
  selectSaleDate,
  sqftMin,
  selectSqftMin,
  sqftMinOptions,
  sqftMax,
  selectSqftMax,
  sqftMaxOptions,
  lotSizeMin,
  selectLotSizeMin,
  lotSizeMinOptions,
  lotSizeMax,
  selectLotSizeMax,
  lotSizeMaxOptions,
  yearBuiltMin,
  selectYearBuiltMin,
  yearBuiltMinOptions,
  yearBuiltMax,
  selectYearBuiltMax,
  yearBuiltMaxOptions,
  applyAllFilters,
  clearAllFilters,
  popoverOpen,
  setPopoverOpen,
  sortOptions,
  selectedSortLabel,
  setSort,
  featured,
  selectFeatured,
  showGoldRush,
}) => {
  const INITIAL_TRIGGER_LABEL = 'Filters'

  const [filtersCount, setFiltersCount] = useState(0)

  useDisableBodyScroll(popoverOpen)

  const headerLabel = `${INITIAL_TRIGGER_LABEL}${filtersCount > 0 ? ' · ' + filtersCount : ''}`

  const togglePopover = (e: any) => {
    e.preventDefault()
    setPopoverOpen(!popoverOpen)
  }

  const handleApplyFilters = () => {
    applyAllFilters()
    setPopoverOpen(false)
  }

  const getFilterCount = (filterNames: Array<Array<any>>) => {
    return filterNames
      .filter(f => {
        const [key, item] = f

        if (key === 'bedsMin' || key === 'bedsMax') {
          return item && item.value
        }
        if (key === 'forSaleOrSold') {
          return item && item === 'sold'
        }
        if (key === 'propertyTypes') {
          return item && Boolean((item as []).length)
        }

        return item && item.value && item.value !== DEFAULT_LISTING_STATUS_FILTER_VALUE
      })
      .filter(Boolean).length
  }

  useEffect(() => {
    const allFiltersCount = getFilterCount([
      ['bedsMin', bedsMin],
      ['bathsMin', bathsMin],
      ['priceMin', priceMin],
      ['priceMax', priceMax],
      ['listingStatus', listingStatus],
      ['saleDate', saleDate],
      ['sqftMin', sqftMin],
      ['sqftMax', sqftMax],
      ['lotSizeMin', lotSizeMin],
      ['lotSizeMax', lotSizeMax],
      ['yearBuiltMin', yearBuiltMin],
      ['yearBuiltMax', yearBuiltMax],
      ['featured', featured],
      ['forSaleOrSold', forSaleOrSold],
      ['propertyTypes', propertyTypes]
    ])

    setFiltersCount(allFiltersCount)
  }, [
    priceMin,
    priceMax,
    bedsMin,
    bathsMin,
    propertyTypes,
    listingStatus,
    saleDate,
    sqftMin,
    sqftMax,
    lotSizeMin,
    lotSizeMax,
    yearBuiltMin,
    yearBuiltMax,
    forSaleOrSold,
    featured
  ])

  useEffect(() => {
    if (!popoverOpen) {
      handleApplyFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverOpen])

  return (
    <StyledPopOverContainer position={`${showMap ? 'initial' : 'relative'}`}>
      <Sort
        sortOptions={sortOptions}
        selectedSortLabel={selectedSortLabel}
        setSort={setSort}
      />
      <Trigger
        onClick={(e: any) => togglePopover(e)}
        active={popoverOpen || filtersCount}
        className={`popover-trigger${filtersCount ? ' trigger-active' : ''}`}
        data-testid="mobile-filters-popover-trigger"
      >
        {headerLabel}
      </Trigger>
      <Suspense fallback={<FallbackLoadingSpinner />}>
        {popoverOpen && (
          <StyledModalBackground>
            <MobileFiltersBody
              headerLabel={headerLabel}
              togglePopover={togglePopover}
              priceMin={priceMin}
              priceMax={priceMax}
              priceMinOptions={priceMinOptions}
              priceMaxOptions={priceMaxOptions}
              selectPriceMin={selectPriceMin}
              selectPriceMax={selectPriceMax}
              bedsMin={bedsMin}
              bedsMax={bedsMax}
              selectBedsMin={selectBedsMin}
              selectBedsMax={selectBedsMax}
              bathsMin={bathsMin}
              selectBathsMin={selectBathsMin}
              propertyTypes={propertyTypes}
              selectPropertyTypes={selectPropertyTypes}
              forSaleOrSold={forSaleOrSold}
              selectForSaleOrSold={selectForSaleOrSold}
              listingStatus={listingStatus}
              selectListingStatus={selectListingStatus}
              saleDate={saleDate}
              selectSaleDate={selectSaleDate}
              sqftMinOptions={sqftMinOptions}
              sqftMin={sqftMin}
              sqftMaxOptions={sqftMaxOptions}
              sqftMax={sqftMax}
              selectSqftMin={selectSqftMin}
              selectSqftMax={selectSqftMax}
              lotSizeMinOptions={lotSizeMinOptions}
              lotSizeMin={lotSizeMin}
              lotSizeMaxOptions={lotSizeMaxOptions}
              lotSizeMax={lotSizeMax}
              selectLotSizeMin={selectLotSizeMin}
              selectLotSizeMax={selectLotSizeMax}
              yearBuiltMinOptions={yearBuiltMinOptions}
              yearBuiltMin={yearBuiltMin}
              yearBuiltMaxOptions={yearBuiltMaxOptions}
              yearBuiltMax={yearBuiltMax}
              selectYearBuiltMin={selectYearBuiltMin}
              selectYearBuiltMax={selectYearBuiltMax}
              handleApplyFilters={handleApplyFilters}
              hasFilters={filtersCount}
              clearAllFilters={clearAllFilters}
              featured={featured}
              selectFeatured={selectFeatured}
              showGoldRush={showGoldRush}
            />
          </StyledModalBackground>
        )}
      </Suspense>
    </StyledPopOverContainer>
  )
}
