import {
  createContext,
  useCallback,
  useState
} from "react"
import {
  IMAGE_PARAMS,
  LISTINGS_IMAGES_URL
} from '../../constants'
import { requestData } from '../../utils/requestData'
import { IField } from "../../utils/buildRequestFields"
import {
  IImagesContext,
  IImagesProvider
} from "./"

export const ImagesContext = createContext<IImagesContext | null>(null)

export const ImagesProvider: React.FC<IImagesProvider> = ({
  listingKey,
  children
}) => {
  const [images, setImages] = useState([])
  const [imagesLoading, setImagesLoading] = useState<boolean>(true)

  const getImages = useCallback((listingKey: string) => {
    if (!listingKey) { return }
    setImagesLoading(true)

    const imageFields: IField[] = [{ 'listing_image': ['resource_key', 'media_list'] }]
    const imageFilters = [{
      field: 'listing_keys',
      operator: '=',
      value: listingKey
    }]

    requestData({
      url: LISTINGS_IMAGES_URL,
      method: 'get',
      filters: imageFilters,
      fields: imageFields
    }).then(({data}) => {
      const imageUrls = data[0].mediaList.map((url: string) => `${url}${IMAGE_PARAMS}`)
      setImages(imageUrls)
      setImagesLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey])


  const contextVal: IImagesContext = {
    getImages,
    images,
    imagesLoading,
  }

  return (
    <ImagesContext.Provider value={contextVal}>
      {children}
    </ImagesContext.Provider>
  )
}
