import React from 'react'
import styled from 'styled-components'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { Dropdown, IDropdownOption } from '../common/Dropdown'
import { NativeDropdown } from '../common/NativeDropdown'
import { TextSmall } from '../common/Text'
import { COLORS, SEARCH_ALERT_FREQUENCY_OPTIONS } from '../../constants'
import { IAlertFrequencyDropdown } from './types'

const FrequencyDropdown = styled.div`
  button {
    color: ${COLORS.darkBlue};
  }
`

const StyledTextSmall = styled(TextSmall)`
  display: block;
  margin-bottom: 6px;
`

export const AlertFrequencyDropdown: React.FC<IAlertFrequencyDropdown> = ({
  selectedFrequency,
  setSelectedFrequency,
  searchId,
  className
}) => {

  const { mobile } = useBreakpoint()
  const ResponsiveDropdown = mobile ? NativeDropdown : Dropdown

  const selectOption = (option: IDropdownOption) => {
    setSelectedFrequency(option, searchId)
  }

  return (
    <FrequencyDropdown className={className}>
      <StyledTextSmall as="label" color={COLORS.darkBlue} className="frequency-dropdown-label">
        Email notifications
      </StyledTextSmall>
      <ResponsiveDropdown
        options={SEARCH_ALERT_FREQUENCY_OPTIONS}
        selectedLabel={selectedFrequency.label}
        displayOptionState={true}
        setSelected={selectOption}
        triggerTestId="select-listing-status"
        optionsMaxHeight="250px"
        optionsOverflow="scroll"
        containerClassName="alert-frequency-dropdown"
      />
    </FrequencyDropdown>
  )
}
