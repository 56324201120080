import styled, { keyframes } from "styled-components"
import { white } from "../../../constants"
import { ILoadingSpinner } from './index'

const rotation = keyframes`{
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
}`

const expandConstrict = keyframes`{
  0%,
  25% {
    stroke-dashoffset: 265;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 265;
    transform: rotate(360deg);
  }
}`

const StyledSpinner = styled.div`
  text-align: center;
`

const StyledSvg = styled.svg<{width: string}>`
  animation: ${rotation} 2s linear infinite;
  display: block;
  margin: auto;
  width: ${props => props.width};
`

const StyledCircle = styled.circle`
  animation: ${expandConstrict} 1.7s ease-in-out infinite both;
  display: block;
  fill: transparent;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 265;
  stroke-width: 8px;
  transform-origin: 50% 50%;
`

export const LoadingSpinner: React.FC<ILoadingSpinner> = ({width='40px', color=white, children=null}) => {
  return (
    <StyledSpinner data-testid="loading-spinner" className="loading-spinner">
      <StyledSvg
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
      >
        <StyledCircle cx="50" cy="50" r="45" {...{ stroke: color }} />
      </StyledSvg>
      {children}
    </StyledSpinner>
  )
}