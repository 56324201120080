import { captureException } from '@sentry/react'
import { ABTEST_SERVICE_URL } from '../../constants'
import { requestData } from '../requestData'
import { getParamObjects } from '../parseParams'

export const fetchAbTest = (visitorId: number | null, experimentName: string) => {
  const urlParams = getParamObjects(window.location.search)
  if (urlParams[experimentName]) {
    return Promise.resolve(urlParams[experimentName])
  }

  return requestData({
    url: ABTEST_SERVICE_URL,
    method: 'post',
    includeBearerToken: true,
    ignoreJsonFormat: true,
    body: {
      visitor_id: visitorId,
      experiment_name: experimentName
    },
  }).then(({ data }) => {
    const alternative = data?.data?.variant
    return alternative || 'control'
  }).catch(err => {
    console.warn('error calling abTestService', err)
    captureException(err)
  })
}
