import { useRef } from 'react'
import { Helmet } from 'react-helmet'
import { StyledBody, Title, BodyLeft } from '../common/PageBody'
import styled from 'styled-components'
import { BREAKPOINT_S, BREAKPOINT_M, coolGray50, darkBlue } from '../../constants'
import { XIcon } from '../icons/XIcon'
import { SavedPropertySearchListView } from '../SavedPropertySearch'
import { NoSavedHomes } from '../NoResults'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { useNavigate } from 'react-router-dom'

const SavedSearchesBody = styled(BodyLeft)`
  &.full-width {
    @media (max-width: ${BREAKPOINT_S}px) {
      padding: 0;
    }
  }
`

const BackNav = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 16px;

  @media (max-width: ${BREAKPOINT_S}px) {
    border-bottom: solid 1px ${coolGray50};
  }

  svg {
    width: 14px;
    height: 14px;
    fill: ${darkBlue};
    cursor: pointer;
    float: right;
  }
`

const StyledTitleGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINT_S}px) {
    margin: 0 16px;
  }

  h1 {
    flex: 0 0 100%;
    font-size: 24px;
    @media (min-width: ${BREAKPOINT_M}px) {
      flex: 1;
      font-size: 30px;
    }
  }
`

const StyledTitle = styled(Title)`
  border-bottom: 1px solid ${coolGray50};
`

type SavedPropertySearchesContentMap = {
  [savedPropertySearches: string]: string
}

interface ITypeContentMapping {
  title: SavedPropertySearchesContentMap
  linkPath: SavedPropertySearchesContentMap
  meta: SavedPropertySearchesContentMap
}

const contentByType: ITypeContentMapping = {
  title: {
    savedPropertySearches: 'Saved searches'
  },
  linkPath: {
    savedPropertySearches: 'saved-property-searches'
  },
  meta: {
    savedPropertySearches: 'Saved property searches on HomeLight'
  }
}

export const SavedPropertySearchesBody: React.FC<{ listViewType: string }> = ({ listViewType }) => {
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const { mobile } = useBreakpoint()
  return (
    <StyledBody data-testid="saved-properties-search">
      <Helmet>
        <title>{`${contentByType.title[listViewType]} | HomeLight`}</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOMELIGHT_REPO_URL}/homes/saved-property-searches`}
        />
        <meta name="description" content={contentByType.meta[listViewType]} />
      </Helmet>
      <SavedSearchesBody
        id="properties-body"
        className="full-width"
        data-testid="properties-body-left"
        ref={ref}
      >
        {mobile && (
          <BackNav className="back-nav">
            {/* @ts-ignore */}
            <div onClick={navigate(-1)}>
              <XIcon />
            </div>
          </BackNav>
        )}
        <StyledTitleGroup>
          <StyledTitle>{contentByType.title[listViewType]}</StyledTitle>
        </StyledTitleGroup>
        <SavedPropertySearchListView
          NoResultsView={() => <NoSavedHomes type="savedSearch" showSearchBar={true} />}
        />
      </SavedSearchesBody>
    </StyledBody>
  )
}
