export const PRICE_OPTIONS = [
  { label: '$50K', value: 50000 },
  { label: '$100K', value: 100000 },
  { label: '$150K', value: 150000 },
  { label: '$200K', value: 200000 },
  { label: '$250K', value: 250000 },
  { label: '$300K', value: 300000 },
  { label: '$350K', value: 350000 },
  { label: '$400K', value: 400000 },
  { label: '$450K', value: 450000 },
  { label: '$500K', value: 500000 },
  { label: '$550K', value: 550000 },
  { label: '$600K', value: 600000 },
  { label: '$650K', value: 650000 },
  { label: '$700K', value: 700000 },
  { label: '$750K', value: 750000 },
  { label: '$800K', value: 800000 },
  { label: '$850K', value: 850000 },
  { label: '$900K', value: 900000 },
  { label: '$950K', value: 950000 },
  { label: '$1M', value: 1000000 },
  { label: '$1.25M', value: 1250000 },
  { label: '$1.5M', value: 1500000 },
  { label: '$1.75M', value: 1750000 },
  { label: '$2M', value: 2000000 },
  { label: '$2.25M', value: 2250000 },
  { label: '$2.5M', value: 2500000 },
  { label: '$2.75M', value: 2750000 },
  { label: '$3M', value: 3000000 },
  { label: '$3.25M', value: 3250000 },
  { label: '$3.5M', value: 3500000 },
  { label: '$3.75M', value: 3750000 },
  { label: '$4M', value: 4000000 },
  { label: '$4.25M', value: 4250000 },
  { label: '$4.5M', value: 4500000 },
  { label: '$4.75M', value: 4750000 },
  { label: '$5M', value: 5000000 },
  { label: '$6M', value: 6000000 },
  { label: '$7M', value: 7000000 },
  { label: '$8M', value: 8000000 },
  { label: '$9M', value: 9000000 },
  { label: '$10M', value: 10000000 }
]
