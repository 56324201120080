import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Body } from '../common/Modal'
import { XIcon } from '../icons/XIcon'
import { DrawnArrowIcon } from '../icons/DrawnArrowIcon'
import { BREAKPOINT_M, BREAKPOINT_XL, white } from '../../constants'
import { Button } from '@homelight/hl-core-ui'
import { MortgageModalProps } from './types'
import { SparklesIcon } from '../icons/SparklesIcon'
import { getBreakpoint } from '../../utils/getBreakpoint'
import { ACTION_VIEW, trackEvent } from '../../utils/tracking'
import { ULTRA_ONBOARDING_VIEW } from '../../constants/events'

const PromptWrapper = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${white};
  background-color: transparent;
  border-radius: 12px;
  z-index: 7;
  background-color: rgba(39, 54, 83, 0.85);
  height: auto;
  width: 470px;
  padding-bottom: 32px;

  ${({ isDesktop }) =>
    isDesktop
      ? ''
      : css`
          margin: 0 24px;
        `}
`

const ArrowWrapper = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT_XL}px) {
    display: block;
    position: absolute;
    z-index: 6;
    left: 420px;
    top: 580px;
  }
  @media (max-height: 900px) {
    svg {
      transform: rotate(148deg) scaleX(-1);
    }
  }
`

const Header = styled.div`
  height: 36px;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 6px;
  cursor: pointer;
`

const StyledBody = styled(Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  text-align: center;

  @media (min-width: ${BREAKPOINT_M}px) {
    padding: 0 32px;
  }
  svg {
    fill: #f1b707;
  }
`

const Title = styled.h4`
  color: ${white};
  font-size: 16px;
  line-height: 24px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 12px;
  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const Message = styled.p`
  color: ${white};
  margin: 8px auto 20px;
  line-height: 24px;
`

export const UnlockFreeTrialContainer = styled.div<{ isDesktop?: boolean }>`
  background-color: ${white};
  border-radius: 12px;
  position: relative;
  ${({ isDesktop }) => (isDesktop ? '' : 'width: 100%;')}
`

export const UnlockMortgagePrompt: React.FC<MortgageModalProps> = ({
  closeModal,
  onStartFlow,
  onCloseFreeTrialModal
}) => {
  const [unlockFreeTrialIsVisible, setUnlockFreeTrialVisible] = useState(false)
  const arrowVisible = window.innerWidth >= BREAKPOINT_XL

  const isDesktop = ['lg', 'xl'].includes(getBreakpoint(window.innerWidth))

  useEffect(() => {
    // @ts-ignore
    trackEvent(ACTION_VIEW, ULTRA_ONBOARDING_VIEW)
  }, [])

  return (
    <>
      <PromptWrapper isDesktop={isDesktop}>
        <Header>
          <IconWrapper onClick={closeModal}>
            <XIcon color={white} />
          </IconWrapper>
        </Header>
        <StyledBody>
          <SparklesIcon width={35} height={35} />
          <Title>Your search has ultra-low rate properties!</Title>
          <Message>
            Look out for homes with {arrowVisible ? 'these ' : ''}blue banners to access the best
            rates in your area. Unlock these competitive properties with your free 3-day trial.
          </Message>
          <Button
            onClick={() => {
              onStartFlow?.()
              setTimeout(() => setUnlockFreeTrialVisible(true), 100)
            }}
          >
            Unlock Free Trial
          </Button>
        </StyledBody>
      </PromptWrapper>
      <ArrowWrapper>
        <DrawnArrowIcon />
      </ArrowWrapper>
    </>
  )
}
