import { SEVEN_DAYS_AGO } from '../utils/getDateInPast'

export const LISTING_STATUS_ACTIVE_FILTER_VALUE = 'Active'
export const LISTING_STATUS_COMING_SOON_FILTER_LABEL = 'Coming soon'
export const LISTING_STATUS_COMING_SOON_FILTER_VALUE = 'Coming Soon'
export const LISTING_STATUS_PENDING_FILTER_VALUE = 'Pending'
export const LISTING_STATUS_CONTINGENT_FILTER_VALUE = 'Contingent,Active Under Contract'
export const LISTING_STATUS_CONTINGENT_LABEL_VALUE = 'Contingent'
export const LISTING_STATUS_ACTIVE_COMING_SOON_FILTER_VALUE = 'Active,Coming Soon'
export const LISTING_STATUS_SOLD_FILTER_VALUE = 'Closed'
export const DEFAULT_LISTING_STATUS_FILTER = {
  label: 'Active + Coming soon',
  value: LISTING_STATUS_ACTIVE_COMING_SOON_FILTER_VALUE
}

export const LISTING_STATUS_OPTIONS = [
  DEFAULT_LISTING_STATUS_FILTER,
  {
    label: LISTING_STATUS_ACTIVE_FILTER_VALUE,
    value: LISTING_STATUS_ACTIVE_FILTER_VALUE
  },
  {
    label: LISTING_STATUS_COMING_SOON_FILTER_LABEL,
    value: LISTING_STATUS_COMING_SOON_FILTER_VALUE
  },
  {
    label: LISTING_STATUS_PENDING_FILTER_VALUE,
    value: LISTING_STATUS_PENDING_FILTER_VALUE
  },
  {
    label: LISTING_STATUS_CONTINGENT_LABEL_VALUE,
    value: LISTING_STATUS_CONTINGENT_FILTER_VALUE
  }
]

export const DEFAULT_LISTING_STATUS_FILTER_VALUE = DEFAULT_LISTING_STATUS_FILTER.value
export const LISTING_STATUS_SOLD_FILTER = {
  label: 'Closed',
  value: LISTING_STATUS_SOLD_FILTER_VALUE
}

export const DEFAULT_DATE_LISTED_OPTION = {
  label: 'Any date',
  value: ''
}
export const DATE_LISTED_OPTIONS = [
  DEFAULT_DATE_LISTED_OPTION,
  {
    label: 'Last 7 days',
    value: SEVEN_DAYS_AGO
  }
]
