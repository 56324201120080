import { IIconProps } from '../types'

export const SearchIcon: React.FC<IIconProps> = ({
  fill
}) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
    <title>Search</title>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.7824 13.8354L12.6666 10.7195C12.5259 10.5789 12.3353 10.5008 12.1353 10.5008H11.6259C12.4884 9.39756 13.001 8.00996 13.001 6.50048C13.001 2.90959 10.0914 0 6.50048 0C2.90959 0 0 2.90959 0 6.50048C0 10.0914 2.90959 13.001 6.50048 13.001C8.00996 13.001 9.39756 12.4884 10.5008 11.6259V12.1353C10.5008 12.3353 10.5789 12.5259 10.7195 12.6666L13.8354 15.7824C14.1292 16.0762 14.6042 16.0762 14.8948 15.7824L15.7793 14.898C16.073 14.6042 16.073 14.1292 15.7824 13.8354ZM6.50048 10.5008C4.29094 10.5008 2.50018 8.71314 2.50018 6.50048C2.50018 4.29094 4.28781 2.50018 6.50048 2.50018C8.71001 2.50018 10.5008 4.28781 10.5008 6.50048C10.5008 8.71001 8.71314 10.5008 6.50048 10.5008Z" fill={`${fill ? fill : '#273653'}`}/>
  </svg>
)
