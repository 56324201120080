import {useState, useEffect, useContext} from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { IPropertiesContext } from "../../contexts/PropertiesContext";
import { PropertiesSitemapContext } from "../../contexts/PropertiesSitemapContext";
import { Pagination } from "../common/Pagination";
import { SearchBar } from '../SearchBar'
import { BreadCrumbs } from '../BreadCrumbs'
import { stateNameOf, stateCodeFromSlug } from '../../utils/stateUtils'
import { PROPERTY_AGGREGATION_URL } from '../../constants'
import { requestData } from '../../utils/requestData'
import { toTitleCase } from '../../utils/toTitleCase'
import { propertyPageUrl } from '../../utils/propertyPageUrl'
import { parseCountySlug } from '../../utils/parseAreaSlug'
import { BREAKPOINT_M } from '../../constants'

const HtmlSitemapBodyWrapper = styled.div`
  margin: 96px 16px;

  @media (min-width: ${BREAKPOINT_M}px) {
    margin: 104px 80px;
  }
`

const StyledSearchBar = styled(SearchBar)`
  margin-bottom: 24px;
`

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 36px;
`

const SubTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 20px;
`

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
`

const ListItem = styled.li`
  text-decoration: none;
  margin-bottom: 12px;
  width: 100%;

  @media (min-width: ${BREAKPOINT_M}px) {
    width: ${props => 100 / props.columns}%;
  }
`

const Link = styled.a`
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }
`

const Section = styled.div`
  margin-bottom: 32px;
`

export const HtmlSitemapBody = ({ areaSlug, pageType }) => {
  const paginationData = useContext(PropertiesSitemapContext) as IPropertiesContext
  const {
    properties: {
      propertiesData
    }
  } = paginationData
  const [citiesData, setCitiesData] = useState([])
  const [countiesData, setCountiesData] = useState([])
  const [zipCodeData, setZipCodeData] = useState([])

  let stateCode: string = ''
  let pageAreaDisplayName: string = ''
  let pageTitle: string = ''
  let pageMeta: string = ''
  if (pageType === 'county') {
    stateCode = stateCodeFromSlug(areaSlug)
    pageAreaDisplayName = `${parseCountySlug(areaSlug).county} County`
    pageTitle = `Properties in ${pageAreaDisplayName}, ${stateCode} | HomeLight`
    pageMeta = `Search for properties in ${pageAreaDisplayName}, ${stateNameOf(stateCode)}`
  } else {
    stateCode = areaSlug.toUpperCase()
    pageAreaDisplayName = stateNameOf(stateCode) as string
    pageTitle = `Properties in ${stateCode} | HomeLight`
    pageMeta = `Search for properties in ${pageAreaDisplayName}`
  }

  const getPropertyAggregations = (areaSlug, pageType, areaType, setData) => {
    const skipArea = ['CARDIFF BY THE SEA NEW']
    if (pageType === areaType) { return }

    const filters = [
      {
        field: 'state_or_province',
        value: stateCode,
        operator: '='
      },
      {
        field: '_listing_count_in_area',
        value: true,
        operator: '='
      },
      {
        field: '_area_type',
        value: areaType,
        operator: '='
      },
      {
        field: 'listing_mls_status',
        value: 'Active',
        operator: '='
      }
    ]

    if (pageType === 'county') {
      filters.push(
        {
          field: 'county_or_parish',
          value: pageAreaDisplayName,
          operator: '='
        }
      )
    }

    requestData({
      url: PROPERTY_AGGREGATION_URL,
      method: 'get',
      filters: filters,
      size: 500,
      ignoreJsonFormat: true,
      includeBearerToken: true,
      usePagination: true
    }).then(({ data, error }) => {
      if (!error) {
        const reduced = data?.data?.reduce((result, area) => {
          if (skipArea.includes(area.attributes.key)) {
            return result
          }

          if (area.attributes.count > 3) {
            result.push(area.attributes.key)
          }

          return result
        }, [])
        setData(reduced)
      }
    })
  }

  useEffect(() => {
    if (!areaSlug) {
      return
    }

    getPropertyAggregations(areaSlug, pageType, 'city', setCitiesData)
    getPropertyAggregations(areaSlug, pageType, 'county_or_parish', setCountiesData)
    getPropertyAggregations(areaSlug, pageType, 'postal_code', setZipCodeData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSlug, pageType])

  return (
    <HtmlSitemapBodyWrapper data-testid="html-sitemap">
      <Helmet>
        <title>{pageTitle}</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOMELIGHT_REPO_URL}/homes/${areaSlug}`}
        />
        <meta name="description" content={pageMeta} />
      </Helmet>
      <StyledSearchBar navigateOnSearch={true} />
      <BreadCrumbs showForSale={false} />
      <Title>Homes in {pageAreaDisplayName}</Title>
      {propertiesData?.length > 0 && (
        <>
          <Section>
            <SubTitle>Homes in {pageAreaDisplayName}</SubTitle>
            <List id="properties-body">
              {propertiesData.map(property => {
                const link = propertyPageUrl(
                  property.formattedCountyOrParish,
                  property.formattedStateOrProvince,
                  property.hlFullAddress,
                  property.formattedCity,
                  property.formattedPostalCode,
                  property.propertyUuidFormatted
                )
                const text = `${toTitleCase(
                  `${property.hlFullAddress}, ${property.formattedCity}`
                )}, ${property.formattedStateOrProvince.toUpperCase()} ${
                  property.formattedPostalCode
                }`

                return (
                  <ListItem columns={2} key={property.propertyUuidFormatted}>
                    <Link href={link} target="_blank">
                      {text}
                    </Link>
                  </ListItem>
                )
              })}
            </List>
          </Section>
          <Pagination paginationData={paginationData} />
        </>
      )}

      {pageType === 'state' && countiesData?.length > 0 && (
        <Section>
          <SubTitle>Homes in {pageAreaDisplayName} counties</SubTitle>
          <List>
            {countiesData.map(county => {
              const slug = `${county} ${stateCode}`.replace(/ /g, '-').toLowerCase()
              const link = `/homes/${slug}`
              return (
                <ListItem columns={4} key={slug}>
                  <Link href={link} target="_blank">{`${county}, ${stateCode}`}</Link>
                </ListItem>
              )
            })}
          </List>
        </Section>
      )}

      {citiesData?.length > 0 && (
        <Section>
          <SubTitle>Homes in {pageAreaDisplayName} cities</SubTitle>
          <List>
            {citiesData.map(city => {
              const slug = `${city} ${stateCode}`.replace(/ /g, '-').toLowerCase()
              const link = `/${slug}/homes-for-sale`
              return (
                <ListItem columns={4} key={slug}>
                  <Link href={link} target="_blank">{`${toTitleCase(city)}, ${stateCode}`}</Link>
                </ListItem>
              )
            })}
          </List>
        </Section>
      )}

      {zipCodeData?.length > 0 && (
        <Section>
          <SubTitle>Homes in {pageAreaDisplayName} zip codes</SubTitle>
          <List>
            {zipCodeData.map(zipCode => {
              const link = `/homes-for-sale/zip/${zipCode}-${stateCode.toLowerCase()}`
              return (
                <ListItem columns={4} key={zipCode}>
                  <Link href={link} target="_blank">
                    {zipCode}
                  </Link>
                </ListItem>
              )
            })}
          </List>
        </Section>
      )}
    </HtmlSitemapBodyWrapper>
  )
}
