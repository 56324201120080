import styled from 'styled-components'

const EllipsesContainer = styled.div`
  width: 32px;
  height: 32px;
  text-align: center;
`

export const Ellipses: React.FC = () => {
  return (
    <EllipsesContainer>...</EllipsesContainer>
  )
}