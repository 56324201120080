import { createContext, useEffect, useState } from 'react'
import { ILocationContext, ILocationProvider, IForSaleOrSoldTitle, IFaqData } from './types'
import geolocator from 'geolocator'
import { requestData } from '../../utils/requestData'
import { NEARBY_AREAS_URL, PROPERTY_FAQ_URL } from '../../constants'
import { IField } from '../../utils/buildRequestFields'
import { IFilter } from '../../components/Filters'
import { stateCodeOf } from '../../utils/stateUtils'
import { ISearchLocation } from '../../utils/autocompleteRequest'
import { parseAreaSlug } from '../../utils/parseAreaSlug'
import { dasherize } from '../../utils/dasherize'

export const LocationContext = createContext<ILocationContext | null>(null)
export const DEFAULT_HEADER = 'Everything you need to buy or sell a home'
const DEFAULT_COUNTRY = 'United States'
const DEFAULT_COORDINATES = [-98, 38.5]
const MAX_META_LOCATION_LENGTH = 15

export const LocationProvider: React.FC<ILocationProvider> = ({
  areaSlug,
  children,
  pageType,
  bedroomCount
}) => {
  const [latitudeFromIP, setLatitudeFromIP] = useState(DEFAULT_COORDINATES[1])
  const [longitudeFromIP, setLongitudeFromIP] = useState(DEFAULT_COORDINATES[0])
  const [cityFromIP, setCityFromIP] = useState('')
  const [stateFromIP, setStateFromIP] = useState('')
  const [countryFromIP, setCountryFromIP] = useState(DEFAULT_COUNTRY)
  const [county, setCounty] = useState('')
  const [countySlug, setCountySlug] = useState('')
  const [resultsTitle, setResultsTitle] = useState(DEFAULT_HEADER)
  const [showMoreSearches, setShowMoreSearches] = useState(false)
  const [nearbyCities, setNearbyCities] = useState([]) as any
  const [nearbyCounties, setNearbyCounties] = useState([]) as any
  const [nearbyZips, setNearbyZips] = useState([]) as any
  const [currentSlug, setCurrentSlug] = useState(areaSlug || '')
  const [forSaleOrSoldTitle, setForSaleOrSoldTitle] =
    useState<IForSaleOrSoldTitle>('Homes for sale in')
  const [locationTitle, setLocationTitle] = useState('')
  const [locationDescription, setLocationDescription] = useState('')
  const [searchAreaType, setSearchAreaType] = useState('')
  const [searchLocation, setSearchLocation] = useState<ISearchLocation[]>([])
  const [areaType, setAreaType] = useState<string>('')
  const [faqData, setFaqData] = useState<IFaqData>({})
  const pageTypeTitleMapping = {
    condo: 'Condos for Sale',
    sfh: 'Single Family Homes for Sale',
    townhome: 'Townhomes for Sale',
    mobile: 'Mobile Homes for Sale',
    bedroom: `${bedroomCount} Bedroom Homes for Sale`,
    newest: 'Newest Listings',
    sold: 'Recently Sold Homes'
  }
  const [currentShape, setCurrentShape] = useState([])

  const slugFromCityState = () => {
    const city = cityFromIP?.toLowerCase().split(' ').join('-')
    const stateCode = stateCodeOf(stateFromIP)
    const state = stateCode?.toLowerCase().split(' ').join('-')
    let slug = city && state ? `${city}-${state}` : ''

    return slug
  }

  const formatCountyName = name => (name.toLowerCase().includes('county') ? name : `${name} County`)

  const getSearchArea = () => {
    let searchAreaType = ''
    const slug = currentSlug || areaSlug

    if (slug?.length) {
      const { city, state, county, zip } = parseAreaSlug(slug)
      const stateCode = stateCodeOf(state)

      if (city.length || state.length) {
        setCityStateLocations(city, state)
        searchAreaType = city.length ? 'city' : 'state'
      }
      if (county.length && state.length) {
        setCountyLocations(county, state)
        searchAreaType = 'county'
      }
      if (zip.length) {
        setAreaType('zip')
        setLocationTitle(stateCode ? `${zip}, ${stateCode}` : zip)
        searchAreaType = 'zip'
        setMetaDescription(zip, stateCode)
      }
    } else {
      geolocator.locateByIP({}, setGeolocatorResults)
    }

    return searchAreaType
  }

  const buildMetaDescription = (locationText: string, listingDetailsText: string) => {
    return `Find homes for sale in ${locationText}. Search by property type, price, rooms, and more. ${listingDetailsText}`
  }

  const setMetaDescription = (location: string | null, stateCode: string | null) => {
    if (!location || !stateCode || !areaType) {
      return
    }

    let getListingsText: string = ''
    let locationText: string = location

    if (areaType === 'state') {
      getListingsText = `Get listing and pricing details for ${stateCode} real estate on HomeLight.`
    } else if (
      areaType === 'zip' ||
      (['county', 'city'].includes(areaType) && location.length <= MAX_META_LOCATION_LENGTH)
    ) {
      getListingsText = `Get listing and pricing details for ${location} real estate on HomeLight.`
      locationText = `${location}, ${stateCode}`
    } else {
      getListingsText = `Get listing details for ${location}, ${stateCode} real estate.`
    }

    setLocationDescription(buildMetaDescription(locationText, getListingsText))
  }

  const setCityStateLocations = (city: string, state: string | null) => {
    const stateCode = stateCodeOf(state)
    let locationTitle: string = ''

    if (state) {
      setStateFromIP(state)
    }
    setCityFromIP(city)

    if (city && stateCode) {
      setAreaType('city')
      locationTitle += city.length <= MAX_META_LOCATION_LENGTH ? `${city}, ${stateCode}` : `${city}`

      setMetaDescription(city, stateCode)
    } else if (!city && stateCode) {
      setAreaType('state')
      locationTitle += `${state}`
      setMetaDescription(state, stateCode)
    }

    setLocationTitle(locationTitle)
  }

  const setCountyLocations = (county: string, state: string | null) => {
    const stateCode = stateCodeOf(state)
    const formattedCounty = formatCountyName(county)
    const locationTitle: string =
      formattedCounty.length <= MAX_META_LOCATION_LENGTH
        ? `${formattedCounty}, ${stateCode}`
        : `${formattedCounty}`

    setCountySlug(areaSlug)
    setCounty(formattedCounty)
    setAreaType('county')

    if (state) {
      setStateFromIP(state)
    }

    setLocationTitle(locationTitle)
    setMetaDescription(formattedCounty, stateCode)
  }

  useEffect(() => {
    const title = pageTypeTitleMapping[pageType]
    if (title) {
      setResultsTitle(locationTitle ? `${locationTitle} ${title}` : title)
    } else if (!forSaleOrSoldTitle || !locationTitle) {
      setResultsTitle(DEFAULT_HEADER)
    } else if (stateFromIP && stateFromIP !== locationTitle) {
      const stateCode = stateCodeOf(stateFromIP)
      setResultsTitle(
        `${forSaleOrSoldTitle} ${locationTitle}${
          locationTitle?.includes(stateCode || '') ? '' : `, ${stateCode}`
        }`
      )
    } else {
      setResultsTitle(`${forSaleOrSoldTitle} ${locationTitle}`)
    }
  }, [forSaleOrSoldTitle, locationTitle, pageType, pageTypeTitleMapping, stateFromIP])

  useEffect(() => {
    const city = searchLocation[0]?.city.replace(/\./g, '')
    const state = searchLocation[0]?.state
    const stateCode = stateCodeOf(state)

    if (!searchLocation?.[0]?.city || !searchLocation?.[0]?.state) {
      return
    }

    setCityFromIP(city)
    setStateFromIP(state)
    setCurrentSlug(dasherize(`${city} ${stateCode}`))
    const countyName = searchLocation[0].county ? formatCountyName(searchLocation[0].county) : ''
    setCounty(countyName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLocation])

  const setGeolocatorResults = (err: any, result: any) => {
    if (result?.coords.longitude && result?.coords.latitude) {
      setLatitudeFromIP(result.coords.latitude)
      setLongitudeFromIP(result.coords.longitude)
    }
    if (result?.address.city && result?.address.state) {
      setStateFromIP(result.address.state)
      setCityFromIP(result.address.city)
      setCountryFromIP(result.address.country)
      const stateCode = stateCodeOf(result.address.state)
      const locationTitle: string = `${result.address.city}${stateCode ? `, ${stateCode}` : ''}`
      setLocationTitle(locationTitle)
      setCurrentSlug(`${result.address.city.replace(' ', '-')}-${stateCode}`)
      setAreaType('city')
    }
  }

  const getAreas = (targetAreaType: string, searchAreaType: string) => {
    const slug = currentSlug || slugFromCityState()
    let fields: IField[]
    let filters: IFilter[]
    const targetAreaTypeMapping = {
      nearbyCities: 'place',
      nearbyCounties: 'county',
      nearbyZips: 'zip_code',
      county: 'county' // special case to determine which county a smaller area is within
    }

    if (!slug || !slug.length) {
      return
    }

    if (targetAreaType === 'singleShapeQuery') {
      fields = []
      filters = [
        { field: 'area_slug', value: slug, operator: '' },
        { field: '_single_shape_query', value: true, operator: '' }
      ]
    } else {
      fields = [{ geo_shape: ['display_name', 'area_slug', 'name'] }]
      const shapeType = (targetAreaTypeMapping as any)[targetAreaType]
      filters = [
        { field: 'area_slug', value: slug, operator: '' },
        { field: 'search_shape_type', value: shapeType, operator: '' },
        { field: 'geo_type', value: shapeType, operator: '' }
      ]
    }
    // This is no longer valid.
    // if (targetAreaType === 'county' && searchAreaType !== 'zip') {
    //   filters.push({ field: '_slug_to_coordinates', value: 'true', operator: '' })
    // }

    const size = targetAreaType === 'county' || targetAreaType === 'singleShapeQuery' ? 1 : 20

    requestData({
      url: NEARBY_AREAS_URL,
      method: 'get',
      filters: filters,
      fields: fields,
      page: 1,
      size: size,
      usePagination: true
    }).then(({ data, error }) => {
      if (!error) {
        const dataAsArray = Array.isArray(data) ? data : [data]

        if (targetAreaType === 'nearbyCities') {
          setNearbyCities(dataAsArray)
        } else if (targetAreaType === 'nearbyCounties') {
          setNearbyCounties(dataAsArray)
        } else if (targetAreaType === 'nearbyZips') {
          setNearbyZips(dataAsArray)
        } else if (targetAreaType === 'county') {
          setCountySlug(dataAsArray[0]?.areaSlug)
          setCounty(dataAsArray[0]?.name ? formatCountyName(dataAsArray[0].name) : '')
        } else if (targetAreaType === 'singleShapeQuery') {
          setCurrentShape(dataAsArray[0]?.geoShape?.coordinates)
        }
      }
    })
  }

  useEffect(() => {
    const searchAreaType = getSearchArea()
    setSearchAreaType(searchAreaType)

    if (searchAreaType === 'state') {
      return
    }

    if (searchAreaType !== 'county') {
      getAreas('county', searchAreaType)
    }

    if (!showMoreSearches) {
      return
    }

    getAreas('nearbyCities', searchAreaType)
    getAreas('nearbyCounties', searchAreaType)
    if (searchAreaType !== 'city') {
      getAreas('nearbyZips', searchAreaType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityFromIP, stateFromIP, areaSlug, showMoreSearches, searchAreaType])

  useEffect(() => {
    getAreas('singleShapeQuery')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlug])

  const getFaqData = currentSlug => {
    if (!currentSlug) {
      return {}
    }

    const filters = [
      {
        field: 'slug',
        value: currentSlug,
        operator: '='
      }
    ]

    requestData({
      url: `${PROPERTY_FAQ_URL}?slug=${currentSlug}`,
      method: 'get',
      filters: filters,
      ignoreJsonFormat: true,
      includeBearerToken: true,
      usePagination: false
    }).then(({ data, error }) => {
      if (!error) {
        const camelCaseData: IFaqData = {
          medianPrice: data?.median_price,
          medianPricePercentChange: data?.median_price_percent_change,
          salesToListRatio: data?.list_to_sell_ratio,
          activeListings: data?.active_listings,
          availablePropertyTypes: data?.available_property_types,
          averageDaysToClose: data?.days_on_market,
          averageDaysToCloseDaysDifference: data?.days_on_market_change,
          soldHomes: data?.sold_homes,
          citiesInState: data?.cities_in_state,
          countiesInState: data?.counties_in_state,
          citiesInCounty: data?.cities_in_county,
          zipCodesInCounty: data?.zip_codes_in_county,
          zipCodesInCity: data?.zip_codes_in_city,
          citiesNearCity: data?.cities_near_city,
          zipCodesNearZipCode: data?.zip_codes_near_zip_code,
          numberAgents: data?.number_agents
        }
        setFaqData(camelCaseData)
      }
    })
  }

  const contextVal: ILocationContext = {
    latitudeFromIP,
    longitudeFromIP,
    cityFromIP,
    stateFromIP,
    countryFromIP,
    resultsTitle,
    showMoreSearches,
    setShowMoreSearches,
    nearbyCities,
    nearbyCounties,
    nearbyZips,
    currentSlug,
    setLatitudeFromIP,
    setLongitudeFromIP,
    setCityFromIP,
    setStateFromIP,
    setCountryFromIP,
    setResultsTitle,
    setCurrentSlug,
    setForSaleOrSoldTitle,
    locationTitle,
    setLocationTitle,
    searchAreaType,
    searchLocation,
    setSearchLocation,
    county,
    countySlug,
    areaType,
    currentShape,
    locationDescription,
    getFaqData,
    faqData
  }

  return <LocationContext.Provider value={contextVal}>{children}</LocationContext.Provider>
}
