import styled from 'styled-components'
import {
  darkBlue,
  coolGray40,
  whiteHover
} from '../../../../constants'
import { TransparentButton } from '../../Button'
import { CaretLeft } from '../../../icons/CaretLeft'
import { CaretRight } from '../../../icons/CaretRight'

const PrevNext = styled(TransparentButton)<{active: boolean}>`
  width: 32px;
  height: 32px;
  margin: 0 8px;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: ${props => props.active ? 'pointer' : '' };
  pointer-events: ${props => props.active ? '' : 'none' };
  border-radius: 16px;
  color: ${props => props.active ? darkBlue : coolGray40 };

  &:hover {
    background-color: ${whiteHover};
  }
  &:focus-visible {
    background-color: ${whiteHover};
  }
`

interface IPrevNextButton {
  useAsPrev?: boolean,
  active: boolean,
  changePage: () => void
}

export const PrevNextButton: React.FC<IPrevNextButton> = ({
  useAsPrev=false,
  active,
  changePage
}) => {
  const fill = active ? darkBlue : coolGray40

  return (
    <PrevNext
      role="link"
      active={active}
      disabled={!active}
      onClick={changePage}
    >
      {useAsPrev ? 
        <CaretLeft fill={fill} /> :
        <CaretRight fill={fill} />
      }
    </PrevNext>
  )
}