import React, { useState } from 'react'
import { Fragment } from 'react'

import { TransparentButton } from '../../../Button'
import { Avatar } from '../../../Avatar'
import { getAgentNavOptions, getUserNavOptions, LabelContainer } from '../../ApplicationNavigation'
import { IUserOptionProps } from './types'
import { fontWeightBold } from '../../../../../constants'
import { OptionsContainer } from '../MobileNavigation'
import { DropdownCollapsibleOption, DropdownOption } from '../../../Dropdown'
import styled from 'styled-components'
import { trackUserEvent } from '../../../../../utils/trackUserEvent'
import { Flex } from '../../../Flex'
import { TextBold, TextSmall } from '../../../Text'

export const UserOption: React.FC<IUserOptionProps> = ({ currentUser, itemProps }) => {
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const [subHighlightedIndex, setSubHighlightedIndex] = useState(-1)
  const userOptions = getUserNavOptions(currentUser?.type)
  const agentOptions = getAgentNavOptions(currentUser?.type)

  const eventTrack = (key?: string) => {
    if (!key) return

    trackUserEvent({
      event_action: 'home_search_nav_manu',
      result: {
        action: 'click',
        target: key
      }
    })
  }

  return (
    <li>
      {currentUser ? (
        <Fragment>
          <TransparentButton
            padding="0"
            fontWeight={fontWeightBold}
            as="a"
            href={`${process.env.REACT_APP_HOMELIGHT_REPO_URL}`}
            {...itemProps}
          >
            <Flex align="center">
              <Avatar
                name={currentUser.firstName}
                displayFirstName={false}
                chevron={false}
                large={true}
              />
              <Flex ml="8px" direction="column" align="start">
                <TextBold>{`${currentUser.firstName} ${currentUser.lastName}`}</TextBold>
                <TextSmall color={'#8E929C'}>{currentUser?.email}</TextSmall>
              </Flex>
            </Flex>
          </TransparentButton>
          <OptionsContainer>
            {agentOptions && (
              <Fragment>
                {agentOptions?.map((option, index) => {
                  if (option?.subitems?.length) {
                    return (
                      <StyledListItem key={index}>
                        <DropdownCollapsibleOption label={option.label}>
                          {option?.subitems?.map((subitem, i) => (
                            <DropdownOption
                              fontSize="16px"
                              lh={'20px'}
                              padding="12px 0"
                              as="a"
                              href={subitem.link}
                              key={i}
                              target="_blank"
                              onClick={() => eventTrack(subitem.key)}
                              onTouchStart={() => setSubHighlightedIndex(i)}
                              onTouchEnd={() => setSubHighlightedIndex(-1)}
                              onMouseDown={() => setSubHighlightedIndex(i)}
                              onMouseUp={() => setSubHighlightedIndex(-1)}
                              onMouseLeave={() => setSubHighlightedIndex(-1)}
                              isHighlighted={i === subHighlightedIndex}
                            >
                              {subitem.label}
                            </DropdownOption>
                          ))}
                        </DropdownCollapsibleOption>
                      </StyledListItem>
                    )
                  }
                  return (
                    <TransparentButton
                      key={index}
                      padding="9px 32px 9px 0"
                      as="a"
                      href={option.link}
                      onTouchStart={() => setHighlightedIndex(index)}
                      onTouchEnd={() => setHighlightedIndex(-1)}
                      onMouseDown={() => setHighlightedIndex(index)}
                      onMouseUp={() => setHighlightedIndex(-1)}
                      onMouseLeave={() => setHighlightedIndex(-1)}
                      isHighlighted={index === highlightedIndex}
                    >
                      {option.label}
                    </TransparentButton>
                  )
                })}
              </Fragment>
            )}
            {!agentOptions &&
              userOptions.map((option, index) => (
                <TransparentButton
                  key={option.label}
                  padding="9px 32px 9px 0"
                  as="a"
                  href={option.link}
                  onClick={e => (option?.action ? option.action(e) : null)}
                  onTouchStart={() => setHighlightedIndex(index)}
                  onTouchEnd={() => setHighlightedIndex(-1)}
                  onMouseDown={() => setHighlightedIndex(index)}
                  onMouseUp={() => setHighlightedIndex(-1)}
                  onMouseLeave={() => setHighlightedIndex(-1)}
                  isHighlighted={index === highlightedIndex}
                >
                  {option.label}
                </TransparentButton>
              ))}
          </OptionsContainer>
        </Fragment>
      ) : (
        <TransparentButton
          as="a"
          href={`${process.env.REACT_APP_HOMELIGHT_REPO_URL}/client/sign-in?ref=homes`}
          padding="0"
          {...itemProps}
        >
          <LabelContainer colorBlue={true}>
            <span>Sign in</span>
          </LabelContainer>
        </TransparentButton>
      )}
    </li>
  )
}

const StyledListItem = styled.li`
  button {
    width: 100%;
  }
`
