import { useContext, useEffect, useState } from 'react'
import { FooterSection } from './FooterSection'
import { FooterColumn } from './FooterColumn'
import { FooterCompliance } from './FooterCompliance'
import { FooterDataType } from './types'
import styled from 'styled-components'
import { BREAKPOINT_L, BREAKPOINT_S, white } from '../../constants'
import { LocationContext, ILocationContext } from '../../contexts/LocationContext'

const StyledFooter = styled.div`
  position: relative;
  z-index: 3;
  margin: 48px 16px;
  background-color: ${white};

  @media (min-width: ${BREAKPOINT_S}px) {
    margin: 54px 32px;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    max-width: 960px;
    margin: 70px auto;
  }
`

const FooterContent = styled.div`
  margin: auto;
`

const FooterColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`

export const buildTopAgentsData = (
  city: string,
  currentSlug: string,
  nearbyCities: [],
  nearbyCounties: []
): [] => {
  if (!city) return []

  const topAgentFooterData = [
    {
      title: `Find top real estate agents in ${city} and nearby cities`,
      links: []
    },
    {
      title: `Find top real estate agents in counties near ${city}`,
      links: []
    }
  ] as Object[]

  const currentCity = {
    displayName: city,
    areaSlug: currentSlug
  }
  nearbyCities.unshift(currentCity)

  topAgentFooterData[0].links = nearbyCities.map(nearbyCity => {
    return {
      name: nearbyCity.displayName?.split(', ')[0],
      url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/${nearbyCity.areaSlug}/top-real-estate-agents`
    }
  })

  topAgentFooterData[1].links = nearbyCounties.map(nearbyCounty => {
    return {
      name: nearbyCounty.displayName?.split(', ')[0],
      url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/${nearbyCounty.areaSlug}/top-real-estate-agents`
    }
  })

  return topAgentFooterData
}

export const Footer = ({
  areaFooterData,
  defaultFooterData,
  showTopAgentsLinks = true,
}: {
  areaFooterData: FooterDataType[]
  defaultFooterData: FooterDataType[]
  showTopAgentsLinks: boolean
}) => {
  const { cityFromIP, nearbyCities, nearbyCounties, currentSlug } = useContext(
    LocationContext
  ) as ILocationContext
  const [topAgentFooterData, setTopAgentFooterData] = useState([])

  useEffect(() => {
    if (showTopAgentsLinks) {
      let agentsFooterData = buildTopAgentsData(
        cityFromIP,
        currentSlug,
        nearbyCities.slice(0, 5),
        nearbyCounties.slice(0, 6)
      )
      agentsFooterData = agentsFooterData.filter(links => links.links.length > 0)
      setTopAgentFooterData(agentsFooterData)
    }
  }, [cityFromIP, nearbyCities, nearbyCounties, showTopAgentsLinks])

  return (
    <StyledFooter>
      <FooterContent>
        {topAgentFooterData.length > 0 && (
          <FooterColumnContainer>
            {topAgentFooterData.map(footer => (
              <FooterSection
                title={footer.title}
                links={footer.links}
                key={footer.title}
                columnCount={2}
              />
            ))}
          </FooterColumnContainer>
        )}
        {areaFooterData.map(footer => (
          <FooterSection
            title={footer.title}
            links={footer.links}
            key={footer.title}
            columnCount={4}
          />
        ))}

        {defaultFooterData.length > 0 && (
          <FooterColumnContainer>
            {defaultFooterData.map(footer => (
              <FooterColumn title={footer.title} links={footer.links} key={footer.title} />
            ))}
          </FooterColumnContainer>
        )}
        <FooterCompliance />
      </FooterContent>
    </StyledFooter>
  )
}
