import styled from 'styled-components'
import { CaretLeft } from '../icons/CaretLeft'
import { CaretRight } from '../icons/CaretRight'
import { COLORS } from '../../constants'

const ArrowButtonWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  background-color: ${COLORS.white};
  opacity: 0.9;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  
  &:hover {
    opacity: 1;
  }
  
  svg {
    height: 10px;
    fill: ${COLORS.darkBlue};
    pointer-events: none;
  }
`

export const LeftArrow = () => (
  <ArrowButtonWrapper data-interaction='no-link' data-testid='slideshow-arrow-left'>
    <CaretLeft />
  </ArrowButtonWrapper>
)

export const RightArrow = () => (
  <ArrowButtonWrapper data-interaction='no-link' data-testid='slideshow-arrow-right'>
    <CaretRight />
  </ArrowButtonWrapper>
)
