import { ISuggestion, ISearchLocation, ISuggestionContext } from '../autocompleteRequest';

const findInContext = (context: ISuggestionContext[], regex: RegExp) => (
  context?.find((c: ISuggestionContext) => regex.test(c.id))?.text || null
)

export const parseMapboxLocation = (suggestion: ISuggestion) => {
  const {context, place_name, text} = suggestion;
  const place_type = suggestion.place_type ? suggestion.place_type[0] : null;

  const parsedLocation: ISearchLocation[] = [{
    areaValue: place_name,
    neighborhood: findInContext(context, /^neighborhood.+/),
    city: place_type === 'place' ? text : findInContext(context, /^place.+/),
    county: place_type === 'district' ? text : findInContext(context, /^district.+/),
    state: place_type === 'region' ? text : findInContext(context, /^region.+/)
  }];

  return parsedLocation;
}
