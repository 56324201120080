import { ISort } from '../../components/Sort'

export const buildRequestSorts = (sorts: ISort[]) => {
  let sortString: string = '';

  (sorts || []).forEach((sort: ISort, index: number) => {
    const { field, operator } = sort
    const translatedOperator = operator === 'desc' ? '=-' : '='
    sortString += `${index === 0 ? '&sort' : ','}${translatedOperator}${field}`
  })
  return sortString
}
