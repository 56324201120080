import { parseCitySlug, parseCountySlug } from '../parseAreaSlug'
import { soldHomesUrl } from '../soldHomesUrl'
import { stateCodeOf } from '../stateUtils'
import { formatCurrency } from '../formatCurrency'
import { formatNumber } from '../formatNumber'
import { dasherize } from '../dasherize'

export const buildFaq = (
  {
    medianPrice,
    medianPricePercentChange,
    salesToListRatio,
    activeListings,
    availablePropertyTypes,
    averageDaysToClose,
    averageDaysToCloseDaysDifference,
    soldHomes,
    citiesInState,
    countiesInState,
    citiesInCounty,
    zipCodesInCounty,
    zipCodesInCity,
    zipCodeDisplayName,
    zipCodesNearZipCode,
    citiesNearCity,
    numberAgents
  },
  locationTitle,
  currentSlug,
  pageAreaType
) => {
  let tempFaqArray = []
  const propertyTypeMapping = {
    single_family_home: 'single family homes',
    condo: 'condos',
    townhome: 'townhomes',
    mobile_manufactured: 'mobile homes'
  }
  const { REACT_APP_HOMELIGHT_REPO_URL } = process.env
  const baseUrl = `${REACT_APP_HOMELIGHT_REPO_URL}`
  const locationTitleWithoutState = locationTitle?.split(',')[0]

  if (medianPrice && typeof medianPricePercentChange === 'number' && salesToListRatio) {
    const medianPricePercentChangeRounded =
      Number.parseFloat(medianPricePercentChange * 100).toFixed(1)
    const medianPriceAbsPercentChange = Math.abs(medianPricePercentChangeRounded)
    const userFriendlyMedianPriceChange = medianPriceAbsPercentChange + '%'
    let medianPriceChangeText
    let salesToListRatioDirection
    let salesToListRatioComparison
    const lastMonth = getUserFriendlyDate(2)
    const currentMonth = getUserFriendlyDate(1)
    const formattedSalesToListRatio = Number.parseFloat(salesToListRatio).toFixed(2)

    if (medianPricePercentChange == 0) {
      medianPriceChangeText = 'flat'
    } else if (medianPricePercentChange > 0) {
      medianPriceChangeText = `up ${userFriendlyMedianPriceChange}`
    } else {
      medianPriceChangeText = `down ${userFriendlyMedianPriceChange}`
    }

    if (formattedSalesToListRatio === '1.00') {
      salesToListRatioDirection = 'about the same'
      salesToListRatioComparison = 'that'
    } else if (formattedSalesToListRatio > 1.0) {
      salesToListRatioDirection = 'a higher'
      salesToListRatioComparison = 'than'
    } else {
      salesToListRatioDirection = 'a lower'
      salesToListRatioComparison = 'than'
    }

    tempFaqArray.push({
      title: `What is the median price of homes sold in ${locationTitle}?`,
      body: `The current median home price in ${locationTitle} is ${formatCurrency(
        medianPrice
      )}, ${medianPriceChangeText} from ${lastMonth} to ${currentMonth}. The current sales-to-list ratio in ${locationTitle} is ${formattedSalesToListRatio}, suggesting that homes are selling at ${salesToListRatioDirection} price ${salesToListRatioComparison} they’ve been listed.`
    })
  }

  if (activeListings) {
    tempFaqArray.push({
      title: `How many homes are available for sale in ${locationTitle}?`,
      body: `There are currently ${formatNumber(
        activeListings,
        true
      )} homes for sale in ${locationTitle}.`
    })
  }

  if (availablePropertyTypes && availablePropertyTypes.length) {
    let userFriendlyPropertyType = availablePropertyTypes.map(propType => {
      const propertyTypeDisplay = propertyTypeMapping[propType]
      const url = `${baseUrl}/${currentSlug}/homes-for-sale/${dasherize(propertyTypeDisplay)}`

      return `<a href=${url}>${propertyTypeDisplay}</a>`
    })
    userFriendlyPropertyType = joinArrayUserFriendly(userFriendlyPropertyType)
    tempFaqArray.push({
      title: `What kinds of homes are available in ${locationTitle}?`,
      body: `There are ${userFriendlyPropertyType} for sale in ${locationTitle}.`
    })
  }

  if (averageDaysToClose && typeof averageDaysToCloseDaysDifference === 'number') {
    const averageDaysToCloseDiff = Math.abs(averageDaysToCloseDaysDifference)
    const averageDaysToCloseComparison = averageDaysToCloseDaysDifference > 0 ? 'more' : 'less'
    const lastMonth = getUserFriendlyDate(2)
    const currentMonth = getUserFriendlyDate(1)
    const pluralizeDays = averageDaysToCloseDiff === 1 ? 'day' : 'days'

    tempFaqArray.push({
      title: `How quickly do homes sell in ${locationTitle}?`,
      body: `Homes in ${locationTitle} are staying on the market for ${formatNumber(
        averageDaysToClose,
        true
      )} days on average. That’s ${formatNumber(
        averageDaysToCloseDiff,
        true
      )} ${pluralizeDays} ${averageDaysToCloseComparison} on average from ${lastMonth} to ${currentMonth}.`
    })
  }

  if (soldHomes) {
    const url = `${baseUrl}/${currentSlug}/sold`
    const lastMonth = getUserFriendlyDate(1)

    tempFaqArray.push({
      title: `How many homes were sold in ${locationTitle} last month?`,
      body: `In ${lastMonth}, there were <a href=${url}>${formatNumber(
        soldHomes,
        true
      )} sold homes in ${locationTitle}</a>.`
    })
  }

  if (
    pageAreaType === 'state' &&
    citiesInState &&
    citiesInState.length &&
    countiesInState &&
    countiesInState.length
  ) {
    let userFriendlyCities = citiesInState.map(citySlug => {
      const { city, state } = parseCitySlug(citySlug)
      const stateCode = stateCodeOf(state)
      const url = soldHomesUrl(stateCode, null, city)

      return `<a href=${url}>${city}</a>`
    })
    userFriendlyCities = joinArrayUserFriendly(userFriendlyCities)
    let userFriendlyCounties = countiesInState.map(countySlug => {
      const { county, state } = parseCountySlug(countySlug)
      const stateCode = stateCodeOf(state)
      const url = soldHomesUrl(stateCode, `${county} County`, null)

      return `<a href=${url}>${county} County</a>`
    })
    userFriendlyCounties = joinArrayUserFriendly(userFriendlyCounties)

    tempFaqArray.push({
      title: `What are some of the most popular cities and counties in ${locationTitle}?`,
      body: `Popular ${locationTitle} cities to live in include ${userFriendlyCities}. You may also be interested in popular counties in ${locationTitle} such as ${userFriendlyCounties}.`
    })
  }

  if (
    pageAreaType === 'county' &&
    citiesInCounty &&
    citiesInCounty.length &&
    zipCodesInCounty &&
    zipCodesInCounty.length
  ) {
    let userFriendlyCities = citiesInCounty.map(citySlug => {
      const { city, state } = parseCitySlug(citySlug)
      const stateCode = stateCodeOf(state)
      const url = soldHomesUrl(stateCode, null, city)

      return `<a href=${url}>${city}</a>`
    })
    userFriendlyCities = joinArrayUserFriendly(userFriendlyCities)
    let userFriendlyZipCodes = zipCodesInCounty.map(zipCode => {
      const zipCodeWithoutState = zipCode.split('-')[0]
      const stateCode = zipCode.split('-')[1]
      const url = soldHomesUrl(stateCode, null, null, zipCodeWithoutState)

      return `<a href=${url}>${zipCodeWithoutState}</a>`
    })
    userFriendlyZipCodes = joinArrayUserFriendly(userFriendlyZipCodes)

    tempFaqArray.push({
      title: `What are some of the most popular cities and zip codes in ${locationTitle}?`,
      body: `Popular ${locationTitle} cities to live in include ${userFriendlyCities}. You may also be interested in popular zip codes in ${locationTitle} such as ${userFriendlyZipCodes}.`
    })
  }

  if (
    pageAreaType === 'city' &&
    zipCodesInCity &&
    zipCodesInCity.length &&
    citiesNearCity &&
    citiesNearCity.length
  ) {
    let userFriendlyZipCodes = zipCodesInCity.map(zipCode => {
      const zipCodeWithoutState = zipCode.split('-')[0]
      const stateCode = zipCode.split('-')[1]
      const url = soldHomesUrl(stateCode, null, null, zipCodeWithoutState)

      return `<a href=${url}>${zipCodeWithoutState}</a>`
    })
    let userFriendlyCities = citiesNearCity.map(citySlug => {
      const { city, state } = parseCitySlug(citySlug)
      const stateCode = stateCodeOf(state)
      const url = soldHomesUrl(stateCode, null, city)

      return `<a href=${url}>${city}</a>`
    })
    userFriendlyZipCodes = joinArrayUserFriendly(userFriendlyZipCodes)
    userFriendlyCities = joinArrayUserFriendly(userFriendlyCities)

    tempFaqArray.push({
      title: `What are some of the most popular zip codes and nearby cities for ${locationTitle}?`,
      body: `Popular ${locationTitleWithoutState} zip codes to live in include ${userFriendlyZipCodes}. You may also be interested in popular cities near ${locationTitle} such as ${userFriendlyCities}.`
    })
  }

  if (
    pageAreaType === 'zip' &&
    zipCodesNearZipCode &&
    zipCodesNearZipCode.length
  ) {
    let userFriendlyZipCodes = zipCodesNearZipCode.map(zipCode => {
      const zipCodeWithoutState = zipCode.split('-')[0]
      const stateCode = zipCode.split('-')[1]
      const url = soldHomesUrl(stateCode, null, null, zipCodeWithoutState)

      return `<a href=${url}>${zipCodeWithoutState}</a>`
    })
    userFriendlyZipCodes = joinArrayUserFriendly(userFriendlyZipCodes)

    tempFaqArray.push({
      title: `What are some of the most popular zip codes near ${locationTitle}?`,
      body: `Popular zip codes to live in near ${locationTitle} include ${userFriendlyZipCodes}.`
    })
  }

  const newestListingsUrl = `${baseUrl}/${currentSlug}/homes-for-sale/newest-listings`

  tempFaqArray.push({
    title: `How often does HomeLight update their listings in ${locationTitle}?`,
    body: `Our listings are updated near real time so you can see the <a href=${newestListingsUrl}>newest homes in ${locationTitle}</a> and latest updates as soon as they come to the market.`
  })

  if (numberAgents) {
    const url = `${baseUrl}/${currentSlug}/top-real-estate-agents`

    tempFaqArray.push({
      title: `How can I find a real estate agent in ${locationTitle}?`,
      body: `There are ${formatNumber(
        numberAgents,
        true
      )} active real estate agents in ${locationTitle}. Working with a <a href=${url}>top ${locationTitleWithoutState} real estate agent</a> can help you navigate the market and get the best deal.`
    })
  }

  return tempFaqArray
}

const getUserFriendlyDate = (monthsAgo = 0) => {
  let date = new Date()

  date.setMonth(date.getMonth() - monthsAgo)

  return `${getMonth(date)} ${date.getFullYear()}`
}

const getMonth = date => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  return months[date.getMonth()]
}

const joinArrayUserFriendly = array => {
  if (array.length === 1) {
    return array[0]
  }

  return array.slice(0, -1).join(', ') + ' and ' + array[array.length - 1]
}
