import { dasherize } from '../dasherize'

export const soldHomesUrl = (
  stateOrProvince?: string | null,
  countyOrParish?: string | null,
  city?: string | null,
  zip?: string | null
) => {
  const { REACT_APP_HOMELIGHT_REPO_URL } = process.env
  const baseUrl = `${REACT_APP_HOMELIGHT_REPO_URL}`
  let slug;
  if (!stateOrProvince) return `${baseUrl}/homes`

  if (countyOrParish) {
    slug = dasherize(`${countyOrParish} ${stateOrProvince}`)

    return `${baseUrl}/homes-for-sale/county/${slug}`
  } else if (zip) {
    slug = dasherize(`${zip} ${stateOrProvince}`)

    return `${baseUrl}/homes-for-sale/zip/${slug}`
  } else if (city) {
    slug = dasherize(`${city} ${stateOrProvince}`)
  } else {
    slug = dasherize(stateOrProvince)

    return `${baseUrl}/states/${slug}/homes-for-sale`
  }

  return `${baseUrl}/${slug}/homes-for-sale`
}
