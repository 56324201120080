import styled from 'styled-components'
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import { Flex } from '../../common/Flex'
import { TextSmall } from '../Text'
import { Dropdown, IDropdownOption } from '../Dropdown'
import { NativeDropdown } from '../NativeDropdown'
import { darkBlue, coolGray30 } from '../../../constants'
import { IRangeDropdownsProps } from './'

const StyledFlex = styled(Flex)<{
  margin?: string,
  padding?: string
}>`
  padding: ${props => props.padding || '0 0 32px'};
  margin: ${props => props.margin || ''};

  .range-dropdown {
    flex-grow: 1;
  }
`

const StyledText = styled(TextSmall)<{isMobile: boolean}>`
  padding: ${props => props.isMobile ? '8px 7px' : '8px 16px'};
`

export const RangeDropdowns: React.FC<IRangeDropdownsProps> = ({
  label,
  minOptions,
  selectedMin,
  maxOptions,
  selectedMax,
  selectMin,
  selectMax,
  minTestId,
  maxTestId,
  margin,
  padding,
  useDynamicOptionsPlacement=false,
  optionsPlacement
}) => {
  const { mobile } = useBreakpoint()

  const { label: selectedMinLabel } = selectedMin
  const { label: selectedMaxLabel } = selectedMax

  const ResponsiveDropdown = mobile ? NativeDropdown : Dropdown;

  return (
    <div className='range-dropdown'>
      {label && (
        <TextSmall
          as="label"
          color={darkBlue}
        >
          {label}
        </TextSmall>
      )}
      <StyledFlex
        margin={margin}
        padding={padding}
      >
        <ResponsiveDropdown
          containerClassName="range-dropdown"
          options={minOptions}
          selectedLabel={selectedMinLabel}
          displayOptionState={true}
          setSelected={(option: IDropdownOption) => selectMin(option)}
          triggerTestId={minTestId}
          optionsMaxHeight="250px"
          optionsOverflow="scroll"
          useDynamicOptionsPlacement={useDynamicOptionsPlacement}
          optionsPlacement={optionsPlacement}
        />
          <StyledText
            color={coolGray30}
            isMobile={mobile}
          >
            to
          </StyledText>
        <ResponsiveDropdown
          containerClassName="range-dropdown"
          options={maxOptions}
          selectedLabel={selectedMaxLabel}
          displayOptionState={true}
          setSelected={(option: IDropdownOption) => selectMax(option)}
          triggerTestId={maxTestId}
          optionsMaxHeight="250px"
          optionsOverflow="scroll"
          useDynamicOptionsPlacement={useDynamicOptionsPlacement}
          optionsPlacement={optionsPlacement}
        />
      </StyledFlex>
    </div>
  )
}
