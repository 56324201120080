import { radii, borderWidths } from '@homelight/particle-tokens'

export const {
  borderRadius4,
  borderRadius6,
  borderRadius8,
  borderRadius12,
  borderRadius20
} = radii

export const {
  borderWidth1,
  borderWidth3
} = borderWidths
