import { useEffect, useState } from 'react'
import { findObjectIndicesByKey } from '../../utils/findObjectIndicesByKey'
import { IDropdownOption } from '../../components/common/Dropdown'
import { NO_MIN_FILTER_OPTION } from '../../constants'

export const useDynamicMinOptions = (
  selectedMaxValue: number | string,
  fullMinOptions: IDropdownOption[],
  defaultOption?: IDropdownOption
) => {
  const [minOptions, setMinOptions] = useState<IDropdownOption[]>([NO_MIN_FILTER_OPTION, ...fullMinOptions])
  const resetOption = defaultOption ? defaultOption : NO_MIN_FILTER_OPTION

  useEffect(() => {
    const reversedMinOptions = [...fullMinOptions].reverse()
    const maxIndex = findObjectIndicesByKey(reversedMinOptions, 'value', selectedMaxValue)[0]
    const minOptions = [...reversedMinOptions.slice(maxIndex)].reverse()
    const updatedMinOptions = maxIndex ? [resetOption, ...minOptions] : [resetOption, ...fullMinOptions]

    setMinOptions(updatedMinOptions)
  }, [selectedMaxValue, fullMinOptions, resetOption])

  return { minOptions }
}
