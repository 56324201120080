import styled from 'styled-components'
import AngleRightIcon from '../icons/AngleRightIcon'

type TProps = {
  onClose: () => void
}

export default function AgentConfirmationHeaderMobile({ onClose }: TProps) {
  return (
    <Container>
      <span onClick={onClose}>
        <AngleRightIcon />
      </span>
      <TitleWrapper>
        <Title>Edit My Agent</Title>
      </TitleWrapper>
    </Container>
  )
}

const Container = styled.header`
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #dbdfe6;
  margin-bottom: 32px;
  align-items: center;
  height: 42px;

  svg {
    transform: rotateZ(-180deg);
    height: 36px;
    width: 36px;
  }
`

const TitleWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const Title = styled.h1`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  font-weight: 600;
  margin: 0;
`
