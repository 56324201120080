export const PRICE_MIN_FILTER = {
  label: 'priceMin',
  field: 'listing_list_price',
  operator: '>=',
  value: 0
}

export const PRICE_MAX_FILTER = {
  label: 'priceMax',
  field: 'listing_list_price',
  operator: '<=',
  value: 0
}

export const BEDS_MIN_FILTER = {
  label: 'bedsMin',
  field: 'listing_bedrooms_total',
  operator: '>=',
  value: 0
}

export const BEDS_MAX_FILTER = {
  label: 'bedsMax',
  field: 'listing_bedrooms_total',
  operator: '<=',
  value: 0
}

export const BATHS_MIN_FILTER = {
  label: 'bathsMin',
  field: 'listing_bathrooms_total_integer',
  operator: '>=',
  value: 0
}

export const PROPERTY_TYPE_FILTER = {
  label: 'propertyType',
  field: 'listing_property_sub_type',
  operator: '=',
  value: ''
}

export const SQFT_MIN_FILTER = {
  label: 'sqftMin',
  field: 'listing_living_area',
  operator: '>=',
  value: 0
}

export const SQFT_MAX_FILTER = {
  label: 'sqftMax',
  field: 'listing_living_area',
  operator: '<=',
  value: 0
}

export const LOT_SIZE_MIN_FILTER = {
  label: 'lotSizeMin',
  field: 'lot_size_sqft',
  operator: '>=',
  value: 0
}

export const LOT_SIZE_MAX_FILTER = {
  label: 'lotSizeMax',
  field: 'lot_size_sqft',
  operator: '<=',
  value: 0
}

export const YEAR_BUILT_MIN_FILTER = {
  label: 'yearBuiltMin',
  field: 'year_built',
  operator: '>=',
  value: 0
}

export const YEAR_BUILT_MAX_FILTER = {
  label: 'yearBuiltMax',
  field: 'year_built',
  operator: '<=',
  value: 0
}

export const FEATURED_FILTER = {
  label: 'featured',
  field: 'featured',
  operator: '=',
  value: ''
}
