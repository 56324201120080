import styled from 'styled-components'
import { IBodyProps } from './'

const StyledBody = styled('div')`
  padding-top: 24px;  
  padding-right: 32px;
  padding-left: 32px;
`

export const Body: React.FC<IBodyProps> = ({children, className=''}) => {
  return (
    <StyledBody className={className}>
      {children}
    </StyledBody>
  )
}
