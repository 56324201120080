import { formatNumber } from '../formatNumber'

export const formatCurrency = (val: number | null, includeCents: boolean = false) => {
  if (!val) {
    return '$-';
  }

  const value = includeCents ? val : Math.round(val);
  let USD = formatNumber(value, true);
  USD = `$${USD}`

  return includeCents ? USD : USD.split('.')[0];
}
