import { IIconProps } from '../types'

export const CaretLeft: React.FC<IIconProps> = ({ fill }) => {
  return (
    <svg width="10" height="16" viewBox="0 0 7 10" fill='none' xmlns="http://www.w3.org/2000/svg" role="img">
        <title>Back</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.610139 4.73442L5.19692 0.110139C5.34377 -0.036713 5.58124 -0.036713 5.72809 0.110139L6.34674 0.728792C6.49359 0.875644 6.49359 1.11311 6.34674 1.25996L2.6442 5L6.34674 8.74004C6.49359 8.88689 6.49359 9.12436 6.34674 9.27121L5.72809 9.88986C5.58124 10.0367 5.34377 10.0367 5.19692 9.88986L0.610139 5.26558C0.463287 5.11873 0.463287 4.88127 0.610139 4.73442Z" fill={fill}/>
    </svg>
  )
}
