import styled from 'styled-components'

const FullScreenPopoverBody = styled('div')`
  position: relative;
  width: 100%;
  z-index: 100;
  top: 80px;
  padding-top: 32px;
  overflow: scroll;
  background-color: white;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
`

export default FullScreenPopoverBody
