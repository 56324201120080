import React from 'react'
import styled from 'styled-components'
import { cadmiumRed, electricBlue, coolGray50 } from '../../../../constants'
import { TransparentInput } from '../TransparentInput'
import { InputWrapper } from '../InputWrapper'
import { TextXSmall } from '../../../common/Text'
import { IDynamicAlphaNumericInput } from '../types'
import { IAlphaNumericInputProps } from './types'

const stateBorderColors = {
  error: cadmiumRed,
  focus: electricBlue,
  valid: coolGray50 
}

const AlphaNumericInputComponents = {
  small: styled(TransparentInput)`height: 32px;`,
  medium: styled(TransparentInput)`height: 38px;`,
  large: styled(TransparentInput)`
    font-size: 16px;
    height: 46px;
  `,
}

const StyledContainer = styled.div<{
  mt?: string,
  mr?: string,
  mb?: string,
  ml?: string,
  pt?: string,
  pr?: string,
  pb?: string,
  pl?: string
}>`
  margin-top: ${props => props.mt ? props.mt : 0};
  margin-right: ${props => props.mr ? props.mr : 0};
  margin-bottom: ${props => props.mb ? props.mb : 0};
  margin-left: ${props => props.ml ? props.ml : 0};
  padding-top: ${props => props.pt ? props.pt : 0};
  padding-right: ${props => props.pr ? props.pr : 0};
  padding-bottom: ${props => props.pb ? props.pb : 0};
  padding-left: ${props => props.pl ? props.pl : 0};
`

export const DynamicAlphaNumericInput: React.FC<IDynamicAlphaNumericInput> = (props) => {
  const {
    mt,
    mr,
    mb,
    ml,
    pt,
    pr,
    pb,
    pl,
    variation,
    type,
    value,
    errorMessage,
    placeholder,
    inputRef,
    inputState,
    onChange,
    children
  } = props
  const AlphaNumericInput = AlphaNumericInputComponents[variation] as React.FC<IAlphaNumericInputProps>
  const borderColor = stateBorderColors[inputState]

  return (
    <StyledContainer mt={mt} mr={mr} mb={mb} ml={ml} pt={pt} pr={pr} pb={pb} pl={pl}>
      <InputWrapper borderColor={borderColor}>
        {children?.prefix}
        {/* @ts-ignore */}
        <AlphaNumericInput ref={inputRef}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        {children?.postfix}
      </InputWrapper>
      {errorMessage && (
        <TextXSmall color={cadmiumRed}>
          {errorMessage}
        </TextXSmall>
      )}
    </StyledContainer>
  )
}