import React from 'react'
import { Layout } from '../../components/Layout'
import { LocationProvider } from '../../contexts/LocationContext'
import { PropertySearchFiltersProvider } from '../../contexts/PropertySearchFiltersContext'
import { SavedPropertySearchesBody } from '../../components/SavedPropertySearchesBody'
import { SavedPropertySearchesProvider } from '../../contexts/SavedPropertySearchesContext/SavedPropertySearchesContext'
import { getParamObjects, getSortsFromParams, getFiltersFromParams } from '../../utils/parseParams'
import { IPropertySearchProps } from '../PropertySearch'
import { ISort } from '../../components/Sort'
import { IPropertyFieldFilter } from '../../components/Filters'
import { useLocation, useParams } from 'react-router-dom'

export const SavedPropertySearches: React.FC<IPropertySearchProps> = () => {
  const { search } = useLocation()
  const { areaSlug = '' } = useParams()
  const searchParams = getParamObjects(search)
  const sorts = getSortsFromParams(searchParams) as ISort[]
  const propertyFilters = getFiltersFromParams(searchParams) as IPropertyFieldFilter[]

  return (
    <LocationProvider areaSlug={areaSlug}>
      <Layout>
        <SavedPropertySearchesProvider>
          <PropertySearchFiltersProvider
            areaSlug={areaSlug}
            searchParamFilters={propertyFilters}
            searchParamSorts={sorts}
          >
            <SavedPropertySearchesBody listViewType="savedPropertySearches" />
          </PropertySearchFiltersProvider>
        </SavedPropertySearchesProvider>
      </Layout>
    </LocationProvider>
  )
}
