import styled from 'styled-components'
import { BedIcon } from '../icons/BedIcon'
import { BathIcon } from '../icons/BathIcon'
import { RulerTriangleIcon } from '../icons/RulerTriangleIcon'
import { COLORS, BREAKPOINT_S } from '../../constants'
import { PropertyDetailsTypes } from './types'

export const PropertyDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 0;
  overflow: hidden;

  @media (min-width: ${BREAKPOINT_S}px) {
    height: auto;
    margin-bottom: 0;
  }

  span {
    color: ${COLORS.darkBlue};
  }

  svg,
  path {
    fill: ${COLORS.darkBlue};
  }
`

export const IconWrapper = styled.div`
  width: 14px;
  margin-right: 9px;
  height: 16px;

  @media (min-width: ${BREAKPOINT_S}px) {
    height: 16px;
  }
`

export const PropertyDetail = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  @media (min-width: ${BREAKPOINT_S}px) {
    font-size: 14px;
  }
`

export const DetailSpan = styled.span`
  display: none;
  padding-left: 5px;
  font-size: 12px;

  @media (min-width: ${BREAKPOINT_S}px) {
    display: inline-block;
    padding-right: 20px;
  }
`

export const NumberSpan = styled.span`
  padding-right: 14px;
  font-size: 12px;

  @media (min-width: ${BREAKPOINT_S}px) {
    padding-right: 0px;
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 2;
`

export const Address = styled.span`
  font-size: 12px;
`

export const PropertyDetails: React.FC<PropertyDetailsTypes> = ({
  bedroomsTotal,
  bedroomsLabel,
  bathroomsTotal,
  bathroomsLabel,
  livingArea,
  formattedAddress,
  locked
}) => {
  return (
    <PropertyDetailsContainer>
      {!locked && <Address>{formattedAddress}</Address>}
      <DetailsContainer>
        <PropertyDetail>
          <IconWrapper>
            <BedIcon />
          </IconWrapper>
          <NumberSpan>{bedroomsTotal}</NumberSpan>
          <DetailSpan>{bedroomsLabel}</DetailSpan>
        </PropertyDetail>
        <PropertyDetail>
          <IconWrapper>
            <BathIcon />
          </IconWrapper>
          <NumberSpan>{bathroomsTotal}</NumberSpan>
          <DetailSpan>{bathroomsLabel}</DetailSpan>
        </PropertyDetail>
        <PropertyDetail>
          <IconWrapper>
            <RulerTriangleIcon />
          </IconWrapper>
          <NumberSpan>{livingArea}</NumberSpan>
          <DetailSpan>Sqft</DetailSpan>
        </PropertyDetail>
      </DetailsContainer>
    </PropertyDetailsContainer>
  )
}
