import { StyledModalBackground, StyledModalContainer } from '../common/Modal'
import styled from 'styled-components'
import { Flex } from '../common/Flex'
import { BREAKPOINT_S, COLORS } from '../../constants'
import { XIcon } from '../icons/XIcon'
import { ListContainer, ListItem } from '../MortgageDeals/styles'
import { UnlockIcon } from '../icons/UnlockIcon'
import { UnionIcon } from '../icons/UnionIcon'
import { DollarIcon } from '../icons/DollarIcon'
import { UltraLogo } from '../icons/UltraLogo'

// @ts-ignore
export default function UltraBannerModal({ onClose }) {
  return (
    <>
      <StyledModalBackground>
        <StyledModalContainer>
          <StyledContainer direction="column" align="baseline">
            <StyledBanner onClick={() => onClose()}>
              <XIcon />
            </StyledBanner>
            <UltraLogo />
            <StyledHeader>Discover 5,000+ Listings With Ultra-Low Mortgage Rates</StyledHeader>
            <StyledText>
              By assuming the current low-rate loan, say goodbye to mortgage stress and hello to the
              home of your dreams
            </StyledText>
            <ListContainer>
              {/* TODO: add icons */}
              <CustomListItem>
                <IconWrapper>
                  <UnlockIcon color="#46B6FF" />
                </IconWrapper>
                Gain access to HomeLight’s exclusive database of eligible, ultra-low rate listings
                in your area
              </CustomListItem>
              <CustomListItem>
                <IconWrapper>
                  <UnionIcon />
                </IconWrapper>
                We enable you to assume the existing loan on our listings
              </CustomListItem>
              <CustomListItem>
                <IconWrapper>
                  <DollarIcon />
                </IconWrapper>
                Save tens of thousands of dollars over the lifetime of the loan
              </CustomListItem>
            </ListContainer>
          </StyledContainer>
        </StyledModalContainer>
      </StyledModalBackground>
    </>
  )
}

const StyledContainer = styled(Flex)`
  width: 512px;
  padding: 0 32px 0px 32px;
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_S - 1}px) {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding-bottom: 16px;
    background-color: white;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
`

const StyledHeader = styled.h2`
  color: ${COLORS.darkBlue};
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -1px;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 16px;

  @media (max-width: ${BREAKPOINT_S - 1}px) {
    white-space: normal;
    word-break: normal;
    font-size: 24px;
    line-height: 28px;
  }
`

const StyledText = styled.p`
  color: ${COLORS.darkBlue}};
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 0;

  @media(max-width: ${BREAKPOINT_S - 1}px) {
    white-space: normal;
    word-break: normal;
    font-size: 16px;
    line-height: 24px;
  }
`
const StyledBanner = styled.p`
  align-self: flex-end;
  margin-top: 24px;
  cursor: pointer;
`

const IconWrapper = styled.div`
  padding: 10px 14px;
  border-radius: 6px;
  background-color: ${COLORS.mistBlue};
  margin-right: 16px;
  box-sizing: border-box;

  svg {
    margin-right: 0;
    width: 20px;
    height: 20px;
    transform: translateY(3px);
  }
`

const CustomListItem = styled(ListItem)`
  @media (max-width: ${BREAKPOINT_S - 1}px) {
    white-space: normal;
    word-break: normal;
  }
`
