import styled from 'styled-components'
import { Dropdown } from '../../common/Dropdown'
import { NativeDropdown } from '../../common/NativeDropdown'
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import { FEATURED_OPTIONS } from '../../../constants'
import { Heading5 } from '../../common/Text'

const StyledFeaturedSelect = styled('div')`
  width: 432px;
  max-width: 100%;
`

const StyledContainer = styled('div')`
  overflow: auto;
  max-height: 42vh;
  margin: 28px 0;
`

export const FeaturedSelect = ({
  featured,
  selectFeatured,
}) => {
  const { mobile } = useBreakpoint()
  const ResponsiveDropdown = mobile ? NativeDropdown : Dropdown
  const featuredLabel = featured ? featured.label : FEATURED_OPTIONS[0].label

  return (
    <StyledFeaturedSelect>
      <StyledContainer className="featured-container">
        <Heading5 as="label">Ultra-low rates</Heading5>
        <ResponsiveDropdown
          containerClassName={'featured-dropdown'}
          options={FEATURED_OPTIONS}
          selectedLabel={featuredLabel}
          displayOptionState={true}
          setSelected={option => selectFeatured(option)}
          triggerTestId="select-listing-status"
          optionsMaxHeight="250px"
          optionsOverflow="scroll"
          pt="5px"
          useDynamicOptionsPlacement={true}
        />
      </StyledContainer>
    </StyledFeaturedSelect>
  )
}
