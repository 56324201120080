import { useContext } from 'react'
import styled from 'styled-components'
import {
  NAV_OPTIONS,
  BREAKPOINT_S,
  BREAKPOINT_M,
  BREAKPOINT_L,
  BREAKPOINT_XL,
  COLORS,
  AGENT_NAV_OPTIONS,
  coolGray50,
  USER_NAV_OPTIONS_MAP
} from '../../../constants'
import { UserContext, IUserContext, IUserTypes, UserType } from '../../../contexts/UserContext'
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import { Flex } from '../Flex'
import { TransparentButton } from '../Button'
import { HomeLightLogo } from '../HomeLightLogo'
import { ApplicationNavigationPropType } from './'
import { MobileNavigation } from './MobileNavigation'
import { AboutDropdown } from './AboutDropdown'
import { UserLogin } from './UserLogin'
import { INavOptions } from './UserLogin/types'
import { SearchBar } from '../../SearchBar'
import { HomeLightIcon } from '../../icons/HomeLightIcon'
import ApplicationNavigationSharedSub from './ApplicationNavigationSharedSub'

export const Divider = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid ${coolGray50};
`

export const LabelContainer = styled.div<{ colorBlue?: boolean }>`
  span {
    color: ${props => (props.colorBlue ? COLORS.electricBlue : COLORS.black)};
  }
`

const NavContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 56px;
  background-color: ${COLORS.white};
  position: fixed;
  z-index: 5;
  top: 0;
  width: 100%;
  border-bottom: 1px solid ${coolGray50};

  @media (min-width: ${BREAKPOINT_S}px) {
    height: 72px;
  }
`

const StyledNav = styled.nav<{ constrainWidth: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => (props.constrainWidth ? '1000px' : '100%')};
  padding: 16px;
  background-color: transparent;

  @media (min-width: ${BREAKPOINT_S}px) {
    justify-content: space-between;
    padding: ${props => (props.constrainWidth ? '20px 35px' : '20px 32px')};
  }
`

const StyledHomeLightLogo = styled.a`
  display: flex;
  align-items: center;

  svg {
    @media (max-width: ${BREAKPOINT_S}px) {
    }
  }
`

const StyledSearch = styled.div`
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT_S}px) {
    width: auto;
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    width: 300px;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    width: 400px;
  }

  & > * {
    padding-bottom: 0px !important;
    padding-top: 0 !important;
    width: 100%;

    & > form {
      width: 100%;
      max-width: 270px;
    }

    @media (min-width: ${BREAKPOINT_S}px) {
      margin: 0;
      width: 272px;
    }

    @media (min-width: ${BREAKPOINT_M}px) {
      margin: 0 16px 8px 16px;
      margin-left: 28px;
      padding-top: 5px !important;
    }

    @media (min-width: ${BREAKPOINT_L}px) {
      width: 400px;
      height: 40px;
      margin-top: -16px;
      margin-left: -30px;
      padding-left: 24px !important;
      padding-top: 14px !important;

      & > form {
        width: 100%;
        min-width: 228px;
        max-width: 448px;
      }
    }

    @media (min-width: ${BREAKPOINT_XL}px) {
      height: 40px;
      margin-top: -16px;
      margin-left: -6px;
      padding-left: 0 !important;
    }
  }
`

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

export const getUserNavOptions = (userType: IUserTypes): INavOptions[] => {
  return userType ? USER_NAV_OPTIONS_MAP[userType] : []
}

export const getAgentNavOptions = (userType: IUserTypes): INavOptions[] | null => {
  return userType && userType === 'agent' ? AGENT_NAV_OPTIONS : null
}

const mapLinksToNavMenu = (options: any) =>
  options.map((item: any) => ({ title: item.label, href: item.link }))

export const getUserNavMenuOptions = (user: UserType) => {
  if (!user) return null

  const userSubitems = []
  if (user.type === 'agent') {
    userSubitems.push(mapLinksToNavMenu(AGENT_NAV_OPTIONS))
  }
  userSubitems.push(mapLinksToNavMenu(USER_NAV_OPTIONS_MAP.client))

  return {
    name: user.firstName,
    email: user.email,
    menu: {
      title: 'User Menu',
      width: '20rem',
      subitems: userSubitems
    }
  }
}

export const ApplicationNavigation: React.FC<ApplicationNavigationPropType> = ({
  setConfirmAgent,
  constrainWidth = false
}) => {
  const {
    user: { currentUser, logoutCurrentUser }
  } = useContext(UserContext) as IUserContext
  const { mobile, smallish } = useBreakpoint()
  const isMobile = mobile || smallish

  const signoutOption = currentUser
    ? {
        label: 'Sign out',
        action: logoutCurrentUser
      }
    : (null as INavOptions | null)

  const mobileNavOptions = [signoutOption].filter(o => Boolean(o)) as INavOptions[]
  const userNavOptions = getUserNavOptions(currentUser?.type)
  const userOptions = userNavOptions.filter(o => Boolean(o)) as INavOptions[]
  const userFirstName = currentUser && currentUser.firstName ? currentUser.firstName : ''
  const agentOptions = getAgentNavOptions(currentUser?.type)

  const myAgentAction = e => {
    e.preventDefault()
    setConfirmAgent(true)
  }
  const myAgentOption = userOptions.find(option => option.label === 'My agent')
  if (myAgentOption) {
    myAgentOption.action = myAgentAction
  }

  return currentUser?.sharedSubscription ? (
    <ApplicationNavigationSharedSub />
  ) : (
    <NavContainer>
      <StyledNav constrainWidth={constrainWidth}>
        <StyledContainer>
          <StyledHomeLightLogo
            href={`${process.env.REACT_APP_HOMELIGHT_REPO_URL}`}
            data-testid="homelight-logo"
          >
            {isMobile ? <HomeLightIcon /> : <HomeLightLogo />}
          </StyledHomeLightLogo>
          <StyledSearch>
            <SearchBar navigateOnSearch={true} mobileFilterPopoverOpen={true} />
          </StyledSearch>
        </StyledContainer>
        <Flex>
          {/* {isMobile && currentUser && <Avatar name={userFirstName} displayFirstName={false} />} */}
          {isMobile && <MobileNavigation currentUser={currentUser} navOptions={mobileNavOptions} />}
          {!isMobile && (
            <Flex>
              <AboutDropdown navOptions={NAV_OPTIONS} />
              <TransparentButton as="a" href="/agents" padding="0 32px" cursor={'pointer'}>
                For Agents
              </TransparentButton>
              {currentUser ? (
                <UserLogin
                  userOptions={userOptions}
                  userFirstName={userFirstName}
                  displayFirstName={true}
                  agentOptions={agentOptions}
                  currentUser={currentUser}
                />
              ) : (
                <TransparentButton
                  as="a"
                  cursor="pointer"
                  padding="0 0 0 32px"
                  href={`${process.env.REACT_APP_HOMELIGHT_REPO_URL}/client/sign-in?ref=homes`}
                >
                  Sign In
                </TransparentButton>
              )}
            </Flex>
          )}
        </Flex>
      </StyledNav>
    </NavContainer>
  )
}
