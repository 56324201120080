import { spacings } from '@homelight/particle-tokens'

export const {
  spacingNano,
  spacingMicro,
  spacingSub,
  spacingXxxs,
  spacingXxs,
  spacingXs,
  spacingSm,
  spacingMd,
  spacingXl,
  spacingXxl
} = spacings

