export const UnlockIcon = ({ color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="123"
      height="140"
      viewBox="0 0 123 140"
      fill="none"
    >
      <path
        d="M39.375 39.375C39.375 27.2891 49.1641 17.5 61.25 17.5C69.9727 17.5 77.4922 22.5859 81.0195 29.9961C83.0977 34.3711 88.3203 36.2305 92.668 34.1523C97.0156 32.0742 98.9023 26.8516 96.8242 22.5039C90.5078 9.21485 76.9727 0 61.25 0C39.5117 0 21.875 17.6367 21.875 39.375V52.5H17.5C7.84766 52.5 0 60.3477 0 70V122.5C0 132.152 7.84766 140 17.5 140H105C114.652 140 122.5 132.152 122.5 122.5V70C122.5 60.3477 114.652 52.5 105 52.5H39.375V39.375Z"
        fill={color}
      />
    </svg>
  )
}
