import { formatNumber } from '../formatNumber'
import { formatPropertiesCount } from '../formatPropertiesCount'

export const formatPaginationSummary = (first: number, last: number = 1, total: number = 1) => {
  const maxCount = Math.min(last, total)
  const formattedFirst = Boolean(first) ? formatNumber(first, true) : ''
  const formattedLast = formatNumber(Math.min(last, total), true)
  const formattedTotal = formatPropertiesCount(total)
  const rangeStatement = maxCount ? ` - ${formattedLast}` : ''
  return `${formattedFirst}${rangeStatement} of ${formattedTotal}`
}
