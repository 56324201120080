import { toTitleCase } from "../toTitleCase"
import { PERMITTED_PROPERTY_TYPES } from "../../constants"

export function formatPropertyTypes(propertyTypes: string) {
  const propertyTypesList = propertyTypes?.split(',') || []
  const hasAllPropertyTypes = propertyTypesList.sort().join(',') === PERMITTED_PROPERTY_TYPES.sort().join(',')
  
  if (hasAllPropertyTypes) {
      return ''
  }

  return propertyTypesList.map(type => toTitleCase(type.split('_').join(' '))).join(', ')
}