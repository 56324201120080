import { isObject } from '../isObject'
import { toCamelCase } from '../toCamelCase'

export const objectKeysToCamel = (obj) => {
  if (isObject(obj)) {
    const newObj = {}
    Object.keys(obj).forEach((key) => {
      let childObj
      if (isObject(obj[key])) {
        childObj = objectKeysToCamel(obj[key])
      }
      newObj[toCamelCase(key)] = childObj || obj[key];
    })
    return newObj
  }
}
