import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BREAKPOINT_S } from '../../../../constants'
import { IImagesContext, ImagesContext } from '../../../../contexts/ImagesContext'
import { PropertyUserInteractions } from '../../../PropertyUserInteractions'
import { ImageCarousel } from '../../../PropertyImg'
import { PropertyStatus } from '../../../PropertyStatus'
import { IPopUpPropertyTypes } from '../PopUpProperty'
import { PropertyDetails, PropertyDetailsContainer } from '../../../PropertyDetails'
import { PropertyPriceFeatured } from '../../../PropertyPrice/PropertyPriceFeatured'
import { PropertyListThumbnailFooter } from '../../../PropertyListThumbnail/Footer'

const StyledLink = styled.a`
  display: block;
  height: 100%;
  outline: none;
  position: relative;
`

export const StyledMapPopUp = styled.div`
  width: 100%;
  z-index: 1;
  text-decoration: none;
  height: 204px;

  &:hover::before,
  &:focus::before {
    opacity: 1;
  }

  &:hover {
    .property-image,
    .property-image-overlay:after {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.45);
    }
  }

  ${PropertyDetailsContainer} {
    position: absolute;
    bottom: 0;
    margin-left: 20px;
    margin-bottom: 12px;

    span {
      color: white;
    }

    svg,
    path {
      fill: white;
    }
  }
`

const DetailsContainer = styled.div`
  background-color: white;
  position: relative;
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0 0 8px 8px;
  box-sizing: border-box;
  pointer-events: none;
`

const PopUpImage = styled(ImageCarousel)<{ hasDisclaimer: boolean }>`
  position: absolute;
  width: 100%;
  height: 204px;

  .slide-frame {
    border-radius: 8px 8px 0 0;
  }

  .property-image-overlay {
    width: 100%;
    height: 100%;

    &.empty-image {
      position: absolute;
    }
  }

  @media (min-width: ${BREAKPOINT_S}px) {
    opacity: unset;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%);
  }
`

const PriceContainer = styled.div`
  box-sizing: border-box;
  padding: 12px 18px;
  height: 68px;
  align-items: center;
  display: flex;
`

export const GoldRushPopUpProperty: React.FC<IPopUpPropertyTypes> = ({
  uuid,
  price,
  bedrooms,
  bedroomsLabel,
  bathrooms,
  bathroomsLabel,
  livingArea,
  propertyPageUrl,
  listingKey,
  disclaimer,
  images,
  propertyStatus,
  listingContractDate,
  hlFullAddress,
  isFavorited,
  sendFavoriteProperty,
  shareProperty,
  featured,
  downPayment,
  mortgageIntRate,
  monthlyPayment,
  marketMortgageRate,
  marketMonthlyMortgagePayment,
  formattedAddress
}) => {
  const {
    images: listingImages,
    imagesLoading,
    getImages
  } = useContext(ImagesContext) as IImagesContext
  const [usingPreviewImages, setUsingPreviewImages] = useState<boolean>(true)
  const partialText = disclaimer?.partialText
  const getListingImages = () => {
    getImages(listingKey)
  }

  useEffect(() => {
    setUsingPreviewImages(imagesLoading)
  }, [imagesLoading])

  return (
    <StyledMapPopUp>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <StyledLink href={propertyPageUrl || '#'} target="_blank">
        <PopUpImage
          images={images.urls}
          isPreview={usingPreviewImages}
          listingImages={listingImages}
          jsxKey={`map-image-${uuid}`}
          hasDisclaimer={Boolean(partialText?.length)}
          listingContractDate={listingContractDate}
          getListingImages={getListingImages}
          featured={featured}
        />
        <PropertyDetails
          bedroomsTotal={bedrooms || ''}
          bedroomsLabel={bedroomsLabel}
          bathroomsTotal={bathrooms || ''}
          bathroomsLabel={bathroomsLabel}
          livingArea={livingArea || ''}
          formattedAddress={formattedAddress}
        />
      </StyledLink>
      <PropertyUserInteractions
        url={propertyPageUrl || ''}
        address={hlFullAddress}
        propertyIsFavorited={isFavorited}
        favoriteProperty={sendFavoriteProperty}
        shareProperty={shareProperty}
      />
      <PropertyStatus status={propertyStatus} featuredAndGoldRushEnabled={featured} />
      <DetailsContainer>
        <PriceContainer>
          <PropertyPriceFeatured
            closePrice={price}
            downPayment={downPayment}
            featuredAndGoldRushEnabled={featured}
            variation="heading4"
            flexStyle={{ direction: 'row', width: '100%' }}
            interestRate={mortgageIntRate}
            monthlyPayment={monthlyPayment}
            marketMortgageRate={marketMortgageRate}
            marketMonthlyMortgagePayment={marketMonthlyMortgagePayment}
            featured={featured}
          />
        </PriceContainer>
        <PropertyListThumbnailFooter />
      </DetailsContainer>
    </StyledMapPopUp>
  )
}
