import { IPropertyListing } from '../../contexts/PropertyContext'

export const calcLoanRemainingMonths = (maturityDate?: string) => {
  if (!maturityDate) {
    return 360
  }

  const maturity = new Date(maturityDate)
  const today = new Date()
  if (maturity - today < 0) {
    // invalid, maturity date in the past
    return 360
  }

  const maturityYear = maturity.getFullYear()
  const thisYear = today.getFullYear()
  const maturityMonth = maturity.getMonth()
  const thisMonth = today.getMonth()
  return Math.floor(maturityYear * 12 + maturityMonth - (thisYear * 12 + thisMonth))
}

export const lifetimeSavings = (property: IPropertyListing) => {
  if (!property?.marketMonthlyMortgagePayment || !property?.monthlyPayment) {
    return 0
  }

  const ultraMonthlySavings = property.marketMonthlyMortgagePayment - property.monthlyPayment
  const remainingMonths = calcLoanRemainingMonths(property.mortgageMaturityDate)
  return Math.ceil(ultraMonthlySavings * remainingMonths)
}

export const tensOrHundreds = (num: number) => num >= 100000 ? 'hundreds' : 'tens'
