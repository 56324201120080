import { useContext, useState } from 'react'
import { IPropertiesContext, PropertiesContext } from '../../contexts/PropertiesContext'
import { ILocationContext, LocationContext } from '../../contexts/LocationContext'
import { ISavedHomesContext, SavedHomesContext } from '../../contexts/SavedHomesContext'
import { PropertySearchBody } from '../PropertySearchBody'
import { IUserContext, UserContext } from '../../contexts/UserContext'
import {
  IFiltersContext,
  PropertySearchFiltersContext
} from '../../contexts/PropertySearchFiltersContext'
import {
  ISavedPropertySearchesContext,
  SavedPropertySearchesContext
} from '../../contexts/SavedPropertySearchesContext'

export const SearchConsumerLayer: React.FC<{
  searchParams: {}
  pageType: string | null
  bedroomCount: number | null
  pageAreaType: string | null
  confirmAgent: boolean
  setConfirmAgent: (confirmAgent: boolean) => void
}> = ({
  searchParams,
  pageType,
  bedroomCount,
  pageAreaType,
  featuredFilter,
  confirmAgent,
  setConfirmAgent
}) => {
  const {
    user: { currentUser, currentUserLoading, visitorId }
  } = useContext(UserContext) as IUserContext
  const { addSavedHome, removeSavedHome, firstFavoriteInSession, setMostRecentFavoritedAt } =
    useContext(SavedHomesContext) as ISavedHomesContext
  const { appliedFilters, filters, sorts, setSorts, savedSearchMatchesFilters } = useContext(
    PropertySearchFiltersContext
  ) as IFiltersContext
  const {
    properties: { propertiesData, propertiesLoading },
    totalPropertiesCount,
    currentPage,
    resetCurrentPage,
    totalFeaturedCount
  } = useContext(PropertiesContext) as IPropertiesContext
  const {
    resultsTitle,
    locationTitle,
    currentSlug,
    showMoreSearches,
    setShowMoreSearches,
    areaType,
    currentShape,
    locationDescription,
    getFaqData,
    faqData,
    cityFromIP,
    stateFromIP
  } = useContext(LocationContext) as ILocationContext
  const { savedSearches } = useContext(
    SavedPropertySearchesContext
  ) as ISavedPropertySearchesContext
  const [mobileFilterPopoverOpen, setMobileFilterPopoverOpen] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const searchBodyProps = {
    searchParams: searchParams,
    currentUser: currentUser,
    currentUserLoading: currentUserLoading,
    visitorId: visitorId,
    addSavedHome: addSavedHome,
    removeSavedHome: removeSavedHome,
    firstFavoriteInSession: firstFavoriteInSession,
    setMostRecentFavoritedAt: setMostRecentFavoritedAt,
    appliedFilters: appliedFilters,
    filters: filters,
    sorts: sorts,
    setSorts: setSorts,
    propertiesData: propertiesData,
    propertiesLoading: propertiesLoading,
    totalPropertiesCount: totalPropertiesCount,
    totalFeaturedCount: totalFeaturedCount,
    currentPage: currentPage,
    resetCurrentPage: resetCurrentPage,
    resultsTitle: resultsTitle,
    locationTitle: locationTitle,
    currentSlug: currentSlug,
    showMoreSearches: showMoreSearches,
    setShowMoreSearches: setShowMoreSearches,
    savedSearches: savedSearches,
    pageType: pageType,
    bedroomCount: bedroomCount,
    areaType: areaType,
    savedSearchMatchesFilters: savedSearchMatchesFilters,
    pageAreaType: pageAreaType,
    currentShape: currentShape,
    locationDescription: locationDescription,
    mobileFilterPopoverOpen: mobileFilterPopoverOpen,
    setMobileFilterPopoverOpen: setMobileFilterPopoverOpen,
    isModalVisible: isModalVisible,
    setIsModalVisible: setIsModalVisible,
    getFaqData: getFaqData,
    faqData: faqData,
    city: cityFromIP,
    state: stateFromIP,
    confirmAgent: confirmAgent,
    setConfirmAgent: setConfirmAgent
  }
  return <PropertySearchBody {...searchBodyProps} />
}
