import { useContext } from "react"
import {
  LocationContext,
  ILocationContext,
} from "../../contexts/LocationContext"
import {
  PropertySearchFiltersContext,
  IFiltersContext
} from "../../contexts/PropertySearchFiltersContext"
import styled from "styled-components"
import { COLORS } from "../../constants"
import { ElectricBlueButton } from "../common/Button"

const VALID_STATES = ["California"]

const StyledNoresults = styled.div`
  padding-bottom: 40px;
`

const Title = styled.h3`
  font-size: 24px;
  margin-top: 45px;
  margin-bottom: 13px;
`

const Text = styled.p`
  margin-bottom: 24px;
`

export const NoResults: React.FC = () => {
  const { cityFromIP, stateFromIP } = useContext(
    LocationContext
  ) as ILocationContext
  const {
    resetPropertyDetailFilters
  } = useContext(PropertySearchFiltersContext) as IFiltersContext
  const coveredText =
    "Try adjusting your search by removing filters, or zooming out on the map."
  const nonCoveredLocationText = cityFromIP ? `We'll be in ${cityFromIP} soon! ` : ''
  const nonCoveredText = nonCoveredLocationText + `Unfortunately, we don't have listings in this area yet. In the meantime, feel free to search for homes for sale in another city.`
  const isLocationCovered = VALID_STATES.includes(stateFromIP)
  const noResultsText = isLocationCovered ? coveredText : nonCoveredText

  return (
    <StyledNoresults>
      <Title>No Results</Title>
      <Text>{noResultsText}</Text>
      {isLocationCovered ? (
        <ElectricBlueButton.Medium
          backgroundColor={COLORS.electricBlue}
          color={COLORS.white}
          onClick={() => resetPropertyDetailFilters()}
        >
          Remove all filters
        </ElectricBlueButton.Medium>
      ) : null}
    </StyledNoresults>
  )
}
