import { toTitleCase } from '../toTitleCase'

export const formatAddress = (
  hlFullAddress: string | null,
  city: string | null,
  stateOrProvince: string | null,
  postalCode: string | null
) => {
  const formattedFullAddress = hlFullAddress ? toTitleCase(hlFullAddress) : ''
  const formattedCity = city ? toTitleCase(city) : ''
  const cityWithSpacing = formattedFullAddress.length && formattedCity.length ? ` ${formattedCity}` : `${formattedCity}`
  const comma = formattedFullAddress.length || formattedCity.length ? ', ' : ''
  const formattedState = stateOrProvince ? `${stateOrProvince.toUpperCase()} ` : ''

  return `${formattedFullAddress.trim()}${cityWithSpacing}${comma}${formattedState}${postalCode?.trim() || ''}`.trim();
};
