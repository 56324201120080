import styled from 'styled-components'
import { BREAKPOINT_S, electricBlue, white } from '../../../constants'
import { ChevronDown } from '../../icons/ChevronDown'
import { Flex } from '../Flex'
import { IAvatar } from './'

const IconContainer = styled(Flex)<{
  large?: boolean
}>`
  justify-content: center;
  align-items: center;
  width: ${props => (props.large ? '38px' : '32px')};
  height: ${props => (props.large ? '38px' : '32px')};
  border-radius: 50%;
  background-color: ${electricBlue};
  font-weight: bold;
  font-size: ${props => (props.large ? '16px' : '14px')};
  p {
    color: ${white};
  }
`

const ChevronIconContainer = styled.span`
  margin-left: 9px;
  margin-top: -5px;
  display: none;

  @media (min-width: ${BREAKPOINT_S}px) {
    display: block;
  }
`

const NameContainer = styled.span`
  margin-left: 8px;
  display: none;

  @media (max-width: ${BREAKPOINT_S}px) {
    display: block;
  }
`

export const Avatar: React.FC<IAvatar> = ({
  name,
  displayFirstName = false,
  chevron = true,
  large = false
}) => {
  const initial = name && name.split('')[0].toUpperCase()
  return (
    <Flex>
      <IconContainer large={large}>
        <p>{initial}</p>
      </IconContainer>
      {displayFirstName && (
        <>
          <NameContainer>{name}</NameContainer>
          {chevron && (
            <ChevronIconContainer>
              <ChevronDown />
            </ChevronIconContainer>
          )}
        </>
      )}
    </Flex>
  )
}
