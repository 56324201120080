export const ChevronDown = () => {
    return (
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
            <title>Chevron Down</title>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.73442 6.34674L0.110139 1.75996C-0.036713 1.61311 -0.036713 1.37564 0.110139 1.22879L0.728792 0.610139C0.875644 0.463287 1.11311 0.463287 1.25996 0.610139L5 4.31269L8.74004 0.610139C8.88689 0.463287 9.12436 0.463287 9.27121 0.610139L9.88986 1.22879C10.0367 1.37564 10.0367 1.61311 9.88986 1.75996L5.26558 6.34674C5.11873 6.49359 4.88127 6.49359 4.73442 6.34674Z"
                  fill="#273653"></path>
        </svg>
    )
}
