import styled from 'styled-components'
import { BREAKPOINT_M, BREAKPOINT_XL } from '../../../constants'

export const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 56px);
  padding-top: 56px;

  @media (min-width: ${BREAKPOINT_M}px) {
    height: calc(100vh - 72px);
    padding-top: 72px !important;
  }
`

export const Title = styled.h1`
  font-size: 20px;
  margin: 0;
  padding: 16px 0;

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 30px;
  }
`

export const BodyLeft = styled.div`
  box-sizing: border-box;
  width: 0%;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
  &.full-width {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 8px 16px 8px;

    @media (min-width: ${BREAKPOINT_M}px) {
      padding: 1px 32px 0 32px;
    }
  }

  @media (min-width: ${BREAKPOINT_XL}px) {
    flex: 4 4;
    max-width: 840px;
    width: 100%;
    padding: 1px 32px 8px 32px;
  }

  > div {
    flex-shrink: 0;
  }
`

