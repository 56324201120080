import styled, { css } from 'styled-components'
import {
  coolGray50,
  darkBlue,
  electricBlue,
  fontWeightRegular
} from '../../../../constants'

export const TransparentButton = styled.button<{
  padding?: string | '',
  fontWeight?: number,
  color?: string,
  cursor?: string,
  isHighlighted?: boolean
}>
`
  display: flex;
  align-items: center;
  padding: ${props => props.padding ? props.padding : "6px 30px"};
  white-space: nowrap;
  color: ${props => props.color ? props.color : darkBlue};
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-size: 16px;
  font-weight: ${props => props.fontWeight ? props.fontWeight : fontWeightRegular};
  line-height: 24px;
  cursor: ${props => props.cursor ? props.cursor : 'default'};
  &:hover {
    background-color: transparent;
    text-decoration: none;
  }
  &:disabled {
    color: ${coolGray50}
  }
  &:focus-visible {
    border-color: ${darkBlue};
  }
  &:focus {
    outline: none;
    border-color: ${electricBlue};
  }

  ${({ isHighlighted }) => isHighlighted && css`
    background-color: #f5f6f9;
    border-radius: 4px;
  `}

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      background-color: transparent;
    }
  }
`
