export const PROPERTY_TYPE_MAPPING: { [type: string]: string } = {
  'Single Family Residence': 'single_family_home',
  'Single Family Home': 'single_family_home',
  'SingleFamilyResidence': 'single_family_home',
  'Single Family': 'single_family_home',
  'Single-Family': 'single_family_home',
  'RES-Single Family': 'single_family_home',
  'Single Family Detached': 'single_family_home',
  'Single Family-Detached': 'single_family_home',
  'Residential Single Family': 'single_family_home',
  'Detached Single Family': 'single_family_home',
  'Single Family Attached': 'single_family_home',
  'Single Family-Rural': 'single_family_home',
  'Lakefront Single Family': 'single_family_home',
  'Single Family Residential': 'single_family_home',
  'Attached Single Family': 'single_family_home',
  'Single Family w/Acreage': 'single_family_home',
  'Single Family/Site Built': 'single_family_home',
  'Single Family w/Att Ohana': 'single_family_home',
  'Package Single Family Houses': 'single_family_home',
  'Single Family Attach': 'single_family_home',
  'Single Family,Villa': 'single_family_home',
  'Condo/Townhouse,Single Family Attach,Villa': 'single_family_home',
  'Single Family Attach,Villa': 'single_family_home',
  'Residential Waterfront Secondary': 'single_family_home',
  'Residential': 'single_family_home',
  'Residential-Detached': 'single_family_home',
  'SF w/Det Ohana or Cottage': 'single_family_home',
  'Residential/SFR': 'single_family_home',
  'Part Ownership SF': 'single_family_home',
  'Residential Income': 'single_family_home',
  'Residential Back Country': 'single_family_home',
  'Residential Investment': 'single_family_home',
  'Rural Residential': 'single_family_home',
  'Ranch': 'single_family_home',
  'Horizontal Property Regime - Detached': 'single_family_home',
  'Detached': 'single_family_home',
  'Site-Built Home': 'single_family_home',
  'LSE-House': 'single_family_home',
  'Stick Built': 'single_family_home',
  'Villa': 'single_family_home',
  'Home/Estate': 'single_family_home',
  'Patio': 'single_family_home',
  'New Builder Home': 'single_family_home',
  'Patio Home': 'single_family_home',
  'New Construction': 'single_family_home',
  'New Patio Home': 'single_family_home',
  'Cabin': 'single_family_home',
  'House': 'single_family_home',
  'PUD,Villa': 'single_family_home',
  'Liveable Homes Outside City': 'single_family_home',
  'Cluster': 'single_family_home',
  'Cottage': 'single_family_home',
  'Earth Home': 'single_family_home',
  'Cluster,PUD,Villa': 'single_family_home',
  'Cluster,Villa': 'single_family_home',

  'Condominium': 'condo',
  'Condo': 'condo',
  'LSE-Condo/Townhome': 'condo',
  'Condo/Villa': 'condo',
  'RES-Condo': 'condo',
  'Condo/Townhouse-Attached': 'condo',
  'Condominium/Townhouse': 'condo',
  'Condo/Townhouse/Duplex/Half Duplex': 'condo',
  'Flat Condo': 'condo',
  'Condo/Co-Op': 'condo',
  'Condo/Coop/Villa': 'condo',
  'Condo Townhouse': 'condo',
  'Condo/Coop/Townhouse': 'condo',
  'Condo/Townhouse': 'condo',
  'Condo - Hotel': 'condo',
  'Town Home/Condo': 'condo',
  'Lakefront Condo': 'condo',
  'Other Condo': 'condo',
  'Condo/Townhse/Duplex/Apt': 'condo',
  'Condex': 'condo',
  'Attached Condo': 'condo',
  'Condo/Townhm/Twin': 'condo',
  'Condo,Cooperative,Villa': 'condo',
  'Condo,Villa': 'condo',
  'Townhouse/Condominium': 'condo',
  'Townhouse/Condo/Patio': 'condo',
  'Tenancy in Common': 'condo',
  'CondoTel': 'condo',
  'Condo,PUD': 'condo',
  'Apartment Complex,Condo': 'condo',
  'Condo,Cooperative': 'condo',
  'Condo-Tel': 'condo',
  'Waterfront Condo': 'condo',
  'Condo Waterfront': 'condo',
  'Hotel Condo': 'condo',
  'Detached Condo': 'condo',
  'Condo,Other': 'condo',
  'Condo,PUD,Villa': 'condo',
  'Condo/Garden,Villa': 'condo',
  'Package of Condos': 'condo',
  'Cluster,Condo': 'condo',
  'Cluster,Condo,Cooperative': 'condo',
  'Condo,Cooperative,PUD,Villa': 'condo',
  'Condo/Garden': 'condo',
  'Apartment Complex,Condo,Cooperative': 'condo',
  'Apartment Complex,Condo,Villa': 'condo',
  'Cluster,Condo,PUD': 'condo',
  'Cluster,Condo,Villa': 'condo',
  'Condo,Cooperative,Other,Villa': 'condo',
  'Condo,Other,Villa': 'condo',
  'Stock Cooperative': 'condo',
  'Co-Op': 'condo',
  'StockCooperative': 'condo',
  'PUD': 'condo',
  'High Rise': 'condo',
  'Loft': 'condo',
  'Co-op': 'condo',
  'Cooperative': 'condo',
  'Coop': 'condo',
  'Cooperative,PUD,Villa': 'condo',
  'Cooperative,Villa': 'condo',
  'Attached Single Unit': 'condo',
  'All Other Attached': 'condo',

  'Townhouse': 'townhome',
  'Townhome': 'townhome',
  'RES-Townhouse': 'townhome',
  'Town Home': 'townhome',
  'New Townhome': 'townhome',
  'Residential Attached': 'townhome',
  'Attached Dwelling': 'townhome',
  'Attached': 'townhome',
  'Horizontal Property Regime - Attached': 'townhome',
  'Manufactured Attached': 'townhome',
  'Package of Townhome': 'townhome',
  'Twin Home': 'townhome',
  'Twinhome': 'townhome',

  'Manufactured Home': 'mobile_manufactured',
  'Mobile Home': 'mobile_manufactured',
  'Manuf. Home/Mobile Home': 'mobile_manufactured',
  'ManufacturedHome': 'mobile_manufactured',
  'Manufactured': 'mobile_manufactured',
  'Mobile/Manufactured': 'mobile_manufactured',
  'MobileHome': 'mobile_manufactured',
  'Mobile': 'mobile_manufactured',
  'Manufactured(Mobile) Home': 'mobile_manufactured',
  'LSE-Mobile': 'mobile_manufactured',
  'Modular': 'mobile_manufactured',
  'Manufactured Mobile': 'mobile_manufactured',
  'Modular Home': 'mobile_manufactured',
  'Manufactured Housing': 'mobile_manufactured',
  'Mobile/Mod/Mfg w/Property': 'mobile_manufactured',
  'Modular/Manufactured': 'mobile_manufactured',
  'Mobile Home/Modular': 'mobile_manufactured',
  'ModularHome': 'mobile_manufactured',
  'Manufactured/Mobile': 'mobile_manufactured',
  'Mobile/MfgHome': 'mobile_manufactured',
  'Manuf. Home w/Real Prop.': 'mobile_manufactured',
  'Manufactured Home Only': 'mobile_manufactured',
  'Mobile Home (No Real Estate)': 'mobile_manufactured',
  'Mobile Home Park': 'mobile_manufactured'
}
