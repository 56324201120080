import { IFilter } from "../../components/Filters";

export const buildAreaSlugFromFilterValues = (filters: IFilter[]) => {
    const slugFilter = filters.filter(f => f.field === 'slug')[0] || null
    const cityFromParams = filters.filter(f => f.field === 'city')[0] || null
    const stateFromParams = filters.filter(f => f.field === 'state_or_province')[0] || null
    let areaSlugFromParams: string | null = null
    if (cityFromParams !== null && stateFromParams !== null && cityFromParams.value && stateFromParams.value) {
        const formattedCity = cityFromParams.value.toString().replace('%20', '-').replace(' ', '-')
        areaSlugFromParams = `${formattedCity}-${stateFromParams.value}`.toLowerCase()
    }
    else if (slugFilter != null && slugFilter.value !== null) {
        areaSlugFromParams = slugFilter.value.toString()
    }
    return areaSlugFromParams
}
