import styled from 'styled-components'
import { ImageWrapper } from './NoSavedHomes'
import { NoResultsImage } from './NoResultsImage'
import { BREAKPOINT_M } from '../../constants'

const StyledNoResults = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
`

const HouseImageWrapper = styled(ImageWrapper)`
  svg {
    max-width: calc(100vw - 32px);
    
    @media (min-width: ${BREAKPOINT_M}px) {
      max-width: 700px;
    }
  }
`

const Title = styled.h3`
  font-size: 24px;
  margin-top: 24px;
  margin-bottom: 13px;
  text-align: center;
`

const Text = styled.p`
  margin-bottom: 24px;
  text-align: center;
`

export const PropertyNotFound: React.FC = () => (
  <StyledNoResults>
    <HouseImageWrapper>
      <NoResultsImage />
    </HouseImageWrapper>
    <Title>We couldn't find this property</Title>
    <Text>Check that the url is entered correctly</Text>
  </StyledNoResults>
)
