import React from 'react'
import styled from 'styled-components'
import { IPropertyUserInteractions, IStyledPropertyUserInteractions } from './types'
import { Flex } from '../common/Flex'
import { ShareProperty } from './ShareProperty'
import { FavoriteProperty } from './FavoriteProperty'

const StyledPropertyUserInteractions = styled(Flex)<IStyledPropertyUserInteractions>`
  position: ${props => (props.useAbsolutePosition ? `absolute` : `initial`)};
  top: 16px;
  right: 16px;
`

export const PropertyUserInteractions: React.FC<IPropertyUserInteractions> = ({
  url,
  address,
  styles,
  shareLabel = '',
  propertyIsFavorited,
  useAbsolutePosition = true,
  favoriteProperty,
  shareProperty
}) => (
  <StyledPropertyUserInteractions
    useAbsolutePosition={useAbsolutePosition}
    rightValue={styles?.rightValue}
    topValue={styles?.topValue}
  >
    <ShareProperty
      url={url}
      address={address}
      shareLabel={shareLabel}
      shareProperty={shareProperty}
    />
    <FavoriteProperty isSelected={propertyIsFavorited} favoriteProperty={favoriteProperty} />
  </StyledPropertyUserInteractions>
)
