import styled from 'styled-components'
import { Button } from './'
import { darkBlue, coolGray50 } from '../../../constants/colors'

export const SecondaryButton = styled(Button)`
  padding: 7px 12px;
  background-color: white;
  border: 1px solid ${coolGray50};
  border-radius: 4px;
  color: ${darkBlue};
`

export const Placeholder = styled.span`
  color: ${darkBlue};
`
