import React from "react";
import styled from 'styled-components';
import { COLORS } from '../../../constants';
import { TransparentButton } from '../../common/Button';
import { ISearchIconButtonProps } from './'
import { SearchIcon } from '../../icons/SearchIcon'

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${COLORS.electricBlue};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover, &:focus {
    background-color: ${COLORS.electricBlueHover};
  }
  &:focus-visible {
    background-color: ${COLORS.electricBlueHover};
  }
`

export const SearchIconButton: React.FC<ISearchIconButtonProps> = ({
  onSearchClick
}) => {
  return (
    <TransparentButton
      id="search-icon"
      style={{'padding': '0 '}}
      onClick={ev=>{ev.preventDefault(); onSearchClick(ev)}}
      onKeyPress={(ev) => ev.key === "Enter" && onSearchClick ? onSearchClick(ev) : null}
    >
      <IconWrapper>
        <SearchIcon
          fill={COLORS.white}
        />
      </IconWrapper>
    </TransparentButton>
  )
}
