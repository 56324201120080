import { useContext, useEffect, useState, useRef, lazy, Suspense } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import {
  BREAKPOINT_M,
  SAVED_HOME_SORT_OPTIONS,
  DEFAULT_SAVED_HOME_SORT,
  darkBlue,
  coolGray60,
  coolGray50,
  USER_PROPERTY_URL,
  SEARCH_ALERT_FREQUENCY_OPTIONS
} from '../../constants'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { UserContext, IUserContext } from '../../contexts/UserContext'
import { IPropertyListing } from '../../contexts/PropertyContext'
import { SavedHomesContext, ISavedHomesContext } from '../../contexts/SavedHomesContext'
import { IPropertiesContext, PropertiesContext } from '../../contexts/PropertiesContext'
import { ShowMapButton } from '../ShowMapButton'
import { Sort, ISort } from '../Sort'
import { ListView } from '../ListView'
import { NoSavedHomes } from '../NoResults'
import { Flex } from '../common/Flex'
import { Toast } from '../common/Toast'
import { FallbackLoadingSpinner } from '../common/FallbackLoadingSpinner'
import { StyledBody, Title, BodyLeft } from '../common/PageBody'
import { XIcon } from '../icons/XIcon'
import { ITypeContentMapping } from './types'
import { requestData } from '../../utils/requestData'
import { AlertFrequencyDropdown } from '../SavedPropertySearch'
import { IDropdownOption } from '../common/Dropdown'
import { toTitleCase } from '../../utils/toTitleCase'
import { useLocation, useNavigate } from 'react-router-dom'

const SavedHomesMap = lazy(
  () => import('../SavedHomesMap/SavedHomesMapContainer/SavedHomesMapContainer')
)

const contentByType: ITypeContentMapping = {
  title: {
    favorites: 'Favorite homes',
    recentlyViewed: 'Recently viewed homes'
  },
  linkPath: {
    favorites: 'homes/favorites',
    recentlyViewed: 'homes/recently-viewed'
  },
  meta: {
    favorites: 'Saved favorite homes on HomeLight',
    recentlyViewed: 'Recently viewed homes on HomeLight'
  }
}

const BackArrow = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;

  svg {
    width: 16px;
    height: 16px;
    fill: ${darkBlue};
    cursor: pointer;
    margin: auto;
  }

  &:hover {
    background-color: ${coolGray60};
  }
`

const StyledTitleGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: solid 1px ${coolGray50};
  flex-direction: column;

  h1 {
    @media (min-width: ${BREAKPOINT_M}px) {
      flex: 1;
    }
  }
`

const StyledAlertFrequencyDropdown = styled(AlertFrequencyDropdown)`
  max-width: 372px;
  margin-bottom: 24px;
`

const TitleControls = styled(Flex)<{ noData: boolean }>`
  display: ${props => (props.noData ? 'none' : 'flex')};

  @media (min-width: ${BREAKPOINT_M}px) {
    flex: 0;
  }
  @media (max-width: ${BREAKPOINT_M - 1}px) {
    margin-bottom: 20px;
  }

  button {
    align-self: center;
  }
`

export const SavedHomesBody: React.FC<{ savedHomesType: string }> = ({ savedHomesType }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    user: { currentUser, visitorId }
  } = useContext(UserContext) as IUserContext
  const {
    sorts,
    setSorts,
    addSavedHome,
    removeSavedHome,
    setRefreshPropertiesRequest,
    savedHomeIds,
    favoritesFrequency,
    setFavoritesFrequency
  } = useContext(SavedHomesContext) as ISavedHomesContext
  const {
    properties: { propertiesData, propertiesLoading }
  } = useContext(PropertiesContext) as IPropertiesContext
  const { desktop, mobile } = useBreakpoint()
  const ref = useRef<HTMLDivElement>(null)

  const [showMap, setShowMap] = useState(desktop)
  const [initialShowMap, setInitialShowMap] = useState(desktop)
  const [requestError, setRequestError] = useState<boolean>(false)
  const [favoriteUpdated, setFavoriteUpdated] = useState<boolean>(false)
  const noData = !propertiesLoading && !propertiesData?.length
  const selectedFrequency = SEARCH_ALERT_FREQUENCY_OPTIONS.find(option => {
    return option.label === toTitleCase(favoritesFrequency)
  })
  const showToast = requestError || favoriteUpdated
  let toastText = ''
  if (requestError) {
    toastText = 'Sorry, something went wrong. Can you try that again?'
  } else if (favoriteUpdated) {
    toastText = 'Favorites updated'
  }

  const handleSetShowMap = (shouldShowMap: boolean): void => {
    if (shouldShowMap) {
      setInitialShowMap(true)
      setShowMap(true)
    } else {
      setShowMap(false)
    }
  }

  const favoriteProperty = (savedState: boolean, property: IPropertyListing) => {
    const updateSavedProperty = savedState ? addSavedHome : removeSavedHome
    const userParam = currentUser ? `user_id=${currentUser.id}` : `visitor_id=${visitorId}`
    const requestParams = `?${userParam}&property_uuid=${property.propertyUuid}`
    setRefreshPropertiesRequest(false)
    updateSavedProperty(requestParams, property.propertyUuid).then(({ error }) => {
      setRequestError(error)
    })

    setRequestError(false)
  }

  useEffect(() => {
    if (desktop) {
      setShowMap(true)
      setInitialShowMap(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop])

  const handleGoBack = () => {
    if (location.key !== 'default') {
      navigate(-1)
    } else {
      navigate('/homes')
    }
  }

  const updateUserPropertyFrequencies = (
    option: IDropdownOption,
    _id: string | number | undefined
  ) => {
    setRequestError(false)
    setFavoriteUpdated(false)
    let url = `${USER_PROPERTY_URL}?id=${savedHomeIds.join(',')}&frequency=${option.field}`
    requestData({
      url: url,
      method: 'put',
      headers: { 'Cache-Control': 'no-cache' }
    })
      .then(({ data }) => {
        setFavoriteUpdated(true)
        setFavoritesFrequency(option.field)
      })
      .catch(err => {
        setRequestError(true)
      })
  }

  return (
    <StyledBody data-testid="saved-properties">
      <Helmet>
        <title>{`${(contentByType.title as any)[savedHomesType]} | HomeLight`}</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOMELIGHT_REPO_URL}/${contentByType.linkPath[savedHomesType]}`}
        />
        <meta name="description" content={(contentByType.meta as any)[savedHomesType]} />
      </Helmet>
      <BodyLeft
        id="properties-body"
        className={showMap ? '' : 'full-width'}
        data-testid="properties-body-left"
        ref={ref}
      >
        {/* @ts-ignore */}
        {mobile && (
          <BackArrow onClick={handleGoBack} className="back-nav">
            <XIcon />
          </BackArrow>
        )}
        <StyledTitleGroup>
          <Title>{(contentByType.title as any)[savedHomesType]}</Title>
          {savedHomesType === 'favorites' && (
            <StyledAlertFrequencyDropdown
              selectedFrequency={selectedFrequency}
              setSelectedFrequency={updateUserPropertyFrequencies}
            />
          )}
          <TitleControls noData={noData} className="title-controls">
            <Sort
              sortOptions={SAVED_HOME_SORT_OPTIONS}
              selectedSortLabel={sorts.length ? sorts[0].label : DEFAULT_SAVED_HOME_SORT.label}
              setSort={(sortOption: ISort) => setSorts([sortOption])}
            />
            <ShowMapButton setShowMap={handleSetShowMap} />
          </TitleControls>
        </StyledTitleGroup>
        <ListView
          favoriteProperty={favoriteProperty}
          NoResultsView={() => <NoSavedHomes type={savedHomesType} />}
        />
      </BodyLeft>
      <Suspense fallback={<FallbackLoadingSpinner />}>
        {initialShowMap && (
          <SavedHomesMap
            favoriteProperty={favoriteProperty}
            showMap={showMap}
            closeMap={() => handleSetShowMap(false)}
          />
        )}
      </Suspense>
      <Toast show={showToast} state={requestError ? 'error' : 'default'}>
        {toastText}
      </Toast>
    </StyledBody>
  )
}
