import styled, { css } from 'styled-components'
import { TransparentButton } from '../Button'

export const DropdownOption = styled(TransparentButton)<{
  fontSize?: string | null
  padding?: string | ''
  lh?: string | ''
  isHighlighted?: boolean
}>`
  padding: ${props => (props.padding ? props.padding : '6px 30px')};
  text-align: center;
  line-height: ${props => (props.lh ? props.lh : '26px')};
  cursor: pointer;
  text-decoration: none;
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  width: calc(100% + 10px);
  position: relative;
  left: -10px;
  padding-left: 10px;

  &:focus-visible {
    outline: none;
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: #f5f6f9;
      border-radius: 4px;
    `}

  &:hover {
    background-color: #f5f6f9;
    border-radius: 4px;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      background-color: transparent;
    }
  }
`
