import styled from 'styled-components'
import {
  coolGray60,
  borderRadius8
} from '../../../constants'
import { FullWidth } from '../../common/FullWidth'
import { Flex } from '../../common/Flex'
import { IPropertiesLoadingSilhouetteProps } from './'

const ImageSilhouette = styled('div')<{
  fullWidth: boolean
}>`
  margin-right: ${props => props.fullWidth ? '0' : '24px'};
  min-width: ${props => props.fullWidth ? '100%' : '304px'};
  height: 208px;
  background-color: ${coolGray60};
  border-radius: ${borderRadius8}px;
`

const AddressSilhouette = styled('div')`
  margin-bottom: 24px;
  width: 174px;
  height: 24px;
  background-color: ${coolGray60};
  border-radius: 2px;
`

const LongDetailSilhouette = styled('div')`
  margin-bottom: 16px;
  width: 100%;
  height: 16px;
  background-color: ${coolGray60};
  border-radius: 2px;
`

const ShortDetailSilhouette = styled('div')`
  margin-bottom: 16px;
  width: 50%;
  height: 16px;
  background-color: ${coolGray60};
  border-radius: 2px;
`

export const PropertyLoadingSilhouette: React.FC<IPropertiesLoadingSilhouetteProps> = ({
  mobile=false
}) => {
  return (
    <FullWidth pt='24px'>
      <Flex direction='row' align='start'>
        <ImageSilhouette fullWidth={mobile} />
        {!mobile && (
          <FullWidth>
            <Flex direction='column' align='start'>
              <AddressSilhouette />
              <LongDetailSilhouette />
              <LongDetailSilhouette />
              <ShortDetailSilhouette />
            </Flex>
          </FullWidth>
        )}
    </Flex>
    </FullWidth>
  )
}