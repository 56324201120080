import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import {
  DEFAULT_SAVED_HOME_SORT,
  USER_PROPERTY_URL,
  MAPPING_FAVORITE,
  MAPPING_REQUESTED_TOUR,
  FAVORITES_FILTER,
  RECENTLY_VIEWED_FILTER,
  REQUESTED_TOUR_FILTER
} from '../../constants'
import { requestData } from '../../utils/requestData'
import { UserContext, IUserContext } from '../UserContext'
import { IPropertyFieldFilter } from '../../components/Filters'
import { ISort } from '../../components/Sort'
import { ISavedProperty, ISavedHomesContext, ISavedHomesProvider } from './'

const filterTypeMap = {
  favorites: FAVORITES_FILTER,
  recentlyViewed: RECENTLY_VIEWED_FILTER,
  requestedTour: REQUESTED_TOUR_FILTER
}

export const SavedHomesContext = createContext<ISavedHomesContext | null>(null)

export const SavedHomesProvider: React.FC<ISavedHomesProvider> = ({
  savedHomesType='favorites',
  searchParamSorts,
  children,
}) => {
  const {
    user: {
      currentUser,
      visitorId
    }
  } = useContext(UserContext) as IUserContext

  const defaultSort = searchParamSorts?.length ? searchParamSorts : [DEFAULT_SAVED_HOME_SORT]
  const [sorts, setSorts] = useState<ISort[]>(defaultSort)
  const [filters, setFilters] = useState<IPropertyFieldFilter[]>([])
  const [savedHomeUuids, setSavedHomeUuids] = useState<string[]>([])
  const [requestedTourUuids, setRequestedTourUuids] = useState<string[]>([])
  const [savedHomesLoading, setSavedHomesLoading] = useState<boolean>(true)
  const [refreshPropertiesRequest, setRefreshPropertiesRequest] = useState<boolean>(true)
  const [savedHomeIds, setSavedHomeIds] = useState<string[]>([])
  const [favoritesFrequency, setFavoritesFrequency] = useState<string>('instant')
  const [mostRecentFavoritedAt, setMostRecentFavoritedAt] = useState<string>('')

  const getUserProperties = () => {
    const typeFilter = filterTypeMap[savedHomesType]
    const fieldValue = currentUser ? { field: 'user_id', value: currentUser.id }: { field: 'visitor_id', value: visitorId}
    const userFilter = {
      ...fieldValue,
      label: 'savedHomesUserFilter',
      operator: '='
    }
    const savedAtSort: ISort = {
      field: 'created_at',
      operator: 'desc'
    }

    // TODO - make this optionally recursive to accommodate long lists of favorites
    // in property search results, default pagination is set to 25 in hapi
    requestData({
      url: USER_PROPERTY_URL,
      method: 'get',
      filters: [
        typeFilter,
        userFilter as IPropertyFieldFilter
      ].filter(f => f.value !== '' && f.value !== 0),
      sorts: [savedAtSort],
      size: 1000,
      usePagination: true,
      headers: {'Cache-Control': 'no-cache'}
    }).then(({data}) => {
      const uuids = data.map((savedProperty: ISavedProperty) => savedProperty.propertyUuid)
      const ids = data.map((savedProperty: ISavedProperty) => savedProperty.id)
      setFavoritesFrequency(data[0]?.frequency || 'instant')
      setMostRecentFavoritedAt(data[0]?.createdAt || '')

      // Don't churn if no change
      if (!savedHomeUuids.length && !uuids.length) {
        setSavedHomesLoading(false)
        return
      }

      if (savedHomesType === 'requestedTour') {
        setRequestedTourUuids(uuids)
      } else {
        setSavedHomeUuids([...savedHomeUuids, ...uuids])
        setSavedHomeIds([...savedHomeIds, ...ids])
      }
      setSavedHomesLoading(false)
    }).catch(err => {
      setSavedHomesLoading(false)
    })
  }

  useEffect(() => {
    if (!currentUser && !visitorId) {
      return
    }

    getUserProperties()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, visitorId, savedHomesType])

  const firstFavoriteInSession = () => {
    if (!mostRecentFavoritedAt) {
      return true
    }

    const NUM_HOURS = 2
    const mostRecent = new Date(mostRecentFavoritedAt)
    const timeSinceLast = new Date() - mostRecent
    return timeSinceLast / 1000 / 60 / 60 > NUM_HOURS
  }

  useEffect(() => {
    // filtering uuids to empty string negates the filter instead of returning no results, any non-uuid will do
    const filterValue = savedHomeUuids.length ? savedHomeUuids.join(',') : 'nope'
    if (!refreshPropertiesRequest) { 
      // This will be reset to false where needed in child components,
      // We want to force reset it in case another place in the app
      // wants to allow the list to refresh 
      setRefreshPropertiesRequest(true)
      return
    }

    setFilters([
      {
        label: 'savedHomesPropertyUuidFilter',
        field: 'uuid',
        operator: '=',
        value: filterValue
      }
    ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedHomeUuids])

  const addSavedHome = useCallback(async (requestParams: string, propertyUuid: string) => {
    const originalSavedList = [...savedHomeUuids]

    setSavedHomeUuids([
      ...savedHomeUuids,
      propertyUuid
    ])

    return await requestData({
      method: 'post',
      url: `${USER_PROPERTY_URL}${requestParams}&mapping_type=${MAPPING_FAVORITE}&frequency=${favoritesFrequency}`,
      ignoreJsonFormat: true
    }).then(result => {
      if (result.error) {
        setSavedHomeUuids(originalSavedList)
      }

      return result
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedHomeUuids])

  const addRequestedTour = useCallback(async (requestParams: string, propertyUuid: string) => {
    const originalSavedList = [...requestedTourUuids]

    setRequestedTourUuids([
      ...requestedTourUuids,
      propertyUuid
    ])

    return await requestData({
      method: 'post',
      url: `${USER_PROPERTY_URL}${requestParams}&mapping_type=${MAPPING_REQUESTED_TOUR}`,
      ignoreJsonFormat: true
    }).then(result => {
      if (result.error) {
        setRequestedTourUuids(originalSavedList)
      }

      return result
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedTourUuids])

  const removeSavedHome = useCallback(async (requestParams: string, propertyUuid: string) => {
    const originalSavedList = [...savedHomeUuids]
    const filteredUuids = savedHomeUuids.filter(uuid => uuid !== propertyUuid)

    setSavedHomeUuids(filteredUuids)

    return await requestData({
      method: 'delete',
      url: `${USER_PROPERTY_URL}${requestParams}&mapping_type=${MAPPING_FAVORITE}`,
      ignoreJsonFormat: true
    }).then(result => {
      if (result.error) {
        setSavedHomeUuids(originalSavedList)
      }

      return result
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedHomeUuids])

  const contextVal: ISavedHomesContext = {
    savedHomeUuids,
    addSavedHome,
    requestedTourUuids,
    addRequestedTour,
    setRefreshPropertiesRequest,
    removeSavedHome,
    savedHomesLoading,
    filters,
    sorts,
    setSorts,
    savedHomeIds,
    favoritesFrequency,
    setFavoritesFrequency,
    firstFavoriteInSession,
    setMostRecentFavoritedAt
  }

  return (
    <SavedHomesContext.Provider value={contextVal}>
      {children}
    </SavedHomesContext.Provider>
  )
}
