import {
  ONE_MONTH_AGO,
  TWO_MONTHS_AGO,
  THREE_MONTHS_AGO,
  FOUR_MONTHS_AGO
} from '../utils/getDateInPast'

// TODO: get real options from the data team
export const SALE_DATE_OPTIONS = [
  {
    label: 'Any date',
    value: ''
  },
  {
    label: 'Last 1 month',
    value: ONE_MONTH_AGO
  },
  {
    label: 'Last 2 months',
    value: TWO_MONTHS_AGO
  },
  {
    label: 'Last 3 months',
    value: THREE_MONTHS_AGO
  },
  {
    label: 'Last 4 months',
    value: FOUR_MONTHS_AGO
  }
]

export const DEFAULT_SALE_DATE = SALE_DATE_OPTIONS[0]