import styled from 'styled-components'
import { BedIcon } from '../icons/BedIcon'
import { BathIcon } from '../icons/BathIcon'
import { RulerTriangleIcon } from '../icons/RulerTriangleIcon'
import { ArrowRightIcon } from '../icons/ArrowRightIcon'
import { COLORS, BREAKPOINT_M, BREAKPOINT_L, MAX_PAGE_WIDTH } from '../../constants'
import {
  Address,
  DetailsContainer,
  DetailSpan,
  IconWrapper,
  NumberSpan,
  PropertyDetail
} from '../PropertyDetails'
import { Flex } from '../common/Flex'

const DreamHomeSection = styled.div``

const TopContent = styled(Flex)`
  flex-direction: column;
  gap: 26px;
  align-items: center;
  text-align: center;
  padding: 80px 16px 0;
  box-sizing: border-box;

  @media (min-width: ${BREAKPOINT_M}px) {
    padding: 80px 60px;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    gap: 100px;
    flex-direction: row;
    text-align: left;
    padding: 80px;
    max-width: calc(${MAX_PAGE_WIDTH}px + 160px);
    margin-left: auto;
    margin-right: auto;
  }
`

const TopText = styled.div`
  order: 1;

  @media (min-width: ${BREAKPOINT_L}px) {
    order: 2;
  }
`

const DreamHomeTitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 16px;
  letter-spacing: -1px;

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 36px;
    margin-bottom: 24px;
  }
`

const ScheduleTourText = styled.div`
  font-size: 16px;
  line-height: 180%;

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 20px;
  }
`

const ListingSummaryCard = styled.a<{ displayImage: string }>`
  position: relative;
  background-image: url(${props => (props.displayImage)});
  background-repeat: no-repeat;
  background-size: cover;
  align-self: stretch;
  height: 262px;
  border-radius: 20px;
  order: 2;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%);
    border-radius: 20px;
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    width: 540px;
    height: 392px;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    order: 1;
  }
  
  span {
    color: ${COLORS.white};
    font-size: 16px;
    z-index: 2;
  }

  svg,
  path {
    fill: ${COLORS.white};
  }
`

const ViewMoreDetails = styled.a`
  display: none;
  color: ${COLORS.electricBlue};
  margin-top: 48px;
  text-decoration: none;
  font-weight: 700;
  
  svg {
    width: 16px;
    height: 16px;
    fill: ${COLORS.electricBlue};
    margin-left: 4px;
    vertical-align: bottom;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    display: block;
  }
`

const ViewMoreDetailsMobile = styled(ViewMoreDetails)`
  display: block;
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${BREAKPOINT_L}px) {
    display: none;
  }
`

const OnlyAtHLSection = styled.div`
  background-color: #E9F6FF;
  text-align: center;
  padding: 48px 16px;
  
  @media (min-width: ${BREAKPOINT_L}px) {
    background-color: ${COLORS.white};
  }
`

const Testimonial = styled.div``

const OnlyAtHomeLight = styled.h3`
  font-size: 24px;
  display: inline-block;
  margin-top: 24px;
  
  @media (min-width: ${BREAKPOINT_L}px) {
    font-size: 48px;
    margin-top: 16px;
  }
  
  .hl-word {
    display: inline-block;
    color: ${COLORS.electricBlue};
  }
`

export const UltraDreamHome = ({ propertyListing, agentFirstName }) => {
  if (!propertyListing) {
    return null;
  }

  const renderListingSummaryCard = () => {
    const displayImage = (propertyListing?.imagePreviews && propertyListing.imagePreviews.length > 0)
      ? propertyListing?.imagePreviews[0]
      : 'https://hl-production-assets.s3.amazonaws.com/house-hunter/default_property.webp'
    return (
      <ListingSummaryCard
        displayImage={displayImage}
        href={propertyListing?.propertyPageUrl}
        target="_blank"
      >
        <Address>{propertyListing.formattedAddress}</Address>
        <DetailsContainer>
          <PropertyDetail>
            <IconWrapper>
              <BedIcon />
            </IconWrapper>
            <NumberSpan>{propertyListing.formattedBedrooms}</NumberSpan>
            <DetailSpan>{propertyListing.bedroomsLabel}</DetailSpan>
          </PropertyDetail>
          <PropertyDetail>
            <IconWrapper>
              <BathIcon />
            </IconWrapper>
            <NumberSpan>{propertyListing.formattedBathrooms}</NumberSpan>
            <DetailSpan>{propertyListing.bathroomsLabel}</DetailSpan>
          </PropertyDetail>
          <PropertyDetail>
            <IconWrapper>
              <RulerTriangleIcon />
            </IconWrapper>
            <NumberSpan>{propertyListing.livingArea}</NumberSpan>
            <DetailSpan>Sqft</DetailSpan>
          </PropertyDetail>
        </DetailsContainer>
      </ListingSummaryCard>
    )
  }

  return (
    <DreamHomeSection>
      <TopContent>
        <TopText>
          <DreamHomeTitle>Your dream home is ready for you</DreamHomeTitle>
          <ScheduleTourText>
            {`Schedule a tour with ${agentFirstName} and learn more about this listing on HomeLight.`}
          </ScheduleTourText>
          <ViewMoreDetails href={propertyListing?.propertyPageUrl}>
            View more details about this listing <ArrowRightIcon />
          </ViewMoreDetails>
        </TopText>
        {renderListingSummaryCard()}
      </TopContent>
      <ViewMoreDetailsMobile href={propertyListing?.propertyPageUrl}>
        View more details about this listing <ArrowRightIcon />
      </ViewMoreDetailsMobile>
      <OnlyAtHLSection>
        <Testimonial>
          “These rates are what we wanted: the most affordable out there.” — Ania & Paul
        </Testimonial>
        <OnlyAtHomeLight>
          Only at <div className="hl-word">HomeLight</div>
        </OnlyAtHomeLight>
      </OnlyAtHLSection>
    </DreamHomeSection>
  )
}
