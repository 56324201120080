import styled from 'styled-components'
import { PrimaryButton } from './'
import { electricBlue, electricBlueHover, coolGray50, white } from '../../../constants'

const ElecticBlueButtonBase = styled(PrimaryButton)<{
  width?: string
}>`
  justify-content: center;  
  width: ${props => props.width ? props.width : 'initial'};
  font-weight: 700;
  border-color: ${electricBlue};
  background-color: ${electricBlue};
  color: ${white};
  cursor: pointer;

  &:hover {
    background-color: ${electricBlueHover}
  }
  &:focus-visible {
    border-color: ${electricBlueHover};
  }
  &:focus {
    border-color: ${electricBlueHover};
    outline: none;
  }
  &:disabled {
    border-color: ${coolGray50};
    background-color: ${coolGray50};
    cursor: default;
    opacity: 0.5;
  }
`

export const ElectricBlueButton = {
  Xsmall: styled(ElecticBlueButtonBase)`
    padding: 3px 12px;
    font-size: 12px;
    line-height: 16px;
  `,
  Small: styled(ElecticBlueButtonBase)`
    padding: 6px 12px;
    font-size: 16px;
    line-height: 20px;
  `,
  Medium: styled(ElecticBlueButtonBase)`
    line-height: 20px;
  `,
  Large: styled(ElecticBlueButtonBase)`
    padding: 12px 20px;
    font-size: 16px;
    line-height: 24px;
  `
}
