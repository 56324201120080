export const SEARCH_ALERT_FREQUENCIES  = {
  never: 'never',
  daily: 'daily',
  instant: 'instant'
}

export const SEARCH_ALERT_FREQUENCY_OPTIONS = [
  {
    label: 'Daily',
    field: SEARCH_ALERT_FREQUENCIES.daily
  },
  {
    label: 'Instant',
    field: SEARCH_ALERT_FREQUENCIES.instant
  },
  {
    label: 'Never',
    field: SEARCH_ALERT_FREQUENCIES.never
  },
]
