import { useEffect, useState } from 'react'
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll'
import MoreFiltersBody from './MoreFiltersBody/MoreFiltersBody'
import { StyledModalBackground, StyledModalContainer } from '../../common/Modal'
import { Trigger } from '../FilterPopover/Trigger'
import { IMoreFiltersModalProps } from './'
import { DEFAULT_LISTING_STATUS_FILTER_VALUE } from '../../../constants'

export const MoreFiltersModal: React.FC<IMoreFiltersModalProps> = ({
  forSaleOrSold,
  selectForSaleOrSold,
  listingStatus,
  selectListingStatus,
  saleDate,
  selectSaleDate,
  sqftMin,
  selectSqftMin,
  sqftMinOptions,
  sqftMax,
  selectSqftMax,
  sqftMaxOptions,
  lotSizeMin,
  selectLotSizeMin,
  lotSizeMinOptions,
  lotSizeMax,
  selectLotSizeMax,
  lotSizeMaxOptions,
  yearBuiltMin,
  selectYearBuiltMin,
  yearBuiltMinOptions,
  yearBuiltMax,
  selectYearBuiltMax,
  yearBuiltMaxOptions,
  clearMoreFilters,
  applyMoreFilters,
  isVisible,
  setIsVisible,
  selectedPropertyTypes,
  selectPropertyTypes,
  featured,
  selectFeatured,
  showGoldRush
}) => {
  const INITIAL_TRIGGER_LABEL = 'Filters'
  const [triggerLabel, setTriggerLabel] = useState<string>(INITIAL_TRIGGER_LABEL)
  const [hasFilters, setHasFilters] = useState(false)
  useDisableBodyScroll(isVisible)

  const getTriggerLabel = (filtersCount: number) => {
    return `${INITIAL_TRIGGER_LABEL}${filtersCount > 0 ? ' · ' + filtersCount : ''}`
  }

  const toggleModal = (e: any) => {
    e.preventDefault()
    setIsVisible(!isVisible)
  }

  const closeModal = () => {
    setIsVisible(false)
  }

  const saveFilters = () => {
    applyMoreFilters()
    closeModal()
  }

  const handleClickDismiss = (e: any) => {
    if (e.currentTarget === e.target) {
      saveFilters()
    }
  }

  useEffect(() => {
    const populatedFilters = [
      ['listingStatus', listingStatus],
      ['saleDate', saleDate],
      ['sqftMin', sqftMin],
      ['sqftMax', sqftMax],
      ['lotSizeMin', lotSizeMin],
      ['lotSizeMax', lotSizeMax],
      ['yearBuiltMin', yearBuiltMin],
      ['yearBuiltMax', yearBuiltMax],
      ['featured', featured],
      ['selectedPropertyTypes', selectedPropertyTypes],
      ['forSaleOrSold', forSaleOrSold]
    ]
      .filter(f => {
        const [key, item] = f

        if (key === 'forSaleOrSold') {
          return item && item === 'sold'
        }
        if (key === 'selectedPropertyTypes') {
          return item && Boolean(item.length)
        }

        return item && item.value && item.value !== DEFAULT_LISTING_STATUS_FILTER_VALUE
      })
      .filter(Boolean)

    const filtersCount = populatedFilters.length
    setHasFilters(!!filtersCount)

    const label = getTriggerLabel(filtersCount)
    setTriggerLabel(label)
  }, [
    saleDate,
    sqftMin,
    sqftMax,
    lotSizeMin,
    lotSizeMax,
    yearBuiltMin,
    yearBuiltMax,
    featured,
    selectedPropertyTypes,
    forSaleOrSold,
    listingStatus
  ])

  return (
    <>
      <Trigger
        active={isVisible || hasFilters}
        onClick={(e: any) => toggleModal(e)}
        data-testid="more-filters-trigger"
      >
        {triggerLabel}
      </Trigger>
      {isVisible && (
        <StyledModalBackground onClick={handleClickDismiss}>
          {/* @ts-ignore */}
          <StyledModalContainer>
            <MoreFiltersBody
              hasFilters={hasFilters}
              closeModal={closeModal}
              forSaleOrSold={forSaleOrSold}
              selectForSaleOrSold={selectForSaleOrSold}
              listingStatus={listingStatus}
              selectListingStatus={selectListingStatus}
              saleDate={saleDate}
              selectSaleDate={selectSaleDate}
              sqftMin={sqftMin}
              selectSqftMin={selectSqftMin}
              sqftMinOptions={sqftMinOptions}
              sqftMax={sqftMax}
              selectSqftMax={selectSqftMax}
              sqftMaxOptions={sqftMaxOptions}
              lotSizeMin={lotSizeMin}
              selectLotSizeMin={selectLotSizeMin}
              lotSizeMinOptions={lotSizeMinOptions}
              lotSizeMax={lotSizeMax}
              selectLotSizeMax={selectLotSizeMax}
              lotSizeMaxOptions={lotSizeMaxOptions}
              yearBuiltMin={yearBuiltMin}
              selectYearBuiltMin={selectYearBuiltMin}
              yearBuiltMinOptions={yearBuiltMinOptions}
              yearBuiltMax={yearBuiltMax}
              selectYearBuiltMax={selectYearBuiltMax}
              yearBuiltMaxOptions={yearBuiltMaxOptions}
              clearMoreFilters={clearMoreFilters}
              applyMoreFilters={saveFilters}
              selectedPropertyTypes={selectedPropertyTypes}
              selectPropertyTypes={selectPropertyTypes}
              featured={featured}
              selectFeatured={selectFeatured}
              showGoldRush={showGoldRush}
            />
          </StyledModalContainer>
        </StyledModalBackground>
      )}
    </>
  )
}
