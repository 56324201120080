import styled from 'styled-components'
import { useDropdown } from '../../hooks/useDropdown'
import { DropdownContainer, DropdownOptionsContainer } from '../common/Dropdown'
import { SecondaryButton, Placeholder } from '../common/Button'
import { TransparentButton } from '../common/Button'
import { ISort, ISortProps } from './'
import { ACTION_TAP, trackEvent } from '../../utils/tracking'
import { BREAKPOINT_S, COLORS } from '../../constants'
import { useLayoutEffect } from 'react'

const SortTrigger = styled(SecondaryButton)`
  border: 2px solid ${COLORS.electricBlue};
  border-radius: 20px;
  padding: 10px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 8px;
  background-color: ${COLORS.coolGray60};

  @media (max-width: ${BREAKPOINT_S}px) {
    padding: 10px 12px;
    font-size: 12px;
  }
`

const SortOption = styled(TransparentButton)`
  min-width: 200px;
  padding: 6px 18px;
  font-size: 14px;
  &:hover,
  &:focus,
  &:focus-visible {
    background-color: ${COLORS.whiteHover};
  }
`

const SelectedSortOption = styled(SortOption)`
  background-color: ${COLORS.whiteHover};
`

const StyledPlaceholder = styled(Placeholder)`
  margin-right: 6px;
`

export const Sort: React.FC<ISortProps> = ({
  sortOptions,
  selectedSortLabel,
  setSort,
}) => {
  const options = sortOptions
  const numberOfItems = options.length
  const { triggerProps, itemProps, isOpen } = useDropdown(numberOfItems)

  const select = (e: React.KeyboardEvent | React.MouseEvent, option: ISort) => {
    e.preventDefault()

    if (!option) {
      return
    }

    setSort(option)
    const eventLabel = option?.label?.replace(/ /g, '_').replace(':', '').toLowerCase()
    trackEvent(ACTION_TAP, `sort_${eventLabel}`)
  }

  useLayoutEffect(() => {
    setSort(options[0])
  }, [options])

  return (
    <DropdownContainer>
      <SortTrigger {...triggerProps}>
        <StyledPlaceholder>Sort By:</StyledPlaceholder>
        <span>{selectedSortLabel}</span>
      </SortTrigger>
      <DropdownOptionsContainer open={isOpen}>
        {options.map((option, index) => (
          <li key={`${option.field}-${option.operator}`}>
            {selectedSortLabel === option.label ? (
              <SelectedSortOption
                as="button"
                type="button"
                onClick={(e: React.KeyboardEvent | React.MouseEvent) => select(e, option)}
                {...itemProps[index]}
              >
                {option.label}
              </SelectedSortOption>
            ) : (
              <SortOption
                as="button"
                type="button"
                onClick={(e: React.KeyboardEvent | React.MouseEvent) => select(e, option)}
                {...itemProps[index]}
              >
                {option.label}
              </SortOption>
            )}
          </li>
        ))}
      </DropdownOptionsContainer>
    </DropdownContainer>
  )
}
