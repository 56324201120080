import styled from "styled-components";
import { EqualHousingIcon } from "../icons/EqualHousingIcon";
import { BbbIcon } from "../icons/BbbIcon";
import { TwitterIcon } from "../icons/TwitterIcon";
import { FacebookIcon } from "../icons/FacebookIcon";
import { InstagramIcon } from "../icons/InstagramIcon";
import { YoutubeIcon } from "../icons/YoutubeIcon";
import { PinterestIcon } from "../icons/PinterestIcon";
import { RssIcon } from "../icons/RssIcon";
import {
  termsUrl,
  privacyPolicyUrl,
  twitterUrl,
  facebookUrl,
  instagramUrl,
  youtubeUrl,
  pinterestUrl,
  rssUrl,
  BREAKPOINT_M,
  BREAKPOINT_L,
  coolGray30,
  darkBlue
} from "../../constants"

const StyledFooterCompliance = styled.div`
  svg {
    width: 24px;
    fill: ${darkBlue};

    &:not(:last-child) {
      margin-right: 25px;
    }
  }

  .equal-housing-icon {
    width: 32px;
  }

  .bbb-icon {
    width: 80px;
    color: ${darkBlue};
  }

  margin-top: 74px;
  margin-bottom: 112px;
  padding-top: 30px;
  color: ${coolGray30};
  font-size: 14px;
  line-height: 20px;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${BREAKPOINT_L}px) {
    flex-direction: row;
    margin-bottom: 24px;
    text-align: unset;
    align-items: flex-start;
  }
`;

const HousingBbbIcons = styled.div`
  display: flex;
  justify-content: center;
`

const BbbText = styled.div`
  margin: 26px auto;
  max-width: 240px;
  line-height: 200%;

  div {
    color: ${coolGray30};
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    margin: 26px 0 36px 0;
    max-width: unset;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    margin: 0 0 0 24px;
  }
`;

const SocialIcons = styled.div`
  margin-bottom: 24px;

  a:not(:last-child) > svg {
    margin-right: 16px;
  }
  
  svg {
   border-radius: 50%;
   transition: background-color .2s ease;
    &:hover, &:focus-visible {
      background-color: rgba(0, 0, 0, .1);
    }
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    margin-left: auto;
    margin-bottom: 0;
  }
`;

const LegalInfo = styled.div`
  text-align: center;
  margin-bottom: 16px;
  font-size: 14px;
  color: ${coolGray30};

  a {
    color: ${coolGray30};
  }
`;

const ToSContainer = styled.div`
  margin-top: 16px;
`;

const ToS = styled.a`
  padding-right: 6px;
  margin-right: 6px;
  border-right: 1px solid #ddd;

  @media (min-width: ${BREAKPOINT_M}px) {
    margin-left: 30px;
  }
`;

const Privacy = styled.a``;

const DesktopAccessibility = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT_M}px) {
    display: block;
  }
`

const MobileAccessibility = styled.div`
  display: block;
  @media (min-width: ${BREAKPOINT_M}px) {
    display: none;
  }
`

const Accessibility = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
  color: ${coolGray30};
  line-height: 20px;

  @media (min-width: ${BREAKPOINT_M}px) {
    text-align: center;
  }

  a {
    color: ${coolGray30};
  }
`;

const DesktopHomeLoans = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT_M}px) {
    display: block;
  }
`

const renderAccessibility = () => (
  <Accessibility>
    Our Commitment to Accessibility: HomeLight is committed to making our website accessible and user
    friendly to all. While we are constantly working to improve, we welcome
    your feedback and accommodation requests. If you are having difficulty
    accessing or navigating our website, or if you have any suggestions to
    improve accessibility,{" "}
    <a href="mailto:legal@homelight.com" title="Email our team">please email our team</a>
    &nbsp;or <a href="https://www.homelight.com/about-us" target="_blank" rel="noreferrer">contact us</a>.
  </Accessibility>
)

export const FooterCompliance = () => {
  return (
    <StyledFooterCompliance>
      <Row>
        <HousingBbbIcons>
          <EqualHousingIcon />
          <BbbIcon />
        </HousingBbbIcons>

        <BbbText>
          <div>HomeLight has an A+ rating with the Better Business Bureau.</div>
        </BbbText>

        <SocialIcons>
          <a href={twitterUrl}>
            <TwitterIcon />
          </a>
          <a href={facebookUrl}>
            <FacebookIcon />
          </a>
          <a href={instagramUrl}>
            <InstagramIcon />
          </a>
          <a href={youtubeUrl}>
            <YoutubeIcon />
          </a>
          <a href={pinterestUrl}>
            <PinterestIcon />
          </a>
          <a href={rssUrl}>
            <RssIcon />
          </a>
        </SocialIcons>
      </Row>

      <DesktopAccessibility>{renderAccessibility()}</DesktopAccessibility>

      <LegalInfo>
        © HomeLight, Inc., 100 California, Suite 800, San Francisco, CA 94015
        <ToSContainer>
          <ToS href={termsUrl}>Terms of Service</ToS>
          <Privacy href={privacyPolicyUrl} title="Privacy Policy">Privacy Policy</Privacy>
        </ToSContainer>
      </LegalInfo>

      <LegalInfo>
        HomeLight, Inc. is a licensed real estate broker in the State of California, DRE license # 01900940.
      </LegalInfo>

      <DesktopHomeLoans>
        <LegalInfo>
          HomeLight Home Loans, Inc. NMLS ID#1529229, Equal Housing Lender
        </LegalInfo>

        <LegalInfo>
          1375 N. Scottsdale Rd., # 110, Scottsdale, AZ 85257 Telephone 844-882-3283
        </LegalInfo>

        <LegalInfo>
          All rights reserved
        </LegalInfo>
      </DesktopHomeLoans>

      <MobileAccessibility>{renderAccessibility()}</MobileAccessibility>
    </StyledFooterCompliance>
  );
};
