import { useState } from 'react'
import styled from 'styled-components'
import { Header, Body } from '../common/Modal'
import { CancelOrConfirm } from '../common/CancelOrConfirm'
import { Heading3, Text } from '../common/Text'
import { AlertFrequencyDropdown } from './AlertFrequencyDropdown'
import {
  BREAKPOINT_S,
  darkBlue,
  SEARCH_ALERT_FREQUENCY_OPTIONS
} from '../../constants'
import { ISaveSearchConfirm } from './types'
import { IDropdownOption } from '../common/Dropdown'

const StyledBody = styled(Body)`
  flex: 1;
  padding-bottom: 24px;

  @media (max-width: ${BREAKPOINT_S}px) {
    padding: 24px 16px;
  }
`

const StyledHeading = styled(Heading3)`
  margin-bottom: 24px;

  @media (min-width: ${BREAKPOINT_S}px) {
    margin-bottom: 8px;
  }
`

const StyledText = styled(Text)`
  padding-bottom: 24px;
`

const StyledFooter = styled.div`
  .cancel-confirm {
    padding: 8px 16px;

    @media (min-width: ${BREAKPOINT_S}px) {
      padding: 16px 24px;
    }

    button {
      font-size: 16px;

      &:first-of-type {
        visibility: hidden;
      }
    }
  }
`

export const SaveSearchConfirm: React.FC<ISaveSearchConfirm> = ({
  locationTitle,
  closeModal,
  createSavedSearch
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState<IDropdownOption>(
    SEARCH_ALERT_FREQUENCY_OPTIONS[0]
  )

  const setFrequencyOption = (option: IDropdownOption, _id: undefined) => {
    setSelectedFrequency(option)
  }

  const bodyText = locationTitle
    ? `Save your search criteria for ${ locationTitle } and get updated when new homes hit the market.`
    : 'Save your search criteria and get updated when new homes hit the market.'
  return (
    <>
      <Header closeModal={closeModal} />
      <StyledBody>
        <StyledHeading as="h3">Save search</StyledHeading>
        <StyledText as="p" color={darkBlue}>
          { bodyText }
        </StyledText>
        <AlertFrequencyDropdown
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={setFrequencyOption}
        />
      </StyledBody>
      <StyledFooter>
        <CancelOrConfirm
          confirmLabel="Save"
          confirm={() => createSavedSearch(selectedFrequency.field)}
          cancelLabel="Cancel"
          cancel={closeModal}
          testLabel="save-search"
        />
      </StyledFooter>
    </>
  )
}
