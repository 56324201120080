import React, { Fragment, useContext } from 'react'
import styled from 'styled-components'
import { coolGray50 } from '../../../../constants'
import { useDropdown } from '../../../../hooks/useDropdown'
import { TransparentButton } from '../../Button'
import { Avatar } from '../../Avatar'
import {
  DropdownContainer,
  DropdownOptionsContainer,
  DropdownOption,
  DropdownCollapsibleOption
} from '../../Dropdown'
import { Divider, LabelContainer } from '../ApplicationNavigation'
import { INavOptions, UserLoginPropType } from './'
import { TextBold, TextSmall } from '../../Text'
import { Flex } from '../../Flex'
import { IUserContext, UserContext } from '../../../../contexts/UserContext'
import { trackUserEvent } from '../../../../utils/trackUserEvent'

const StyledListItem = styled.li`
  button {
    width: 100%;
  }
  &:last-child {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid ${coolGray50};
  }
`

const StyledDropdownOptionsContainer = styled(DropdownOptionsContainer)`
  width: 348px;
  padding: 24px;
  max-height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const UserLogin: React.FC<UserLoginPropType> = ({
  userOptions,
  userFirstName,
  displayFirstName = false,
  agentOptions,
  currentUser
}) => {
  const {
    user: { logoutCurrentUser }
  } = useContext(UserContext) as IUserContext

  const { triggerProps, itemProps, isOpen, setIsOpen } = useDropdown(userOptions?.length)
  const name = `${currentUser?.firstName} ${currentUser?.lastName}`

  const signoutOption = currentUser
    ? {
        label: 'Sign out',
        action: logoutCurrentUser
      }
    : (null as INavOptions | null)

  const eventTrack = (key?: string) => {
    if (!key) return

    trackUserEvent({
      event_action: 'home_search_nav_manu',
      result: {
        action: 'click',
        target: key
      }
    })
  }

  return (
    <DropdownContainer
      onClick={() => setIsOpen(true)}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <TransparentButton
        id="signed-in-user"
        padding="0 0 0 32px"
        cursor={'pointer'}
        {...triggerProps}
      >
        <Avatar name={userFirstName} displayFirstName={displayFirstName} />
      </TransparentButton>
      <StyledDropdownOptionsContainer
        right="0"
        mt="0"
        role="menu"
        aria-labelledby="signed-in-user"
        open={isOpen}
        left="initial"
        slideFadeIn
      >
        <Flex align="center">
          <Avatar
            name={userFirstName}
            displayFirstName={displayFirstName}
            chevron={false}
            large={true}
          />
          <Flex ml="8px" direction="column" align="start">
            <TextBold>{name}</TextBold>
            <TextSmall color={'#8E929C'}>{currentUser?.email}</TextSmall>
          </Flex>
        </Flex>
        <Divider style={{ marginTop: '24px' }} />
        {agentOptions && (
          <Fragment>
            {agentOptions?.map((option, index) => {
              if (option?.subitems?.length) {
                return (
                  <StyledListItem key={index}>
                    <DropdownCollapsibleOption label={option.label}>
                      {option?.subitems
                        ?.filter(
                          i => i.key !== 'sign_referral_agreement' || !currentUser.inContract
                        )
                        .map((subitem, i) => (
                          <DropdownOption
                            fontSize="16px"
                            lh={'20px'}
                            padding="12px 0"
                            as="a"
                            href={subitem.link}
                            key={i}
                            target={subitem.openInNewTab ? '_blank' : '_self'}
                            onClick={() => eventTrack(subitem.key)}
                          >
                            {subitem.label}
                          </DropdownOption>
                        ))}
                    </DropdownCollapsibleOption>
                  </StyledListItem>
                )
              }

              return (
                <StyledListItem key={option.label}>
                  <DropdownOption
                    fontSize="16px"
                    lh={'20px'}
                    padding="12px 0"
                    as="a"
                    href={option.link}
                    target={option.openInNewTab ? '_blank' : '_self'}
                    onClick={() => eventTrack(option.key)}
                    {...itemProps[index]}
                  >
                    {option.label}
                  </DropdownOption>
                </StyledListItem>
              )
            })}
          </Fragment>
        )}
        {!agentOptions?.length &&
          userOptions?.map((option, index) => (
            <StyledListItem key={option.label}>
              {option.link || option.label === 'My agent' ? (
                <DropdownOption
                  fontSize="16px"
                  lh={'20px'}
                  padding="12px 0"
                  as="a"
                  onClick={e => (option?.action ? option.action(e) : null)}
                  href={option.link}
                  {...itemProps[index]}
                >
                  {option.label}
                </DropdownOption>
              ) : (
                <DropdownOption
                  fontSize="16px"
                  lh={'20px'}
                  padding="12px 0"
                  as="button"
                  onClick={() => (option?.action ? option.action() : null)}
                  {...itemProps[index]}
                >
                  <LabelContainer colorBlue={true}>
                    <span>{option.label}</span>
                  </LabelContainer>
                </DropdownOption>
              )}
            </StyledListItem>
          ))}
        <Divider />
        <DropdownOption
          fontSize="16px"
          lh={'20px'}
          padding="12px 0"
          as="button"
          onClick={() => (signoutOption?.action ? signoutOption.action() : null)}
        >
          <LabelContainer colorBlue={true}>
            <span>{signoutOption?.label}</span>
          </LabelContainer>
        </DropdownOption>
      </StyledDropdownOptionsContainer>
    </DropdownContainer>
  )
}
