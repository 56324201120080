import styled from 'styled-components'
import { useDropdown } from '../../../../hooks/useDropdown'
import { TransparentButton } from '../../Button'
import { Relative } from '../../Positioned'
import { NormalizedUnordered } from '../../List'
import { LabelContainer } from '../ApplicationNavigation'
import { UserOption } from './UserOption'
import { IMobileNavigationProps } from '.'
import { HamburgerIcon } from '../../../icons/HamburgerIcon'
import { black, coolGray50, white } from '../../../../constants'
import { useEffect } from 'react'

export const NavContainer = styled(Relative)<{ open: boolean }>`
  padding: 0;
  width: ${props => (props.open ? '100%' : 'auto')};
  height: ${props => (props.open ? '64px' : 'auto')};
  position: relative;
`

export const NavList = styled(NormalizedUnordered)<{ open: boolean }>`
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: ${props => (props.open ? '0' : '-100%')};
  overflow: auto;
  background-color: white;
  color: ${black};
  padding: 58px 24px 120px;
  width: 100%;
  box-sizing: border-box;

  ${props =>
    props.open &&
    `
    animation: slide-in 0.4s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0s 1 normal forwards;

    @keyframes slide-in {
    0% {
      left: -100%;
    }
    100% {
      left: 0;
    }
  `}
}
`

const NavListButtonContainer = styled.div`
  z-index: 101;
  position: relative;
  display: flex;
  height: 100%;
  background-color: ${white};
`

export const OptionsContainer = styled.div`
  padding-top: 20px;
  margin-top: 24px;
  border-top: 1px solid ${coolGray50};
`

export const MobileNavigation: React.FC<IMobileNavigationProps> = ({ currentUser, navOptions }) => {
  const { triggerProps, itemProps, isOpen } = useDropdown(navOptions.length + 1)

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  return (
    <NavContainer open={isOpen}>
      <NavList aria-labelledby="mobile-menu" open={isOpen}>
        <UserOption currentUser={currentUser} itemProps={itemProps[1]} />
        <OptionsContainer as="li">
          <NormalizedUnordered role="menu">
            {navOptions.map((option, index) => (
              <li key={`${option.label}-mobile`} role="menuitem">
                <TransparentButton
                  padding="9px 32px 9px 0"
                  onClick={e => (option?.action ? option.action(e) : null)}
                  {...itemProps[index + 2]}
                >
                  <LabelContainer colorBlue={true}>
                    <span>{option.label}</span>
                  </LabelContainer>
                </TransparentButton>
              </li>
            ))}
          </NormalizedUnordered>
        </OptionsContainer>
      </NavList>
      <NavListButtonContainer>
        <TransparentButton
          id="mobile-menu"
          padding="0"
          {...triggerProps}
          aria-label={`${isOpen ? 'Close' : 'Open'} mobile navigation menu`}
        >
          <HamburgerIcon closeMark={isOpen} />
        </TransparentButton>
      </NavListButtonContainer>
    </NavContainer>
  )
}
