import styled from 'styled-components'
import { Flex } from '../Flex'
import { electricBlue } from '../../../constants'

const AgentInfoWrapper = styled(Flex)`
  height: 56px;
  gap: 16px;
`

const AgentImage = styled.img`
  flex: 0 0 56px;
  width: 68px;
  border-radius: 50%;
`

const NameAndBrokerage = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

const AgentName = styled.div`
  font-weight: 700;
`

const AgentBrokerage = styled.div`
  font-size: 14px;
`

const AgentEmail = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${electricBlue};
`

export const AgentInfo = ({ fullName, brokerage, image, email }) => (
  <AgentInfoWrapper className="agent-info-wrapper">
    <AgentImage src={image} />
    <NameAndBrokerage>
      <AgentName>{fullName}</AgentName>
      <AgentBrokerage>{brokerage}</AgentBrokerage>
      <AgentEmail>{email}</AgentEmail>
    </NameAndBrokerage>
  </AgentInfoWrapper>
)
