import {
  Heading2,
  Heading4,
  TextBold
} from '../../common/Text'
import { IPrice } from './'

const PriceHeading2: React.FC<IPrice> = (props) => {
  const {
    color,
    price
  } = props

  return (
    <Heading2 color={color}>
      { price }
    </Heading2>
  )
}

const PriceHeading4: React.FC<IPrice> = (props) => {
  const {
    color,
    price
  } = props

  return (
    <Heading4 color={color}>
      { price }
    </Heading4>
  )
}

const PriceHeadingTextBold: React.FC<IPrice> = (props) => {
  const {
    color,
    price
  } = props

  return (
    <TextBold color={color}>
      { price }
    </TextBold>
  )
}

const priceComponents = {
  heading2: PriceHeading2,
  heading4: PriceHeading4,
  textBold: PriceHeadingTextBold
}

export const DynamicPrice: React.FC<IPrice> = (props) => {
  const Price = priceComponents[props.variation]
  return <Price {...props} />
}