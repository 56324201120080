import { useEffect, useState } from 'react'
import { findObjectIndicesByKey } from '../../utils/findObjectIndicesByKey'
import { IDropdownOption } from '../../components/common/Dropdown'
import { NO_MAX_FILTER_OPTION } from '../../constants'

export const useDynamicMaxOptions = (
  selectedMinValue: number | string,
  fullMaxOptions: IDropdownOption[],
  defaultOption?: IDropdownOption
) => {
  const [maxOptions, setMaxOptions] = useState<IDropdownOption[]>([NO_MAX_FILTER_OPTION, ...fullMaxOptions])
  const resetOption = defaultOption ? defaultOption : NO_MAX_FILTER_OPTION

  useEffect(() => {
    const minIndex = findObjectIndicesByKey(fullMaxOptions, 'value', selectedMinValue)[0]
    const maxOptions = fullMaxOptions.slice(minIndex)
    const updatedMaxOptions = minIndex ? [resetOption, ...maxOptions] : [resetOption, ...fullMaxOptions]

    setMaxOptions(updatedMaxOptions)
  }, [selectedMinValue, fullMaxOptions, resetOption])

  return { maxOptions }
}
