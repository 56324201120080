import { useContext, useState, useEffect, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { BREAKPOINT_S } from '../../constants'
import { ACTION_ENTRY, ACTION_TAP, trackEvent, ACTION_VIEW } from '../../utils/tracking'
import { IImagesContext, ImagesContext } from '../../contexts/ImagesContext'
import { ImageCarousel } from '../PropertyImg'

import { PropertyDetails } from '../PropertyDetails'
import { PropertyStatus } from '../PropertyStatus'
import { PropertyUserInteractions } from '../PropertyUserInteractions'
import { PropertyListThumbnailDataTypes } from './types'
import flagsmith from 'flagsmith'
import { PropertyDetailsContainer as PropertyDetailsStyle } from '../PropertyDetails'
import { PropertyPriceFeatured } from '../PropertyPrice/PropertyPriceFeatured'
import { InfoIcon } from '../icons/InfoIcon'
import { PropertyListThumbnailFooter } from './Footer'
import { useSearchParams } from 'react-router-dom'
import { useDidUpdate } from '../../hooks/useDidUpdate'

const PropertyThumbnailWrapper = styled.div`
  position: relative;
`

export const PropertyThumbnailContainer = styled.a`
  flex-direction: column;
  padding: 0;
  display: flex;
  position: relative;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  border: 1px solid rgb(0, 0, 0, 0.1);
  height: 300px;
  margin-bottom: 0;
  outline: none;

  @media (max-width: ${BREAKPOINT_S}px) {
    margin-bottom: 25px;
    box-shadow: 0px 1px 5px rgba(29, 71, 113, 0.12), 0px 5px 10px rgba(29, 71, 123, 0.04);
  }

  &:hover,
  &:focus {
    .property-image-overlay:after {
      opacity: 1;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.45);
    }

    .slideshow-arrow-wrapper {
      @media (min-width: ${BREAKPOINT_S}px) {
        opacity: 1;
      }
    }
  }

  ${PropertyDetailsStyle} {
    position: absolute;
    bottom: 0;
    margin-left: 20px;
    margin-bottom: 12px;

    span {
      color: white;
    }

    svg,
    path {
      fill: white;
    }
  }
`

export const PropertyImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 204px;
`

export const PropertyDetailsContainer = styled.div`
  width: 100%;
  padding: 12px 18px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  height: 68px;
`

const StyledImageCarousel = styled(ImageCarousel)`
  position: relative;
  min-height: 200px;
  min-width: unset;

  @media (min-width: ${BREAKPOINT_S}px) {
    width: 100%;
  }

  .property-image-overlay {
    height: 100%;

    &:after {
      background-color: unset;
      opacity: unset;
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%);
    }
  }
`

const PROPERTY_CARD_PREFIX = 'property-card-'

export const PropertyListThumbnailFeatured: React.FC<PropertyListThumbnailDataTypes> = ({
  property,
  setSelectedPropertyUuid,
  images,
  favoriteProperty,
  shareProperty,
  firstListingPageView,
  setFirstListingPageView,
  activeTest,
  setUltraRateModal
}) => {
  const {
    images: listingImages,
    imagesLoading,
    getImages
  } = useContext(ImagesContext) as IImagesContext

  const [hasStockImage, setHasStockImage] = useState(false)
  const [usingPreviewImages, setUsingPreviewImages] = useState(true)
  const firstViewTests = ['saved_search_modal', 'first_listing_page_view']
  const [url, setUrl] = useState(property.propertyPageUrl)
  const containerRef = useRef()
  const linkRef = useRef()
  const [resultHasBeenTracked, setResultHasBeenTracked] = useState(false)
  const stateRef = useRef(resultHasBeenTracked)
  const setTrackedResults = data => {
    stateRef.current = data
    setResultHasBeenTracked(data)
  }
  const featuredAndGoldRushEnabled = property?.featured
  const status = featuredAndGoldRushEnabled ? 'ULTRA-LOW RATE' : property?.propertyStatus

  const [searchParams, setSearchParams] = useSearchParams()

  const puuid = searchParams.get('puuid')

  useDidUpdate(() => {
    if (puuid === property.propertyUuid) {
      linkRef?.current?.focus()
    }
  }, [puuid, property.propertyUuid])

  const sendFavoriteProperty = (state: boolean) => {
    favoriteProperty(state, property)
  }

  const getListingImages = () => {
    getImages(property.listingKey)
  }

  useEffect(() => {
    setUsingPreviewImages(imagesLoading)
  }, [imagesLoading])

  useEffect(() => {
    if (hasStockImage) {
      trackEvent(ACTION_ENTRY, 'stock_image_shown', 0, true)
    }
  }, [hasStockImage])

  const trackOnClick = e => {
    trackEvent(ACTION_TAP, 'listing_viewed')

    if (firstListingPageView) {
      setFirstListingPageView(false)
    }
  }

  useEffect(() => {
    const newUrl =
      firstListingPageView && firstViewTests.includes(activeTest)
        ? `${property.propertyPageUrl}?first_listing_page_view=true`
        : property.propertyPageUrl

    setUrl(newUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstListingPageView, activeTest])

  const isInViewport = (element: HTMLElement) => {
    const elem = element?.current

    if (elem) {
      const rect = elem.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }
  }

  const trackVisibleProperties = () => {
    if (!stateRef.current && isInViewport(containerRef)) {
      setTrackedResults(true)

      if (flagsmith.hasFeature('home-search-tile-redesign')) {
        trackEvent(
          ACTION_VIEW,
          'results_in_page_view',
          0,
          true,
          'search_tile_redesign',
          flagsmith.getValue('home-search-tile-redesign')
        )
      } else {
        trackEvent(ACTION_VIEW, 'results_in_page_view', 0, true)
      }
    }
  }

  useLayoutEffect(() => {
    trackVisibleProperties()
    window.addEventListener('wheel', () => trackVisibleProperties())

    searchParams.delete('puuid')
    setSearchParams(searchParams)

    return () => {
      window.removeEventListener('wheel', trackVisibleProperties)
    }
  }, [])

  const handleClick = e => {
    setUltraRateModal(true)
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <PropertyThumbnailWrapper
      className={`property-wrapper ${property}`}
      ref={containerRef}
      id={`${PROPERTY_CARD_PREFIX}${property.propertyUuid}`}
    >
      <PropertyThumbnailContainer
        target="_blank"
        href={url}
        data-testid={`property-thumbnail-container-${property.propertyUuid}`}
        onMouseEnter={() => setSelectedPropertyUuid(property.propertyUuid)}
        onMouseLeave={() => setSelectedPropertyUuid(undefined)}
        onClick={trackOnClick}
        className={puuid === property.propertyUuid ? 'hover-active' : ''}
        ref={linkRef}
      >
        <PropertyImgContainer className="property-img-container">
          <StyledImageCarousel
            className="styled-image-carousel"
            images={images.urls}
            isPreview={usingPreviewImages}
            listingImages={listingImages}
            trackStockImage={() => setHasStockImage(true)}
            trackSlideChange={() => trackEvent(ACTION_TAP, 'list_thumbnail_viewed')}
            jsxKey={`list-thumb-${property.propertyUuid}`}
            getListingImages={getListingImages}
            listingContractDate={property.listingContractDate}
            featured={true}
          />
          <PropertyStatus status={status} featuredAndGoldRushEnabled={true} />
          <PropertyDetails
            bedroomsTotal={property.formattedBedrooms}
            bedroomsLabel={property.bedroomsLabel}
            bathroomsTotal={property.formattedBathrooms}
            bathroomsLabel={property.bathroomsLabel}
            livingArea={property.formattedLivingArea}
            formattedAddress={property.formattedAddress}
            locked={property.locked}
          />
        </PropertyImgContainer>
        <PropertyDetailsContainer>
          <PropertyPriceFeatured
            closePrice={property.formattedPrice}
            variation="heading4"
            flexStyle={{ direction: 'row' }}
            interestRate={property?.mortgageIntRate}
            monthlyPayment={property?.monthlyPayment}
            marketMortgageRate={property?.marketMortgageRate}
            marketMonthlyMortgagePayment={property?.marketMonthlyMortgagePayment}
            featured={true}
          />
        </PropertyDetailsContainer>
      </PropertyThumbnailContainer>
      <PropertyListThumbnailFooter onClick={handleClick}>
        <span>HOMELIGHT RATE</span>
        <InfoIcon />
      </PropertyListThumbnailFooter>
      <PropertyUserInteractions
        url={property.propertyPageUrl}
        address={property.hlFullAddress}
        styles={{ topValue: '16px', rightValue: '16px' }}
        propertyIsFavorited={property.isFavorited}
        favoriteProperty={sendFavoriteProperty}
        shareProperty={shareProperty}
      />
    </PropertyThumbnailWrapper>
  )
}
