import {ISuggestion, ISuggestionContext } from "../autocompleteRequest";

export const stateValues = (ctx: Array<ISuggestionContext>) => {
    return ctx && ctx.filter(function(x: any) {
        return x.id.indexOf('region.') > -1;
    })[0];
}

export const zipValue = (ctx: Array<ISuggestionContext>) => {
    let val = ctx && ctx.filter(function(x: any) {
        return x.id.indexOf('postcode.') > -1;
    })[0];
    return val && val.text
}

export const normalizeOption = (result: ISuggestion | undefined) => {
    if (!result) { return ''}
    let context = result.context,
        place_name = result.place_name,
        text = result.text,
        place_type = result.place_type;
    let isAddress: boolean = place_type && place_type[0] === 'address';
    let isCity: boolean = place_type && place_type[0] === 'place';
    let isNeighborhood: boolean = place_type && place_type[0] === 'neighborhood';
    let isZip: boolean = place_type && place_type[0] === 'postcode';
    let state: ISuggestionContext = stateValues(context);
    let postal_code = isZip ? text : zipValue(context);
    let neighborhood = place_name.replace(', United States', '');
    let fullAddress = place_name && place_name.replace(', United States', '');
    let description;
    let formattedState: string = state && [state.short_code && state.short_code.replace('US-', ''), state.text].filter(function(x) {
        return x;
    })[0];

    if (isCity) {
        description = "".concat(text, ", ").concat(formattedState);
    } else if (isAddress) {
        let addressParts = fullAddress.split(',');
        addressParts[addressParts.length - 1] = ` ${formattedState}`;
        description = addressParts.join(',')
    } else if (isZip) {
        description = postal_code;
    } else if (isNeighborhood) {
        description = neighborhood;
    }
    return description
}
