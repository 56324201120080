import styled from 'styled-components'
import { coolGray50 } from '../../../../constants'
import { Flex } from '../../Flex'

export const InputWrapper = styled(Flex)<{
  borderColor?: string
}>`
  padding: 0 12px;  
  border: 1px solid;
  border-radius: 6px;
  border-color: ${props => props.borderColor ? props.borderColor : coolGray50}
`