import { RangeDropdowns } from '../../common/RangeDropdowns'
import { Heading5 } from '../../common/Text'
import { IPriceRangeSelectProps } from './'

export const PriceRangeSelect: React.FC<IPriceRangeSelectProps> = ({
  priceMin,
  priceMinOptions,
  priceMax,
  priceMaxOptions,
  selectPriceMin,
  selectPriceMax
}) => {
  return (
    <div>
      <Heading5 as="label">Price range</Heading5>
      <RangeDropdowns 
        minOptions={priceMinOptions}
        selectedMin={priceMin}
        maxOptions={priceMaxOptions}
        selectedMax={priceMax}
        selectMin={selectPriceMin}
        selectMax={selectPriceMax}
        minTestId="minimum-price-select"
        maxTestId="maximum-price-select"
        padding="16px 0"
      />
    </div>
  )
}