import styled from 'styled-components'
import { DropdownOption } from './DropdownOption'
import AngleDownIcon from '../../icons/AngleDownIcon'
import { useEffect, useRef, useState } from 'react'

type TProps = {
  label?: string
  children?: React.ReactElement | Array<React.ReactElement>
}

export const DropdownCollapsibleOption = ({ label, children }: TProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const [currentHeight, setCurrentHeight] = useState(0)

  useEffect(() => {
    if (ref?.current?.scrollHeight && !currentHeight) {
      setCurrentHeight(ref?.current?.scrollHeight || 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current?.scrollHeight])

  return (
    <Container isExpanded={isExpanded}>
      <Row isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
        <DropdownOption
          fontSize="16px"
          lh={'20px'}
          padding="12px 0"
          as="a"
          fontWeight={isExpanded ? 600 : 400}
        >
          {label}
        </DropdownOption>
        <AngleDownIcon />
      </Row>
      <Collapsible isExpanded={isExpanded} height={currentHeight || 0} ref={ref}>
        {children}
      </Collapsible>
    </Container>
  )
}

const Container = styled.div<{ isExpanded: boolean }>`
  padding-bottom: ${({ isExpanded }) => (isExpanded ? '20px' : '0px')};
`
const Row = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;

  width: 100%;
  left: -10px;
  position: relative;
  padding-left: 10px;

  & svg {
    transform: ${({ isExpanded }) => (isExpanded ? 'rotateZ(180deg)' : 'rotateZ(0deg)')};
    transition: all ease-in-out 200ms;
  }

  a:hover {
    background-color: transparent;
  }

  &:hover {
    background-color: #f5f6f9;
    border-radius: 4px;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      background-color: transparent;
    }
  }
`
const Collapsible = styled.div<{ isExpanded: boolean; height: number }>`
  max-height: ${({ isExpanded, height }) => (isExpanded ? `${height}px` : `0px`)};
  transform: ${({ isExpanded }) => (isExpanded ? 'translateY(4%)' : 'translateY(0)')};
  overflow: hidden;
  transition: all ease-in-out 500ms;

  a {
    left: 0;
    width: calc(100% - 10px);
    padding-right: 0;
    padding-left: -10px;
  }
`
