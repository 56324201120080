import React from 'react'
import { COLORS } from '../../../constants'
import { HeartIconDataType } from './types'

export const HeartIcon: React.FC<HeartIconDataType> = ({ fill, solid = false }) => {
  if (solid)
    return (
      <svg viewBox="0 0 26 22" role="img" height="16" width="18">
        <title>Like Solid</title>
        <path
          d="M23.203,1.507C20.527,-0.771 16.524,-0.393 14.04,2.17L13.072,3.172L12.105,2.175C10.101,0.103 6.06,-1.144 2.941,1.507C-0.143,4.14 -0.305,8.864 2.455,11.712L11.957,21.524C12.262,21.838 12.665,22 13.067,22C13.47,22 13.873,21.843 14.177,21.524L23.679,11.712C26.449,8.864 26.287,4.14 23.203,1.507Z"
          fill={`${fill ? fill : COLORS.electricBlue}`}
        />
      </svg>
    )
  else
    return (
      <svg viewBox="0 0 26 22" role="img" height="16" width="18">
        <title>Like</title>
        <path
          d="M23.2029 1.50749C20.5266 -0.771094 16.5243 -0.392967 14.0395 2.17044L13.0721 3.17223L12.1047 2.17535C10.1011 0.103018 6.05956 -1.14431 2.94124 1.50749C-0.142704 4.13964 -0.304759 8.86378 2.45508 11.712L11.9574 21.5237C12.2618 21.8379 12.6645 22 13.0672 22C13.4699 22 13.8725 21.8429 14.177 21.5237L23.6793 11.712C26.4489 8.86378 26.2869 4.13964 23.2029 1.50749ZM22.5596 10.6218L13.0917 20.4335L3.58455 10.6218C1.69882 8.67717 1.30596 4.96956 3.96267 2.70571C6.65376 0.407483 9.81627 2.07222 10.9752 3.27044L13.0721 5.43608L15.169 3.27044C16.3083 2.09187 19.5002 0.422216 22.1815 2.70571C24.8333 4.96465 24.4454 8.67226 22.5596 10.6218Z"
          fill={`${fill ? fill : COLORS.darkBlue}`}
        />
      </svg>
    )
}
