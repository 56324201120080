import { findObjectIndicesByKey } from '../../utils/findObjectIndicesByKey'
import { IGetAppliedPropertyFilterProps } from './'
import {
  PRICE_OPTIONS,
  BEDS_OPTIONS,
  BATHS_OPTIONS,
  SQFT_OPTIONS,
  LOT_SIZE_OPTIONS,
  YEAR_BUILT_OPTIONS,
  LISTING_STATUS_OPTIONS,
  DATE_LISTED_OPTIONS,
  SALE_DATE_OPTIONS,
  LISTING_STATUS_SOLD_FILTER,
  FEATURED_FILTER,
} from "../../constants";
import { IDropdownOption } from '../../components/common/Dropdown';

const OPTIONS_MAP = {
  priceMin: PRICE_OPTIONS,
  priceMax: PRICE_OPTIONS,
  bedsMin: BEDS_OPTIONS,
  bedsMax: BEDS_OPTIONS,
  bathsMin: BATHS_OPTIONS,
  listingStatus: [...LISTING_STATUS_OPTIONS, LISTING_STATUS_SOLD_FILTER],
  dateListed: DATE_LISTED_OPTIONS,
  saleDate: SALE_DATE_OPTIONS,
  sqftMin: SQFT_OPTIONS,
  sqftMax: SQFT_OPTIONS,
  lotSizeMin: LOT_SIZE_OPTIONS,
  lotSizeMax: LOT_SIZE_OPTIONS,
  yearBuiltMin: YEAR_BUILT_OPTIONS,
  yearBuiltMax: YEAR_BUILT_OPTIONS,
  featured: [
    { label: 'Featured', value: true },
    { label: 'Not Featured', value: false },
    { label: 'Default', value: null }
  ]
}

export const getAppliedPropertyFilter = ({
  field,
  filters
}: IGetAppliedPropertyFilterProps): IDropdownOption | null => {
  let appliedOption = {} as IDropdownOption

  if (!field || !filters?.length) {
    return null
  }

  const index = findObjectIndicesByKey(filters, 'label', field)[0]
  const appliedFilter = filters[index]
  if (appliedFilter) {
    const fieldOptions = OPTIONS_MAP[field] as IDropdownOption[]
    const filterVal = appliedFilter.value as string
    appliedOption =
      fieldOptions.filter(o => o.value === appliedFilter.value)[0] ||
      fieldOptions.filter(o => o.value === parseInt(filterVal, 10))[0]
  }

  return appliedOption?.label ? appliedOption : null
}
