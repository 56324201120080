export function SparklesIcon({ width = 14, height = 14 }: { width?: number; height?: number }) {
  return (
    <svg
      viewBox="0 0 13 14"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="#f1b707"
    >
      <g clipPath="url(#clip0_9896_50641)">
        <path d="M8.20197 2.93433C8.09485 2.97479 8.02344 3.07715 8.02344 3.19141C8.02344 3.30566 8.09485 3.40802 8.20197 3.44849L9.54688 3.95312L10.0515 5.29803C10.092 5.40515 10.1943 5.47656 10.3086 5.47656C10.4229 5.47656 10.5252 5.40515 10.5657 5.29803L11.0703 3.95312L12.4152 3.44849C12.5223 3.40802 12.5938 3.30566 12.5938 3.19141C12.5938 3.07715 12.5223 2.97479 12.4152 2.93433L11.0703 2.42969L10.5657 1.08478C10.5252 0.977661 10.4229 0.90625 10.3086 0.90625C10.1943 0.90625 10.092 0.977661 10.0515 1.08478L9.54688 2.42969L8.20197 2.93433ZM5.28839 2.65106C5.2265 2.51538 5.09082 2.42969 4.94324 2.42969C4.79565 2.42969 4.65997 2.51538 4.59808 2.65106L3.34125 5.36469L0.627625 6.61914C0.491943 6.68103 0.40625 6.81671 0.40625 6.96667C0.40625 7.11664 0.491943 7.24994 0.627625 7.31183L3.34363 8.56628L4.5957 11.2799C4.65759 11.4156 4.79327 11.5013 4.94086 11.5013C5.08844 11.5013 5.22412 11.4156 5.28601 11.2799L6.54047 8.5639L9.25647 7.30945C9.39215 7.24756 9.47784 7.11188 9.47784 6.96429C9.47784 6.81671 9.39215 6.68103 9.25647 6.61914L6.54285 5.36707L5.28839 2.65106ZM9.54688 10.0469L8.20197 10.5515C8.09485 10.592 8.02344 10.6943 8.02344 10.8086C8.02344 10.9229 8.09485 11.0252 8.20197 11.0657L9.54688 11.5703L10.0515 12.9152C10.092 13.0223 10.1943 13.0938 10.3086 13.0938C10.4229 13.0938 10.5252 13.0223 10.5657 12.9152L11.0703 11.5703L12.4152 11.0657C12.5223 11.0252 12.5938 10.9229 12.5938 10.8086C12.5938 10.6943 12.5223 10.592 12.4152 10.5515L11.0703 10.0469L10.5657 8.70197C10.5252 8.59485 10.4229 8.52344 10.3086 8.52344C10.1943 8.52344 10.092 8.59485 10.0515 8.70197L9.54688 10.0469Z" />
      </g>
      <defs>
        <clipPath id="clip0_9896_50641">
          <rect width="13" height="13" fill="#f1b707" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
