import { LocationProvider } from '../../contexts/LocationContext'
import { PropertyProvider } from '../../contexts/PropertyContext'
import { SavedHomesProvider } from '../../contexts/SavedHomesContext'
import { UserProvider } from '../../contexts/UserContext'
import { UltraAgentBody } from '../../components/UltraAgent'
import { Footer } from '../../components/Footer'
import { useParams } from 'react-router-dom'


export const UltraAgent = () => {
  const { propertyUuid } = useParams()

  return (
    <LocationProvider areaSlug={null}>
      <UserProvider>
        <SavedHomesProvider>
          <PropertyProvider propertyUuid={propertyUuid} includeAgent>
            <UltraAgentBody propertyUuid={propertyUuid} />
          </PropertyProvider>
        </SavedHomesProvider>
        <Footer
          areaFooterData={[]}
          defaultFooterData={[]}
          showTopAgentsLinks={false}
        />
      </UserProvider>
    </LocationProvider>
  )
}
