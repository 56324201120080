import { PROPERTIES_API_URL } from '../../constants'
import { requestData } from '../../utils/requestData'
import { buildPropertyInterface } from '../../utils/buildPropertyInterface'
import { PropertyType, propertyFields } from '../../contexts/PropertiesContext'

const targetPropertyCount = 12
const pageSize = 24

export const getRecommendedListings = async (slug, leadLat, leadLong) => {
  const baseFilters = [
    { field: 'listing_bedrooms_total', operator: '>=', value: 2 },
    { field: '_listing_link', operator: '=', value: true },
    { field: '_url_eligible', operator: '=', value: true },
    { field: 'listing_mls_status', operator: '=', value: 'Active,Coming Soon' },
    {
      field: 'listing_property_sub_type',
      operator: '=',
      value: 'single_family_home,condo,townhome,mobile_manufactured'
    }
  ]

  let price
  const params = window.location.search.split('&')
  const priceParam = params.find(param => param.includes('listing_list_price'))
  if (priceParam) {
    const parts = priceParam.includes('%3D') ? priceParam.split('%3D') : priceParam.split('=')
    price = Number(parts[parts.length - 1])
    if (price && !isNaN(price)) {
      const newMax = price * 1.25
      baseFilters.push({ field: 'listing_list_price', operator: '<=', value: newMax })
    }
  }

  const filtersWithSlug = [...baseFilters, { field: 'slug', operator: '=', value: slug }]

  const filtersWithLatLong = [
    ...baseFilters,
    { field: 'latitude', operator: '=', value: leadLat },
    { field: 'longitude', operator: '=', value: leadLong },
    { field: 'distance', operator: '=', value: '15' }
  ]

  const formatAndSort = (data: PropertyType[]) => {
    let dedupedListings: PropertyType[] = []
    data.forEach(listing => {
      if (dedupedListings.findIndex(l => listing.uuid === l.uuid) === -1) {
        dedupedListings.push(listing)
      }
    })

    const propertyListings = dedupedListings.map(prop => {
      const property = prop as PropertyType

      return buildPropertyInterface({
        property: property,
        listing: null,
        savedHomes: []
      })
    })

    const featuredSorted = propertyListings
      .filter(p => p.featured)
      .sort((a, b) => (a.price || 0) - (b.price || 0))
    const nonFeaturedSorted = propertyListings
      .filter(p => !p.featured)
      .sort((a, b) => (a.price || 0) - (b.price || 0))

    return [...featuredSorted, ...nonFeaturedSorted].slice(0, targetPropertyCount)
  }

  const listings = await requestData({
    url: PROPERTIES_API_URL,
    method: 'get',
    filters: filtersWithSlug,
    fields: propertyFields,
    sorts: [{ label: 'Ultra-Low Rates', field: 'featured', operator: 'asc' }],
    size: pageSize,
    page: 1,
    usePagination: true
  }).then(({ data, meta, links, error }) => {
    return data
  })

  let additionalListings = []

  if (listings.length < targetPropertyCount && leadLat && leadLong) {
    additionalListings = await requestData({
      url: PROPERTIES_API_URL,
      method: 'get',
      filters: filtersWithLatLong,
      fields: propertyFields,
      sorts: [{ label: 'Ultra-Low Rates', field: 'featured', operator: 'asc' }],
      size: pageSize,
      page: 1,
      usePagination: true
    }).then(({ data, meta, links, error }) => {
      return data
    })
  }

  return formatAndSort([...listings, ...additionalListings])
}
