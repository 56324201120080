import { useState } from 'react'
import styled from 'styled-components'
import { Header, Body } from '../common/Modal'
import { Heading3, Text, TextSmall } from '../common/Text'
import { Flex } from '../common/Flex'
import { RangeButtons } from '../common/RangeButtons'
import { PriceRangeSelect } from '../Filters/PriceRangeSelect'
import { ElectricBlueButton } from '../common/Button'
import { SearchIcon } from '../icons/SearchIcon'
import {
  BREAKPOINT_S,
  darkBlue,
  BEDS_OPTIONS,
  COLORS,
  PRICE_OPTIONS,
  BEDS_MIN_FILTER,
  BEDS_MAX_FILTER
} from '../../constants'
import { hasFiltersSelected } from '../../utils/hasFiltersSelected'
import { formatSavedSearch } from '../../utils/formatSavedSearch'
import { ACTION_TAP, trackEvent } from '../../utils/tracking'
import { useDynamicMinOptions } from '../../hooks/useDynamicMinOptions'
import { useDynamicMaxOptions } from '../../hooks/useDynamicMaxOptions'
import { ISavedSearchPromptModal } from './'

const StyledBody = styled(Body)`
  padding-bottom: 24px;
  @media (min-width: ${BREAKPOINT_S}px) {
    flex: 1;
  }

  @media (max-width: ${BREAKPOINT_S}px) {
    padding: 24px 16px;
  }
`

const StyledHeading = styled(Heading3)`
  margin-bottom: 24px;

  @media (min-width: ${BREAKPOINT_S}px) {
    margin-bottom: 8px;
  }
`

const StyledText = styled(Text)`
  padding-bottom: 24px;
  
  @media (min-width: ${BREAKPOINT_S}px) {
   padding-bottom: 32px;
  }
`

const StyledFooter = styled(Flex)`
  margin: 0 16px;
  button {
    width: 100%;
    height: 48px;
  }

  @media (min-width: ${BREAKPOINT_S}px) {
    margin: 0;
    padding: 0 32px 32px;
  }
`

const SearchDisplay = styled.div`
  display: flex;
  margin-bottom: 24px;
`

const SearchDisplayText = styled.div`
  .search-area {
    font-weight: bold;
    margin-bottom: 4px;
  }
`

const IconSpace = styled.div`
  flex-basis: 32px;
  padding-left: 4px;
  padding-top: 4px;
`

const StyledPriceContainer = styled.div`
  .range-dropdown > div {
    padding-top: 24px;
    padding-bottom: 32px;
  }
`

const BedsLabel = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 700;
`

const StyledFrequencyDisplay = styled.div`
  margin-top: 24px;
  
  @media (min-width: ${BREAKPOINT_S}px) {
    margin-top: 32px;
    margin-bottom: 8px;
  }
`

export const SavedSearchPromptModal: React.FC<ISavedSearchPromptModal> = ({
  locationTitle,
  filters,
  appliedFilters,
  closeModal,
  attemptSaveSearch
}) => {
  const [filterSelectMode, setFilterSelectMode] = useState(!hasFiltersSelected(filters))

  const {
    priceMin: { setPriceMin, selectedPriceMin, setSelectedPriceMin },
    priceMax: { setPriceMax, selectedPriceMax, setSelectedPriceMax },
    bedsMin: { setBedsMin, selectedBedsMin, setSelectedBedsMin },
    bedsMax: { setBedsMax, selectedBedsMax, setSelectedBedsMax },
    bathsMin: { selectedBathsMin },
    listingStatus: { selectedListingStatus },
    propertyType: { selectedPropertyTypes },
    sqftMin: { selectedSqftMin },
    sqftMax: { selectedSqftMax },
    lotSizeMin: { selectedLotSizeMin },
    lotSizeMax: { selectedLotSizeMax },
    yearBuiltMin: { selectedYearBuiltMin },
    yearBuiltMax: { selectedYearBuiltMax }
  } = appliedFilters

  const { minOptions: priceMinOptions } = useDynamicMinOptions(
    selectedPriceMax.value,
    PRICE_OPTIONS
  )
  const { maxOptions: priceMaxOptions } = useDynamicMaxOptions(
    selectedPriceMin.value,
    PRICE_OPTIONS
  )

  const applyFilters = () => {
    setPriceMin(selectedPriceMin)
    setPriceMax(selectedPriceMax)
    setBedsMin(selectedBedsMin)
    setBedsMax(selectedBedsMax)
  }

  const formattedSearchString = filterSelectMode ? '' : formatSavedSearch({
    priceMin: selectedPriceMin.value,
    priceMax: selectedPriceMax.value,
    bedsMin: selectedBedsMin.value,
    bedsMax: selectedBedsMax.value,
    bathsMin: selectedBathsMin.value,
    propertyTypes: selectedPropertyTypes.value,
    status: selectedListingStatus.value,
    sqftMin: selectedSqftMin.value,
    sqftMax: selectedSqftMax.value,
    lotSizeMin: selectedLotSizeMin.value,
    lotSizeMax: selectedLotSizeMax.value,
    yearBuiltMin: selectedYearBuiltMin.value,
    yearBuiltMax: selectedYearBuiltMax.value
  })

  const saveSearch = () => {
    applyFilters()
    closeModal()
    attemptSaveSearch('daily')
    trackEvent(ACTION_TAP, 'attempt_save_search_SEM_test_saved_search_modal')
  }

  const renderFilters = () => {
    return (
      <div className='search-prompt-filters'>
        <StyledPriceContainer>
          <PriceRangeSelect
            priceMin={selectedPriceMin}
            priceMinOptions={priceMinOptions}
            priceMax={selectedPriceMax}
            priceMaxOptions={priceMaxOptions}
            selectPriceMin={setSelectedPriceMin}
            selectPriceMax={setSelectedPriceMax}
          />
        </StyledPriceContainer>
        <div>
          <BedsLabel>Beds</BedsLabel>
          <RangeButtons
            rangeOptions={BEDS_OPTIONS}
            min={selectedBedsMin || BEDS_MIN_FILTER}
            setMin={setSelectedBedsMin}
            max={selectedBedsMax || BEDS_MAX_FILTER}
            setMax={setSelectedBedsMax}
          />
          <TextSmall as='label' color={COLORS.coolGray3}>
            Tap 2 numbers to select a range.
          </TextSmall>
        </div>
      </div>
    )
  }

  const renderSearchString = () => (
    <SearchDisplay className='search-summary'>
      <IconSpace>
        <SearchIcon />
      </IconSpace>
      <SearchDisplayText>
        <div className='search-area'>{locationTitle}</div>
        <div className='search-details'>{formattedSearchString}</div>
      </SearchDisplayText>
    </SearchDisplay>
  )

  return (
    <>
      <Header closeModal={closeModal} />
      <StyledBody>
        <StyledHeading as='h3'>Create a saved search</StyledHeading>
        <StyledText as='p' color={darkBlue}>
          {`Save your search criteria for ${locationTitle} and get updated when similar homes hit the market.`}
        </StyledText>
        { filterSelectMode ? renderFilters() : renderSearchString() }
        <StyledFrequencyDisplay>
          Email notifications: Daily
        </StyledFrequencyDisplay>
      </StyledBody>
      <StyledFooter>
        <ElectricBlueButton.Medium
          onClick={saveSearch}
          data-testid='search-prompt-save'
        >
          Save
        </ElectricBlueButton.Medium>
      </StyledFooter>
    </>
  )
}
