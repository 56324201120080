import { useState } from 'react'
import styled from 'styled-components'
import { Flex } from '../Flex'
import { ChevronDown } from '../../icons/ChevronDown'
import { coolGray50 } from '../../../constants'

const StyledAccordion = styled.div`
  border-bottom: 1px solid ${coolGray50};

  svg.rotate {
    transform: rotate(180deg);
  }
`

const StyledFlex = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`

const Title = styled.h3`
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
`

const Body = styled.div`
  margin-bottom: 0;
  line-height: 28px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease 0s;
  transition: margin-bottom 0.2s ease 0s;

  &.show-body {
    margin-bottom: 23px;
    max-height: 200px;
  }
`

const ChevronContainer = styled.div`
  margin-top: 16px;
  transition: transform 0.2s ease 0s;

  &.rotate {
    transform: rotate(180deg);
  }
`

export const Accordion: React.FC<{
  title: string | null
  body: string | null
}> = ({ title, body }) => {
  const [showBody, setShowBody] = useState(false)

  return (
    <StyledAccordion>
      <StyledFlex justify="space-between" align="start" onClick={() => setShowBody(!showBody)}>
        <Title>{title}</Title>
        <ChevronContainer className={showBody && 'rotate'}>
          <ChevronDown />
        </ChevronContainer>
      </StyledFlex>
      <Body dangerouslySetInnerHTML={{ __html: body }} className={showBody && 'show-body'} />
    </StyledAccordion>
  )
}
