import {
  fontSizes,
  fontWeights,
  letterSpacings
} from '@homelight/particle-tokens'

export const {
  fontSizeXxl,
  fontSizeXl,
  fontSizeLg,
  fontSizeMd,
  fontSizeSm,
  fontSizeXs,
  fontSizeMicro  
} = fontSizes

export const {
  fontWeightRegular,
  fontWeightSemibold,
  fontWeightBold
} = fontWeights

export const {
  spacingN1,
  spacingN8,
  spacingN6,
  spacingN5
} = letterSpacings
