import React from 'react'
import { formatPaginationSummary } from '../../../../utils/formatPaginationSummary'
import { IPropertiesContext } from '../../../../contexts/PropertiesContext'
import { Flex } from '../../Flex'

interface IPaginationSummary {
  mt?: string,
  ml?: string,
  paginationData: IPropertiesContext
}

export const PaginationSummary: React.FC<IPaginationSummary> = ({ mt, ml, paginationData, fontSize }) => {
  const {
    pageNumbers: { last },
    currentPage,
    totalPropertiesCount,
    pageSize
  } = paginationData

  const lastPage = last || 1

  const lastPropertyIndex = !lastPage ? totalPropertiesCount : currentPage * pageSize
  const firstPropertyIndex =
    lastPropertyIndex && lastPropertyIndex > pageSize ? lastPropertyIndex - (pageSize - 1) : 1
  const paginationSummary = formatPaginationSummary(
    firstPropertyIndex,
    lastPropertyIndex,
    totalPropertiesCount
  )

  return (
    <Flex justify="center" mt={mt} ml={ml} fontSize={fontSize} >
      {paginationSummary}
    </Flex>
  )
}
