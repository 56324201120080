import { useState } from 'react'
import styled from 'styled-components'
import { StyledModalBackground, StyledModalContainer } from '../common/Modal'
import { ElectricBlueButton } from '../common/Button'
import { XIcon } from'../icons/XIcon'
import { COLORS } from '../../constants/colors'
import { WorkingWithAgentIcon } from '../icons/WorkingWithAgentIcon'
import { Flex } from '../common/Flex'
import { requestData } from '../../utils/requestData'
import { QUEUE_LEAD, BREAKPOINT_S } from '../../constants'
import { formatPhone } from '../../utils/formatPhone'

const ModalContainer = styled(StyledModalContainer)`
  position: absolute;
  bottom: 0;
  top: 10%;

  @media (min-width: ${BREAKPOINT_S}px) {
    position: relative;
    top: 0;
  }
`

const StyledContainer = styled(Flex)`
  width: 100%;
  padding: 0 32px 36px 32px;
  box-sizing: border-box;

  @media (min-width: ${BREAKPOINT_S}px) {
    width: 512px;
  }
`

const StyledHeader = styled.h2`
  color: ${COLORS.darkBlue};
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -1px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 16px;
`

const StyledText = styled.p`
  color: ${COLORS.darkBlue}};
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 0;
`

const StyledWorkingWithAgentIcon = styled(WorkingWithAgentIcon)`
  margin: auto;
`

const StyledButton = styled(ElectricBlueButton.Large)`
  margin-top: 40px;
  width: 100%;
`

const StyledSubText = styled.p`
  color: ${COLORS.coolGray1};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`

const StyledBanner = styled.p`
  align-self: flex-end;
  margin-top: 24px;
`

const FinishedButton = styled(ElectricBlueButton.Large)`
  background-color: white;
  color: ${COLORS.electricBlue};
  margin-top: 40px;
  position: absolute;
  bottom: 36px;
  width: calc(100% - 48px);

  @media (min-width: ${BREAKPOINT_S}px) {
    position: relative;
    margin: 0;
    margin-top: 40px;
    width: 100%;
  }
`

export const UltraRateModal = ({ setUltraRateModal }) => {
  return (
    <>
      <StyledModalBackground>
        <ModalContainer>
          <StyledContainer direction="column" align="baseline">
            <StyledBanner onClick={() => setUltraRateModal(false)}>
              <XIcon onClick={() => setUltraRateModal(false)} />
            </StyledBanner>
            <StyledHeader>HomeLight Rate</StyledHeader>
            <StyledText>
              All information presented here is for illustrative purposes only and is based on
              assumptions and market data. The HomeLight Rate reflects an
              estimated blended rate based on assumptions and market data including, among others,
              an assumed down payment amount, the interest rate and amount of the assumable loan
              attached to this property, and market interest rates for second mortgage loans.
            </StyledText>
          </StyledContainer>
        </ModalContainer>
      </StyledModalBackground>
    </>
  )
}
