import styled from 'styled-components'
import { fontWeightBold, UTM_PARAMS_SHARE } from '../../../constants'
import { trackEvent, ACTION_TAP, ACTION_SHARE } from "../../../utils/tracking"
import { postShortUrl } from '../../../utils/postShortUrl'
import { ArrowShareIcon } from '../../icons/ArrowShareIcon'
import { StyledCircularIconTrigger } from '../../Filters/FilterPopover/Trigger'
import { TransparentButton } from '../../common/Button'
import { IShareProperty } from './'
import { TextSmall } from '../../common/Text'

const Container = styled('div')`
  margin-right: 8px;
`

const StyledIconTrigger = styled(TransparentButton)`
  padding-left: 7px;
  padding-right: 7px;
`

const StyledTextBold = styled(TextSmall)`
  font-weight: ${fontWeightBold};
  margin-left: 8px;
`

export const ShareProperty: React.FC<IShareProperty> = ({
  url,
  address,
  shareLabel = '',
  shareProperty
}) => {
  const Trigger = Boolean(shareLabel.length) ? StyledIconTrigger : StyledCircularIconTrigger
  const handleMobileShare = async () => {
    const mobileNativeUrl = await postShortUrl(`${url}${UTM_PARAMS_SHARE}mobile_native`)

    trackEvent(ACTION_TAP, "Share")

    if (navigator?.share) {
      navigator.share({
        title: "Homes on HomeLight",
        text: "Check out this home on HomeLight",
        url: mobileNativeUrl,
      })
      trackEvent(ACTION_SHARE, "Mobile_Native")
    }
  }

  const onOpenSharePropertyModal = () => {
    trackEvent(ACTION_TAP, "Share")
    shareProperty(url, address)
  }

  const useNativeShare = navigator?.share
  // @ts-ignore
  const onTriggerClick = useNativeShare ? handleMobileShare : onOpenSharePropertyModal

  return (
    <Container>
      <Trigger onClick={() => onTriggerClick()}>
        <ArrowShareIcon />
        {Boolean(shareLabel.length) && (
          <StyledTextBold>
            {shareLabel}
          </StyledTextBold>
        )}
      </Trigger>
    </Container>
  )
}
