import styled from 'styled-components'
import {
  darkBlue,
  fontSizeXxl,
  fontSizeXl,
  fontSizeLg,
  fontSizeMd,
  fontSizeXs,
  fontSizeSm,
  fontWeightRegular,
  fontWeightBold,
  spacingN1,
  spacingN8,
  spacingN6,
  spacingN5
} from '../../../constants'

export const TextNormalize = styled.span`
  margin: 0;
`

export const Heading1 = styled.h1`
  font-size: ${fontSizeXxl}px;
  line-height: 48px;
  font-weight: ${fontWeightBold};
  letter-spacing: ${spacingN1};
  color: ${darkBlue};
`

export const Heading2 = styled(TextNormalize)`
  font-size: ${fontSizeXl}px;
  line-height: 40px;
  font-weight: ${fontWeightBold};
  letter-spacing: ${spacingN8};
  color: ${darkBlue};
`

export const Heading3 = styled(TextNormalize)`
  font-size: ${fontSizeLg}px;
  line-height: 32px;
  font-weight: ${fontWeightBold};
  letter-spacing: ${spacingN6};
  color: ${darkBlue};
`

export const Heading4 = styled(TextNormalize)<{color?: string}>`
  font-size: ${fontSizeMd}px;
  line-height: 28px;
  font-weight: ${fontWeightBold};
  letter-spacing: ${spacingN5};
  color: ${props => props.color ? props.color : darkBlue};
`

export const Heading5 = styled.h5`
  font-size: ${fontSizeXs}px;
  line-height: 22px;
  font-weight: ${fontWeightBold};
  color: ${darkBlue};
  margin: 0;
  padding: 0;
`

export const Heading6 = styled.h6`
  font-size: ${fontSizeSm}px;
  line-height: 22px;
  font-weight: ${fontWeightBold};
  color: ${darkBlue};
`

export const Paragraph = styled.p<{color?: string}>`
  font-size: ${fontSizeXs}px;
  line-height: 28px;
  font-weight: ${fontWeightRegular};
  color: ${props => props.color ? props.color : darkBlue};
`

export const Text = styled(TextNormalize)<{color?: string}>`
  font-size: ${fontSizeXs}px;
  line-height: 24px;
  font-weight: ${fontWeightRegular};
  color: ${props => props.color ? props.color : darkBlue};
`

export const TextBold = styled(TextNormalize)<{color?: string}>`
  font-size: ${fontSizeXs}px;
  line-height: 24px;
  font-weight: ${fontWeightBold};
  color: ${props => props.color ? props.color : darkBlue};
`

export const TextSmall = styled(TextNormalize)<{color?: string}>`
  font-size: ${fontSizeSm}px;
  line-height: 20px;
  font-weight: ${fontWeightRegular};
  color: ${props => props.color ? props.color : darkBlue};
`

export const TextXSmall = styled(TextNormalize)<{color?: string}>`
  font-size: 12px;
  line-height: 16px;
  font-weight: ${fontWeightRegular};
  color: ${props => props.color ? props.color : darkBlue};
`
