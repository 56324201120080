export const TL_LAT_FILTER = {
  field: "top_left_lat",
  value: "",
  operator: "="
}

export const TL_LON_FILTER = {
  field: "top_left_lon",
  value: "",
  operator: "="
}

export const BR_LAT_FILTER = {
  field: "bottom_right_lat",
  value: "",
  operator: "="
}

export const BR_LON_FILTER = {
  field: "bottom_right_lon",
  value: "",
  operator: "="
}

export const STATE_OR_PROVINCE_FILTER = {
  field: "state_or_province",
  value: "",
  operator: "="
}

export const COUNTY_OR_PARISH_FILTER = {
  field: "county_or_parish",
  value: "",
  operator: "="
}

export const CITY_FILTER = {
  field: "city",
  value: "",
  operator: "="
}

export const POSTAL_CODE_FILTER = {
  field: "postal_code",
  value: "",
  operator: "="
}

export const HL_FULL_ADDRESS_FILTER = {
  field: "hl_full_address",
  value: "",
  operator: "="
}

export const SLUG_FILTER = {
  field: "slug",
  value: "",
  operator: "="
}

export const STREET_NUMBER_FILTER = {
  field: 'street_number',
  value: '',
  operator: '='
}

export const STREET_NAME_FILTER = {
  field: 'street_name',
  value: '',
  operator: '='
}

export const LOCATION_FILTERS = [
  TL_LAT_FILTER,
  TL_LON_FILTER,
  BR_LAT_FILTER,
  BR_LON_FILTER,
  STATE_OR_PROVINCE_FILTER,
  COUNTY_OR_PARISH_FILTER,
  CITY_FILTER,
  POSTAL_CODE_FILTER,
  HL_FULL_ADDRESS_FILTER,
  SLUG_FILTER,
]

export const NAMED_LOCATION_FILTERS = [
  STATE_OR_PROVINCE_FILTER,
  COUNTY_OR_PARISH_FILTER,
  CITY_FILTER,
  POSTAL_CODE_FILTER,
  HL_FULL_ADDRESS_FILTER,
  SLUG_FILTER
]

export const NAMED_LOCATION_FILTER_FIELDS = [
  'state_or_province',
  'county_or_parish',
  'city',
  'postal_code',
  'hl_full_address',
  'slug',
]

export const MAP_LOCATION_FIELDS  = [
  "top_left_lat",
  "top_left_lon",
  "bottom_right_lat",
  "bottom_right_lon",
]
