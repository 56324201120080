import styled from 'styled-components'
import { 
  darkBlue,
  electricBlue,
  white,
  whiteHover
} from '../../../../constants'
import { TransparentButton } from '../../Button'
import { IPageLink } from './types'

const StyledPageLink = styled(TransparentButton)<{active: boolean}>`
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  background-color: ${props => props.active ? electricBlue : white };
  color: ${props => props.active ? white : darkBlue};
  border-radius: 16px;
  text-align: center;
  cursor: pointer;

  &:hover, &:focus-visible {
    background-color: ${props => props.active ? electricBlue : whiteHover};
  }
`

export const PageLink: React.FC<IPageLink> = ({
  page,
  active,
  clickPageLink
}) => {
  return (
    <StyledPageLink
      role="link"
      active={active}
      onClick={clickPageLink}
    >
      {page}
    </StyledPageLink>
  )
}