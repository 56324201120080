import { getParamObjects } from "../parseParams";

export const getUtmParamsFromUrl = () => {
    const searchString = window.location.search
    const {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_term: utmTerm
    } = getParamObjects(searchString)

    return {
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
        utmTerm
    }
}
