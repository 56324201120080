import { toTitleCase } from '../../toTitleCase'
import { stateNameOf } from '../../stateUtils'
import { isCounty } from '../isCounty'

export const parseCountySlug = (slug: string) => {
  if (!slug || !isCounty(slug)) {
      return {
          county: '',
          state: ''
      }
  }
  const splitSlug: string[] = slug.split("-county-");
  let stateCode = splitSlug[1];
  let stateName = stateCode ? stateNameOf(stateCode) : "";
  let county = splitSlug[0].replace("-", " ");

  return {
      county: toTitleCase(county),
      state: stateName
  };
}
