import styled from 'styled-components'
import { Button } from './'
import { 
  white,
  darkBlue,
  electricBlue,
  coolGray40
} from '../../../constants'

export const PrimaryButton = styled(Button)<{
  backgroundColor?: string,
  color?: string
}>`
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 6px;
  text-decoration: none;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : white};
  color: ${props => props.color ? props.color : darkBlue};
  &:focus-visible {
    border-color: ${darkBlue};
  }
  &:focus {
   outline: none;
   border-color: ${electricBlue};
 }
`

export const Placeholder = styled.span`
  color: ${coolGray40};
`
