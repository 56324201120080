import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LoadingSpinner } from '../common/LoadingSpinner'
import { TextSmall } from '../common/Text'
import { OrangeButton } from '../common/Button'
import { Toast } from '../common/Toast'
import { Header, StyledModalBackground, StyledModalContainer } from '../common/Modal'
import { InfoIcon } from '../icons/InfoIcon'
import { XIcon } from '../icons/XIcon'
import { formatPhone } from '../../utils/formatPhone'
import { PiiForm, PiiInput, PiiStep } from '../MortgageDeals/styles'
import {
  actionOrange,
  BREAKPOINT_S,
  BREAKPOINT_M,
  coolGray50,
  electricBlue,
  mistBlue,
  white,
} from '../../constants'

const ModalContainer = styled(StyledModalContainer) `
  @media (max-width: ${BREAKPOINT_M}px) {
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const AgentFormSection = styled.div`
  margin: 16px 0 24px;
`

const AgentInfoTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 20px;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: ${BREAKPOINT_S}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const AgentPiiForm = styled(PiiForm)`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: ${BREAKPOINT_S}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const AgentPiiStep = styled(PiiStep)`
  @media (min-width: ${BREAKPOINT_M}px) {
    flex: 0 0 calc(50% - 12px);
  }
`

const InputLabel = styled.div`
  margin-bottom: 6px;
`

const SubmitButtonWrapper = styled.div`
  padding: 0 16px 16px;
  @media (min-width: ${BREAKPOINT_S}px) {
    padding: 0 24px 36px;
  }
  @media (min-width: ${BREAKPOINT_M}px) {
    width: 100%;
    position: relative;
    border-top: solid 1px ${coolGray50};
    padding-top: 16px;
    box-sizing: border-box;
  }
`

const SubmitAgentInfoButton = styled(OrangeButton)`
  width: 100%;
  
  @media (min-width: ${BREAKPOINT_M}px) {
    position: absolute;
    right: 24px;
    width: unset;
    padding: 8px 24px;
  }
`

const InfoHighlight = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  margin: 0 16px 16px;
  padding: 12px;
  background-color: ${mistBlue};
  color: #0C6EBA;
  border-radius: 6px;
  
  @media (min-width: ${BREAKPOINT_M}px) {
    margin: 0 24px 24px;
  }
  
  .info-icon {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
  }
  
  .close-icon {
    flex: 0 0 12px;
  }
`

const InfoText = styled.div`
  font-size: 14px;
  color: #0C6EBA;
`

export const AgentInfoForm = ({ submitAgent, onClose }) => {
  const [nameVal, setNameVal] = useState('')
  const [emailVal, setEmailVal] = useState('')
  const [phoneVal, setPhoneVal] = useState('')
  const [nameIsValid, setNameIsValid] = useState(true)
  const [phoneOrEmailIsValid, setPhoneOrEmailIsValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showInfoHighlight, setShowInfoHighlight] = useState(true)

  const nameValIsPresent = nameVal?.length > 0
  const emailOrPhonePresent = emailVal?.length > 0 || phoneVal?.length > 0
  const submitEnabled = nameValIsPresent && emailOrPhonePresent

  const handleSubmit = () => {
    if (!nameValIsPresent) {
      setNameIsValid(false)
      return null
    }
    if (!emailOrPhonePresent) {
      setPhoneOrEmailIsValid(false)
      return null
    }

    setNameIsValid(true)
    setPhoneOrEmailIsValid(true)
    setLoading(true)

    submitAgent(
      {
        name: nameVal,
        email: emailVal,
        phone: phoneVal
      }
    )
  }

  return (
    <StyledModalBackground>
      <ModalContainer>
        <Header
          closeModal={onClose}
          hideBorder
        />
        <AgentFormSection>
          <AgentInfoTitle>
            Enter your agent information
          </AgentInfoTitle>
          <AgentPiiForm>
            <AgentPiiStep>
              <TextSmall as="label">
                <InputLabel>Agent full name</InputLabel>
                <PiiInput
                  variation="large"
                  type="text"
                  inputState={nameIsValid ? 'valid' : 'error'}
                  errorMessage={nameIsValid ? null : "Please enter your agent's full name"}
                  value={nameVal}
                  placeholder="Enter your agent full name"
                  onChange={e => setNameVal(e.target.value)}
                />
              </TextSmall>
            </AgentPiiStep>
            <div>
              Include at least one of the below
            </div>
            <AgentPiiStep>
              <TextSmall as="label">
                <InputLabel>Agent email address</InputLabel>
                <PiiInput
                  variation="large"
                  type="email"
                  value={emailVal}
                  inputState={phoneOrEmailIsValid ? 'valid' : 'error'}
                  errorMessage={phoneOrEmailIsValid ? null : "Please enter at least one: email or phone"}
                  placeholder="Enter your agent email address"
                  onChange={e => setEmailVal(e.target.value)}
                />
              </TextSmall>
            </AgentPiiStep>
            <AgentPiiStep>
              <TextSmall as="label">
                <InputLabel>Agent phone number</InputLabel>
                <PiiInput
                  variation="large"
                  type="tel"
                  value={phoneVal}
                  inputState={phoneOrEmailIsValid ? 'valid' : 'error'}
                  errorMessage={phoneOrEmailIsValid ? null : "Please enter at least one: email or phone"}
                  placeholder="Enter your agent phone number"
                  onChange={e => setPhoneVal(formatPhone(e.target.value))}
                />
              </TextSmall>
            </AgentPiiStep>
          </AgentPiiForm>
          {showInfoHighlight && (
            <InfoHighlight>
              <InfoIcon fill="#0C6EBA" />
              <InfoText>
                We'll only use this information to inform your agent that you are using HomeLight to search for your home.
              </InfoText>
              <XIcon color="#0C6EBA" className="close-icon" onClick={() => setShowInfoHighlight(false)} />
            </InfoHighlight>
          )}
          <SubmitButtonWrapper>
            <SubmitAgentInfoButton onClick={handleSubmit} disabled={!submitEnabled}>
              {loading ? (
                <LoadingSpinner width="18px" color={electricBlue} />
              ) : 'Submit'}
            </SubmitAgentInfoButton>
          </SubmitButtonWrapper>
          <Toast show={error} state="error">
            Sorry, something went wrong submitting
          </Toast>
        </AgentFormSection>
      </ModalContainer>
    </StyledModalBackground>
  )
}
