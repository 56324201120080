export function toNearestThousandth(num: number = 0) {
  return Math.round(Math.round(num) / 1000)
}

export function formatPrice(num: number | null, options?: { noSign: boolean }) {
  if (!num) {
    return '$-'
  }
  const USD = abbreviateNumber(Math.round(num))

  return options?.noSign ? USD : `$${USD}`
}

const isRoundDifferenceValid = (num: number, difference: number): boolean => {
  return 1e5 - (num % 1e5) <= difference
}

export const toShortNumber = (
  num: number,
  maxRoundNumber: number,
  fractionDigits: number = 0,
  roundToCeiling: boolean = false
): number => {
  return roundToCeiling
    ? +(Math.ceil(num) / maxRoundNumber).toFixed(fractionDigits)
    : +(num / maxRoundNumber).toFixed(fractionDigits)
}

const abbreviateNumber = (num: number) => {
  if (num < 1e3) return num.toString()

  let shortValue: string = ''

  if (num >= 1e3 && num < 9995e2) {
    shortValue = isRoundDifferenceValid(num, 5e2)
      ? `${toShortNumber(num, 1e3, 0, true)}K`
      : `${toShortNumber(num, 1e3, 0)}K`
  }
  if (num >= 9995e2 && num < 1e9) {
    shortValue = isRoundDifferenceValid(num, 5e3)
      ? `${toShortNumber(num, 1e6, 1, true)}M`
      : `${toShortNumber(num, 1e6, 2)}M`
  }
  if (num >= 1e9 && num < 1e12) {
    shortValue = isRoundDifferenceValid(num, 5e3)
      ? `${toShortNumber(num, 1e9, 1, true)}B`
      : `${toShortNumber(num, 1e9, 2)}B`
  }
  if (num >= 1e12) {
    shortValue = isRoundDifferenceValid(num, 5e3)
      ? `${toShortNumber(num, 1e12, 1, true)}T`
      : `${toShortNumber(num, 1e12, 2)}T`
  }

  return shortValue
}
