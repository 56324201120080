import styled from 'styled-components'
import { NormalizedUnordered } from '../List'

export const DropdownOptionsContainer = styled(NormalizedUnordered)<{
  open: boolean,
  slideFadeIn?: boolean,
  useDynamicOptionsPlacement?: boolean,
  top?: string,
  right?: string,
  bottom?: string,
  left?: string,
  maxHeight?: string,
  overflow?: string,
  mt?: string,
  mr?: string,
  mb?: string,
  ml?: string,
  padding?: string,
}>`
  display: ${props => props.open ? "block" : "none"};
  ${props => (props.useDynamicOptionsPlacement ? 
    (
      `position: fixed;`
    ) : ( 
      `position: absolute;`
  ))}
  z-index: 100;
  right: ${props => props.right ? props.right : "unset"};
  top: ${props => props.top ? props.top : "unset"};
  right: ${props => props.right ? props.right : "unset"};
  bottom: ${props => props.bottom ? props.bottom : "unset"};
  left: ${props => props.left ? props.left : "unset"};
  max-height: ${props => props.maxHeight ? props.maxHeight : 'initial'};
  overflow: ${props => props.overflow ? props.overflow : 'initial'};
  margin-top: ${props => props.mt ? props.mt : "16px"};
  margin-right: ${props => props.mr ? props.mr : 0};
  margin-bottom: ${props => props.mb ? props.mb : 0};
  margin-left: ${props => props.ml ? props.ml : 0};
  padding: ${props => props.padding ? props.padding : '10px 0'};
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,0.2);
  ${props => (props.open && props.slideFadeIn) && `
    animation: slide-fade-in 0.4s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0s 1 normal forwards;

    @keyframes slide-fade-in {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `}
`
