import styled from 'styled-components'
import { Flex } from '../../Flex'
import { XIcon } from '../../../icons/XIcon'
import { BREAKPOINT_S, coolGray50, fontWeightBold } from '../../../../constants'
import { TransparentButton } from '../../Button'
import { IHeader } from './types'
import { TextSmall } from '../../Text'

const StyledHeader = styled(Flex)<{
  hideBorder: boolean
}>`
  padding: 11px 16px 10px;
  border-bottom: ${props => (props.hideBorder ? 'none' : `1px solid ${coolGray50}`)};

  @media (min-width: ${BREAKPOINT_S}px) {
    padding: 15px 16px 14px;
  }
`

const StyledTextBold = styled(TextSmall)`
  font-weight: ${fontWeightBold};
  margin-left: 12px;
`

export const Header: React.FC<IHeader> = ({
  closeModal,
  label = '',
  justify = 'end',
  reverse = false,
  hideBorder = false,
  children,
  style
}) => {
  return (
    <StyledHeader
      width="auto"
      direction={`${reverse ? 'row-reverse' : 'row'}`}
      justify={justify}
      hideBorder={hideBorder}
      style={style}
    >
      {children}
      <TransparentButton
        className="modal-header-close"
        padding="6px"
        onClick={closeModal}
        cursor="pointer"
        data-testid="modal-header-close"
      >
        <XIcon />
        {Boolean(label.length) && <StyledTextBold>{label}</StyledTextBold>}
      </TransparentButton>
    </StyledHeader>
  )
}
