export const AREA_FOOTER_LINKS = [
  {
    title: "Find top real estate agents in all major US cities",
    links: [
      {
        name: "Atlanta",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/atlanta-ga/top-real-estate-agents`
      }, {
        name: "Austin",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/austin-tx/top-real-estate-agents`
      }, {
        name: "Boston",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/boston-ma/top-real-estate-agents`
      }, {
        name: "Chicago",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/chicago-il/top-real-estate-agents`
      }, {
        name: "Dallas",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/dallas-tx/top-real-estate-agents`
      }, {
        name: "Denver",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/denver-co/top-real-estate-agents`
      }, {
        name: "Fort Lauderdale",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/fort-lauderdale-fl/top-real-estate-agents`
      }, {
        name: "Houston",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/houston-tx/top-real-estate-agents`
      }, {
        name: "Los Angeles",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/los-angeles-ca/top-real-estate-agents`
      }, {
        name: "Miami",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/miami-fl/top-real-estate-agents`
      }, {
        name: "New York",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/new-york-ny/top-real-estate-agents`
      }, {
        name: "Portland",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/portland-or/top-real-estate-agents`
      }, {
        name: "San Diego",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/san-diego-ca/top-real-estate-agents`
      }, {
        name: "San Francisco",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/san-francisco-ca/top-real-estate-agents`
      }, {
        name: "Sarasota",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/sarasota-fl/top-real-estate-agents`
      }, {
        name: "Seattle",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/seattle-wa/top-real-estate-agents`
      }
    ]
  }
]

export const DEFAULT_FOOTER_LINKS = [
  {
    title: "For Sellers",
    links: [
      {
        name: "Find a Listing Agent",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/find-real-estate-agents`
      }, {
        name: "Simple Sale",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/simple`
      }, {
        name: "HomeLight Buy Before You Sell",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/buy-before-you-sell`
      }, {
        name: "Home Value Estimator",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/home-value-estimator`
      }, {
        name: "Sellers Resource Center",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/blog/`
      }, {
        name: "Sell Your Home Fast",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/sell-fast`
      }, {
        name: "Best Time To Sell House",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/best-time-to-sell-house`
      }, {
        name: "Commissions Calculator",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/real-estate-agent-commissions`
      }, {
        name: "Net Proceeds Calculator",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/net-proceeds-calculator`
      }, {
        name: "How To Sell Your Home",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/blog/guide-how-to-sell-a-house/`
      }
    ]
  },
  {
    title: "For Buyers",
    links: [
      {
        name: "Find a Buyer's Agent",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/find-real-estate-agents/buying`
      }, {
        name: "Find Homes for Sale",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/homes`
      }, {
        name: "Get Pre-approved",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/home-loans`
      }, {
        name: "Buyers Resource Center",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/blog/buy/`
      }, {
        name: "Housing Market Trends",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/housing-market`
      }, {
        name: "Home Affordability Calculator",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/how-much-house-can-i-afford`
      }, {
        name: "Down Payment Calculator",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/down-payment-calculator`
      }
    ]
  },
  {
    title: "For Agents",
    links: [
      {
        name: "The Walkthrough",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/blog/for-agents/podcast/`
      }, {
        name: "Agent Resource Center",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/blog/agents/`
      }, {
        name: "Agent Sign Up",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/agents`
      }, {
        name: "FAQ for Agents",
        url: `https://homelight.zendesk.com/hc/en-us`
      }, {
        name: "Agent Directory",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/states`
      }
    ]
  },
  {
    title: "Company",
    links: [
      {
        name: "About HomeLight",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/about-us`
      }, {
        name: "Testimonials",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/testimonials`
      }, {
        name: "Careers",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/careers`
      }, {
        name: "Press",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/press`
      }, {
        name: "Affiliate Program",
        url: 'https://affiliate.homelight.com'
      }, {
        name: "Real Estate Investors",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/ibuyers`
      }, {
        name: "Sign In",
        url: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/client/sign-in`
      }
    ]
  }
]

export const termsUrl = `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/terms`
export const privacyPolicyUrl = `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/privacy-policy`;
export const twitterUrl = "https://twitter.com/gohomelight";
export const facebookUrl = "https://www.facebook.com/gohomelight/";
export const instagramUrl = "https://www.instagram.com/gohomelight/";
export const youtubeUrl = "https://www.youtube.com/channel/UCimugR9XF17-OZ7B9fNnxWw";
export const pinterestUrl = "https://www.pinterest.com/homelightapp/";
export const rssUrl = "https://www.homelight.com/blog/feed/";
