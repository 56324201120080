export const UnionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 2.5H20.1875C20.3594 2.5 20.5 2.64062 20.5 2.8125V3.4375C20.5 3.60938 20.3594 3.75 20.1875 3.75H18V5.625H18.625C19.3164 5.625 19.875 6.18359 19.875 6.875V13.75C19.875 14.4414 19.3164 15 18.625 15H7.375C6.68359 15 6.125 14.4414 6.125 13.75V6.875C6.125 6.18359 6.68359 5.625 7.375 5.625H8V3.75H4.25V19.6875C4.25 19.8594 4.10938 20 3.9375 20H3.3125C3.14062 20 3 19.8594 3 19.6875V3.75H0.8125C0.640625 3.75 0.5 3.60938 0.5 3.4375V2.8125C0.5 2.64062 0.640625 2.5 0.8125 2.5H3V0.3125C3 0.140625 3.14062 0 3.3125 0H3.9375C4.10938 0 4.25 0.140625 4.25 0.3125V2.5ZM9.25 5.625H16.75V3.75H9.25V5.625Z"
        fill="#46B6FF"
      />
    </svg>
  )
}
