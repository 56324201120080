// Ultra-low Rates events
export const ULTRA_ONBOARDING_VIEW = 'ultra_onboarding_view'
export const ULTRA_SUBSCRIPTION_MODAL_GENERIC_VIEW = 'ultra_subscription_modal_generic_view'
export const ULTRA_SUBSCRIPTION_MODAL_PROPERTY_VIEW = 'ultra_subscription_modal_property_view'
export const ULTRA_SUBSCRIPTION_FORM_STEP_VIEW = 'ultra_subscription_form_step_view'
export const ULTRA_SUBSCRIPTION_VERIFICATION_STEP_VIEW = 'ultra_subscription_verification_step_view'
export const ULTRA_SUBSCRIPTION_PAYMENT_STEP_VIEW = 'ultra_subscription_payment_step_view'
export const ULTRA_PROPERTY_IMAGE_CHANGE = 'ultra_property_image_change'
export const ULTRA_VERIFICATION_CODE_INVALID = 'ultra_verification_code_invalid'
export const ULTRA_PII_FIELD_INVALID = 'ultra_pii_field_invalid'
export const ULTRA_CLIENT_CHECKOUT_SESSION = 'client_checkout_session_initiated'
export const ULTRA_BANNER_SHOW = 'ultra_banner_show'
export const ULTRA_BANNER_CLICK = 'ultra_banner_click'

// Home Run
export const HOME_RUN_POST_BUYER_LEAD_MODAL_VIEW = 'home_run_post_buyer_lead_modal_view_srp'
export const HOME_RUN_IM_INTERESTED_CLICK = 'home_run_im_interested_click_srp'
