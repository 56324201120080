import { createContext, useEffect, useState, useContext } from 'react'
import { PROPERTIES_API_URL } from '../../constants'
import { requestData } from '../../utils/requestData'
import {
  IPropertiesContext,
  IPropertiesProvider,
  PropertyType,
  IListingImages,
  IPropertiesState,
  IPageState
} from '.'
import { buildPageNavigation, getCurrentPage, IPageNumbers } from '../../utils/buildPageNavigation'
import { stateNameOf } from '../../utils/stateUtils'
import { buildPropertyInterface } from '../../utils/buildPropertyInterface'
import { mapImagePreviewsToListings } from '../../utils/mapImagesToListings'
import { PropertySearchFiltersContext, IFiltersContext } from '../PropertySearchFiltersContext'
import {
  SavedHomesContext,
  ISavedHomesContext,
  shouldSortBySavedAt,
  sortBySavedAt
} from '../SavedHomesContext'
import { ILocationContext, LocationContext } from '../LocationContext'
import { trackEvent, ACTION_CALL } from '../../utils/tracking'
import { IUserContext, UserContext } from '../../contexts/UserContext'
import { IField } from "../../utils/buildRequestFields"

const initialPropertiesState: IPropertiesState = { data: [], loading: true, error: false, count: 0 }
const initialPageNumbers: IPageNumbers = {
  first: 1,
  next: undefined,
  last: undefined,
  prev: undefined
}

export const propertyFields: IField[] = [{ reso_property: ['uuid','uuid_latest','listing_mls_status','featured','mortgage_int_rate','mortgage_amount','mortgage_loan_type','mortgage_int_rate_type','mortgage_term','current_balance',
                                              'postal_code','monthly_payment','listing_list_price','display_address','bedrooms_total','bathrooms_full','bathrooms_half','living_area','latitude',
                                              'longitude','city','county_or_parish','state_or_province','postal_code','listing_hl_created_at','image_url','favorite','locked','listing_hl_mls','agent_id',
                                              'agent_email','agent_phone','agent_office','agent_image','listing_hl_updated_at','hl_full_address','lot_size_acres','lot_size_sqft','year_built',
                                              'listing_property_sub_type','listing_property_type','building_area_total','market_monthly_mortgage_payment','market_mortgage_rate','listing_key',
                                              'listing_hl_feed_platform_code','listing_living_area','living_area','listing_lot_size_square_feet','final_living_area','monthly_payment_incl_fee','uuid_formatted'] }]

export const PropertiesContext = createContext<IPropertiesContext | null>(null)

export const PropertiesProvider: React.FC<IPropertiesProvider> = ({ initialPage, children }) => {
  const consumedFiltersContext = useContext(PropertySearchFiltersContext)
  const consumedSavedHomesContext = useContext(SavedHomesContext)
  const paramContext = consumedFiltersContext || consumedSavedHomesContext
  const { filters, sorts } = paramContext as IFiltersContext | ISavedHomesContext
  const { savedHomeUuids, savedHomesLoading } = useContext(SavedHomesContext) as ISavedHomesContext
  const { stateFromIP, setStateFromIP } = useContext(LocationContext) as ILocationContext

  const [propertiesState, setPropertiesState] = useState<IPropertiesState>(initialPropertiesState)
  const [listingImages, setListingImages] = useState<IListingImages>({})
  const [pageState, setPageState] = useState<IPageState>({
    pageNumbers: initialPageNumbers,
    currentPage: initialPage
  })
  const {
    user: { currentUser }
  } = useContext(UserContext) as IUserContext

  const pageSize = 20

  const omitBlankSorts = () => sorts.filter(sort => !!sort.field)

  const getProperties = () => {
    setPropertiesState({ ...propertiesState, loading: true })
    requestData({
      url: PROPERTIES_API_URL,
      method: 'get',
      filters: filters,
      fields: propertyFields,
      sorts: omitBlankSorts(),
      size: pageSize,
      page: pageState.currentPage,
      usePagination: true,
      metaParams: "min_max_price"
    }).then(({ data, meta, links, error }) => {
      const dataAsArray = Array.isArray(data) ? data : [data]
      const pages = links ? buildPageNavigation(links) : initialPageNumbers
      const propertiesCount = meta?.total_properties || 0
      let featuredCount = meta?.total_featured || 0

      let propertyListings = dataAsArray.map(prop => {
        const property = prop as PropertyType

        if (property.featured) {
          featuredCount += 1
        }
        return buildPropertyInterface({
          property: property,
          listing: null,
          savedHomes: savedHomeUuids,
          currentUser: currentUser
        })
      })

      if (shouldSortBySavedAt(sorts[0])) {
        propertyListings = sortBySavedAt(sorts[0].operator, savedHomeUuids, propertyListings)
      }

      setPropertiesState({
        data: propertyListings,
        error: error,
        loading: false,
        count: propertiesCount,
        countFeatured: featuredCount,
        meta
      })

      if (pages) {
        const updatedCurrentPage = getCurrentPage(pages)
        setPageState({ pageNumbers: pages, currentPage: updatedCurrentPage })
      }

      trackEvent(ACTION_CALL, 'properties_endpoint_called', 0, true)
      if (!meta?.shape_id) {
        let slug = filters.find(filter => filter.field === 'slug')?.value

        if (!slug) {
          slug = filters.find(filter => filter.field === 'state_or_province')?.value
        }

        trackEvent('Shape Error', slug, 0, true)
      }
    })
  }

  useEffect(() => {
    if (!filters?.length || savedHomesLoading || !sorts?.length) {
      return
    }

    getProperties()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sorts, pageState.currentPage, savedHomesLoading])

  useEffect(() => {
    const properties = [...propertiesState.data]
    const propertyListings = properties.map(prop => {
      return {
        ...prop,
        isFavorited: savedHomeUuids?.includes(prop.propertyUuid.toString()) || false
      }
    })
    setPropertiesState({
      ...propertiesState,
      data: propertyListings
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedHomeUuids])

  useEffect(() => {
    const buildImagePreviews = () => {
      const imageMapping = mapImagePreviewsToListings(propertiesState.data)
      setListingImages(imageMapping)
    }

    if (!propertiesState.data.length) {
      return
    }

    buildImagePreviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(propertiesState.data.map(property => property.propertyUuid))])

  useEffect(() => {
    if (!propertiesState.data.length) {
      return
    }

    if (!stateFromIP) {
      const stateCode = propertiesState.data[0].formattedState
      setStateFromIP(stateNameOf(stateCode))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(propertiesState.data), stateFromIP])

  const setCurrentPage = (value: number) => setPageState({ ...pageState, currentPage: value })
  const resetCurrentPage = () => setCurrentPage(1)

  const contextVal: IPropertiesContext = {
    properties: {
      propertiesData: propertiesState.data,
      propertiesLoading: propertiesState.loading,
      propertiesError: propertiesState.error
    },
    totalPropertiesCount: propertiesState.count,
    totalFeaturedCount: propertiesState.countFeatured || 0,
    activeZones: propertiesState.activeZones,
    pageSize: pageSize,
    pageNumbers: pageState.pageNumbers,
    currentPage: pageState.currentPage,
    setCurrentPage: setCurrentPage,
    resetCurrentPage: resetCurrentPage,
    listingImages,
    meta: propertiesState.meta
  }

  return <PropertiesContext.Provider value={contextVal}>{children}</PropertiesContext.Provider>
}
