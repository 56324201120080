export default function AngleDownIcon({ ...props }) {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.6813 7.44196L0.132167 1.93782C-0.0440556 1.7616 -0.0440556 1.47665 0.132167 1.30042L0.874551 0.55804C1.05077 0.381817 1.33573 0.381817 1.51195 0.55804L6 5.0011L10.4881 0.55804C10.6643 0.381817 10.9492 0.381817 11.1255 0.55804L11.8678 1.30042C12.0441 1.47665 12.0441 1.7616 11.8678 1.93782L6.3187 7.44196C6.14248 7.61819 5.85752 7.61819 5.6813 7.44196Z"
        fill="#273653"
      />
    </svg>
  )
}
