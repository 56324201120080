import styled from 'styled-components'
import { Button } from './'
import {
  white,
  actionOrange,
  coolGray30,
  coolGray50
} from '../../../constants'

export const OrangeButton = styled(Button)<{
  disabled?: boolean
}>`
  height: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 6px;
  border: none;
  text-decoration: none;
  background-color: ${props => props.disabled ? coolGray50 : actionOrange};
  color: ${props => props.disabled ? coolGray30 : white};
  font-weight: 700;
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};
  outline: none;
  &:hover, &:active {
    background-color: ${props => props.disabled ? coolGray50 : '#F06C00'};
  }
`
