import { SHORT_URL_URL } from '../../constants'
import { requestData } from "../requestData"

export const postShortUrl = (url: string): Promise<string> => {
  const date = new Date()
  date.setDate(date.getDate() + 28)

  return new Promise(resolve => {
    requestData({
      url: SHORT_URL_URL,
      method: "post",
      body: {
        url: url,
        expires_at: date,
      },
      ignoreJsonFormat: true,
    }).then(({ data, error }) => {
      if (!error) {
        resolve(data.short_url)
      }
    })
  })
}