import { SORT_OPTIONS} from '../../../constants'

export const getSortsFromParams = (params: {}) => {
  return Object.keys(params).map(key => {
    let sort = {
      label: '',
      field: '',
      operator: ''
    }
    if (key.startsWith('sort')) {
      const field = (params as any)[key].replace('-', '')
      sort.label = SORT_OPTIONS.filter(s => s.field === field)[0]?.label || ''
      sort.field = field
      sort.operator = (params as any)[key].startsWith('-') ? 'desc' : 'asc' 
    }
    return sort
  }).filter(s => Boolean(s.field.length))
}
