import { LinkType } from './types'
import styled from 'styled-components'
import { FooterTitle } from './FooterTitle'
import { FooterLink } from "./FooterLink";
import { BREAKPOINT_S } from '../../constants'

const StyledFooterSection = styled.div`
  flex-basis: 100%;

  @media (min-width: ${BREAKPOINT_S}px) {
    flex-basis: 50%;
  }
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ResponsiveLink = styled(FooterLink)`
`;

type IFooterSection = {
  title: string;
  links: LinkType[];
  columnCount: number;
};

export const FooterSection: React.FC<IFooterSection> = ({ title, links, columnCount }) => {
  return (
    <StyledFooterSection>
      <FooterTitle title={title} />
      <LinksContainer>
        {links.map(({ name, url }: { name: string; url: string }) => {
          return (
            <ResponsiveLink
              key={`responsive-link-${name}`}
              name={name}
              url={url}
              columnCount={columnCount}
            />
          );
        })}
      </LinksContainer>
    </StyledFooterSection>
  );
};
