import { IListingImages } from '../../contexts/PropertiesContext'
import { IPropertyListing } from '../../contexts/PropertyContext'

export const mapImagePreviewsToListings = (properties: IPropertyListing[]) => {
  const propertyImageMap: IListingImages = {}
  properties.forEach(property => {
    if (property.imagePreviews) {
      propertyImageMap[property.listingKey] = {
        urls: property.imagePreviews,
        isPreview: true
      }
    }
  })

  return propertyImageMap
}
