import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SparklesIcon } from '../icons/SparklesIcon'
import UltraBannerModal from './UltraBannerModal'
import { Description, Title } from './styles'
import { COLORS } from '../../constants'
import AngleRightIcon from '../icons/AngleRightIcon'
import { ACTION_TAP, ACTION_VIEW, trackEvent } from '../../utils/tracking'
import { ULTRA_BANNER_CLICK, ULTRA_BANNER_SHOW } from '../../constants/events'

export default function UltraBannerMobile() {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    trackEvent(ACTION_VIEW, ULTRA_BANNER_SHOW)
  }, [])

  function handleClickLearnMore() {
    setShowModal(true)

    trackEvent(ACTION_TAP, ULTRA_BANNER_CLICK)
  }

  return (
    <Container>
      <IconContainer>
        <SparklesIcon width={30} height={30}></SparklesIcon>
      </IconContainer>
      <CustomTitle>Rates as low as 3% are back</CustomTitle>
      <CustomDescription>
        With HomeLight Ultra, you assume the current low-rate loan on our listings, so you can buy
        with peace of mind and save thousands over the lifetime of your loan.{' '}
      </CustomDescription>
      <LearnMore onClick={handleClickLearnMore}>
        Learn more <AngleRightIcon />
      </LearnMore>
      {showModal && <UltraBannerModal onClose={() => setShowModal(false)} />}
    </Container>
  )
}

const Container = styled.div`
  padding: 32px 24px;
  background-color: ${COLORS.mistBlue};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  box-sizing: border-box;
  border-radius: 12px;
  height: auto;
`

const IconContainer = styled.span`
  margin-bottom: 16px;
`

const CustomTitle = styled(Title)`
  margin-bottom: 16px;
  white-space: normal;
  word-break: normal;
`

const CustomDescription = styled(Description)`
  margin-bottom: 24px;
  white-space: normal;
  word-break: normal;
`

const LearnMore = styled.span`
  cursor: pointer;
  font-weight: bold;
  align-items: center;

  svg {
    transform: translateY(5px);
  }
`
