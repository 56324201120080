import styled from 'styled-components'
import { Flex } from '../common/Flex'

const ConfirmationWrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 32px 40px;
  text-align: center;
`

const ImageWrapper = styled.div`
  margin-bottom: 16px;
  width: 260px;
  height: 160px;
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const Thanks = styled.h2`
  margin: 16px 0;
  font-size: 24px;
`

const FollowUp = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 180%;
`

export const UltraConfimationModal: React.FC<{ agentFirstName: string }> = ({ agentFirstName }) => (
  <ConfirmationWrapper>
    <ImageWrapper>
      <img
        src="https://hl-production-assets.s3.amazonaws.com/house-hunter/house-with-heart.png"
        alt="No information on this listing"
      />
    </ImageWrapper>
    <Thanks>Thanks for applying!</Thanks>
    <FollowUp>
      {`${agentFirstName} will be reaching out shortly.`}
    </FollowUp>
  </ConfirmationWrapper>
)
