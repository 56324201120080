import { useRef } from 'react'
import styled from 'styled-components'
import { useDropdown } from '../../../hooks/useDropdown'
import {
  DropdownContainer,
  DropdownOptionsContainer,
  IDropdownOption,
  DropdownPropType,
  DropdownOption
} from './'
import { SecondaryButton } from '../Button'
import { CaretDown } from '../../icons/CaretDown'
import {
  coolGray30,
  darkBlue,
  electricBlue,
  white,
  whiteHover
} from '../../../constants'
import { CheckIcon } from '../../icons/CheckIcon'

const Option = styled(DropdownOption)`
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  min-width: 200px;
  padding: 6px 50px;
  font-size: 14px;
  &:focus-visible {
    border-color: ${darkBlue};
  }
   &:focus {
    border-color: ${electricBlue};
  }
`

const SelectedOption = styled(Option)<{
  displayOptionState: boolean
}>`
  padding: ${props => props.displayOptionState ? `6px 28px` : `6px 12px`};
  background-color: ${props => props.displayOptionState ? white : whiteHover};
`

const SelectedIconWrapper = styled('span')`
  margin: 0 16px 0 -8px;
`

const DropdownTrigger = styled(SecondaryButton)`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: ${coolGray30};
  &:focus-visible {
    border-color: ${darkBlue};
  }
   &:focus {
    outline: none;
    border-color: ${electricBlue};
  }
`

const FullWidthDropdownOptionsContainer = styled(DropdownOptionsContainer)<{
  width: string
}>`
  width: ${props => props.width ? `${props.width}` : '100%'};
  overflow-x: hidden;
`

export const Dropdown: React.FC<DropdownPropType> = ({
  options,
  selectedLabel,
  displayOptionState=true,
  setSelected,
  triggerTestId,
  optionsMaxHeight,
  optionsOverflow,
  containerClassName,
  useDynamicOptionsPlacement=false,
  optionsPlacement,
  pt
}) => {
  let bottomOffset = "unset"
  let topOffset = "unset"
  const numberOfItems = options.length
  const dropdownContainerEl = useRef(null)
  const { triggerProps, itemProps, isOpen } = useDropdown(numberOfItems)

  const size = useDynamicOptionsPlacement ? 
    dropdownContainerEl?.current?.getBoundingClientRect() :
    null

  if (useDynamicOptionsPlacement) {
    bottomOffset = optionsPlacement === 'bottom' ? '7px' : "unset"
    topOffset = optionsPlacement === 'container' ? `${size?.top + size?.height}px` : "unset"
  }

  const select = (e: React.KeyboardEvent | React.MouseEvent, option: IDropdownOption) => {
    e.preventDefault()
    setSelected(option)
  }

  return (
    <DropdownContainer 
      ref={dropdownContainerEl}
      pt={pt}
      className={containerClassName}
    >
      <DropdownTrigger
        {...triggerProps}
        data-testid={triggerTestId}
      >
        { selectedLabel }
        <CaretDown />
      </DropdownTrigger>
      <FullWidthDropdownOptionsContainer
        width={size?.width ? `${size.width}px` : '100%'}
        top={topOffset}
        bottom={bottomOffset}
        open={isOpen}
        maxHeight={optionsMaxHeight}
        overflow={optionsOverflow}
        useDynamicOptionsPlacement={useDynamicOptionsPlacement}
      >
        {options.map((option, index) => (
          <li key={`${option.label}`}>
            {selectedLabel === option.label ? (
              <SelectedOption
                as="button"
                type="button"
                onClick={(e: React.KeyboardEvent | React.MouseEvent) => select(e, option)}
                {...itemProps[index]}
                displayOptionState={displayOptionState}
              >
                {displayOptionState && (
                  <SelectedIconWrapper>
                    <CheckIcon
                      fill={electricBlue}
                    />
                  </SelectedIconWrapper>
                )}
                {option.label}
              </SelectedOption>
            ) : (
              <Option
                as="button"
                type="button"
                onClick={(e: React.KeyboardEvent | React.MouseEvent) => select(e, option)}
                {...itemProps[index]}
              >
                {option.label}
              </Option>
            )}
          </li>
        ))}
      </FullWidthDropdownOptionsContainer>
    </DropdownContainer>
  )
}
