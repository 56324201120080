import { useEffect, useState, useRef } from 'react'

export const useHideOnClickElsewhere = (initialVisibility: boolean) => {
  const [isVisible, setIsVisible] = useState(initialVisibility)
  const ref = useRef<HTMLElement>(null)

  const handleClickOutside = (e: any) => {
    if (ref.current && !ref?.current?.contains(e.target)) {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return { ref, isVisible, setIsVisible }
}
