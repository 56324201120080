import { IIconProps } from '../types'

export const CaretRight: React.FC<IIconProps> = ({ fill, width='10px', height='16px' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 7 10" fill='none' xmlns="http://www.w3.org/2000/svg" role="img">
        <title>Next</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.34674 5.26558L1.75996 9.88986C1.61311 10.0367 1.37564 10.0367 1.22879 9.88986L0.610139 9.27121C0.463287 9.12436 0.463287 8.88689 0.610139 8.74004L4.31269 5L0.610139 1.25996C0.463287 1.11311 0.463287 0.875644 0.610139 0.728792L1.22879 0.110139C1.37564 -0.036713 1.61311 -0.036713 1.75996 0.110139L6.34674 4.73442C6.49359 4.88127 6.49359 5.11873 6.34674 5.26558Z" fill={fill}/>
    </svg>
  )
}
