import {
  getParamObjects,
  getSortsFromParams,
  getPaginationFromParams
} from '../../utils/parseParams'
import { LocationProvider } from '../../contexts/LocationContext'
import { PropertiesProvider } from '../../contexts/PropertiesContext'
import { SavedHomesProvider } from '../../contexts/SavedHomesContext'
import { SavedHomesBody } from '../../components/SavedHomesBody'
import { Layout } from '../../components/Layout'
import { ISort } from '../../components/Sort'
import { useLocation } from 'react-router-dom'

export const SavedHomes: React.FC<{
  type: 'recentlyViewed' | 'favorites'
}> = ({ type }) => {
  const { search } = useLocation()
  const searchParams = getParamObjects(search)
  const sorts = getSortsFromParams(searchParams) as ISort[]
  const initialPage = getPaginationFromParams(searchParams)

  return (
    <LocationProvider areaSlug={null}>
      <Layout>
        <SavedHomesProvider savedHomesType={type} searchParamSorts={sorts}>
          <PropertiesProvider initialPage={initialPage}>
            <SavedHomesBody savedHomesType={type} />
          </PropertiesProvider>
        </SavedHomesProvider>
      </Layout>
    </LocationProvider>
  )
}
