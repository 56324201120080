import styled from 'styled-components'
import { BREAKPOINT_M } from '../../constants'
import { NoFavoritesImage } from './'
import { SearchBar } from '../SearchBar'

const NoSavedHomesWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: ${BREAKPOINT_M}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ImageWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  @media (min-width: ${BREAKPOINT_M}px) {
    margin-top: 48px;
  }
`

const Title = styled.h3<{
  withSearch?: boolean
}>`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 12px;
  @media (min-width: ${BREAKPOINT_M}px) {
    margin-top: ${props => props.withSearch ? '18px' : '48px' };
    margin-bottom: ${props => props.withSearch ? '0px' : '12px' };
    font-size: 24px;
    text-align: center;
  }
`

const Text = styled.p`
  margin-bottom: 24px;
  max-width: 560px;
  @media (min-width: ${BREAKPOINT_M}px) {
    text-align: center;
  }
`

const StyledSearchBar = styled.div`
  margin-top: 42px;
  @media (min-width: ${BREAKPOINT_M}px) {
    margin-top: 18px;
  }
`

export const NoSavedHomes: React.FC<{
  type: string,
  showSearchBar?: boolean
}> = ({
  type,
  showSearchBar = false
}) => {
  const titleText = {
    favorites: <Title withSearch={showSearchBar}>You have not saved any homes</Title>,
    recentlyViewed: <Title withSearch={showSearchBar}>You have not viewed any homes</Title>,
    savedSearch: <Title withSearch={showSearchBar}>You have not saved any searches</Title>
  }

  const secondaryText = {
    favorites: <Text>Save homes that you like by clicking the Heart button.</Text>,
    recentlyViewed: <Text>Search for <a href='/'>homes in your area</a> with HomeLight.</Text>,
    savedSearch: <Text>Save your search criteria by clicking the Save Search button on the home search page.</Text>
  }

  return (
    <NoSavedHomesWrapper className='no-saved-homes'>
      <ImageWrapper>
        <NoFavoritesImage />
      </ImageWrapper>
      {(titleText as any)[type]}
      {(secondaryText as any)[type]}
      {showSearchBar && (
        <StyledSearchBar>
          <SearchBar navigateOnSearch={true} />
        </StyledSearchBar>
      )}
    </NoSavedHomesWrapper>
  )
}
