import { dasherize } from '../dasherize'
import { IFilter } from '../../components/Filters'

const findFilterValue = (filters: IFilter[], field: string) => {
  return filters.find(filter => filter.field === field)?.value
}

export const buildSearchUrlFromFilters = (filters: IFilter[]): string => {
  const queryParams = window.location.search

  const slug = findFilterValue(filters, 'slug')
  if (slug) {
    return `/${slug}/homes-for-sale${queryParams}`
  }

  const zip = findFilterValue(filters, 'postal_code')
  if (zip) {
    const stateCode = findFilterValue(filters, 'state_or_province')
    return `/homes-for-sale/zip/${zip}${
      stateCode ? `-${stateCode.toString().toLowerCase()}` : ''
    }${queryParams}`
  }

  const stateCode = findFilterValue(filters, 'state_or_province')
  const city = findFilterValue(filters, 'city')
  if (!city || !stateCode) {
    return `/homes${queryParams}`
  }

  const citySlug = dasherize(`${city} ${stateCode}`)
  return `/${citySlug}/homes-for-sale${queryParams}`
}
