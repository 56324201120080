import { IMap } from './types'

export const getObjectPriorityOrder = (obj1: IMap, obj2: IMap, priorityKey: string, reverseSort = false) => {
  const objectsExist = Boolean(obj1) && Boolean(obj2)

  if (!objectsExist || !priorityKey) {
    return []
  }

  const objSorted = [obj1, obj2].sort((a, b) => {
    const value1 = a[priorityKey]
    const value2 = b[priorityKey]

    if (value1 < value2) {
      return -1
    }
    if (value1 > value2) {
      return 1
    }
    return 0
  })

  if (reverseSort) {
    objSorted.reverse()
  }

  return objSorted
}
