import { isCity } from './isCity'
import { isCounty } from './isCounty'
import { isZipCode } from './isZipCode'
import { parseCitySlug } from './parseCitySlug'
import { parseCountySlug } from './parseCountySlug'
import { parseZipCodeSlug } from './parseZipCodeSlug'

export const parseAreaSlug = (areaSlug: string) => {
  const slugHasCity = isCity(areaSlug)
  const city = parseCitySlug(areaSlug)
  const slugHasCounty = isCounty(areaSlug)
  const county = parseCountySlug(areaSlug)
  const slugHasZip = isZipCode(areaSlug)
  const zip = parseZipCodeSlug(areaSlug)
  const state = city.state || county.state || zip.state

  return {
    city: slugHasCity ?  city.city : '',
    state: state || '',
    county: slugHasCounty ? county.county : '',
    zip: slugHasZip ? zip.zip : ''
  }
}
