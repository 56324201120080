export const DEFAULT_FEATURED_OPTION = {
  label: 'Default',
  value: null
}
export const FEATURED_OPTIONS = [
  DEFAULT_FEATURED_OPTION,
  {
    label: 'Ultra-Low Rates',
    value: 'true'
  },
  {
    label: 'Non-Ultra-Low Rates',
    value: 'false'
  }
]
