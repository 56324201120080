import { darkBlue } from '../../../constants'
import { Flex } from '../Flex'
import { LoadingSpinner } from '../LoadingSpinner'

export const FallbackLoadingSpinner = () => (
  <Flex
    width='100%'
    pt="24px"
    pb="24px"
    direction='column'
    align='center'
  >
    <LoadingSpinner color={darkBlue}/>
  </Flex>
)
