import { IBuildPropertyInterfaceParams } from './types'
import { toTitleCase } from '../toTitleCase'
import { formatAddress } from '../formatAddress'
import { formatNumber } from '../formatNumber'
import { propertyPageUrl as getPropertyPageUrl } from '../propertyPageUrl'
import { countBathrooms, formatBathrooms } from '../bathroomsUtils'
import { buildPropertyStatus } from '../buildPropertyStatus'
import { getPropertyListingPrice } from '../getPropertyListingPrice'
import { IPropertyListing } from '../../contexts/PropertyContext'
import { CLOSED, FEET_IN_AN_ACRE, IMAGE_PARAMS } from '../../constants'
import { formatCurrency } from '../formatCurrency'
import { getPropertyListingPriceToUse } from '../getPropertyListingPriceToUse'

export const buildPropertyInterface = ({
  property,
  listing,
  savedHomes,
  currentUser
} : IBuildPropertyInterfaceParams) : IPropertyListing => {
  const {
    latitude,
    longitude,
    streetName,
    stateOrProvince,
    countyOrParish,
    finalLivingArea,
    propertyType,
    lotSizeSqft,
    yearBuilt,
    listingPublicRemarks,
    listingKey,
    listingShouldDisplay,
    isMlsActivated
  } = property

  const hlFullAddress = toTitleCase(listing?.location?.hlFullAddress || property.hlFullAddress) || ''
  const city = toTitleCase(listing?.location?.city || property.city)
  const state = listing?.location?.state || stateOrProvince || ''
  const postalCode = String(listing?.location?.zipCode || property.postalCode || '')
  const formattedCityStateZip = `${city}, ${state} ${postalCode}`
  const formattedCityState = city.length && state.length ? `${city}, ${state}` : `${city}${state}`

  const bedroomsCount = listing?.listingDetails?.bedroomsTotal || property.listingBedroomsTotal || property.bedroomsTotal
  const bathroomsFull = listing?.listingDetails?.bathroomsFull || property.listingBathroomsFull || property.bathroomsFull
  let bathroomsHalf = listing?.listingDetails?.bathroomsHalf || property.listingBathroomsHalf
  // don't fallback to property bathroomsHalf if value is 0
  bathroomsHalf = !bathroomsHalf && bathroomsHalf === 0 ? property.bathroomsHalf : bathroomsHalf
  const bathroomsThreeQuarter = listing?.listingDetails?.bathroomsThreeQuarter || property.listingBathroomsThreeQuarter || 0
  const bathroomsOneQuarter = listing?.listingDetails?.bathroomOneQuarter || property.listingBathroomOneQuarter || 0
  const bathroomsCount = countBathrooms(
    bathroomsFull,
    bathroomsHalf,
    bathroomsThreeQuarter,
    bathroomsOneQuarter
  )

  // lotSizeSqft is more reliable than lotSizeAcres
  const lotSizeAcres = lotSizeSqft ? Math.round((lotSizeSqft / FEET_IN_AN_ACRE) * 100) / 100 : null

  const priceLabel = property.listingListPrice || listing?.listingDetails?.closePrice ? 'Price' : 'Estimated home value'
  const priceToUse = getPropertyListingPriceToUse({property, listing })

  const pricePerSqft = priceToUse && finalLivingArea ? priceToUse / finalLivingArea : null
  const formattedPricePerSqft = formatCurrency(pricePerSqft)
  const formattedPrice = getPropertyListingPrice(priceToUse, false)

  const listingMlsStatus = property.listingMlsStatus || ''
  const listingCloseDate = property.closeDate || ''
  const listingHlCreatedAt = property.listingHlCreatedAt || ''
  const statusDate = listingMlsStatus.toLowerCase() === CLOSED ? listingCloseDate : listingHlCreatedAt
  const listingContractDate = property.listingListingContractDate || ''

  const imagePreviews = (property.imageUrl || []).map((url: string) => `${url}${IMAGE_PARAMS}`)
  const favorite = property.favorite || savedHomes?.includes(property.uuid.toString()) || false

  return {
    propertyUuid: listing?.propertyUuid || property.uuid,
    propertyUuidFormatted: listing?.propertyUuidFormatted || property.uuidFormatted,
    bedroomsCount,
    bathroomsFull,
    bathroomsHalf,
    formattedBedrooms: formatNumber(bedroomsCount, false),
    bedroomsLabel: 'Bd',
    formattedBathrooms: formatBathrooms(bathroomsCount),
    bathroomsLabel: 'Ba',
    livingArea: finalLivingArea || null,
    formattedLivingArea: formatNumber(finalLivingArea || null, true),
    formattedLotSizeSqft: lotSizeSqft ? `${formatNumber(lotSizeSqft, true)} Sq. Ft.` : '-',
    lotSizeAcres,
    yearBuilt,
    formattedYearBuilt: yearBuilt ? `${yearBuilt}` : '-',
    propertyType,
    propertySubType: property.listingPropertySubType,
    latitude,
    longitude,
    hlFullAddress,
    formattedAddress: formatAddress(hlFullAddress, city, state, postalCode),
    formattedStreetName: toTitleCase(streetName),
    formattedCity: city,
    formattedCountyOrParish: toTitleCase(countyOrParish),
    formattedState: state,
    formattedCityState,
    formattedStateOrProvince: toTitleCase(stateOrProvince),
    formattedPostalCode: postalCode,
    formattedCityStateZip,
    price: priceToUse,
    formattedPrice,
    formattedPricePerSqft,
    abbreviatedPrice: getPropertyListingPrice(priceToUse, true),
    priceLabel,
    isFavorited: favorite,
    listingKey: listingKey || '',
    listingHlMls: property.listingHlMls || '',
    listingMlsStatus,
    listingDescription: listingPublicRemarks || null,
    listingListOfficeName: property.listingListOfficeName || '',
    listingListAgentFullName: property.listingListAgentFullName || '',
    listingListAgentStateLicense: property.listingListAgentStateLicense || '',
    listingContractDate,
    listingHlUpdatedAt: property.listingHlUpdatedAt || '',
    imagePreviews: imagePreviews,
    listingHlCreatedAt,
    soldDate: statusDate,
    propertyStatus: buildPropertyStatus(listingMlsStatus, statusDate),
    propertyPageUrl: getPropertyPageUrl(
      property.countyOrParish,
      property.stateOrProvince,
      property.hlFullAddress,
      property.city,
      postalCode,
      property.uuidFormatted
    ),
    displayAsListing: Boolean(isMlsActivated && listingShouldDisplay && listingKey?.length),
    mortgageIntRate: property.mortgageIntRate,
    isMortgageIntRateEstimated: property.isMortgageIntRateEstimated,
    monthlyPayment: property.monthlyPayment,
    downPayment: property.downPayment,
    featured: property.featured,
    marketMortgageRate: property.marketMortgageRate,
    marketMonthlyMortgagePayment: property.marketMonthlyMortgagePayment,
    monthlyPaymentInclFee: property.monthlyPaymentInclFee,
    currentBalance: property.currentBalance,
    mortgageMaturityDate: property.mortgageMaturityDate,
    locked: property.locked,
    agentId: property.agentId,
    agentName: property.agentName,
    agentEmail: property.agentEmail,
    agentPhone: property.agentPhone,
    agentOffice: property.agentOffice,
    agentImage: property.agentImage,
    agentUltraConfirmed: property.agentUltraConfirmed,
    skipPropertyService: property.skipPropertyService,
  }
}
