import { ISaveSearchTypes } from './'
import { ElectricBlueButton } from '../../common/Button'
import styled from "styled-components";

export const StyledSaveSearchButton = styled.div`
  margin-left: 8px;
  .save-search-button {
    height: 40px;
    width: 107px;
  }
`
export const SaveSearch: React.FC<ISaveSearchTypes> = ({
  attemptSaveSearch,
  savedSearchCreated,
  disableSavedSearch,
  savedSearchMatchesFilters
}) => {
  return (
    <>
      <StyledSaveSearchButton>
        <ElectricBlueButton.Medium
          as="button"
          className="save-search-button"
          onClick={() => attemptSaveSearch('daily')}
          disabled={savedSearchCreated || disableSavedSearch}
        >
          {savedSearchMatchesFilters || savedSearchCreated ? 'Saved' : 'Save search'}
        </ElectricBlueButton.Medium>
      </StyledSaveSearchButton>
    </>
  )
}
