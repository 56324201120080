import { TopNavItemType } from '@homelight/hl-core-ui'

export const NAV_OPTIONS = [
  {
    label: 'Company',
    link: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/about-us`
  },
  {
    label: 'Testimonials',
    link: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/testimonials`
  },
  {
    label: 'Press',
    link: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/press`
  },
  {
    label: 'Careers',
    link: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/careers`
  }
]

let agentNavOptions = [
  {
    label: 'Saved searches',
    link: '/homes/saved-property-searches'
  },
  {
    label: 'Favorite homes',
    link: '/homes/favorites'
  },
  {
    label: 'Recently viewed',
    link: '/homes/recently-viewed'
  },
  {
    label: 'My agent',
    link: ''
  }
]

export const AGENT_NAV_OPTIONS = [
  {
    label: 'Go to Agent Dashboard',
    link: `${process.env.REACT_APP_AGENT_PORTAL_URL}/dashboard`,
    key: 'agent_dashboard',
    openInNewTab: true
  },
  {
    label: 'Referrals',
    subitems: [
      {
        label: 'View my Referrals',
        link: `${process.env.REACT_APP_AGENT_PORTAL_URL}/referrals`,
        key: 'referrals',
        openInNewTab: true
      },
      {
        label: 'Change Preferences',
        link: `${process.env.REACT_APP_AGENT_PORTAL_URL}/settings/referrals`,
        key: 'change_preferences',
        openInNewTab: true
      },
      {
        label: 'Upload Transactions',
        link: `${process.env.REACT_APP_AGENT_PORTAL_URL}/transactions`,
        key: 'upload_transactions',
        openInNewTab: true
      },
      {
        label: 'Sign Referral Agreement',
        link: `${process.env.REACT_APP_AGENT_PORTAL_URL}/referrals`,
        key: 'sign_referral_agreement',
        openInNewTab: true
      }
    ]
  },
  {
    label: 'Transactions',
    link: `${process.env.REACT_APP_AGENT_PORTAL_URL}/transactions`,
    key: 'transactions',
    openInNewTab: true
  },
  {
    label: 'Messages',
    link: `${process.env.REACT_APP_AGENT_PORTAL_URL}/messages`,
    key: 'messages',
    openInNewTab: true
  },
  {
    label: 'Home Search',
    subitems: [
      {
        label: 'Saved Searches',
        link: `${process.env.REACT_APP_HOME_URL}homes/saved-property-searches`,
        key: 'saved_searches'
      },
      {
        label: 'Recently Viewed',
        link: `${process.env.REACT_APP_HOME_URL}homes/recently-viewed`,
        key: 'recently_viewed'
      },
      {
        label: 'Favorite Homes',
        link: `${process.env.REACT_APP_HOME_URL}homes/favorites`,
        key: 'favorite_homes'
      }
    ]
  },
  {
    label: 'Help and Resources',
    subitems: [
      {
        label: 'Agent Help Center',
        link: 'https://help.homelight.com',
        key: 'agent_help_center',
        openInNewTab: true
      },
      {
        label: 'Client Resource Center',
        link: 'https://www.homelight.com/blog',
        key: 'client_resource_center',
        openInNewTab: true
      },
      {
        label: 'Marketing Resources',
        link: 'https://homelight.com/marketing',
        key: 'marketing_resources',
        openInNewTab: true
      }
    ]
  },
  {
    label: 'Settings',
    subitems: [
      {
        label: 'Manage Subscription',
        link: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/ultra-low-rates/dashboard`,
        key: 'manage_subscription',
        openInNewTab: true
      },
      {
        label: 'Update Profile',
        link: `${process.env.REACT_APP_AGENT_PORTAL_URL}/settings`,
        key: 'update_profile',
        openInNewTab: true
      },
      {
        label: 'Change Password',
        link: `${process.env.REACT_APP_AGENT_PORTAL_URL}/users/edit`,
        key: 'change_password',
        openInNewTab: true
      }
    ]
  }
]

export const USER_NAV_OPTIONS = [...agentNavOptions]

export const USER_NAV_OPTIONS_MAP = {
  agent: agentNavOptions,
  client: USER_NAV_OPTIONS,
  'client-lead': USER_NAV_OPTIONS
}

const BUY_NAV_OPTIONS: TopNavItemType = {
  title: 'Buy',
  subitems: [
    {
      title: 'Find the perfect buying agent',
      details: 'Hire the right agent in your area.',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/client-agent-matching`,
      iconClass: 'award'
    },
    {
      title: 'Discover homes for sale',
      details: "Find a place you'll love to live.",
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/homes-for-sale`,
      iconClass: 'search'
    },
    {
      title: 'Get the best home loan',
      details: 'Get your pre-approval in just 5 minutes.',
      href: 'https://www.homelighthomeloans.com',
      iconClass: 'landMark'
    },
    {
      title: 'Buy with cash',
      details: 'Buy on your terms in as few as 8 days.',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/cash-offer`,
      iconClass: 'cashOffer'
    },
    {
      title: 'Learn about homebuying',
      details: 'Everything you need to know about buying a home.',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/blog/buy`,
      iconClass: 'bookAlt'
    }
  ]
}

const SELL_NAV_OPTIONS: TopNavItemType = {
  title: 'Sell',
  subitems: [
    [
      {
        title: 'Find the perfect listing agent',
        details: 'Hire the perfect real estate agent in your area.',
        href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/selling-starts-here`,
        iconClass: 'award'
      },
      {
        title: 'Find out your home value',
        details: 'See what your home is worth.',
        href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/home-value-estimator`,
        iconClass: 'calculator'
      },
      {
        title: 'Sell to HomeLight',
        details: 'Skip the listing process by selling to us instead.',
        href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/simple`,
        iconClass: 'cashOffer'
      },
      {
        title: 'Buy before you sell',
        details: 'Move into your new home and get the most money out of your old home.',
        href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/trade-in`,
        iconClass: 'tradeIn'
      },
      {
        title: 'Learn about selling',
        details: 'Everything you need to sell your home.',
        href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/blog/`,
        iconClass: 'bookAlt'
      }
    ]
  ]
}

const BUY_AND_SELL_NAV_OPTIONS: TopNavItemType = {
  title: 'Buy & Sell',
  subitems: [
    {
      title: 'Find the perfect agent',
      details: 'Hire the perfect real estate agent in your area.',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/selling-starts-here`,
      iconClass: 'award'
    },
    {
      title: 'Find out your home value',
      details: 'See what your home is worth.',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/home-value-estimator`,
      iconClass: 'calculator'
    },
    {
      title: 'Discover homes for sale',
      details: "Find a place you'll love to live.",
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/homes-for-sale`,
      iconClass: 'search'
    },
    {
      title: 'Buy before you sell',
      details: 'Move into your new home and get the most money out of your old home.',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/trade-in`,
      iconClass: 'tradeIn'
    },
    {
      title: 'Buy with cash',
      details: 'Buy on your terms in as few as 8 days.',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/cash-offer`,
      iconClass: 'cashOffer'
    }
  ]
}

const FINANCE_NAV_OPTIONS: TopNavItemType = {
  title: 'Finance',
  subitems: [
    {
      title: 'Get the best home loan',
      details: 'Get your pre-approval in just 5 minutes.',
      href: 'https://www.homelighthomeloans.com',
      iconClass: 'landMark'
    },
    {
      title: 'See how much home you can afford',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/how-much-house-can-i-afford`,
      iconClass: 'calculator'
    },
    {
      title: "See today's mortgage rates",
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/mortgages/rates`,
      iconClass: 'percentage'
    },
    {
      title: 'Buy with cash',
      details: 'Buy on your terms in as few as 8 days.',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/cash-offer`,
      iconClass: 'cashOffer'
    },
    {
      title: 'Buy before you sell',
      details: 'Move into your new home and get the most money out of your old home.',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/trade-in`,
      iconClass: 'tradeIn'
    }
  ]
}

const ABOUT_US_NAV_OPTIONS: TopNavItemType = {
  title: 'About Us',
  width: '10rem',
  subitems: [
    {
      title: 'Company',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/about-us`
    },
    {
      title: 'Testimonials',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/testimonials`
    },
    {
      title: 'Press',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/press`
    },
    {
      title: 'Careers',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/careers`
    },
    {
      title: 'For Agents',
      href: `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/agents`
    }
  ]
}

export const CORE_UI_NAV_OPTIONS = [
  BUY_NAV_OPTIONS,
  SELL_NAV_OPTIONS,
  BUY_AND_SELL_NAV_OPTIONS,
  FINANCE_NAV_OPTIONS,
  ABOUT_US_NAV_OPTIONS
]
