import { DEFAULT_LISTING_STATUS_FILTER_VALUE, PERMITTED_PROPERTY_TYPES } from '../../constants'
import { IFilter } from '../../components/Filters'

const findFilterValue = (filters: IFilter[], field: string) => {
  return filters.find(filter => filter.field === field)?.value
}

export const hasPropertyTypeFilterSelected = (typeFilterValue?: string) => {
  // 'all types selected' is effectively no filter
  return typeFilterValue &&
    typeFilterValue.split(',').sort().join(',') !== PERMITTED_PROPERTY_TYPES.sort().join(',')
}

export const hasFiltersSelected = (filters: IFilter[]) => {
  const typeFilterValue = findFilterValue(filters, 'listing_property_sub_type')
  if (hasPropertyTypeFilterSelected(typeFilterValue as string | undefined)) {
    return true
  }

  const status = findFilterValue(filters, 'listing_mls_status')
  if (status && status !== DEFAULT_LISTING_STATUS_FILTER_VALUE) {
    return true
  }

  const price = findFilterValue(filters, 'listing_list_price')
  const beds = findFilterValue(filters, 'listing_bedrooms_total')
  const baths = findFilterValue(filters, 'listing_bathrooms_total_integer')
  const sqft = findFilterValue(filters, 'listing_living_area')
  const lot = findFilterValue(filters, 'lot_size_sqft')
  const yearBuilt = findFilterValue(filters, 'year_built')

  return Boolean(
    price || beds || baths || sqft || lot || yearBuilt
  )
}
