import styled from "styled-components";
import { COLORS, BREAKPOINT_S } from "../../constants";

export const Title = styled.p`
  color: ${COLORS.darkBlue};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375;
  margin: 0;
  margin-top: 22px;
  margin-bottom: 20px;
  max-width: 230px;
  font-weight: 700;

  @media (min-width: ${BREAKPOINT_S}px) {
    font-size: 16px;
    margin-top: 33px;
    margin-bottom: 20px;
  }
`;


export const FooterTitle = ({ title } : { title: string }) => {
  return (
    <Title>{title}</Title>
  )
}
