import { IField } from '.'

// Format of fields query param is `/api/properties?fields[geo_shapes]=area_slug,name`
export const buildRequestFields = (fields: IField[]) => {
  let fieldsString: string = '';

  (fields || []).forEach((field: IField) => {
    const dataType = Object.keys(field)[0]
    const fieldValues = field[dataType]
    if (fieldValues) {
      const queryParam = `&fields[${dataType}]=${fieldValues.join(',')}`
      fieldsString += queryParam
    }
  })

  return fieldsString
}