import styled from "styled-components";
import { COLORS, BREAKPOINT_XL } from "../../constants";

export const Link = styled.a`
  color: ${COLORS.darkBlue};
  flex-basis: 50%;
  font-size: 14px;
  line-height: 1.6;
  text-decoration: none;
  margin-bottom: 12px;

  @media (min-width: ${BREAKPOINT_XL}px) {
    flex-basis: ${props => (100 / props.columnCount).toString()}%;
  }

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`

export const FooterLink = ({ name, url, columnCount = 4 }: { name: string; url: string; columnCount: number }) => {
  return (
    <Link href={url} key={name} title={name} columnCount={columnCount}>
      {name}
    </Link>
  )
}
