import styled from 'styled-components'
import {memo, useContext, useState} from 'react'
import { ACTION_ENTRY, ACTION_TAP, trackEvent } from '../../utils/tracking'
import { useDynamicMinOptions } from '../../hooks/useDynamicMinOptions'
import { useDynamicMaxOptions } from '../../hooks/useDynamicMaxOptions'
import {
  BREAKPOINT_S,
  BREAKPOINT_M,
  NO_MIN_FILTER_OPTION,
  NO_MAX_FILTER_OPTION,
  BEDS_MIN_FILTER,
  BEDS_MAX_FILTER,
  BEDS_NO_MIN_MAX_OPTION,
  BATHS_NO_MIN_OPTION,
  PRICE_OPTIONS,
  SQFT_OPTIONS,
  DEFAULT_LISTING_STATUS_FILTER,
  LISTING_STATUS_SOLD_FILTER,
  LOT_SIZE_OPTIONS,
  YEAR_BUILT_OPTIONS,
  FOR_SALE,
  SOLD,
  DEFAULT_SALE_DATE,
  CLOSED,
  DEFAULT_FEATURED_OPTION,
  NEWEST_PROPERTY_SORT
} from '../../constants'
import { MobileFilters } from './MobileFilters'
import { Price } from './Price'
import { BedsBaths } from './BedsBaths'
import { MoreFiltersModal } from './MoreFiltersModal'
import { Flex } from '../common/Flex'
import { IFiltersProps } from './'
import { ListingStatusType } from './ForSaleOrSold'
import { SaveSearch } from './SaveSearch'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { Sort } from '../Sort'
import { IUserContext, UserContext } from '../../contexts/UserContext'

const StyledFilters = styled(Flex)`
  @media (min-width: ${BREAKPOINT_S}px) {
    padding-bottom: 12px;
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    padding-bottom: 0;
  }
`

export const FiltersComponent: React.FC<IFiltersProps> = ({
  showMap,
  appliedFilters,
  attemptSaveSearch,
  savedSearchCreated,
  disableSavedSearch,
  savedSearchMatchesFilters,
  isModalVisible,
  setIsModalVisible,
  mobileFilterPopoverOpen,
  setMobileFilterPopoverOpen,
  resetCurrentPage,
  sortOptions,
  selectedSortLabel,
  setSort,
  showGoldRush,
}) => {
  const {
    listingStatus: { setListingStatus, selectedListingStatus, setSelectedListingStatus },
    priceMin: { setPriceMin, selectedPriceMin, setSelectedPriceMin },
    priceMax: { setPriceMax, selectedPriceMax, setSelectedPriceMax },
    bedsMin: { setBedsMin, selectedBedsMin, setSelectedBedsMin },
    bedsMax: { setBedsMax, selectedBedsMax, setSelectedBedsMax },
    bathsMin: { setBathsMin, selectedBathsMin, setSelectedBathsMin },
    propertyType: { setPropertyTypes, selectedPropertyTypes, setSelectedPropertyTypes },
    sqftMin: { setSqftMin, selectedSqftMin, setSelectedSqftMin },
    sqftMax: { setSqftMax, selectedSqftMax, setSelectedSqftMax },
    lotSizeMin: { setLotSizeMin, selectedLotSizeMin, setSelectedLotSizeMin },
    lotSizeMax: { setLotSizeMax, selectedLotSizeMax, setSelectedLotSizeMax },
    yearBuiltMin: { setYearBuiltMin, selectedYearBuiltMin, setSelectedYearBuiltMin },
    yearBuiltMax: { setYearBuiltMax, selectedYearBuiltMax, setSelectedYearBuiltMax },
    saleDate: { setSaleDate, selectedSaleDate, setSelectedSaleDate },
    featured: { setFeatured, selectedFeatured, setSelectedFeatured }
  } = appliedFilters
  const { mobile } = useBreakpoint()

  const { user: { sessionId } } = useContext(UserContext) as IUserContext

  const initialForSaleOrSold =
    selectedListingStatus.label.toLocaleLowerCase() === CLOSED ? SOLD : FOR_SALE
  const [forSaleOrSold, setForSaleOrSold] = useState<ListingStatusType>(initialForSaleOrSold)

  const { minOptions: priceMinOptions } = useDynamicMinOptions(
    selectedPriceMax.value,
    PRICE_OPTIONS
  )
  const { maxOptions: priceMaxOptions } = useDynamicMaxOptions(
    selectedPriceMin.value,
    PRICE_OPTIONS
  )

  const { minOptions: sqftMinOptions } = useDynamicMinOptions(selectedSqftMax.value, SQFT_OPTIONS)
  const { maxOptions: sqftMaxOptions } = useDynamicMaxOptions(selectedSqftMin.value, SQFT_OPTIONS)

  const { minOptions: lotSizeMinOptions } = useDynamicMinOptions(
    selectedLotSizeMax.value,
    LOT_SIZE_OPTIONS
  )
  const { maxOptions: lotSizeMaxOptions } = useDynamicMaxOptions(
    selectedLotSizeMin.value,
    LOT_SIZE_OPTIONS
  )

  const { minOptions: yearBuiltMaxOptions } = useDynamicMinOptions(
    selectedYearBuiltMin.value,
    YEAR_BUILT_OPTIONS,
    NO_MAX_FILTER_OPTION
  )
  const { maxOptions: yearBuiltMinOptions } = useDynamicMaxOptions(
    selectedYearBuiltMax.value,
    YEAR_BUILT_OPTIONS,
    NO_MIN_FILTER_OPTION
  )

  const applyPriceFilters = () => {
    setPriceMin(selectedPriceMin)
    setPriceMax(selectedPriceMax)

    if (selectedPriceMin.value) {
      trackEvent(ACTION_ENTRY, 'min_home_value')
    }
    if (selectedPriceMax.value) {
      trackEvent(ACTION_ENTRY, 'max_home_value')
    }
    resetCurrentPage()
  }

  const clearPriceFilters = () => {
    setSelectedPriceMin(NO_MIN_FILTER_OPTION)
    setSelectedPriceMax(NO_MAX_FILTER_OPTION)
    // For now, update the network request on "clear"
    setPriceMin(NO_MIN_FILTER_OPTION)
    setPriceMax(NO_MAX_FILTER_OPTION)
    resetCurrentPage()
  }

  const applyBedBathFilters = () => {
    setBedsMin(selectedBedsMin)
    setBedsMax(selectedBedsMax)
    setBathsMin(selectedBathsMin)
    resetCurrentPage()

    if (sessionId) {
      if (selectedBedsMin.value > 0) {
        trackEvent(ACTION_ENTRY, 'br_filter')
      }
      if (selectedBathsMin.value > 0) {
        trackEvent(ACTION_ENTRY, 'bath_filter')
      }
    }
  }

  const clearBedsBathsFilters = () => {
    setSelectedBedsMin(BEDS_NO_MIN_MAX_OPTION)
    setSelectedBedsMax(BEDS_NO_MIN_MAX_OPTION)
    setSelectedBathsMin(BATHS_NO_MIN_OPTION)
    // For now, update the network request on "clear"
    setBedsMin(BEDS_NO_MIN_MAX_OPTION)
    setBedsMax(BEDS_NO_MIN_MAX_OPTION)
    setBathsMin(BATHS_NO_MIN_OPTION)
    resetCurrentPage()
  }

  const applyPropertyTypeFilter = () => {
    setPropertyTypes(selectedPropertyTypes)

    if (selectedPropertyTypes.length > 0 && !!sessionId) {
      const propertyTypesEventLabel = selectedPropertyTypes
        .join(' ')
        .replace(/[^0-9a-zA-Z]/g, '_')
        .toLowerCase()
      trackEvent(ACTION_ENTRY, `property_type_filter_${propertyTypesEventLabel}`)
    }
    resetCurrentPage()
  }

  const clearPropertyTypeFilter = () => {
    setSelectedPropertyTypes([])
    // For now, update the network request on "clear"
    setPropertyTypes([])
    resetCurrentPage()
  }

  const applyForSaleOrSold = () => {
    setForSaleOrSold(forSaleOrSold)

    if (forSaleOrSold === SOLD) {
      setListingStatus(LISTING_STATUS_SOLD_FILTER)
    }

    trackEvent(ACTION_ENTRY, forSaleOrSold)
    resetCurrentPage()
  }

  const clearForSaleOrSold = () => {
    setForSaleOrSold(FOR_SALE)
    clearListingStatus()
  }

  const applyListingStatus = () => {
    if (forSaleOrSold === SOLD) {
      setListingStatus(LISTING_STATUS_SOLD_FILTER)
      setSort(NEWEST_PROPERTY_SORT)
      return
    }

    setListingStatus(selectedListingStatus)
    resetCurrentPage()
  }

  const clearListingStatus = () => {
    setSelectedListingStatus(DEFAULT_LISTING_STATUS_FILTER)
    setListingStatus(DEFAULT_LISTING_STATUS_FILTER)
    resetCurrentPage()
  }

  const applyFeatured = () => {
    setFeatured(selectedFeatured)

    if (selectedFeatured?.label === 'Non-Ultra-Low Rates') {
      setSort(NEWEST_PROPERTY_SORT)
    }

    resetCurrentPage()
  }

  const clearFeatured = () => {
    setSelectedFeatured(DEFAULT_FEATURED_OPTION)
    setFeatured(DEFAULT_FEATURED_OPTION)
    resetCurrentPage()
  }

  const applySaleDate = () => {
    setSaleDate(selectedSaleDate)
    resetCurrentPage()
  }

  const clearSaleDate = () => {
    setSelectedSaleDate(DEFAULT_SALE_DATE)
    setSaleDate(DEFAULT_SALE_DATE)
    resetCurrentPage()
  }

  const applySqftFilters = () => {
    setSqftMin(selectedSqftMin)
    setSqftMax(selectedSqftMax)

    if (selectedSqftMin.value) {
      trackEvent(ACTION_TAP, `sq_ft_min_${selectedSqftMin.value}`)
    }
    if (selectedSqftMax.value) {
      trackEvent(ACTION_TAP, `sq_ft_min_${selectedSqftMax.value}`)
    }
    resetCurrentPage()
  }

  const clearSqftFilters = () => {
    setSelectedSqftMin(NO_MIN_FILTER_OPTION)
    setSelectedSqftMax(NO_MAX_FILTER_OPTION)
    // For now, update the network request on "clear"
    setSqftMin(NO_MIN_FILTER_OPTION)
    setSqftMax(NO_MAX_FILTER_OPTION)
    resetCurrentPage()
  }

  const applyLotSizeFilters = () => {
    setLotSizeMin(selectedLotSizeMin)
    setLotSizeMax(selectedLotSizeMax)

    if (selectedLotSizeMin.value) {
      trackEvent(ACTION_TAP, `lot_size_min_${selectedLotSizeMin.value}`)
    }
    if (selectedLotSizeMax.value) {
      trackEvent(ACTION_TAP, `lot_size_max_${selectedLotSizeMax.value}`)
    }
    resetCurrentPage()
  }

  const clearLotSizeFilters = () => {
    setSelectedLotSizeMin(NO_MIN_FILTER_OPTION)
    setSelectedLotSizeMax(NO_MAX_FILTER_OPTION)
    // For now, update the network request on "clear"
    setLotSizeMin(NO_MIN_FILTER_OPTION)
    setLotSizeMax(NO_MAX_FILTER_OPTION)
    resetCurrentPage()
  }

  const applyYearBuiltFilters = () => {
    setYearBuiltMin(selectedYearBuiltMin)
    setYearBuiltMax(selectedYearBuiltMax)

    if (selectedYearBuiltMin.value) {
      trackEvent(ACTION_TAP, `year_built_min_${selectedYearBuiltMin.value}`)
    }
    if (selectedYearBuiltMin.value) {
      trackEvent(ACTION_TAP, `year_built_min_${selectedYearBuiltMin.value}`)
    }
    resetCurrentPage()
  }

  const clearYearBuiltFilters = () => {
    setSelectedYearBuiltMin(NO_MIN_FILTER_OPTION)
    setSelectedYearBuiltMax(NO_MAX_FILTER_OPTION)
    // For now, update the network request on "clear"
    setYearBuiltMin(NO_MIN_FILTER_OPTION)
    setYearBuiltMax(NO_MAX_FILTER_OPTION)
    resetCurrentPage()
  }

  const applyMoreFilters = () => {
    applyForSaleOrSold()
    applyListingStatus()
    applySaleDate()
    applySqftFilters()
    applyLotSizeFilters()
    applyYearBuiltFilters()
    applyPropertyTypeFilter()
    applyFeatured()
    resetCurrentPage()
  }

  const clearMoreFilters = () => {
    clearForSaleOrSold()
    clearListingStatus()
    clearSaleDate()
    clearSqftFilters()
    clearLotSizeFilters()
    clearYearBuiltFilters()
    clearFeatured()
    clearPropertyTypeFilter()
    resetCurrentPage()
  }

  const applyMobileFilters = () => {
    applyPriceFilters()
    applyBedBathFilters()
    applyPropertyTypeFilter()
    applyForSaleOrSold()
    applyListingStatus()
    applySaleDate()
    applySqftFilters()
    applyLotSizeFilters()
    applyYearBuiltFilters()
    applyFeatured()
    resetCurrentPage()
  }

  const clearMobileFilters = () => {
    clearPriceFilters()
    clearBedsBathsFilters()
    clearPropertyTypeFilter()
    clearForSaleOrSold()
    clearListingStatus()
    clearSaleDate()
    clearSqftFilters()
    clearLotSizeFilters()
    clearYearBuiltFilters()
    clearFeatured()
    resetCurrentPage()
  }

  return (
    <StyledFilters isModalVisible={isModalVisible}>
      {mobile ? (
        <>
          <MobileFilters
            showMap={showMap}
            priceMin={selectedPriceMin}
            selectPriceMin={option => setSelectedPriceMin(option)}
            priceMinOptions={priceMinOptions}
            priceMax={selectedPriceMax}
            selectPriceMax={option => setSelectedPriceMax(option)}
            priceMaxOptions={priceMaxOptions}
            bedsMin={selectedBedsMin}
            selectBedsMin={beds => setSelectedBedsMin(beds)}
            bedsMax={selectedBedsMax}
            selectBedsMax={beds => setSelectedBedsMax(beds)}
            bathsMin={selectedBathsMin}
            selectBathsMin={baths => setSelectedBathsMin(baths)}
            propertyTypes={selectedPropertyTypes}
            selectPropertyTypes={propertyTypes => setSelectedPropertyTypes(propertyTypes)}
            forSaleOrSold={forSaleOrSold}
            selectForSaleOrSold={forSaleOrSold => setForSaleOrSold(forSaleOrSold)}
            listingStatus={selectedListingStatus}
            selectListingStatus={option => setSelectedListingStatus(option)}
            saleDate={selectedSaleDate}
            selectSaleDate={option => setSelectedSaleDate(option)}
            sqftMin={selectedSqftMin}
            selectSqftMin={option => setSelectedSqftMin(option)}
            sqftMinOptions={sqftMinOptions}
            sqftMax={selectedSqftMax}
            selectSqftMax={option => setSelectedSqftMax(option)}
            sqftMaxOptions={sqftMaxOptions}
            lotSizeMin={selectedLotSizeMin}
            selectLotSizeMin={option => setSelectedLotSizeMin(option)}
            lotSizeMinOptions={lotSizeMinOptions}
            lotSizeMax={selectedLotSizeMax}
            selectLotSizeMax={option => setSelectedLotSizeMax(option)}
            lotSizeMaxOptions={lotSizeMaxOptions}
            yearBuiltMin={selectedYearBuiltMin}
            selectYearBuiltMin={option => setSelectedYearBuiltMin(option)}
            yearBuiltMinOptions={yearBuiltMinOptions}
            yearBuiltMax={selectedYearBuiltMax}
            selectYearBuiltMax={option => setSelectedYearBuiltMin(option)}
            yearBuiltMaxOptions={yearBuiltMaxOptions}
            applyAllFilters={applyMobileFilters}
            clearAllFilters={clearMobileFilters}
            popoverOpen={mobileFilterPopoverOpen}
            setPopoverOpen={setMobileFilterPopoverOpen}
            clearPriceFilters={clearPriceFilters}
            applyPriceFilters={applyPriceFilters}
            clearBedsBathsFilters={clearBedsBathsFilters}
            applyBedBathFilters={applyBedBathFilters}
            sortOptions={sortOptions}
            selectedSortLabel={selectedSortLabel}
            setSort={setSort}
            featured={selectedFeatured}
            selectFeatured={setSelectedFeatured}
            showGoldRush={showGoldRush}
          />
        </>
      ) : (
        <>
          <Sort
            sortOptions={sortOptions}
            selectedSortLabel={selectedSortLabel}
            setSort={setSort}
            showGoldRush={showGoldRush}
          />
          <Price
            priceMin={selectedPriceMin}
            selectPriceMin={option => setSelectedPriceMin(option)}
            priceMinOptions={priceMinOptions}
            priceMax={selectedPriceMax}
            selectPriceMax={option => setSelectedPriceMax(option)}
            priceMaxOptions={priceMaxOptions}
            clearPriceFilters={clearPriceFilters}
            applyPriceFilters={applyPriceFilters}
            mobile={mobile}
          />
          <BedsBaths
            bedsMin={selectedBedsMin || BEDS_MIN_FILTER}
            selectBedsMin={beds => setSelectedBedsMin(beds)}
            bedsMax={selectedBedsMax || BEDS_MAX_FILTER}
            selectBedsMax={beds => setSelectedBedsMax(beds)}
            bathsMin={selectedBathsMin}
            selectBathsMin={baths => setSelectedBathsMin(baths)}
            clearBedsBathsFilters={clearBedsBathsFilters}
            applyBedBathFilters={applyBedBathFilters}
            mobile={mobile}
          />
          <MoreFiltersModal
            forSaleOrSold={forSaleOrSold}
            selectForSaleOrSold={forSaleOrSold => setForSaleOrSold(forSaleOrSold)}
            listingStatus={selectedListingStatus}
            selectListingStatus={option => setSelectedListingStatus(option)}
            saleDate={selectedSaleDate}
            selectSaleDate={option => setSelectedSaleDate(option)}
            sqftMin={selectedSqftMin}
            selectSqftMin={option => setSelectedSqftMin(option)}
            sqftMinOptions={sqftMinOptions}
            sqftMax={selectedSqftMax}
            selectSqftMax={option => setSelectedSqftMax(option)}
            sqftMaxOptions={sqftMaxOptions}
            lotSizeMin={selectedLotSizeMin}
            selectLotSizeMin={option => setSelectedLotSizeMin(option)}
            lotSizeMinOptions={lotSizeMinOptions}
            lotSizeMax={selectedLotSizeMax}
            selectLotSizeMax={option => setSelectedLotSizeMax(option)}
            lotSizeMaxOptions={lotSizeMaxOptions}
            yearBuiltMin={selectedYearBuiltMin}
            selectYearBuiltMin={option => setSelectedYearBuiltMin(option)}
            yearBuiltMinOptions={yearBuiltMinOptions}
            yearBuiltMax={selectedYearBuiltMax}
            selectYearBuiltMax={option => setSelectedYearBuiltMax(option)}
            yearBuiltMaxOptions={yearBuiltMaxOptions}
            clearMoreFilters={clearMoreFilters}
            applyMoreFilters={applyMoreFilters}
            isVisible={isModalVisible}
            setIsVisible={setIsModalVisible}
            selectedPropertyTypes={selectedPropertyTypes}
            selectPropertyTypes={propertyTypes => setSelectedPropertyTypes(propertyTypes)}
            featured={selectedFeatured}
            selectFeatured={setSelectedFeatured}
            showGoldRush={showGoldRush}
          />
        </>
      )}
      <SaveSearch
        attemptSaveSearch={attemptSaveSearch}
        savedSearchCreated={savedSearchCreated}
        disableSavedSearch={disableSavedSearch}
        savedSearchMatchesFilters={savedSearchMatchesFilters}
      />
    </StyledFilters>
  )
}

export const Filters = memo(FiltersComponent)
