import styled, { css } from 'styled-components'
import { white } from '../../constants'
import { SparklesIcon } from '../icons/SparklesIcon'
import { PropertyThumbnailContainer } from '../PropertyListThumbnail/PropertyListThumbnailFeatured'
import { StyledMapPopUp } from '../common/Map/GoldRushPopUpProperty/GoldRushPopUpProperty'

export const StyledPropertyStatus = styled.div<{ featuredAndGoldRushEnabled: boolean }>`
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: ${props =>
    props.featuredAndGoldRushEnabled ? white : 'rgba(255,255,255,0.90)'};
  color: ${props => (props.featuredAndGoldRushEnabled ? '#F1B707' : '#000')};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 3px 5px;
  font-size: 12px;
  font-weight: bold;

  ${({ featuredAndGoldRushEnabled }) =>
    featuredAndGoldRushEnabled
      ? css`
          display: flex;
          flex-direction: row;
          padding: 6px;

          span {
            margin-left: 8px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #f1b707 !important;
          }

          svg {
            fill: #f1b707;
          }
          ${PropertyThumbnailContainer}:hover &, ${PropertyThumbnailContainer}:focus & {
            background-color: rgba(0, 0, 0, 0.25);
          }
          ${StyledMapPopUp}:hover & {
            background-color: rgba(0, 0, 0, 0.25);
            opacity: 1;
          }
        `
      : ''};
`

interface IPropertyStatus {
  status: string
  featuredAndGoldRushEnabled: boolean
}

export const PropertyStatus: React.FC<IPropertyStatus> = ({
  status,
  featuredAndGoldRushEnabled
}) => {
  return (
    <>
      {Boolean(status?.length) && (
        <StyledPropertyStatus featuredAndGoldRushEnabled={featuredAndGoldRushEnabled}>
          {Boolean(featuredAndGoldRushEnabled) && <SparklesIcon />}
          <span>{status}</span>
        </StyledPropertyStatus>
      )}
    </>
  )
}
