export const ImageNotFoundIcon = ({ width='32', fill='#ffffff' }) => {
  return (
    <svg
      className="image-not-found-icon"
      width={width}
      viewBox="0 0 32 24"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <title>Image not found</title>
      <path fill={fill} d="M29 0H3C1.34312 0 0 1.34312 0 3V21C0 22.6569 1.34312 24 3 24H29C30.6569 24 32 22.6569 32 21V3C32 1.34312 30.6569 0 29 0ZM28.625 21H3.375C3.27554 21 3.18016 20.9605 3.10983 20.8902C3.03951 20.8198 3 20.7245 3 20.625V3.375C3 3.27554 3.03951 3.18016 3.10983 3.10984C3.18016 3.03951 3.27554 3 3.375 3H28.625C28.7245 3 28.8198 3.03951 28.8902 3.10984C28.9605 3.18016 29 3.27554 29 3.375V20.625C29 20.7245 28.9605 20.8198 28.8902 20.8902C28.8198 20.9605 28.7245 21 28.625 21ZM8 5.5C6.61931 5.5 5.5 6.61931 5.5 8C5.5 9.38069 6.61931 10.5 8 10.5C9.38069 10.5 10.5 9.38069 10.5 8C10.5 6.61931 9.38069 5.5 8 5.5ZM6 18H26V13L20.5303 7.53031C20.2374 7.23744 19.7626 7.23744 19.4696 7.53031L12 15L9.53031 12.5303C9.23744 12.2374 8.76256 12.2374 8.46963 12.5303L6 15V18Z" />
    </svg>
  );
}
