import { IFilter } from "."

export const buildRequestFilters = (filters: IFilter[]) => {
  let filterString: string = '';

  (filters || []).forEach((filter: IFilter, index: number) => {
    const { field, operator, value} = filter
    if (field && value) {
      const encodedOperator = operator && operator !== '=' ? encodeURIComponent(operator) : ''
      filterString += `&filter[${encodedOperator}${field}]=${value}`
    }
  })

  return filterString
}
