import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { NoResults } from '../NoResults'
import { Flex } from '../common/Flex'
import { AlertFrequencyDropdown } from './'
import {
  StyledModalBackground,
  StyledModalContainer
} from '../common/Modal'
import { ConfirmDeleteModal, SaveSearchEditModal } from './'
import { Toast } from '../common/Toast'
import { Trash } from '../icons/Trash'
import { PenEditIcon } from '../icons/PenEditIcon/PenEditIcon'
import {
  SavedPropertySearchesContext,
  ISavedPropertySearchesContext,
  ISavedPropertySearch
} from '../../contexts/SavedPropertySearchesContext'
import {
  BREAKPOINT_M,
  BREAKPOINT_S,
  COLORS,
  SAVED_SEARCHES_URL,
  SEARCH_ALERT_FREQUENCIES,
  SEARCH_ALERT_FREQUENCY_OPTIONS
} from '../../constants'
import { formatSavedSearch } from '../../utils/formatSavedSearch'
import { requestData } from '../../utils/requestData'
import { useHideOnClickElsewhere } from '../../hooks/useHideOnClickElsewhere'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { ISavedPropertySearchListView } from './types'
import { IDropdownOption } from '../common/Dropdown'

let frequencyToOptionMap = {}
Object.keys(SEARCH_ALERT_FREQUENCIES).forEach(frequency => {
  frequencyToOptionMap[frequency] = SEARCH_ALERT_FREQUENCY_OPTIONS.find(option => option.field === frequency)
})

const SearchListColumnHeaders = styled.div`
  @media (max-width: calc(${BREAKPOINT_S}px - 1px)) {
    display: none;
  }
  
  display: flex;
  padding: 16px 0;
  
  .criteria-header {
    flex: 1;
    color: ${COLORS.coolGray3};
  }
  
  .notifications-header {
    flex: 0 0 292px;
    color: ${COLORS.coolGray3};
  }
`

const StyledSearchesList = styled.div`
  margin-top: 14px;
  
  @media (max-width: calc(${BREAKPOINT_S}px - 1px)) {
    margin-top: 0;
    padding: 0 16px;
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    margin-top: inherit;
  }
`

const StyledSavedSearchContainer = styled.div`
  flex-direction: column;
  padding: 0;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
  align: center;
  min-height: 72px;
  border-bottom: solid 1px ${COLORS.coolGray5};
  
  &:first-of-type {
    border-top: solid 1px ${COLORS.coolGray5};
  }

  .search-text-section {
    flex: 1;
    flex-direction: column;
    align-items: start;
    margin-top: 15px;
    margin-bottom: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }
  
  .area-display {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 6px;
  }

  .search-string {
    font-size: 14px;
    white-space: normal;
    overflow: auto;
    word-wrap: normal;
    word-break: normal;
    min-height: 16px;
    line-height: 22px;
  }
  
  .edit-section {
    flex-basis: 230px;
    
    .frequency-dropdown-label {
      display: none;
    }
    
    .alert-frequency-dropdown {
      padding-top: 0;
      
      & > button {
        padding: 8px 12px;
      }
      
      & > ul {
        overflow: auto;
      }
    }
  }
  
  .mobile-edit-section {
    align-self: flex-start;
    padding: 18px 0 16px 24px;

    .pen-edit-icon {
      width: 16px;
      height: 16px;
      color: ${COLORS.darkBlue};
    }
  }
  
  .delete-section {
    fill: ${COLORS.darkBlue};
    flex: 0 1 auto;
    margin-left: 32px;

    .trash-icon-wrapper {
      align: center;
      border-radius: 50%;
      height: 32px;
      &:hover, &:focus-visible {
        background-color: ${COLORS.whiteHover};
      }
      svg {
        margin: 8px 9px;
        width: 14px;
        height: 16px;
        transition: background-color .2s ease;
      }
    }
  }
`;

const FullScreenModalBackground = styled(StyledModalBackground)`
  justify-content: flex-end;

  & > div {
    flex-basis: calc(100% - 88px);
    display: flex;
    flex-direction: column;
  }
`

export const SavedPropertySearchListView: React.FC<ISavedPropertySearchListView> = (
  {
    NoResultsView= NoResults
  }) => {
  const { savedSearches, refreshSavedSearches, savedSearchesLoading } = useContext(
    SavedPropertySearchesContext
  ) as ISavedPropertySearchesContext
  const [frequencyUpdated, setFrequencyUpdated] = useState<boolean>(false)
  const [savedSearchDeleted, setSavedSearchDeleted] = useState<boolean>(false)
  const [searchToUpdate, setSearchToUpdate] = useState<ISavedPropertySearch | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [requestError, setRequestError] = useState<boolean>(false)
  const orderedSavedSearches = savedSearches.sort((a, b) => a.id > b.id ? -1 : 1)

  const { mobile } = useBreakpoint()

  const showToast = requestError || savedSearchDeleted || frequencyUpdated
  let toastText = ''
  if (requestError) {
    toastText = 'Sorry, something went wrong. Can you try that again?'
  } else if (savedSearchDeleted) {
    toastText = 'Saved search deleted'
  } else if (frequencyUpdated) {
    toastText = 'Saved search updated'
  }

  const {
    ref: editModalContainerRef,
    isVisible: showEditModal,
    setIsVisible: setShowEditModal
  } = useHideOnClickElsewhere(false)

  const {
    ref: confirmDeleteRef,
    isVisible: showConfirmDelete,
    setIsVisible: setShowConfirmDelete
  } = useHideOnClickElsewhere(false)

  const editFrequency = (option: IDropdownOption, id: number | string) => {
    const searchToEdit = savedSearches.find(search => Number(search.id) === Number(id))
    setRequestError(false)
    setSavedSearchDeleted(false)
    setFrequencyUpdated(false)
    setIsLoading(true)
    requestData({
      url: `${SAVED_SEARCHES_URL}/${id}`,
      method: 'put',
      body: { frequency: option.field, search_url: searchToEdit?.searchUrlQuery },
      ignoreJsonFormat: true,
      includeBearerToken: false
    }).then(({error}) => {
      setIsLoading(false)

      if (error) {
        setRequestError(error)
      } else {
        refreshSavedSearches()
        setFrequencyUpdated(true)
      }
    })
  }

  const openEditModal = (search: ISavedPropertySearch) => {
    setSearchToUpdate(search)
    setShowConfirmDelete(false)
    setShowEditModal(true)
  }

  const confirmDelete = (search: ISavedPropertySearch) => {
    setSearchToUpdate(search)
    setShowEditModal(false)
    setShowConfirmDelete(true)
  }

  const deleteSearch = () => {
    setRequestError(false)
    setSavedSearchDeleted(false)
    setFrequencyUpdated(false)
    setIsLoading(true)

    requestData({
      url: `${SAVED_SEARCHES_URL}/${searchToUpdate?.id}`,
      method: 'delete',
      ignoreJsonFormat: true,
      includeBearerToken: false
    }).then(({error}) => {
      setIsLoading(false)
      setShowConfirmDelete(false)

      if (error) {
        setRequestError(error)
      } else {
        setSavedSearchDeleted(true)
        setSearchToUpdate(null)
        refreshSavedSearches()
      }
    })
  }

  const handleModalBlur = (e: any) => {
    if (!e.currentTarget.contains(e.target)) {
      setShowConfirmDelete(false)
    }
  }

  const searchDisplayName = (search?: ISavedPropertySearch | null) => {
    if (!search) { return 'this search' }

    return search.areaName ? `${search?.areaName}, ${search.stateCode}` : search?.stateCode;
  }

  const renderDeleteSection = (search: ISavedPropertySearch) => (
    <Flex className='delete-section' direction='column' justify='end'>
      <div
        className='trash-icon-wrapper'
        onClick={() => confirmDelete(search)}
        data-testid={`trash-button-${search.id}`}
      >
        <Trash />
      </div>
    </Flex>
  )

  const renderEditDelete = (search: ISavedPropertySearch) => {
    return mobile ? (
      <div
        className='mobile-edit-section'
        onClick={() => openEditModal(search)}
        data-testid={`edit-button-${search.id}`}
      >
        <PenEditIcon />
      </div>
    ) : (
      <>
        <div className='edit-section'>
          <AlertFrequencyDropdown
            selectedFrequency={frequencyToOptionMap[search.frequency] || SEARCH_ALERT_FREQUENCY_OPTIONS[0]}
            setSelectedFrequency={editFrequency}
            searchId={search.id}
          />
        </div>
        {renderDeleteSection(search)}
      </>
    )
  }

  const renderColumnHeaders = () => {
    return orderedSavedSearches.length > 0 ? (
      <SearchListColumnHeaders>
        <div className='criteria-header'>Search criteria</div>
        <div className='notifications-header'>Email notifications</div>
      </SearchListColumnHeaders>
    ) : null
  }

  return savedSearchesLoading ? null : (
    <div className="saved-search-list-view">
      {renderColumnHeaders()}
      <StyledSearchesList>
        {orderedSavedSearches.map(search => (
          <StyledSavedSearchContainer key={`${search.id}`}>
            <Flex direction="row">
              <a
                href={`${process.env.REACT_APP_HOMELIGHT_REPO_URL}/homes-for-sale?${search.searchUrlQuery}`}
                className="search-text-section"
              >
                <div className="area-display">{searchDisplayName(search)}</div>
                <div className="search-string">{formatSavedSearch(search)}</div>
              </a>
              {renderEditDelete(search)}
            </Flex>
          </StyledSavedSearchContainer>
        ))}
      </StyledSearchesList>
      {savedSearches.length ? null : <NoResultsView />}
      {showConfirmDelete && (
        <StyledModalBackground data-testid="modal-overlay">
          {/* @ts-ignore */}
          <StyledModalContainer
            ref={confirmDeleteRef}
            onBlur={handleModalBlur}
            data-testid="delete-modal"
          >
            <ConfirmDeleteModal
              closeModal={() => setShowConfirmDelete(false)}
              deleteSearch={deleteSearch}
              cityName={searchDisplayName(searchToUpdate)}
              isLoading={isLoading}
            />
          </StyledModalContainer>
        </StyledModalBackground>
      )}
      {showEditModal && (
        <FullScreenModalBackground className="edit-modal-background">
          {/* @ts-ignore */}
          <StyledModalContainer
            ref={editModalContainerRef}
            onBlur={e => handleModalBlur(e)}
            data-testid="search-edit-modal"
          >
            <SaveSearchEditModal
              searchToUpdate={searchToUpdate}
              closeModal={() => setShowEditModal(false)}
              onUpdate={() => setFrequencyUpdated(true)}
              confirmDelete={confirmDelete}
              setError={setRequestError}
              refreshSavedSearches={refreshSavedSearches}
            />
          </StyledModalContainer>
        </FullScreenModalBackground>
      )}
      <Toast show={showToast} state={requestError ? 'error' : 'default'}>
        {toastText}
      </Toast>
    </div>
  )
}
