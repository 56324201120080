import { DarkMapIcon } from '../icons/DarkMapIcon'
import styled from 'styled-components'
import { COLORS, BREAKPOINT_XL } from '../../constants'

const StyledShowMapButton = styled.button`
  margin-left: auto;
  border: none;
  background-color: white;
  margin-right: 0;
  display: flex;
  align-items: center;
  align-self: flex-start;
  flex-basis: 36px;

  @media (max-width: ${BREAKPOINT_XL}px) {
    position: fixed;
    bottom: 24px;
    left: calc(50% - 50px);
    height: 40px;
    border-radius: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    padding: 8px 20px;
    font-weight: 700;
    color: ${COLORS.darkBlue};
    z-index: 3;
  }

  &:hover,
  &:focus-visible {
    cursor: pointer;
    background-color: ${COLORS.whiteHover};
  }
  &:focus-visible {
    border-color: ${COLORS.electricBlue};
  }
  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${COLORS.whiteHover};
  }

  @media (min-width: ${BREAKPOINT_XL}px) {
    border-radius: 4px;
    height: 32px;
    width: auto;
    padding: 0 10px 0 4px;
    display: none;
    visibility: hidden;
  }
`

const StyledMapIcon = styled(DarkMapIcon)`
  width: 18px;
`

const ShowMap = styled.span`
  white-space: nowrap;
  padding-left: 12px;
  font-size: 16px;
  color: ${COLORS.darkBlue};
  line-height: 20px;
  font-weight: bold;
`

type IShowMapButton = {
  setShowMap: (value: boolean) => void
  showMap: boolean
}

export const ShowMapButton = ({ setShowMap, showMap }: IShowMapButton) => {
  return !showMap ? (
    <StyledShowMapButton data-testid="show-map-button" onClick={() => setShowMap(true)}>
      <StyledMapIcon width="36" />
      <ShowMap>Map</ShowMap>
    </StyledShowMapButton>
  ) : null
}
