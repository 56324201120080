import { useCallback, useState } from 'react'
import { SUBSCRIPTION_CHECKOUT_URL } from '../../constants'
import { requestData } from '../../utils/requestData'
import { PaymentStatus } from '../../constants/paymentStatus'

type UseCheckoutSessionType = {
  sessionId: string
}

export function useLazyCheckoutSession({ sessionId }: UseCheckoutSessionType) {
  const [isSubscriptionPaid, setIsSubscriptionPaid] = useState(false)

  const trigger = useCallback(() => {
    if (sessionId) {
      requestData({
        url: `${SUBSCRIPTION_CHECKOUT_URL}/${sessionId}`,
        method: 'get',
        ignoreJsonFormat: true
      })
        .then(({ data }) => {
          setIsSubscriptionPaid(data.payment_status === PaymentStatus.PAID)
        })
        .catch(e => {
          setIsSubscriptionPaid(false)
          throw new Error('Something wrong has happened useCheckoutSession. ', e)
        })
    }
  }, [sessionId])

  return {
    isSubscriptionPaid,
    trigger
  }
}
