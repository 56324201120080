import { formatPrice } from '../formatPrice'
import { formatSqftOrAcres } from '../formatSqftOrAcres'
import { formatPropertyTypes } from '../formatPropertyTypes'
import { formatValueRange } from '../formatValueRange'
import { formatBedsAndBaths } from '../formatBedsAndBaths'
import { ISavedPropertySearch } from '../../contexts/SavedPropertySearchesContext'
import { formatStatus }  from '../formatStatus'

export const formatSavedSearch = (search : ISavedPropertySearch) => {
  const {
    priceMin,
    priceMax,
    bedsMin,
    bedsMax,
    bathsMin,
    propertyTypes,
    sqftMin,
    sqftMax,
    lotSizeMin,
    lotSizeMax
  } = search
  const minPrice = Boolean(priceMin) ? formatPrice(priceMin) : ''
  const maxPrice = Boolean(priceMax) ? formatPrice(priceMax) : ''
  const minSqft = Boolean(sqftMin) ? `${sqftMin?.toLocaleString()} sq. ft.` : null
  const maxSqft = Boolean(sqftMax) ? `${sqftMax?.toLocaleString()} sq. ft.` : null
  const minLotSize = formatSqftOrAcres(lotSizeMin)
  const maxLotSize = formatSqftOrAcres(lotSizeMax)

  const priceString = formatValueRange(minPrice, maxPrice)
  const bedsAndBathsString = formatBedsAndBaths(bedsMin, bedsMax, bathsMin);
  const propertyTypesString = formatPropertyTypes(propertyTypes || '')
  const sqftString = sqftMin || sqftMax ? `Square Feet: ${formatValueRange(minSqft, maxSqft)}` : ''
  const lotSizeString = minLotSize || maxLotSize ? `Lot Size: ${formatValueRange(minLotSize, maxLotSize)}` : ''
  const yearBuiltString = formatValueRange(search.yearBuiltMin, search.yearBuiltMax);
  const statusString = formatStatus(search.status)
  const searchStringValues = [priceString, bedsAndBathsString, propertyTypesString, sqftString, lotSizeString, yearBuiltString, statusString];
  return searchStringValues.filter(item => item?.length).join(', ')
}
