import { colors } from '@homelight/particle-tokens'

// Variable names in `10s` gives us room to
// add colors in between existing variables,
// without renaming all of them, ie: coolGray15
export const COLORS = {
  ...colors,
  coolGray10: colors.coolGray1, // #55585E
  coolGray20: colors.coolGray2, // #72757D
  coolGray30: colors.coolGray3, // #8E929C
  coolGray40: colors.coolGray4, // #C5C8CD
  coolGray50: colors.coolGray5, // #DBDFE6
  coolGray60: colors.coolGray6, // #EEF0F6
  coolGray70: '#F5F6F9',
  black: '#000000',
  mistBlue: '#F2F9FE',
  aaBlue: '#1192E5',
  actionOrange: '#FF8927'
}

export const {
  darkBlue,
  electricBlue,
  electricBlueHover,
  coolGray10,
  coolGray20,
  coolGray30,
  coolGray40,
  coolGray50,
  coolGray60,
  coolGray70,
  white,
  whiteHover,
  cadmiumRed,
  dandelionYellow,
  viridianGreen,
  mediumBlue,
  cobaltBlue,
  glacierBlue,
  mistBlue,
  aaBlue,
  actionOrange,
  alertRedHover,
  black
} = COLORS