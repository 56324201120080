import { IPageNumbers, IPageLinks } from "./types"

const getPageNumFromLink = (link: string) => {
  const pageNumMatch = 'page%5Bnumber%5D='
  const pageNumRegex = /page%5Bnumber%5D=[0-9]+/g
  let match = link.match(pageNumRegex)
  if (match) {
    const pageNum = match[0] && match[0].replace(pageNumMatch, '')
    return +pageNum
  }
  return undefined
}

const getLastPage = (pageNum: string, totalPages = 500) => {
  const actualLastPage = getPageNumFromLink(pageNum)
  if (actualLastPage && +actualLastPage > totalPages) {
    return totalPages
  } else {
    return actualLastPage
  }
}

export const getCurrentPage = (pageNumbers: IPageNumbers) => {
  if (pageNumbers?.next) {
    return (pageNumbers.next - 1)
  } else if (pageNumbers?.last) {
    return pageNumbers.last
  } else {
    return 1
  }
}

export const buildPageNavigation = (links: IPageLinks, totalPages?: number) => {
  if (!links) {return}
  const { first, last, next } = links

  const firstPage = first ? getPageNumFromLink(first) : 1
  const lastPage = last ? getLastPage(last, totalPages) : undefined
  const nextPage = next ? getPageNumFromLink(next) : undefined
    // get previous page from `next` page, if available
  const prevPage = nextPage ? nextPage - 2 : undefined

  const pages: IPageNumbers = {
    first: firstPage,
    last: lastPage ? lastPage : undefined,
    next: nextPage ? nextPage : undefined,
    prev: prevPage
  }
  return pages
}
