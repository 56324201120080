import { useDropdown } from '../../../../hooks/useDropdown'
import { TransparentButton } from '../../Button'
import {
  DropdownContainer,
  DropdownOptionsContainer,
  DropdownOption
} from '../../Dropdown'
import { INavOptions } from '../UserLogin/types'

interface INavigation {
  navOptions: INavOptions[]
}

export const AboutDropdown: React.FC<INavigation>  = ({ navOptions })  => {
  const numberOfItems = navOptions.length
  const { triggerProps, itemProps, isOpen, setIsOpen } = useDropdown(numberOfItems);

  return (
    <DropdownContainer
      role="navigation"
      onClick={() => setIsOpen(true)}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <TransparentButton padding="0 32px"
        as="button"
        id="about-homelight"
        cursor='pointer'
        {...triggerProps}
      >
        About Us
      </TransparentButton>
      <DropdownOptionsContainer
        role="menu"
        aria-labelledby="about-homelight"
        open={isOpen}
        mt="0"
        slideFadeIn
      >
        {navOptions.map((option, index) => (
          <li key={option.label} role='menuitem' >
            <DropdownOption fontSize='14px'
                            lh={'20px'}
                            as="a"
                            href={option.link}
                            {...itemProps[index]}
            >
              {option.label}
            </DropdownOption>
          </li>
        ))}
      </DropdownOptionsContainer>
    </DropdownContainer>
  )
}
