import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Accordion } from '../common/Accordion'
import { buildFaq } from '../../utils/buildFaq'
import { coolGray50 } from '../../constants'
import { IFaqData } from "../../contexts/LocationContext";

const StyledFaq = styled.div`
  border-top: 1px solid ${coolGray50};
  padding-top: 40px;
`

const Title = styled.h2`
  margin-bottom: 32px;
`

declare interface IFaqProps {
  currentSlug: string | null
  locationTitle: string | null
  pageAreaType: string | null
  getFaqData:  (currentSlug: string) => void,
  faqData: IFaqData
}

export const Faq: React.FC<IFaqProps> = ({
                                           currentSlug,
                                           locationTitle,
                                           pageAreaType,
                                           getFaqData,
                                           faqData
                                         }) => {
  const [faqArray, setFaqArray] = useState([])
  const [jsonSchema, setJsonSchema] = useState([])

  useEffect(() => {
    getFaqData(currentSlug)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlug])

  useEffect(() => {
    if (!locationTitle || !currentSlug) {
      return
    }
    const faq = buildFaq(faqData, locationTitle, currentSlug, pageAreaType)
    setFaqArray(faq)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(faqData)])

  const buildJsonSchema = faqData => {
    const mainEntity = faqData.map(faq => {
      return {
        '@type': 'Question',
        name: faq.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.body
        }
      }
    })

    return JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: mainEntity
    })
  }

  useEffect(() => {
    setJsonSchema(buildJsonSchema(faqArray))
  }, [faqArray])

  return (
    <StyledFaq>
      {faqArray && Boolean(faqArray.length) && <Title>Frequently asked questions</Title>}
      <div>
        {faqArray.map(faq => {
          return <Accordion key={faq.title} title={faq.title} body={faq.body} />
        })}
      </div>
      <script type="application/ld+json">{jsonSchema}</script>
    </StyledFaq>
  )
}
