import { useRef, useState } from 'react'
import styled from 'styled-components'
import { LeftArrow, RightArrow } from './'
import { BREAKPOINT_S } from '../../constants'
import { ISlideshowProps } from './types'
import { useSize } from '../../hooks/useSize'

const StyledSlideshowWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }

  &:hover {
    .slideshow-arrow-wrapper {
      opacity: 1;
    }
  }
`

const StyledFrame = styled.div<{
  removeBorderRadius?: boolean
}>`
  flex: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: inherit;
  border-radius: ${props => (props.removeBorderRadius ? `0` : `8px 8px 0 0`)};

  @media (min-width: ${BREAKPOINT_S}px) {
    border-radius: ${props => (props.removeBorderRadius ? `0` : `8px`)};
  }

  @media (min-width: ${BREAKPOINT_S}px) {
    border-radius: 8px 8px 0 0;
  }
`

const SlideTrack = styled.div`
  position: absolute;
  width: max-content;
  height: 100%;
  display: flex;
  transition: all 0.3s ease-in-out;

  &.swiping {
    will-change: transform;
    transition: none;
  }

  img {
    margin: 0 auto;
  }
`

const ArrowWrapper = styled.div<{right?: boolean}>`
  display: none;
  @media (min-width: ${BREAKPOINT_S}px) {
    z-index: 1;
    left: ${props => props.right? 'unset' : '8px'};
    right: ${props => props.right? '8px' : 'unset'};
    display: block;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  button {
    background: none;
    border: none;
  }

  .slideshow-next-arrow {
    svg {
      margin-left: 1px;
    }
  }
`

let swipeEvent = {
  startX: 0,
  distance: 0,
  startingTrackPosition: 0
}

const SWIPE_THRESHOLD = 80 // required min distance traveled to be considered swipe

// TODO - Fix wonky `children` prop
export const Slideshow: React.FC<ISlideshowProps> = ({
  children,
  slideIndex,
  setSlideIndex,
  showArrows = true,
  removeBorderRadius = false,
  onClickSlideshow = () => null,
  onSlideChange = () => null,
}) => {
  const nullOrUndefined = (num: any) => num === null || num === undefined || Number.isNaN(num)
  const parentControlled = !nullOrUndefined(slideIndex) && typeof setSlideIndex === 'function'

  const [privateSlideIndex, setPrivateSlideIndex] = useState(slideIndex || 0)
  const [trackTranslateValue, setTrackTranslateValue] = useState(0)
  const [touchActive, setTouchActive] = useState(false)
  const slideshowWrapper = useRef(null)
  const slideFrame = useRef(null)
  const slideDimensions = useSize(slideFrame)
  // @ts-ignore
  const frameWidth = slideDimensions?.width || 0

  // supports top-down index control, but in absence tracking is done locally
  const getSlideIndex = () => (parentControlled ? slideIndex : privateSlideIndex)

  const onTouchStart = (e: React.TouchEvent | React.MouseEvent) => {
    const currentSlideIndex = getSlideIndex() || 0
    // @ts-ignore
    const touchObj = e.changedTouches[0]
    swipeEvent.startX = touchObj.pageX
    swipeEvent.startingTrackPosition = getTranslateValue(currentSlideIndex)
    setTouchActive(true)
  }

  const onTouchMove = (e: React.TouchEvent | React.MouseEvent) => {
    // @ts-ignore
    const touchObj = e.changedTouches[0]
    swipeEvent.distance = touchObj.pageX - swipeEvent.startX
    const translateValue = swipeEvent.startingTrackPosition + swipeEvent.distance
    setTrackTranslateValue(translateValue)
  }

  const onTouchEnd = (e: React.TouchEvent | React.MouseEvent) => {
    setTouchActive(false)
    // @ts-ignore
    const touchObj = e.changedTouches[0]
    swipeEvent.distance = touchObj.pageX - swipeEvent.startX

    const isSwipeyEnough = Math.abs(swipeEvent.distance) >= SWIPE_THRESHOLD
    if (isSwipeyEnough) {
      performSwipe()
    } else {
      setTrackTranslateValue(swipeEvent.startingTrackPosition)
      resetSwipe()
    }
  }

  const performSwipe = () => {
    if (swipeEvent.distance < 0) {
      changeSlide(1)()
    } else {
      changeSlide(-1)()
    }
    resetSwipe()
  }

  const resetSwipe = () => {
    swipeEvent = {
      startX: 0,
      distance: 0,
      startingTrackPosition: 0
    }
  }

  const changeSlide = (increment: number) => {
    return () => {
      const currentIndex = getSlideIndex()
      // @ts-ignore
      const newIndex = newSlideIndex(currentIndex || 0, children?.length, increment)
      const translateValue = getTranslateValue(newIndex)

      if (setSlideIndex) {
        setSlideIndex(newIndex)
      }
      if (onSlideChange) {
        onSlideChange()
      }

      setPrivateSlideIndex(newIndex)
      setTrackTranslateValue(translateValue)
      setTouchActive(false)
    }
  }

  const newSlideIndex = (currentSlideIndex: number, slideCount: number, increment: number) => {
    let index = currentSlideIndex + increment
    if (index < 0) {
      return slideCount - 1
    } else if (index >= slideCount) {
      return 0
    }
    return index
  }

  const getTranslateValue = (currentSlideIndex: number) => -(frameWidth * currentSlideIndex)

  const renderArrows = () => (
    <>
      <ArrowWrapper className="slideshow-arrow-wrapper back-arrow-wrapper">
        <button className="slideshow-arrow slideshow-back-arrow" onClick={changeSlide(-1)}>
          <LeftArrow />
        </button>
      </ArrowWrapper>
      <ArrowWrapper right className="slideshow-arrow-wrapper next-arrow-wrapper">
        <button className="slideshow-arrow slideshow-next-arrow" onClick={changeSlide(1)}>
          <RightArrow />
        </button>
      </ArrowWrapper>
    </>
  )

  const trackStyle = { transform: `translateX(${trackTranslateValue}px)` }
  const slideStyle = { width: `${frameWidth}px` }

  const trackClasses = ['slide-track']
  if (touchActive) {
    trackClasses.push('swiping')
  }

  // @ts-ignore
  const renderedSlides = children?.map((slide: HTMLDivElement, i: number) => {
    return (
      <div key={i} className="slide" style={slideStyle}>
        {slide}
      </div>
    )
  })

  // TODO: Do we _need_ this tabindex?
  return (
    <StyledSlideshowWrapper
      data-testid="slideshow"
      className="slideshow-wrapper"
      ref={slideshowWrapper}
      tabIndex={-1}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onClick={onClickSlideshow}
    >
      <StyledFrame
        className="slide-frame"
        ref={slideFrame}
        removeBorderRadius={removeBorderRadius}
      >
        <SlideTrack className={trackClasses.join(' ')} style={trackStyle}>
          {renderedSlides}
        </SlideTrack>
      </StyledFrame>
      {showArrows && renderArrows()}
    </StyledSlideshowWrapper>
  )
}
