import { LocationProvider } from '../../contexts/LocationContext'
import { Layout } from '../../components/Layout'
import { PropertiesSitemapProvider } from '../../contexts/PropertiesSitemapContext'
import { getParamObjects, getSortsFromParams, getFiltersFromParams } from '../../utils/parseParams'
import { isState, parseAreaSlug } from '../../utils/parseAreaSlug'
import { ISort } from '../../components/Sort'
import { IPropertyFieldFilter } from '../../components/Filters'
import { PropertySearchFiltersProvider } from '../../contexts/PropertySearchFiltersContext'
import { HtmlSitemapBody } from '../../components/HtmlSitemapBody'
import { IHtmlSitemapProps } from './types'
import { useLocation, useParams } from 'react-router-dom'

export const getPageNumberFromParam = (pageNumberParam: string) => {
  const initialPage = 1
  if (!pageNumberParam) {
    return initialPage
  }

  const pageNumber = (pageNumberParam as any).match(/(?:page-)(\d+)$/)
  return !pageNumber ? initialPage : parseInt(pageNumber[1], 10)
}

const getSitemapAreaType = (slug: string) => {
  const { state, county } = parseAreaSlug(slug)
  const validState = isState(slug) || state
  if (county.length) {
    return 'county'
  } else if (validState) {
    return 'state'
  } else {
    return null
    // TODO Do we want 404? Redirect? Try to derive larger area for sitemap?
  }
}

export const HtmlSitemap: React.FC<IHtmlSitemapProps> = ({ history }) => {
  const { areaSlug = '', pageNumber = '1' } = useParams()
  const { search } = useLocation()

  const searchParams = getParamObjects(search)
  const sorts = getSortsFromParams(searchParams) as ISort[]
  const initialPropertiesPage = getPageNumberFromParam(pageNumber)
  const propertyFilters = getFiltersFromParams(searchParams) as IPropertyFieldFilter[]
  const pageType = getSitemapAreaType(areaSlug)

  return (
    <LocationProvider areaSlug={areaSlug}>
      <Layout>
        <PropertySearchFiltersProvider
          areaSlug={areaSlug}
          searchParamFilters={propertyFilters}
          searchParamSorts={sorts}
        >
          <PropertiesSitemapProvider
            initialPage={initialPropertiesPage}
            areaSlug={areaSlug}
            pageType={pageType}
          >
            <HtmlSitemapBody areaSlug={areaSlug} pageType={pageType} />
          </PropertiesSitemapProvider>
        </PropertySearchFiltersProvider>
      </Layout>
    </LocationProvider>
  )
}
