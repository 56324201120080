import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ImageNotFoundIcon } from '../icons/ImageNotFoundIcon'
import { BREAKPOINT_S, coolGray40, COMING_SOON_PLACEHOLDER_URL } from '../../constants'
import { IPropertyImgProps } from './'
import 'lazysizes'

// @ts-ignore
window.lazySizesConfig = window.lazySizesConfig || {}
// @ts-ignore
window.lazySizesConfig.loadHidden = false

const noop = () => {}

// TODO: Can this use the extend the `Image` component?
// ie: const Img = styled(Image)
const Img = styled.img<{
  failedToLoad?: boolean
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  visibility: ${props => (props.failedToLoad ? 'hidden' : 'visible')};
  position: ${props => (props.failedToLoad ? 'absolute' : 'relative')};
`

const ImgContainer = styled.div<{ visible: boolean }>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  height: 100%;
`

const PropertyImgContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    border-radius: 8px;
    transition: opacity 0.2s;

    @media (max-width: ${BREAKPOINT_S}px) {
      border-radius: 8px 8px 0 0;
    }
  }

  &.empty-image {
    display: flex;
  }
`

const EmptyImage = styled.div`
  background-color: ${coolGray40};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.5;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0.9) 91%,
    rgba(0, 0, 0, 0.8) 55%
  );
`
export const PropertyImg: React.FC<IPropertyImgProps> = ({
  url = '',
  alt = '',
  className = '',
  trackStockImage = noop,
  onImageFail = noop,
  listingContractDate = '',
  imageIndex,
  slideIndex
}) => {
  const [loaded, setLoaded] = useState(false)
  const [failedToLoad, setFailedToLoad] = useState(false)
  const [fallbackSrc, setFallbackSrc] = useState(false)
  const emptyImage = failedToLoad || !url.length
  let wrapperClasses = `property-image-overlay ${className}`
  let recentProperty
  let visible =
    typeof imageIndex !== 'undefined' &&
    typeof slideIndex !== 'undefined' &&
    imageIndex === slideIndex

  if (listingContractDate) {
    const twoDaysAgo = new Date(new Date().setDate(new Date().getDate() - 2))
    recentProperty = new Date(listingContractDate) > twoDaysAgo
  }

  if (emptyImage) {
    wrapperClasses += ' empty-image'
  }

  const onImageLoad = () => {
    setLoaded(true)
    if (failedToLoad) {
      setFailedToLoad(false)
    }
  }

  const onFail = () => {
    if (onImageFail) {
      onImageFail(url)
      setFallbackSrc(true)
    } else {
      setFailedToLoad(true)
    }
  }

  useEffect(() => {
    if (!url.length || failedToLoad) {
      trackStockImage()
    }
  }, [url, failedToLoad, trackStockImage])

  return (
    <PropertyImgContainer className={wrapperClasses}>
      {url.length ? (
        <ImgContainer visible={visible}>
          <Img
            src={fallbackSrc ? url : null}
            data-src={url}
            alt={loaded ? alt : ''}
            failedToLoad={failedToLoad}
            onError={onFail}
            onLoad={onImageLoad}
            data-testid={`property-image-${url}`}
            className="property-image lazyload"
          />
          {failedToLoad &&
            (recentProperty ? (
              <Img
                data-src={COMING_SOON_PLACEHOLDER_URL}
                alt="Coming Soon Placeholder Image"
                data-testid={`property-image-${url}`}
                className="property-image lazyload"
              />
            ) : (
              <EmptyImage>
                <ImageNotFoundIcon />
              </EmptyImage>
            ))}
          <Overlay />
        </ImgContainer>
      ) : recentProperty ? (
        <Img
          data-src={COMING_SOON_PLACEHOLDER_URL}
          alt="Coming Soon Placeholder Image"
          data-testid={`property-image-${url}`}
          className="property-image lazyload"
        />
      ) : (
        <EmptyImage>
          <ImageNotFoundIcon />
        </EmptyImage>
      )}
    </PropertyImgContainer>
  )
}
