export const NoResultsImage = () => (
  <svg width="1033" height="290" viewBox="0 0 1033 290" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_13280_223095)">
      <path d="M270.477 203.338C277.016 210.584 282.446 219.26 284.615 228.867C287.09 239.824 286.222 251.714 285.708 262.831C285.547 265.932 290.367 265.916 290.528 262.831C291.09 251.055 291.91 238.475 289.114 226.923C286.64 216.769 280.824 207.644 273.883 199.932C271.794 197.634 268.404 201.04 270.477 203.338Z" fill="#9BB0C9"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M301.534 219.266C301.434 221.182 301.198 223.122 300.513 224.787L300.507 224.802L300.507 224.802C299.152 228.005 296.781 229.979 294.88 231.562C294.703 231.709 294.53 231.853 294.363 231.994C292.304 233.728 291.087 235.001 290.992 237.101L287.782 236.957C287.945 233.338 290.246 231.261 292.292 229.536C292.451 229.403 292.609 229.27 292.766 229.139C294.719 227.504 296.505 226.008 297.544 223.557C298.014 222.412 298.23 220.922 298.325 219.099C298.382 218.016 298.393 216.943 298.406 215.815C298.414 214.985 298.424 214.126 298.451 213.21C298.574 209.165 299.05 204.535 302.146 201.241L304.487 203.442C302.298 205.771 301.786 209.247 301.663 213.307C301.64 214.075 301.63 214.893 301.621 215.72C301.606 216.931 301.592 218.159 301.534 219.266Z" fill="#9BB0C9"/>
      <path d="M261.673 136.389C268.999 128.019 281.531 122.99 288.214 125.464C292.874 127.183 287.877 133.995 294.207 136.084C300.537 138.173 323.448 136.084 325.199 144.567C326.131 149.114 314.643 153.387 316.202 157.758C317.503 161.437 328.701 160.682 334.935 162.144C336.453 162.33 337.875 162.987 339 164.023C340.35 165.63 340.189 167.911 341.121 169.727C342.647 172.667 346.632 173.615 348.206 176.523C349.781 179.431 347.997 183.464 345.153 185.343C342.31 187.223 338.727 187.577 335.321 187.882C327.047 188.621 312.009 187.882 310.466 190.099C308.924 192.316 318.853 197.795 318.499 201.538C318.146 205.282 307.365 211.532 299.895 211.789C292.424 212.046 285.017 210.182 277.546 210.182C264.693 210.182 254.7 209.909 247.02 212.592C242.056 214.327 234.762 210.841 235.597 206.921C236.432 203 248.707 200.896 249.109 196.574C249.51 192.252 231.87 193.73 228.946 186.532C226.375 180.25 239.951 174.804 240.192 172.073C240.433 169.341 235.983 167.542 236.368 163.911C236.754 160.28 245.06 159.573 249.735 156.312C254.411 153.05 254.732 144.326 261.673 136.389Z" fill="#BBD3EA"/>
      <path d="M689.889 286.979H319.062V207.885H338.92V149.901H385.673L472.319 69.5533L561.117 149.901H689.889V286.979Z" fill="#BBD3EA"/>
      <path d="M482.392 207.885H308.169L329.248 183.287H374.828V199.096H482.392V207.885Z" fill="#273653"/>
      <path d="M689.889 159.204H580.895V286.786H689.889V159.204Z" fill="white"/>
      <path d="M660.793 210.519H603.211V215.789H660.793V210.519Z" fill="#0C6EBA"/>
      <path d="M630.652 172.876H610.714V207.885H630.652V172.876Z" fill="#273653"/>
      <path d="M653.29 172.94H633.352V207.836H653.29V172.94Z" fill="#273653"/>
      <path d="M369.365 159.204H338.92V183.287H369.365V159.204Z" fill="white"/>
      <path d="M478.874 199.096H374.828V168.201L472.319 77.8114L518.493 118.925L478.874 153.886V199.096Z" fill="white"/>
      <path d="M425.742 147.571H410.865V174.611H425.742V147.571Z" fill="#273653"/>
      <path d="M442.548 147.571H427.67V174.611H442.548V147.571Z" fill="#273653"/>
      <path d="M447.223 176.539H406.173V181.809H447.223V176.539Z" fill="#0C6EBA"/>
      <path d="M698.324 153.404L659.652 84.8645H504.66L471.885 55.2863L439.784 84.8645H394.284L386.701 98.9226H355.516L329.167 153.404H357.38H365.397L353.235 164.618H369.783L472.319 69.5533L522.735 115.182L466.728 164.618L482.328 164.586L529.997 121.753L577.376 164.618H593.057L580.638 153.404H698.324Z" fill="#273653"/>
      <path d="M577.376 286.995H482.392V172.523L530.029 129.802L577.376 172.523V286.995Z" fill="white"/>
      <path d="M547.798 218.215H511.97V286.802H547.798V218.215Z" fill="#273653"/>
      <path d="M544.167 252.324H541.597V260.55H544.167V252.324Z" fill="white"/>
      <path d="M781.773 286.802H249.768V289.775H781.773V286.802Z" fill="#BBD3EA"/>
      <path d="M781.724 286.995C782.737 280.199 766.14 277.998 766.975 271.186C767.184 269.435 768.389 267.973 768.839 266.238C770.124 261.225 764.582 257.015 759.505 256.051C754.428 255.087 748.82 255.682 744.418 252.967C743.484 252.245 742.45 251.66 741.35 251.232C739.665 250.961 737.938 251.213 736.401 251.955C731.469 253.77 728.047 243.327 722.086 240.997C716.125 238.668 712.446 242.122 709.49 240.065C706.534 238.009 708.719 228.417 707.257 224.754C706.538 223.289 705.435 222.046 704.065 221.158C702.696 220.269 701.112 219.769 699.481 219.709C696.196 219.646 692.928 220.191 689.841 221.316V286.995H781.724Z" fill="#BBD3EA"/>
      <path d="M319.062 253.256C318.448 252.272 317.524 251.522 316.435 251.123C315.347 250.724 314.157 250.7 313.053 251.055C310.866 251.808 308.807 252.892 306.948 254.268C304.43 255.825 301.696 256.999 298.834 257.755C297.236 258.268 295.516 258.268 293.918 257.755C292.311 257.128 291.267 255.714 289.741 255.071C288.621 254.631 287.38 254.615 286.249 255.027C285.119 255.438 284.178 256.248 283.603 257.305C282.446 259.586 283.025 262.864 280.92 264.31C279.942 264.858 278.825 265.109 277.707 265.033C274.124 265.145 271.409 263.86 267.842 263.587C264.629 263.346 259.809 264.342 258.588 267.394C258.175 268.997 258.131 270.672 258.459 272.295C258.829 275.283 253.286 276.327 254.121 280.328C254.732 283.284 250.635 282.979 249.735 286.754L319.03 286.979L319.062 253.256Z" fill="#BBD3EA"/>
      <path d="M343.145 286.802L319.062 286.979V214.391H479.099V286.802H453.939V231.309H343.145V286.802Z" fill="white"/>
      <path d="M449.745 235.487H347.306V246.733H449.745V235.487Z" fill="white"/>
      <path d="M449.745 248.838H347.306V260.084H449.745V248.838Z" fill="white"/>
      <path d="M449.745 262.237H347.306V273.483H449.745V262.237Z" fill="white"/>
      <path d="M449.745 275.62H347.306V286.867H449.745V275.62Z" fill="white"/>
      <path d="M528.92 169.036H514.043V196.076H528.92V169.036Z" fill="#273653"/>
      <path d="M545.726 169.036H530.848V196.076H545.726V169.036Z" fill="#273653"/>
      <path d="M550.401 197.988H509.352V203.257H550.401V197.988Z" fill="#0C6EBA"/>
      <path d="M660.793 268.519H603.211V273.789H660.793V268.519Z" fill="#0C6EBA"/>
      <path d="M630.652 230.859H610.714V265.868H630.652V230.859Z" fill="#273653"/>
      <path d="M653.29 230.924H633.352V265.82H653.29V230.924Z" fill="#273653"/>
      <path d="M180.393 286.819H39.4108V196.783L109.171 139.233L180.393 196.301V286.819Z" fill="#E8EEF9"/>
      <path d="M180.393 207.74L109.171 150.126L39.4108 207.74H26.1239L45.1947 192.011V160.714H67.1092V173.936L109.171 139.233L194.66 207.74H180.393Z" fill="#BBD3EA"/>
      <path d="M180.393 287.365L39.4108 286.819V215.564L109.669 157.966L180.393 215.564V287.365Z" fill="white"/>
      <path d="M126.282 224.128H93.5222V286.819H126.282V224.128Z" fill="#BBD3EA"/>
      <path d="M122.956 255.312H120.61V262.831H122.956V255.312Z" fill="white"/>
      <path d="M223.499 286.819H0V289.534H223.499V286.819Z" fill="#BBD3EA"/>
      <path d="M65.3579 224.128H51.7658V248.838H65.3579V224.128Z" fill="#E8EEF9"/>
      <path d="M80.7013 224.128H67.1091V248.838H80.7013V224.128Z" fill="#E8EEF9"/>
      <path d="M85.007 250.605H47.476V255.425H85.007V250.605Z" fill="#BBD3EA"/>
      <path d="M152.454 224.128H138.861V248.838H152.454V224.128Z" fill="#E8EEF9"/>
      <path d="M167.813 224.128H154.221V248.838H167.813V224.128Z" fill="#E8EEF9"/>
      <path d="M172.103 250.605H134.572V255.425H172.103V250.605Z" fill="#BBD3EA"/>
      <path d="M0 286.819C0.305261 281.838 3.82379 280.922 4.38611 278.046C4.94844 275.17 3.4382 272.455 4.49858 269.804C5.55896 267.153 10.4592 267.009 12.2104 264.824C13.9617 262.639 13.2226 259.088 14.9256 256.999C16.6287 254.911 21.3522 255.248 23.4408 254.268C25.5294 253.288 26.7665 250.685 29.3372 250.075C32.8961 249.675 36.4787 250.589 39.4108 252.645V286.819H0Z" fill="#BBD3EA"/>
      <path d="M223.515 286.819C218.502 283.348 221.073 278.962 219.707 276.231C218.197 273.21 214.534 273.789 212.911 271.7C211.289 269.611 208.638 261.755 204.284 260.968C199.335 260.068 196.716 263.924 192.652 264.374C189.438 264.711 185.084 261.578 180.393 266.077V286.819H223.515Z" fill="#BBD3EA"/>
      <path d="M989.51 287.06H848.528V197.008L918.304 139.474L989.51 196.542V287.06Z" fill="#E8EEF9"/>
      <path d="M989.511 207.965L918.304 150.367L848.528 207.965H835.241L854.312 192.252V160.939H876.243V174.161L918.304 139.474L1003.79 207.965H989.511Z" fill="#BBD3EA"/>
      <path d="M989.51 287.606L848.528 287.06V215.805L918.786 158.191L989.51 215.805V287.606Z" fill="white"/>
      <path d="M935.399 224.353H902.64V287.043H935.399V224.353Z" fill="#BBD3EA"/>
      <path d="M932.073 255.537H929.728V263.056H932.073V255.537Z" fill="white"/>
      <path d="M1032.62 287.06H809.117V289.775H1032.62V287.06Z" fill="#BBD3EA"/>
      <path d="M874.475 224.353H860.883V249.063H874.475V224.353Z" fill="#E8EEF9"/>
      <path d="M889.835 224.353H876.243V249.063H889.835V224.353Z" fill="#E8EEF9"/>
      <path d="M894.125 250.83H856.594V255.65H894.125V250.83Z" fill="#BBD3EA"/>
      <path d="M961.571 224.353H947.979V249.063H961.571V224.353Z" fill="#E8EEF9"/>
      <path d="M976.931 224.353H963.338V249.063H976.931V224.353Z" fill="#E8EEF9"/>
      <path d="M981.22 250.83H943.689V255.65H981.22V250.83Z" fill="#BBD3EA"/>
      <path d="M809.117 286.995C809.423 281.999 812.941 281.083 813.504 278.223C814.066 275.363 812.556 272.632 813.616 269.981C814.676 267.33 819.577 267.185 821.328 264.984C823.079 262.783 822.34 259.249 824.059 257.16C825.778 255.071 830.486 255.425 832.558 254.445C834.631 253.465 835.884 250.846 838.455 250.252C842.012 249.836 845.599 250.745 848.528 252.806V286.995H809.117Z" fill="#BBD3EA"/>
      <path d="M1032.63 286.995C1027.62 283.509 1030.19 279.123 1028.84 276.408C1027.23 273.387 1023.67 273.949 1022.03 271.877C1020.39 269.804 1017.76 261.932 1013.42 261.128C1008.47 260.229 1005.83 264.101 1001.77 264.535C998.556 264.872 994.202 261.739 989.51 266.238V286.995H1032.63Z" fill="#BBD3EA"/>
    </g>
    <g clipPath="url(#clip1_13280_223095)">
      <path d="M739.553 144.401L739.27 144.604C739.349 144.536 739.429 144.446 739.497 144.378L739.553 144.412V144.401Z" fill="white"/>
      <path d="M704.7 117.701L714.193 104.456L730.042 115.816L720.55 129.06L704.7 117.701Z" fill="#BBD3EA"/>
      <path d="M597.242 28.584C621.064 -3.05826 666.022 -9.40026 697.664 14.4217C729.306 38.2437 735.648 83.202 711.826 114.844C688.004 146.487 643.046 152.829 611.404 129.007C579.762 105.185 573.42 60.2263 597.242 28.584ZM702.708 107.983C722.739 81.3739 717.412 43.5701 690.803 23.5398C664.194 3.50944 626.39 8.83581 606.36 35.4451C586.329 62.0545 591.656 99.8582 618.265 119.889C644.874 139.919 682.678 134.593 702.708 107.983Z" fill="#46B6FF"/>
      <path d="M734.159 110.092L716.456 134.793C716.094 135.297 716.211 136.006 716.715 136.367L788.24 187.63C789.762 188.722 791.872 188.373 792.964 186.851L808.038 165.819C809.129 164.297 808.781 162.187 807.258 161.095L735.734 109.832C735.229 109.471 734.521 109.588 734.159 110.092Z" fill="#273653"/>
      <path d="M703.16 124.426L702.922 124.584C702.99 124.528 703.046 124.46 703.114 124.403L703.16 124.426Z" fill="white"/>
      <path d="M611.404 129.007C589.771 112.723 579.976 86.5535 583.553 61.5128C584.061 57.9468 584.874 54.426 585.923 50.939C588.293 43.0962 592.017 35.5128 597.242 28.584C621.064 -3.05826 666.022 -9.40026 697.664 14.4217C729.306 38.2437 735.648 83.202 711.826 114.844C709.175 118.365 706.241 121.514 703.115 124.391C703.047 124.448 702.991 124.515 702.923 124.572C700.655 126.648 698.274 128.544 695.802 130.282C693.116 132.166 690.329 133.859 687.44 135.349C663.731 147.581 634.143 146.125 611.404 129.007ZM692.259 118.681C696.084 115.612 699.628 112.08 702.72 107.983C722.75 81.3739 717.424 43.5701 690.814 23.5398C664.205 3.50944 626.401 8.83581 606.371 35.4451C601.135 42.3965 597.682 50.1265 595.854 58.0935C595.165 61.1178 594.68 64.1647 594.454 67.2341C592.999 87.0049 601.27 107.08 618.276 119.889C638.6 135.191 665.401 135.597 685.883 123.105C688.083 121.762 690.239 120.317 692.27 118.681H692.259Z" fill="#008CE7"/>
      <path d="M611.404 129.007C589.771 112.723 579.976 86.5535 583.553 61.5128C584.061 57.9468 584.874 54.426 585.923 50.939C588.293 43.0962 592.017 35.5128 597.242 28.584C621.064 -3.05826 666.022 -9.40026 697.664 14.4217C729.306 38.2437 735.648 83.202 711.826 114.844C709.175 118.365 706.241 121.514 703.115 124.391C703.047 124.448 702.991 124.515 702.923 124.572C700.655 126.648 698.274 128.544 695.802 130.282C693.116 132.166 690.329 133.859 687.44 135.349C663.731 147.581 634.143 146.125 611.404 129.007ZM692.259 118.681C696.084 115.612 699.628 112.08 702.72 107.983C722.75 81.3739 717.424 43.5701 690.814 23.5398C664.205 3.50944 626.401 8.83581 606.371 35.4451C601.135 42.3965 597.682 50.1265 595.854 58.0935C595.165 61.1178 594.68 64.1647 594.454 67.2341C592.999 87.0049 601.27 107.08 618.276 119.889C638.6 135.191 665.401 135.597 685.883 123.105C688.083 121.762 690.239 120.317 692.27 118.681H692.259Z" fill="#46B6FF"/>
      <path opacity="0.43" d="M654.512 132.235C621.281 132.235 594.342 105.295 594.342 72.0645C594.342 38.8335 621.281 11.8945 654.512 11.8945C687.743 11.8945 714.682 38.8335 714.682 72.0645C714.682 105.295 687.743 132.235 654.512 132.235Z" fill="url(#paint0_linear_13280_223095)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_13280_223095" x1="576.207" y1="187.891" x2="710.495" y2="-11.2843" gradientUnits="userSpaceOnUse">
        <stop stopColor="#ACE5FC"/>
        <stop offset="1" stopColor="#ACE5FC" stopOpacity="0"/>
      </linearGradient>
      <clipPath id="clip0_13280_223095">
        <rect width="1032.63" height="243.775" fill="white" transform="translate(0 46)"/>
      </clipPath>
      <clipPath id="clip1_13280_223095">
        <rect width="225.694" height="189.583" fill="white" transform="matrix(-1 0 0 1 808.694 0)"/>
      </clipPath>
    </defs>
  </svg>
)
