import { ISort } from '../../components/Sort'
import { IPropertyListing } from '../PropertyContext'

export const shouldSortBySavedAt = (sort: ISort) => {
  return sort?.label?.toLowerCase().includes('date saved')
}

export const sortBySavedAt = (operator: string, uuids: string[], properties: IPropertyListing[]) => {
  if (!uuids || !uuids.length) { return properties }

  return [...properties].sort((a, b) => {
    const current = uuids.indexOf(a.propertyUuid)
    const compare = uuids.indexOf(b.propertyUuid)
    return operator === 'asc' ? compare - current : current - compare
  })
}
