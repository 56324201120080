import { dasherize } from '../dasherize';

export const propertyPageUrl = (
  countyOrParish: string | null,
  stateOrProvince: string | null,
  hlFullAddress: string | null,
  city: string | null,
  postalCode: string | null,
  uuidFormatted: string | null
) : string => {
  let slug = dasherize(`${countyOrParish} ${stateOrProvince}`);
  let addressPropertyUUID = dasherize(
    `${hlFullAddress} ${city} ${stateOrProvince} ${postalCode} ${uuidFormatted}`
  )
  slug = slug.replace(/[.]/gi, '')
  addressPropertyUUID = addressPropertyUUID.replace(/[\s/&%,#.]/ig, '-')
  return `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/homes/${slug}/${addressPropertyUUID}`;
};
