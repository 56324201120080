/* istanbul ignore file */
import React from 'react'
import ReactDOM from 'react-dom'
import { GlobalStyles } from './components/common/GlobalStyles'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { createServer, Response } from 'miragejs'
import { passthroughs } from './passthroughs'
import { initializeGTM } from './utils/tracking'
import { initializeFlagsmith } from './utils/flagsmith'
import { initializeSentry } from './utils/sentry'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import { PropertySearch } from './pages/PropertySearch'
import { SavedPropertySearches } from './pages/SavedPropertySearches'
import { SavedHomes } from './pages/SavedHomes'
import { HtmlSitemap } from './pages/HtmlSitemap'
import { UltraAgent } from './pages/UltraAgent'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="" Component={PropertySearch} />
      <Route
        path="/homes-for-sale/county/:areaSlug"
        Component={props => <PropertySearch {...props} pageAreaType="county" />}
      />
      <Route
        path="/homes-for-sale/zip/:areaSlug"
        Component={props => <PropertySearch {...props} pageAreaType="zip" />}
      />
      <Route
        path={'/condos-for-sale/:areaSlug'}
        Component={props => <PropertySearch {...props} pageType={'condo'} />}
      />
      <Route
        path={'/condos-for-sale'}
        Component={props => <PropertySearch {...props} pageType={'condo'} />}
      />
      <Route
        path={'/states/:areaSlug/homes-for-sale/condos'}
        Component={props => <PropertySearch {...props} pageType={'condo'} />}
      />
      <Route
        path={'/:areaSlug/homes-for-sale/condos'}
        Component={props => <PropertySearch {...props} pageType={'condo'} />}
      />
      <Route
        path={'/single-family-homes-for-sale/:areaSlug'}
        Component={props => <PropertySearch {...props} pageType={'sfh'} />}
      />
      <Route
        path={'/single-family-homes-for-sale'}
        Component={props => <PropertySearch {...props} pageType={'sfh'} />}
      />
      <Route
        path={'/states/:areaSlug/homes-for-sale/single-family-homes'}
        Component={props => <PropertySearch {...props} pageType={'sfh'} />}
      />
      <Route
        path={'/:areaSlug/homes-for-sale/single-family-homes'}
        Component={props => <PropertySearch {...props} pageType={'sfh'} />}
      />
      <Route
        path={'/townhomes-for-sale/:areaSlug'}
        Component={props => <PropertySearch {...props} pageType={'townhome'} />}
      />
      <Route
        path={'/townhomes-for-sale'}
        Component={props => <PropertySearch {...props} pageType={'townhome'} />}
      />
      <Route
        path={'/states/:areaSlug/homes-for-sale/townhomes'}
        Component={props => <PropertySearch {...props} pageType={'townhome'} />}
      />
      <Route
        path={'/:areaSlug/homes-for-sale/townhomes'}
        Component={props => <PropertySearch {...props} pageType={'townhome'} />}
      />
      <Route
        path={'/mobile-homes-for-sale/:areaSlug'}
        Component={props => <PropertySearch {...props} pageType={'mobile'} />}
      />
      <Route
        path={'/mobile-homes-for-sale'}
        Component={props => <PropertySearch {...props} pageType={'mobile'} />}
      />
      <Route
        path={'/states/:areaSlug/homes-for-sale/mobile-homes'}
        Component={props => <PropertySearch {...props} pageType={'mobile'} />}
      />
      <Route
        path={'/:areaSlug/homes-for-sale/mobile-homes'}
        Component={props => <PropertySearch {...props} pageType={'mobile'} />}
      />
      <Route
        path={'/:bedroomCount-bedroom-homes-for-sale/:areaSlug'}
        Component={props => <PropertySearch {...props} pageType={'bedroom'} />}
      />
      <Route
        path={'/:bedroomCount-bedroom-homes-for-sale'}
        Component={props => <PropertySearch {...props} pageType={'bedroom'} />}
      />
      <Route
        path={'/states/:areaSlug/homes-for-sale/:bedroomCount-bedroom'}
        Component={props => <PropertySearch {...props} pageType={'bedroom'} />}
      />
      <Route
        path={'/:areaSlug/homes-for-sale/:bedroomCount-bedroom'}
        Component={props => <PropertySearch {...props} pageType={'bedroom'} />}
      />
      <Route
        path={'/states/:areaSlug/homes-for-sale/newest-listings'}
        Component={props => <PropertySearch {...props} pageType={'newest'} />}
      />
      <Route
        path={'/:areaSlug/homes-for-sale/newest-listings'}
        Component={props => <PropertySearch {...props} pageType={'newest'} />}
      />
      <Route
        path={'/newest-listings/:areaSlug'}
        Component={props => <PropertySearch {...props} pageType={'newest'} />}
      />
      <Route
        path={'/newest-listings'}
        Component={props => <PropertySearch {...props} pageType={'newest'} />}
      />
      <Route
        path={'/:areaSlug/sold'}
        Component={props => <PropertySearch {...props} pageType={'sold'} />}
      />
      <Route
        path={'/states/:areaSlug/sold'}
        Component={props => <PropertySearch {...props} pageType={'sold'} />}
      />
      <Route
        path={'/sold-homes/:areaSlug'}
        Component={props => <PropertySearch {...props} pageType={'sold'} />}
      />
      <Route
        path={'/sold-homes'}
        Component={props => <PropertySearch {...props} pageType={'sold'} />}
      />
      <Route
        path="/states/:areaSlug/homes-for-sale"
        Component={props => <PropertySearch {...props} pageAreaType="state" />}
      />
      <Route
        path="/:areaSlug/homes-for-sale"
        Component={props => <PropertySearch {...props} pageAreaType="city" />}
      />
      <Route
        path="/:areaSlug/featured"
        Component={props => <PropertySearch {...props} pageAreaType="city" pageType="featured" />}
      />
      <Route path="/homes/saved-property-searches" Component={() => <SavedPropertySearches />} />
      <Route path="/homes/favorites" Component={() => <SavedHomes type="favorites" />} />
      <Route path="/homes/recently-viewed" Component={() => <SavedHomes type="recentlyViewed" />} />
      <Route
        path="/homes/:areaSlug/:pageNumber?"
        Component={({ location, history, match }) => (
          <HtmlSitemap location={location} history={history} match={match} />
        )}
      />
      <Route path="/ultra-agent/:propertyUuid" Component={() => <UltraAgent />} />
      <Route path="/homes-for-sale" Component={props => <PropertySearch {...props} />} />
    </>
  )
)

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
)

initializeGTM()
initializeFlagsmith()
initializeSentry()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (window.Cypress) {
  // If your app makes requests to domains other than / (the current domain), add them
  // here so that they are also proxied from your app to the handleFromCypress function.
  // For example: let otherDomains = ["https://my-backend.herokuapp.com/"]
  let otherDomains = []
  let methods = ['get', 'put', 'patch', 'post', 'delete']

  createServer({
    environment: 'test',
    routes() {
      for (const domain of ['/', ...otherDomains]) {
        for (const method of methods) {
          this[method](`${domain}*`, async (schema, request) => {
            let [status, headers, body] = await window.handleFromCypress(request)
            return new Response(status, headers, body)
          })
        }
      }

      // If your central server has any calls to passthrough(), you'll need to duplicate them here
      passthroughs.forEach(passthrough => this.passthrough(passthrough))
    }
  })
}
