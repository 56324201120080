import { LinkType } from './types'
import styled from 'styled-components'
import { FooterTitle } from './FooterTitle'
import { FooterLink } from './FooterLink'
import { BREAKPOINT_XL, BREAKPOINT_S } from "../../constants";

const StyledFooterColumn = styled.div`
  flex-basis: 100%;

  @media (min-width: ${BREAKPOINT_S}px) {
    flex-basis: 50%;
  }

  @media (min-width: ${BREAKPOINT_XL}px) {
    flex-basis: 25%;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FooterColumn = ({title, links} : {title: string, links: LinkType[] }) => {

  return (
    <StyledFooterColumn>
      <FooterTitle title={title} />
      <LinkContainer>
        {links.map(({ name, url }: { name: string; url: string }) => {
          return <FooterLink key={`link-${name}`} name={name} url={url} />;
        })}
      </LinkContainer>
    </StyledFooterColumn>
  );
}