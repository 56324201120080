import styled from 'styled-components'
import { white} from '../../../constants';
import { BREAKPOINT_S } from '../../../constants/breakpoints'

export const StyledModalBackground = styled('div')<{
  fullPage?: boolean
}>`
  position: fixed;
  z-index: ${props => props.fullPage ? '7' : '100'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.fullPage ? white : 'rgba(0,0,0,.4)'};
`
export const StyledModalContainer = styled("div")<{ ref?: HTMLElement }>`
  width: 512px;
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_S}px) {
    width: 100%;
  }
`;
