import styled from 'styled-components'
import { coolGray50 } from '../../../constants'
import { Flex } from '../Flex'
import { ElectricBlueButton, TransparentButton } from '../Button'
import { ICancelOrConfirmProps } from '.'

const StyledFlex = styled(Flex)`
  padding: 16px;
  border-top: 1px solid ${coolGray50};
`

const StyledClear = styled(TransparentButton)`
  font-size: 14px;
  font-weight: 700;
  padding-left: 0;
  padding-right: 0;
  &:disabled {
    color: ${coolGray50}
  }
`
export const CancelOrConfirm: React.FC<ICancelOrConfirmProps> = ({
  confirmLabel,
  confirm,
  cancelLabel,
  disableCancel,
  cancel,
  testLabel
}) => {
  return (
    <StyledFlex
      justify="space-between"
      className='cancel-confirm'
    >
      <StyledClear
        disabled={disableCancel}
        onClick={cancel}
        data-testid={`${testLabel}-clear`}
      >
        {cancelLabel?.length ? cancelLabel : 'Clear'}
      </StyledClear>
      <ElectricBlueButton.Medium
        onClick={confirm}
        data-testid={`${testLabel}-save`}
      >
        {confirmLabel?.length ? confirmLabel : 'Save'}
      </ElectricBlueButton.Medium>
    </StyledFlex>
  )
}
