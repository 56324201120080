import { UserProvider } from "../../contexts/UserContext";
import { ApplicationNavigation } from "../common/ApplicationNavigation";
import { Footer } from "../Footer";
import { AREA_FOOTER_LINKS, DEFAULT_FOOTER_LINKS } from "../../constants";

type LayoutProps = {
  children: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ setConfirmAgent, children }) => {
  return (
    <UserProvider>
      <ApplicationNavigation
        constrainWidth={false}
        setConfirmAgent={setConfirmAgent}
      />
      {children}
      {/* TODO: Move sign in / sign up modals here? */}
      <Footer areaFooterData={AREA_FOOTER_LINKS} defaultFooterData={DEFAULT_FOOTER_LINKS} />
    </UserProvider>
  )
}