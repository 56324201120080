export const Trash = () => {
    return (
        <svg
            width="22px"
            height="24px"
            viewBox="0 0 22 24"
            fill="none"
            role="img"
        >
            <title>Delete</title>
            <path
                d="M21.125 3H16.25L14.675 0.9C14.4654 0.620559 14.1937 0.393751 13.8812 0.237539C13.5688 0.0813265 13.2243 0 12.875 0L9.125 0C8.7757 0 8.43119 0.0813265 8.11877 0.237539C7.80634 0.393751 7.53458 0.620559 7.325 0.9L5.75 3H0.875C0.775544 3 0.680161 3.03951 0.609835 3.10984C0.539509 3.18016 0.5 3.27554 0.5 3.375L0.5 4.125C0.5 4.22446 0.539509 4.31984 0.609835 4.39016C0.680161 4.46049 0.775544 4.5 0.875 4.5H1.76094L3.31719 21.9516C3.36751 22.5111 3.62534 23.0316 4.03996 23.4106C4.45459 23.7897 4.99603 23.9999 5.55781 24H16.4422C17.004 23.9999 17.5454 23.7897 17.96 23.4106C18.3747 23.0316 18.6325 22.5111 18.6828 21.9516L20.2391 4.5H21.125C21.2245 4.5 21.3198 4.46049 21.3902 4.39016C21.4605 4.31984 21.5 4.22446 21.5 4.125V3.375C21.5 3.27554 21.4605 3.18016 21.3902 3.10984C21.3198 3.03951 21.2245 3 21.125 3ZM8.525 1.8C8.59511 1.70712 8.68575 1.63171 8.78983 1.57967C8.89392 1.52763 9.00863 1.50036 9.125 1.5H12.875C12.9914 1.50036 13.1061 1.52763 13.2102 1.57967C13.3142 1.63171 13.4049 1.70712 13.475 1.8L14.375 3H7.625L8.525 1.8ZM17.1875 21.8156C17.1721 22.0025 17.0868 22.1767 16.9487 22.3035C16.8105 22.4304 16.6297 22.5005 16.4422 22.5H5.55781C5.37029 22.5005 5.18946 22.4304 5.05134 22.3035C4.91321 22.1767 4.82794 22.0025 4.8125 21.8156L3.26562 4.5H18.7344L17.1875 21.8156Z"
                fill="#273653"
            />
        </svg>
    )
}
