export {
  darkBlue,
  electricBlue,
  electricBlueHover,
  coolGray10,
  coolGray20,
  coolGray30,
  coolGray40,
  coolGray50,
  coolGray60,
  coolGray70,
  white,
  whiteHover,
  cadmiumRed,
  dandelionYellow,
  viridianGreen,
  mediumBlue,
  cobaltBlue,
  glacierBlue,
  mistBlue,
  aaBlue,
  actionOrange,
  alertRedHover,
  black,
  COLORS
} from './colors'
export * from './typeface'
export * from './borders'
export * from './spacing'

export { BREAKPOINT_S, BREAKPOINT_M, BREAKPOINT_L, BREAKPOINT_XL } from './breakpoints'
export * from './footer'
export {
  AGENT_NAV_OPTIONS,
  NAV_OPTIONS,
  USER_NAV_OPTIONS,
  USER_NAV_OPTIONS_MAP,
  CORE_UI_NAV_OPTIONS
} from './header'
export { MAX_PAGE_WIDTH } from './layout'

export { PROPERTY_TYPE_OPTIONS, PERMITTED_PROPERTY_TYPES } from './propertyTypes'

export { PROPERTY_TYPE_MAPPING } from './propertyTypeMapping'

export * from './pagination'

// Dropdown options
export { PRICE_OPTIONS } from './price'
export { BEDS_OPTIONS, BEDS_NO_MIN_MAX_OPTION } from './beds'
export { BATHS_OPTIONS, BATHS_NO_MIN_OPTION } from './baths'
export {
  SORT_OPTIONS,
  SAVED_HOME_SORT_OPTIONS,
  DEFAULT_PROPERTY_SORT,
  DEFAULT_SAVED_HOME_SORT,
  NEWEST_PROPERTY_SORT
} from './sort'
export {
  LISTING_STATUS_OPTIONS,
  DEFAULT_DATE_LISTED_OPTION,
  DATE_LISTED_OPTIONS,
  DEFAULT_LISTING_STATUS_FILTER,
  DEFAULT_LISTING_STATUS_FILTER_VALUE,
  LISTING_STATUS_ACTIVE_FILTER_VALUE,
  LISTING_STATUS_SOLD_FILTER,
  LISTING_STATUS_SOLD_FILTER_VALUE,
  LISTING_STATUS_ACTIVE_COMING_SOON_FILTER_VALUE
} from './listingOptions'
export { SALE_DATE_OPTIONS, DEFAULT_SALE_DATE } from './sale'
export { SQFT_OPTIONS } from './sqft'
export { LOT_SIZE_OPTIONS, FEET_IN_AN_ACRE } from './lotSize'
export { YEAR_BUILT_OPTIONS } from './yearBuilt'
export const NO_MIN_FILTER_OPTION = { label: 'No min', value: 0 }
export const NO_MAX_FILTER_OPTION = { label: 'No max', value: 0 }
export { DEFAULT_FEATURED_OPTION, FEATURED_OPTIONS } from './featuredOptions'

// Filters
export {
  FOR_SALE,
  SOLD,
  CLOSED,
  HAS_LISTING_FILTER,
  LISTING_STATUS_FILTER,
  URL_ELIGIBLE,
  DATE_LISTED_FILTER
} from './listingFilters'
export {
  PRICE_MIN_FILTER,
  PRICE_MAX_FILTER,
  BEDS_MIN_FILTER,
  BEDS_MAX_FILTER,
  BATHS_MIN_FILTER,
  PROPERTY_TYPE_FILTER,
  SQFT_MIN_FILTER,
  SQFT_MAX_FILTER,
  LOT_SIZE_MIN_FILTER,
  LOT_SIZE_MAX_FILTER,
  YEAR_BUILT_MIN_FILTER,
  YEAR_BUILT_MAX_FILTER,
  FEATURED_FILTER
} from './propertyDetailFilters'
export {
  TL_LAT_FILTER,
  TL_LON_FILTER,
  BR_LAT_FILTER,
  BR_LON_FILTER,
  STATE_OR_PROVINCE_FILTER,
  COUNTY_OR_PARISH_FILTER,
  CITY_FILTER,
  POSTAL_CODE_FILTER,
  HL_FULL_ADDRESS_FILTER,
  SLUG_FILTER,
  LOCATION_FILTERS,
  NAMED_LOCATION_FILTERS,
  NAMED_LOCATION_FILTER_FIELDS,
  STREET_NUMBER_FILTER,
  STREET_NAME_FILTER
} from './locationFilters'
export { SALE_DATE_FILTER } from './saleFilters'

export { VIEW_TYPES, COOLING_TYPES, YES_NO, GARAGE_TYPES, HEATING_TYPES } from './propertyFields'

export {
  FAVORITES_FILTER,
  RECENTLY_VIEWED_FILTER,
  FAVORITE_PROPERTIES_FILTER,
  REQUESTED_TOUR_FILTER
} from './savedHomesTypeFilters'

export { SEARCH_ALERT_FREQUENCIES, SEARCH_ALERT_FREQUENCY_OPTIONS } from './searchAlertFrequencies'

export const MIN_LAT_LONG_DECIMAL_PRECISION = 5

export const IMAGE_PARAMS = '?width=525&height=350&fit=cover&auto=webp&optimize=medium&format=pjpg'

export const COMING_SOON_PLACEHOLDER_URL =
  'https://mls-media.homelight.com/misc/coming-soon-placeholder.jpeg?width=525&height=350&fit=cover&auto=webp&optimize=medium&format=pjpg'

export const MAPPING_FAVORITE = 'favorites'
export const MAPPING_REQUESTED_TOUR = 'requested_tour'

export const UTM_PARAMS_SHARE = '?utm_source=homes&utm_medium=share&utm_campaign='

export { GENERAL_DISCLAIMER_MLS } from './generalDisclaimerMls'

export const PROPERTIES_API_URL = 'property-service/properties'
export const LISTINGS_API_URL = 'mls-listing-service/listings/hapi-client-listings'
export const CREATE_LEAD_URL = '/home-search-service/home-search/create-lead'
export const LISTINGS_IMAGES_URL = 'mls-listing-service/listings/images'
export const DISCLAIMERS_API_URL = '/mls-listing-service/listings/disclaimers'
export const HAPI_AUTH_URL = '/home-search-service/home-search/current-user'
export const HAPI_LOGIN_EMAIL_URL = 'sales-comms-service/user-login/magic-link-email'
export const HAPI_SIGN_UP_URL = 'client-data-service/clients'
export const HAPI_SIGN_UP_SAVE_SEARCH_URL =
  'home-search-service//home-search/new-client-saved-search'
export const HAPI_LOGOUT_URL = 'user-auth-service/user-login/logout'
export const NEARBY_AREAS_URL = 'mapping-service/geo-shapes'
export const SHORT_URL_URL = 'short-url-service/generate'
export const USER_PROPERTY_URL = 'property-service/property-lookups'
export const FIND_OR_CREATE_VISITOR_URL = 'session-service/visitors/find-or-create'
export const SAVED_SEARCHES_URL = 'property-service/saved-searches'
export const PROPERTY_AGGREGATION_URL = 'property-service/properties/aggregations'
export const PROPERTY_FAQ_URL = 'property-service/home-search/property-faq'
export const TRACK_EVENT_URL = 'session-service/events/track'
export const LATEST_VISITOR_SESSION_URL = 'session-service/visitors/latest-session'
export const CREATE_OR_UPDATE_SESSION_URL = 'session-service/sessions/update-or-create'
export const TRACK_SESSION_TEST_URL = 'session-service/tests/track'
export const ABTEST_SERVICE_URL = '/ab-test-service/find-or-initialize'
export const AUTOCOMPLETE_API = '/mapping-service/geo-shapes/autocomplete'
export const SUBSCRIPTION_CHECKOUT_URL = 'subscription-service/checkout-sessions'
export const NEW_CLIENT_WITH_SUBSCRIPTION =
  'home-search-service/home-search/new-client-with-subscription'
export const QUEUE_LEAD = '/dialer-service/dialer/queue-lead'
export const RECORD_USER_EVENT_PATH = '/events-service/user-events/record-user-event'
export const VERIFY_PHONE = '/user-verification-service/verifications'
export const RESEND_VERIFICATION = '/user-verification-service/verifications'
export const UPDATE_CLIENT_PHONE = '/home-search-service/update-client-phone'
export const AGENT_LISTING_DETAILS_URL = 'property-service/properties/agent-team-listing-details'
export const CREATE_PENDING_AGENT = '/agent-data-service/home-search/pending-agents'
export const PROVIDER_AGENT_UPDATE_STAGE =
  '/lead-disposition-service/sales/leads/:lead_id/provider-leads/:id/hapi_client_update'

export { DIRECTIONAL_PREFIX } from './directionalPrefixes'

export const MAPBOX_BASE_URI = 'https://api.mapbox.com/styles'
export const MAPBOX_DEFAULT_STATIC_MAP_STYLE = 'v1/homelight/ckh2biwmr1fgm19s1838oy674'
