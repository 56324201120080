import { useEffect, useRef } from 'react'

// A useEffect that does not run in the first render.
export function useDidUpdate(callback: () => void, deps: Array<unknown>) {
  const hasMount = useRef(false)

  useEffect(() => {
    if (hasMount.current) {
      callback?.()
    } else {
      hasMount.current = true
    }
  }, deps)
}
