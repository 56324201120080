export const getParamObjects = (params: string) => {
  const search = params ? params.split('?')[1] : ''
  const escapedSearch = escapeOperators(search)
  return Object.fromEntries(new URLSearchParams(escapedSearch))
}

const escapeOperators = (search: string) => {
  return search.replace(/>=/g, '%3E%3D')
    .replace(/<=/g, '%3C%3D')
    .replace(/%3E=/g, '%3E%3D')
    .replace(/%3C=/g, '%3C%3D')
}
