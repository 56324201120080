import styled from 'styled-components'
import { BREAKPOINT_XL } from '../../../constants'
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import { IPropertiesContext } from '../../../contexts/PropertiesContext'
import { Flex } from '../Flex'
import { PrevNextButton } from './PrevNextButton'
import { Ellipses } from './Ellipses'
import { PageLink } from './PageLink'
import { PaginationSummary } from './PaginationSummary'

export const PaginationContainer = styled.div`
  margin: 40px 0 16px;

  @media (min-width: ${BREAKPOINT_XL}px) {
    margin-bottom: 36px;
  }
`
interface IPagination {
  paginationData: IPropertiesContext
}

export const Pagination: React.FC<IPagination> = ({paginationData}) => {
  const {
    pageNumbers: {
      first,
      last,
      prev
    },
    currentPage,
    setCurrentPage,
    properties: {
      propertiesLoading
    }
  } = paginationData
  const { mobile = true } = useBreakpoint()

  const firstPage = first || 1
  const lastPage = last || 1
  const prevPage = prev

  const activatePrev = currentPage !== firstPage && !propertiesLoading
  const activateNext = currentPage !== lastPage && !propertiesLoading

  const previousIsNotFirst = prevPage !== firstPage && prevPage !== 0
  const currentIsNotFirstOrSecond = currentPage !== firstPage && currentPage !== 2
  const currentIsNotLastOrSecondLast = currentPage !== lastPage && currentPage !== lastPage - 1

  const showLeftEllipses = mobile ? currentIsNotFirstOrSecond : previousIsNotFirst
  const showRightEllipses = mobile ? currentIsNotLastOrSecondLast : lastPage - currentPage > 3

  const getDesktopMiddlePages = () => {
    const desktopMiddlePages = []
    const firstMiddlePage = Boolean(prevPage) && previousIsNotFirst ? prevPage : currentPage

    if (currentPage === lastPage) {
      for (let i = firstMiddlePage; desktopMiddlePages.length < 4 && i > firstPage; i--) {
        let previous = i
        desktopMiddlePages.unshift(previous)
      }
    } else {
      for (let i = firstMiddlePage; desktopMiddlePages.length < 4 && i < lastPage; i++) {
        desktopMiddlePages.push(+i)
      }
    }
    return desktopMiddlePages.filter(page => page !== firstPage && page !== lastPage)
  }

  const getMobileMiddlePages = () => {
    return currentPage === first || currentPage === last ? [] : [ currentPage ]
  }

  const middlePages = mobile ? getMobileMiddlePages() : getDesktopMiddlePages()

  const goToPrevious = () => {
    const goTo = currentPage - 1
    goToPage(goTo)
  }

  const goToNext = () => {
    const goTo = currentPage + 1
    goToPage(goTo)
  }

  const scrollToTopOfList = () => {
    const listViewWrapper = document.querySelector('#properties-body')
    if (listViewWrapper) {
      listViewWrapper.scrollTop = -72
    }
  }

  const goToPage = (page: number) => { 
    setCurrentPage(page)
    scrollToTopOfList()
  }

  return (
    <PaginationContainer>
      <Flex justify="center" align="center">
        <PrevNextButton
          useAsPrev
          active={activatePrev}
          changePage={() => goToPrevious()}
        />
        <PageLink
            page={firstPage} 
            active={currentPage === firstPage}
            clickPageLink={() => goToPage(firstPage)}
          />
        {showLeftEllipses && <Ellipses /> }
        {middlePages.map(page => (
          <PageLink
            key={`page-${page}`}
            page={page}
            active={currentPage === page}
            clickPageLink={() => goToPage(page)}
          />
        ))} 
        {showRightEllipses && <Ellipses /> }
        {(lastPage > 1) && (
          <PageLink
            page={lastPage}
            active={currentPage === lastPage}
            clickPageLink={() => goToPage(lastPage)}
          />
        )}
        <PrevNextButton
          active={activateNext}
          changePage={() => goToNext()}
        />
      </Flex>
      <PaginationSummary mt="20px" paginationData={paginationData}/>
    </PaginationContainer>
  )
}
