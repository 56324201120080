export const formatBathrooms = (
    bathroomsCount?: number | null,
): string => {
  return bathroomsCount ? `${bathroomsCount}` : '-';
}

export const countBathrooms = (
  bathroomsFull?: number | null,
  bathroomsHalf?: number | null,
  bathroomsThreeQuarter?: number | null,
  bathroomsOneQuarter?: number | null
): number => {
  if (!(bathroomsFull || bathroomsHalf || bathroomsThreeQuarter || bathroomsOneQuarter)) {
    return 0
  }

  return (
    (bathroomsFull || 0) +
    (bathroomsThreeQuarter || 0) +
    (bathroomsHalf || 0) / 2 +
    (bathroomsOneQuarter || 0) / 2
  )
}
