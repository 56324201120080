export default function AngleRightIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3467 12.2656L10.76 16.8899C10.6131 17.0367 10.3756 17.0367 10.2288 16.8899L9.61014 16.2712C9.46329 16.1244 9.46329 15.8869 9.61014 15.74L13.3127 12L9.61014 8.25996C9.46329 8.11311 9.46329 7.87564 9.61014 7.72879L10.2288 7.11014C10.3756 6.96329 10.6131 6.96329 10.76 7.11014L15.3467 11.7344C15.4936 11.8813 15.4936 12.1187 15.3467 12.2656Z"
        fill="#273653"
      />
    </svg>
  )
}
