export const LOT_SIZE_OPTIONS = [
  { label: '1,000 sq. ft.', value: 1000 },
  { label: '2,000 sq. ft.', value: 2000 },
  { label: '3,000 sq. ft.', value: 3000 },
  { label: '4,000 sq. ft.', value: 4000 },
  { label: '5,000 sq. ft.', value: 5000 },
  { label: '6,000 sq. ft.', value: 6000 },
  { label: '7,000 sq. ft.', value: 7000 },
  { label: '8,000 sq. ft.', value: 8000 },
  { label: '.25 acres', value: 10890 },
  { label: '.5 acres', value: 21780 },
  { label: '1 acre', value: 43560 },
  { label: '2 acres', value: 87120 },
  { label: '3 acres', value: 130680 },
  { label: '4 acres', value: 174240 },
  { label: '5 acres', value: 217800 },
  { label: '10 acres', value: 435600 },
  { label: '20 acres', value: 871200 },
  { label: '50 acres', value: 2178000 },
  { label: '100 acres', value: 4356000 }
]

export const FEET_IN_AN_ACRE = 43560
