import Jsona from 'jsona'
import axios from 'axios'
import { captureException, captureMessage } from '@sentry/react'

import { IRequestDataParameters, IRequestConfig } from '.'
import { TJsonaModel } from 'jsona/lib/JsonaTypes'
import { IPageLinks } from '../buildPageNavigation'
import { MetaType } from '../../contexts/PropertiesContext'

import { buildRequestFilters } from '../buildRequestFilters'
import { buildRequestSorts } from '../buildRequestSorts'
import { buildRequestFields } from '../buildRequestFields'
import { objectKeysToCamel } from '../objectKeysToCamel'

export const requestData = ({
  method,
  url,
  filters = [],
  sorts = [],
  fields = [],
  page = 1,
  size = 20,
  usePagination = false,
  body = {},
  headers = {},
  includeBearerToken = true,
  ignoreJsonFormat = false,
  metaParams = ""
}: IRequestDataParameters) => {
  const dataFormatter = new Jsona()
  const origin =
    process.env.REACT_APP_NODE_ENV === 'production' ? 'https://homes.homelight.com' : ''

  // only hit API proxy when token is needed - skip for localdev to avoid needing express server
  const shouldProxy = process.env.REACT_APP_NODE_ENV !== 'development' && includeBearerToken
  const baseUrl = shouldProxy ? `${origin}/api` : process.env.REACT_APP_HAPI_URL || ''
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: headers
  })

  const makeRequest = async () => {
    let response
    let data: TJsonaModel | TJsonaModel[] | {} = []
    let meta: MetaType | { total_properties: 0; total_featured: 0; active_zones: false }
    let links: IPageLinks
    let error = false
    let config: IRequestConfig | undefined = undefined

    // add token here for localdev since we're skipping API proxy
    if (process.env.REACT_APP_NODE_ENV === 'development' && includeBearerToken) {
      config = {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_HAPI_TOKEN}` }
      }
    }

    try {
      switch (method) {
        case 'get':
          const pageParams = usePagination ? `page[number]=${page}&page[size]=${size}` : ''
          const formattedFilters = buildRequestFilters(filters)
          const formattedSorts = buildRequestSorts(sorts)
          const formattedFields = buildRequestFields(fields)
          const formattedMetaParams = metaParams ? `&meta=${metaParams}` : "";
          const queryStringSeparator = url?.includes('?') ? '&' : '?'
          const requestUrl = `${url}${queryStringSeparator}${pageParams}${formattedFilters}${formattedSorts}${formattedFields}${formattedMetaParams}`
          response = await axiosInstance.get(requestUrl, config)
          break
        case 'post':
          response = await axiosInstance.post(url, body, config)
          break
        case 'put':
          response = await axiosInstance.put(url, body, config)
          break
        case 'delete':
          response = await axiosInstance.delete(url, config)
          break
        default:
          response = {
            data: {
              data: [],
              meta: {},
              links: {}
            }
          }
      }

      const serializedData = await response.data

      if (ignoreJsonFormat) {
        return { data: serializedData, meta: undefined, links: undefined, error }
      }

      const formattedData = dataFormatter.deserialize(serializedData)

      links = serializedData.links
      meta = serializedData.meta

      data = Array.isArray(formattedData)
        ? formattedData.map((object: object) => objectKeysToCamel(object))
        : objectKeysToCamel(formattedData) || []
    } catch (err) {
      data = []
      meta = { total_properties: 0 }
      links = {}
      error = true

      const hapiErrorMessage = parseHapiError(err)
      console.error(hapiErrorMessage)
      const statusCode = err.response?.status
      // Don't forward unauthorized errors
      if (statusCode !== 401) {
        if (hapiErrorMessage) {
          captureMessage(hapiErrorMessage)
        } else {
          captureException(err)
        }
      }
    }
    return { data, meta, links, error }
  }

  return makeRequest().then(({ data, meta, links, error }) => ({ data, meta, links, error }))
}

const parseHapiError = errObj => {
  const errResponse = errObj.response?.data
  return errResponse?.message || errResponse?.messages || errResponse?.errors
}
