import {BedIconDataType} from './types';

export const BedIcon: React.FC<BedIconDataType> = ({ solid = false }) => {
  if (solid) return (
    <svg className="beds-light-icon" viewBox="0 0 14 12" role="img">
        <title>Beds</title>
      <path d="M2.14286 3.42857C2.14286 3.20124 2.23316 2.98323 2.39391 2.82248C2.55465 2.66173 2.77267 2.57143 3 2.57143H5.57143C5.79876 2.57143 6.01677 2.66173 6.17752 2.82248C6.33827 2.98323 6.42857 3.20124 6.42857 3.42857V4.28571H7.28571V3.42857C7.28571 3.20124 7.37602 2.98323 7.53677 2.82248C7.69751 2.66173 7.91553 2.57143 8.14286 2.57143H10.7143C10.9416 2.57143 11.1596 2.66173 11.3204 2.82248C11.4811 2.98323 11.5714 3.20124 11.5714 3.42857V4.28571H12.8571V0.857143C12.8571 0.629814 12.7668 0.411797 12.6061 0.251051C12.4453 0.0903058 12.2273 0 12 0H1.71429C1.48696 0 1.26894 0.0903058 1.10819 0.251051C0.947449 0.411797 0.857143 0.629814 0.857143 0.857143V4.28571H2.14286V3.42857ZM12 5.14286H1.71429C1.25963 5.14286 0.823594 5.32347 0.502103 5.64496C0.180612 5.96645 0 6.40249 0 6.85714L0 11.5714C0 11.6851 0.0451529 11.7941 0.125526 11.8745C0.205898 11.9548 0.314907 12 0.428571 12H1.28571C1.39938 12 1.50839 11.9548 1.58876 11.8745C1.66913 11.7941 1.71429 11.6851 1.71429 11.5714V10.2857H12V11.5714C12 11.6851 12.0452 11.7941 12.1255 11.8745C12.2059 11.9548 12.3149 12 12.4286 12H13.2857C13.3994 12 13.5084 11.9548 13.5888 11.8745C13.6691 11.7941 13.7143 11.6851 13.7143 11.5714V6.85714C13.7143 6.40249 13.5337 5.96645 13.2122 5.64496C12.8907 5.32347 12.4547 5.14286 12 5.14286Z" />
    </svg>
  )
  else return (
    <svg viewBox="0 0 16 14" role="img">
        <title>Beds</title>
      <path d="M14.9996 6.06719V1.5C14.9996 1.10218 14.8416 0.720644 14.5603 0.43934C14.279 0.158035 13.8974 0 13.4996 0H2.4996C2.10178 0 1.72025 0.158035 1.43894 0.43934C1.15764 0.720644 0.999603 1.10218 0.999603 1.5V6.06719C0.428978 6.21875 -0.000396729 6.71531 -0.000396729 7.33344V13.75C-0.000396729 13.8163 0.0259425 13.8799 0.0728266 13.9268C0.119711 13.9737 0.183299 14 0.249603 14H0.749603C0.815907 14 0.879496 13.9737 0.92638 13.9268C0.973264 13.8799 0.999603 13.8163 0.999603 13.75V12H14.9996V13.75C14.9996 13.8163 15.0259 13.8799 15.0728 13.9268C15.1197 13.9737 15.1833 14 15.2496 14H15.7496C15.8159 14 15.8795 13.9737 15.9264 13.9268C15.9733 13.8799 15.9996 13.8163 15.9996 13.75V7.33344C15.9996 6.71531 15.5702 6.21875 14.9996 6.06719ZM1.9996 5C1.9996 4.73478 2.10496 4.48043 2.2925 4.29289C2.48003 4.10536 2.73439 4 2.9996 4H6.4996C6.76482 4 7.01917 4.10536 7.20671 4.29289C7.39425 4.48043 7.4996 4.73478 7.4996 5V6H1.9996V5ZM13.9996 6H8.4996V5C8.4996 4.73478 8.60496 4.48043 8.7925 4.29289C8.98003 4.10536 9.23439 4 9.4996 4H12.9996C13.2648 4 13.5192 4.10536 13.7067 4.29289C13.8942 4.48043 13.9996 4.73478 13.9996 5V6ZM2.4996 1H13.4996C13.6322 1 13.7594 1.05268 13.8532 1.14645C13.9469 1.24021 13.9996 1.36739 13.9996 1.5V3.27812C13.697 3.09802 13.3518 3.002 12.9996 3H9.4996C9.21572 2.99996 8.93509 3.06046 8.67643 3.17745C8.41778 3.29443 8.18704 3.46523 7.9996 3.67844C7.81217 3.46523 7.58143 3.29443 7.32277 3.17745C7.06412 3.06046 6.78349 2.99996 6.4996 3H2.9996C2.64745 3.002 2.30222 3.09802 1.9996 3.27812V1.5C1.9996 1.36739 2.05228 1.24021 2.14605 1.14645C2.23982 1.05268 2.367 1 2.4996 1ZM0.999603 11V7.33344C0.999768 7.24506 1.03495 7.16034 1.09745 7.09784C1.15994 7.03535 1.24466 7.00016 1.33304 7H14.6662C14.7545 7.00016 14.8393 7.03535 14.9018 7.09784C14.9643 7.16034 14.9994 7.24506 14.9996 7.33344V11H0.999603Z" />
    </svg>
  );
};
