import { findObjectIndicesByKey } from '../findObjectIndicesByKey'
import { getObjectPriorityOrder } from '../getObjectPriorityOrder'
import { mergeObjects } from '../mergeObjects'

import { IMergeObjectArraysParameters } from '.'

export const mergeObjectArrays = ({arr1, arr2, priorityKey = '', updateDupe = false, key = "id"}: IMergeObjectArraysParameters): Object[] => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return []
  }

  const duplicateIndices: number[] = []

  const updatedArr1 = (arr1 as any[]).map((item: { id : string | number }) => {
    // Take the first found duplicate index (assumes only 1 exists)
    const duplicateIndex: number = findObjectIndicesByKey((arr2 as any), key, (item as any)[key])[0]

    if (duplicateIndex > -1) {
      duplicateIndices.push(duplicateIndex)
      const objects = priorityKey.length ? getObjectPriorityOrder(item, arr2[duplicateIndex], priorityKey) : [item, arr2[duplicateIndex]]
      return updateDupe ? mergeObjects(objects[0], objects[1]) : objects[0]
    }

    return item
  })

  const filteredArr2 = arr2.filter((item, index) => !duplicateIndices.includes(index))
  return updatedArr1.concat(filteredArr2)
}
