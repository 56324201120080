import styled from 'styled-components'
import { ScheduleTourButton } from './sharedStyles'
import { Flex } from '../common/Flex'
import {
  BREAKPOINT_M,
  BREAKPOINT_L,
  coolGray50,
  darkBlue,
  electricBlue,
  MAX_PAGE_WIDTH,
  white,
} from '../../constants'

const SectionWrapper = styled.div``

const SectionContent = styled.div`
  padding: 60px 16px 80px;
  
  @media (min-width: ${BREAKPOINT_L}px) {
    padding: 80px 120px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Title = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 48px;
  text-align: center;
  letter-spacing: -1px;

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 36px;
    margin-bottom: 56px;
  }
`

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media (min-width: ${BREAKPOINT_M}px) {
    
  }
`

const Step = styled(Flex)`
  display: flex;
  gap: 16px;
  align-items: flex-start;

  @media (min-width: ${BREAKPOINT_M}px) {
    gap: 48px;
  }
`

const StepNumberMargin = styled(Flex)`
  flex-direction: column;
  align-self: stretch;

  @media (min-width: ${BREAKPOINT_M}px) {
    margin-right: 14px;
  }
`

const StepNumber = styled(Flex)`
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${electricBlue};
  color: ${white};
  border-radius: 50%;
  font-weight: 700;
`

const StepNumberLine = styled.div`
  border-left: solid 1px ${coolGray50};
  flex: 1;
`

const StepNumberTopLine = styled(StepNumberLine)`
  flex: 0 0 36px;
`

const StepNumberTopNoLine = styled.div`
  flex: 0 0 36px;
`

const StepWrapper = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 16px;
  
  @media (min-width: ${BREAKPOINT_M}px) {
    flex-direction: row;
    gap: 48px;
  }
`

const StepImage = styled.div`
  width: 96px;
  height: 96px;
  flex: 0 0 96px;
  
  img {
    width: 100%;
    height: 100%;
  }
`

const StepText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${white};

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 20px;
  }
`

const StepTitle = styled.h3`
  margin: 4px 0 16px;
`

const StepContent = styled.p`
  font-weight: 400;
  line-height: 180%;
  max-width: 1000px;
`

const ModernSolutionCta = styled.div`
  padding: 48px 16px;
  background-color: ${darkBlue};

  @media (min-width: ${BREAKPOINT_M}px) {
    padding: 80px 120px;
  }
`

const CtaTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 24px;
  text-align: center;
  color: ${white};

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 32px;
  }
`

const CtaButton = styled(ScheduleTourButton)`
  width: 100%;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
`

export const ModernSolution = ({
  agentFirstName,
  propertyListing,
  hlRate,
  rateEstimated,
  goToForm,
  savingsSizeCopy,
  address
}) => (
  <SectionWrapper>
    <SectionContent>
      <Title>Your Modern Buying Solution</Title>
      <Steps>
        <Step>
          <StepNumberMargin>
            <StepNumberTopNoLine />
            <StepNumber>1</StepNumber>
            <StepNumberLine />
          </StepNumberMargin>
          <StepWrapper>
            <StepImage>
              <img
                src="https://hl-production-assets.s3.amazonaws.com/house-hunter/choose-date.png"
                alt=""
              />
            </StepImage>
            <StepText>
              <StepTitle>
                Tour&nbsp;
                <a href={propertyListing?.propertyPageUrl}>{address}</a>
                &nbsp;with the click of a button
              </StepTitle>
              <StepContent>
                Once you indicate your interest, we'll set up a tour of the home to ensure it fits
                all of your needs.
              </StepContent>
            </StepText>
          </StepWrapper>
        </Step>
        <Step>
          <StepNumberMargin>
            <StepNumberTopLine />
            <StepNumber>2</StepNumber>
            <StepNumberLine />
          </StepNumberMargin>
          <StepWrapper>
            <StepImage>
              <img
                src="https://hl-production-assets.s3.amazonaws.com/house-hunter/certified-agent.png"
                alt=""
              />
            </StepImage>
            <StepText>
              <StepTitle>
                {`We help you assume the mortgage at ${rateEstimated ? 'as low as ' : ''}${hlRate}%, start to finish`}
              </StepTitle>
              <StepContent>
                Together with HomeLight, we're your mortgage concierge. We handle the process of you
                assuming the seller's existing, low-rate loan, so you can focus on preparing for
                your move.
              </StepContent>
            </StepText>
          </StepWrapper>
        </Step>
        <Step>
          <StepNumberMargin>
            <StepNumberTopLine />
            <StepNumber>3</StepNumber>
          </StepNumberMargin>
          <StepWrapper>
            <StepImage>
              <img
                src="https://hl-production-assets.s3.amazonaws.com/house-hunter/receive-cash.png"
                alt=""
              />
            </StepImage>
            <StepText>
              <StepTitle>
                {`You save up to ${savingsSizeCopy} of thousands over the lifetime of the loan`}
              </StepTitle>
              <StepContent>
                With HomeLight Ultra, you've locked in an ultra-low rate mortgage on the home of
                your dreams.
              </StepContent>
            </StepText>
          </StepWrapper>
        </Step>
      </Steps>
    </SectionContent>
    <ModernSolutionCta>
      <CtaTitle>Ready to get started?</CtaTitle>
      <CtaButton onClick={goToForm}>{`Schedule a Tour with ${agentFirstName}`}</CtaButton>
    </ModernSolutionCta>
  </SectionWrapper>
)
