import styled from 'styled-components'
import { COLORS, darkBlue, white } from '../../constants'
import { Flex } from '../common/Flex'
import { DynamicPrice } from '../common/Price'
import { PropertyPriceDataTypes } from './types'
import { formatCurrency } from '../../utils/formatCurrency'
import { formatNumber } from '../../utils/formatNumber'
import { Heading5 } from '../common/Text'

export const NumberText = styled.span`
  color: ${COLORS.electricBlue};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0;

  @media (max-width: 1300px) {
    font-size: 14px;
  }
`

const NumberTextGray = styled.span`
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-decoration: line-through;
  margin: 0;

  @media (max-width: 1300px) {
    font-size: 12px;
  }
`

export const PropertyPriceFeatured: React.FC<PropertyPriceDataTypes> = ({
  closePrice,
  variation,
  invertColors = false,
  flexStyle = {},
  interestRate,
  monthlyPayment,
  marketMortgageRate,
  marketMonthlyMortgagePayment,
  featured
}) => {
  const color = invertColors ? white : darkBlue

  return (
    <Flex
      align="center"
      justify="space-between"
      direction="row"
      {...flexStyle}
      className="property-price-featured"
    >
      {window.innerWidth < 1300 ? (
        <Heading5>{closePrice}</Heading5>
      ) : (
        <DynamicPrice color={color} price={closePrice} variation={variation} />
      )}

      {featured ? (
        <Flex justify="space-between" direction="column" align="end">
          <NumberText>
            {`${formatNumber(interestRate || 0, false, 2)}% at ${formatCurrency(
              monthlyPayment || 0
            )} / mo`}
          </NumberText>
          <NumberTextGray className="gray-market-rate">
            {`${marketMortgageRate}% at ${formatCurrency(marketMonthlyMortgagePayment || 0)} / mo`}
          </NumberTextGray>
        </Flex>
      ) : null}
    </Flex>
  )
}
