import styled from 'styled-components';
import { SearchIconButton } from '../SearchIconButton';
import { ClearIcon } from '../../icons/ClearIcon';
import { BREAKPOINT_S } from '../../../constants';
import { TransparentButton } from '../../common/Button';
import { IInputDecoratorProps } from './index'

const DecoratorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  height: 100%;

  @media (min-width: ${BREAKPOINT_S}px){
    margin-right: 8px;
  }
`

export const InputDecorator = ({
  mobile,
  showClearButton,
  onClearClick,
  onSearchClick
}: IInputDecoratorProps) => {
  return (
    <DecoratorWrapper>
      {showClearButton && (
        <TransparentButton
          id="clear-icon"
          style={{'padding': `${mobile? '0' : '0 16px 0 0'}`}}
          onClick={ev=>{ev.preventDefault(); onClearClick()}}
          onKeyPress={(ev) =>
            ev.key === "Enter" && onClearClick ? onClearClick() : null
          }
        >
          <ClearIcon />
        </TransparentButton>
      )}

      {!mobile && (
        <SearchIconButton 
          onSearchClick={ev=>{ev.preventDefault(); onSearchClick(ev)}}
        />
      )}
    </DecoratorWrapper>
  );
}
