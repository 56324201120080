import styled from 'styled-components'
import { Toast } from '../common/Toast'
import { Link } from '../Footer/FooterLink'
import { IPropertySearchToast } from './'

const StyledToastLink = styled(Link)`
  text-decoration: underline;
  display: block;
  margin-top: 6px;
  margin-bottom: 0;
`

const SavedSearchLimitMessage: React.FC = () => (
  <>
    <div>Saved search limit reached. Please delete one of your saved searches and then try to save your search again.</div>
    <StyledToastLink as='a' href='/homes/saved-property-searches'>Edit saved searches</StyledToastLink>
  </>
)
const SaveSearchCreatedMessage: React.FC = () => (
  <>
    <div>Search saved.</div>
    <StyledToastLink as='a' href='/homes/saved-property-searches'>Edit saved searches</StyledToastLink>
  </>
)
const RequestErrorMessage: React.FC = () => <span>Sorry, something went wrong. Can you try that again?</span>
const HomeFavoritedMessage: React.FC = () => <span>Saved to favorite homes</span>
const ResentLoginMessage: React.FC = () => <span>Link re-sent</span>
const LeadSubmittedMessage: React.FC = () => <span>Message sent</span>

const toastState = {
  'requestError': 'error',
  'savedSearchCreated': 'default',
  'savedSearchLimit': 'error',
  'resentLoginLink': 'default',
  'homeFavorited': 'default',
  'leadSubmitted': 'default'
}

const toastMessages = {
  'requestError': RequestErrorMessage,
  'savedSearchCreated': SaveSearchCreatedMessage,
  'savedSearchLimit': SavedSearchLimitMessage,
  'resentLoginLink': ResentLoginMessage,
  'homeFavorited': HomeFavoritedMessage,
  'leadSubmitted': LeadSubmittedMessage
}

export const PropertySearchToast: React.FC<IPropertySearchToast> = ({ show, variation}) => {
  const ToastMessage = (toastMessages as any)[variation]

  return (
    <Toast
      show={show}
      state={(toastState as any)[variation]}
    >
      <ToastMessage />
    </Toast>
  )
}
