import styled from 'styled-components'

export const Flex = styled('div')<{
  width?: string
  direction?: 'column' | 'row' | 'column-reverse' | 'row-reverse'
  align?: 'start' | 'end' | 'center' | 'stretch' | 'baseline'
  justify?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  mt?: string
  mr?: string
  mb?: string
  ml?: string
  pt?: string
  pr?: string
  pb?: string
  pl?: string
  borderRadius?: string
  backgroundColor?: string
  shadow?: boolean
  wrap?: string
  fontSize?: string
  overflow?: string
}>`
  width: ${props => (props.width ? props.width : '')};
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  align-content: ${props => (props.align ? props.align : 'center')};
  align-items: ${props => (props.align ? props.align : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'start')};
  margin-top: ${props => (props.mt ? props.mt : 0)};
  margin-right: ${props => (props.mr ? props.mr : 0)};
  margin-bottom: ${props => (props.mb ? props.mb : 0)};
  margin-left: ${props => (props.ml ? props.ml : 0)};
  padding-top: ${props => (props.pt ? props.pt : 0)};
  padding-right: ${props => (props.pr ? props.pr : 0)};
  padding-bottom: ${props => (props.pb ? props.pb : 0)};
  padding-left: ${props => (props.pl ? props.pl : 0)};
  border-radius: ${props => (props.borderRadius ? props.borderRadius + 'px' : '')};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : '')};
  box-shadow: ${props => (props.shadow ? '0px 2px 8px 0px #0000004D' : '')};
  flex-wrap: ${props => (props.wrap ? props.wrap : 'nowrap')};
  font-size: ${props => props.fontSize ? props.fontSize : ''};
  overflow: ${props => props.overflow ? props.overflow : 'default'};
`
