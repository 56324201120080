import styled from 'styled-components'
import { StyledCircularIconTrigger } from '../../Filters/FilterPopover/Trigger'
import { HeartIcon } from '../../icons/HeartIcon'
import { IFavoriteProperty } from './types'
import { ACTION_TAP, trackEvent } from '../../../utils/tracking'

export const StyledBorderlessCircularIconTrigger = styled(StyledCircularIconTrigger)<{
  onClick: (e: any) => void
}>`
  &:focus {
    border: none;
  }
`

export const FavoriteProperty: React.FC<IFavoriteProperty> = ({
  favoriteProperty,
  isSelected = false
}) => {
  const toggleFavorite = () => {
    trackEvent(ACTION_TAP, `Favorite - ${isSelected ? 'Unselect' : 'Select'}`)
    favoriteProperty(!isSelected)
  }

  return (
    <StyledBorderlessCircularIconTrigger onClick={toggleFavorite} className='favorite-trigger'>
      <HeartIcon solid={isSelected} />
    </StyledBorderlessCircularIconTrigger>
  )
}
