import styled from 'styled-components'
import { Heading5, TextSmall } from '../../common/Text'
import { RangeButtons } from '../../common/RangeButtons'
import { IBedsBathsSelectProps } from './'
import { Flex } from '../../common/Flex'
import { BREAKPOINT_S, COLORS, BEDS_OPTIONS, BATHS_OPTIONS } from '../../../constants'

const StyledBedsBathsSelect = styled('div')`
  width: 100%;
`

const BathsSection = styled(Flex)`
  @media (max-width: ${BREAKPOINT_S - 1}px) {
    margin-bottom: 32px;
  }
`

export const BedsBathsSelect: React.FC<IBedsBathsSelectProps> = ({
  bedsMin,
  selectBedsMin,
  bedsMax,
  selectBedsMax,
  bathsMin,
  selectBathsMin,
  applyFilters
}) => {

  return (
    <StyledBedsBathsSelect>
      <Heading5 as='label'>Beds &amp; baths</Heading5>
      <Flex direction='column' align='start' pt='16px' pb='24px'>
        <TextSmall as='label' color={COLORS.darkBlue}>
          Bedrooms
        </TextSmall>
        <RangeButtons
          rangeOptions={BEDS_OPTIONS}
          min={bedsMin}
          setMin={selectBedsMin}
          max={bedsMax}
          setMax={selectBedsMax}
          applyFilters={applyFilters}
        />
        <TextSmall as='label' color={COLORS.coolGray3}>
          Tap 2 numbers to select a range.
        </TextSmall>
      </Flex>
      <BathsSection direction='column' align='start'>
        <TextSmall as='label' color={COLORS.darkBlue}>
          Bathrooms
        </TextSmall>
        <RangeButtons
          rangeOptions={BATHS_OPTIONS}
          min={bathsMin}
          setMin={selectBathsMin}
          applyFilters={applyFilters}
          singleSelectMode={true}
        />
      </BathsSection>
    </StyledBedsBathsSelect>
  )
}
