import { toTitleCase } from '../../toTitleCase'
import { stateNameOf } from '../../stateUtils'

export const parseCitySlug = (slug: string) => {
    let splitSlug = slug.split("-");
    const indexBeforeLast = splitSlug.length - 1;
    let stateCode = splitSlug[indexBeforeLast];
    let stateName = stateCode ? stateNameOf(stateCode) : "";
    let city = splitSlug.slice(0, indexBeforeLast).join(" ");
    return {
        city: toTitleCase(city),
        state: stateName
    };
};
