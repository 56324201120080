import { captureException } from '@sentry/react'
import { RECORD_USER_EVENT_PATH } from '../../constants'
import { requestData } from '../requestData'

export const trackUserEvent = ({
  event_action,
  result = {}
}) => {
  requestData({
    url: RECORD_USER_EVENT_PATH,
    method: 'post',
    body: {
      event_action,
      result
    },
    includeBearerToken: false,
    ignoreJsonFormat: true
  }).then(({error}) => {
    if (error) {
      console.warn('error', error)
      captureException(error)
    }
  })
}
