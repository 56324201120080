import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { PropertyImg, Slideshow } from './'
import { CaretLeft } from '../icons/CaretLeft'
import { CaretRight } from '../icons/CaretRight'
import { BREAKPOINT_S, COLORS, COMING_SOON_PLACEHOLDER_URL } from '../../constants'
import { IImageCarousel } from './types'

const Thumbnail = styled(PropertyImg)`
  width: 100%;
  height: 204px;

  @media (max-width: ${BREAKPOINT_S - 1}px) {
    &:after {
      background-color: unset;
      opacity: unset;
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%);
    }
  }
`

const StyledCarousel = styled('div')<{
  isHero: boolean
  goldRushExp: boolean
}>`
  width: 100%;
  height: ${props => (props.isHero ? '300px' : '204px')};

  @media (min-width: ${BREAKPOINT_S}px) {
    .slide-counter-wrapper {
      bottom: ${({ goldRushExp }) => (goldRushExp ? '54px' : '8px')};
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover .slide-counter-wrapper {
      opacity: 1;
    }
  }

  &.preview {
    .back-arrow-wrapper {
      display: none;
    }
  }
`

const MobileCaret = styled.div<{ left?: boolean }>`
  position: absolute;
  left: ${props => (props.left ? '16px' : 'calc(100% - 30px)')};
  top: 0;
  width: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 75%;
  pointer-events: none;

  @media (min-width: ${BREAKPOINT_S}px) {
    display: none;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`

const CounterWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${BREAKPOINT_S}px) {
    justify-content: center;
  }
`

const SlideCounter = styled.div`
  padding: 1px 9px;
  margin-right: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  color: ${COLORS.white};
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;

  @media (min-width: ${BREAKPOINT_S}px) {
    margin-right: 0;
  }
`

const ImageCarouselComponent: React.FC<IImageCarousel> = ({
  images,
  isPreview,
  listingImages,
  isHero = false,
  trackStockImage,
  className = '',
  trackSlideChange,
  jsxKey,
  getListingImages,
  listingContractDate,
  onClickSlideshow,
  removeBorderRadius = false,
  featured = false
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  // Preview paths are guesses, not the legit list from an MLS. While previewing, keep in local state to remove failures
  const [previewUrls, setPreviewUrls] = useState<string[]>(images)

  useEffect(() => {
    setPreviewUrls(images)
  }, [images])

  const onSlideChange = (value: number) => {
    const compareLength = isPreview ? previewUrls?.length : listingImages.length
    if (value < 0 || value >= compareLength) {
      return
    }

    if (isPreview) {
      getListingImages()
    }

    setCurrentIndex(value)
    if (trackSlideChange) {
      trackSlideChange()
    }
  }

  const handleClick = (e: any) => {
    if (e.target.dataset.interaction === 'no-link') {
      e.preventDefault()
    }
  }

  const onImageFail = (_url: string) => {
    const filteredPreviews: string[] = previewUrls?.filter(
      (el: string, i: number) => i !== currentIndex
    )
    setPreviewUrls(filteredPreviews.length ? filteredPreviews : [COMING_SOON_PLACEHOLDER_URL])
  }

  const slides = isPreview
    ? (previewUrls || []).map((image: string, index: number) => {
        return (
          <PropertyImg
            key={`${jsxKey}${image}`}
            url={image}
            trackStockImage={() => {}}
            onImageFail={onImageFail}
            imageIndex={index}
            slideIndex={currentIndex}
          />
        )
      })
    : (listingImages || []).map((image: string, index: number) => {
        return (
          <PropertyImg
            key={`${jsxKey}${image}`}
            url={image}
            trackStockImage={trackStockImage}
            imageIndex={index}
            slideIndex={currentIndex}
          />
        )
      })

  let mobileCarets = []
  const slidesBehind = currentIndex > 0
  const slidesAhead = currentIndex < slides.length - 1
  if (slidesBehind && !isPreview) {
    mobileCarets.push(
      <MobileCaret left={true} key={`${jsxKey}-left`}>
        <CaretLeft fill={COLORS.white} />
      </MobileCaret>
    )
  }
  if (slidesAhead) {
    mobileCarets.push(
      <MobileCaret key={`${jsxKey}-right`}>
        <CaretRight fill={COLORS.white} />
      </MobileCaret>
    )
  }
  const wrapperClass = `${className}${isPreview ? ' preview' : ''}`

  return images?.length ? (
    <StyledCarousel
      onClick={handleClick}
      className={wrapperClass}
      key={jsxKey}
      isHero={isHero}
      goldRushExp={featured}
    >
      <Slideshow
        slideIndex={currentIndex}
        setSlideIndex={onSlideChange}
        onClickSlideshow={onClickSlideshow}
        removeBorderRadius={removeBorderRadius}
      >
        {slides}
      </Slideshow>
      {mobileCarets}
      {!isPreview && (
        <CounterWrapper className="slide-counter-wrapper">
          <SlideCounter>{`${currentIndex + 1 || 1} / ${slides.length}`}</SlideCounter>
        </CounterWrapper>
      )}
    </StyledCarousel>
  ) : (
    <Thumbnail
      url=""
      trackStockImage={trackStockImage}
      className={className}
      key={jsxKey}
      listingContractDate={listingContractDate}
    />
  )
}

export const ImageCarousel = memo(ImageCarouselComponent)
