import styled from 'styled-components'
import { COLORS, BREAKPOINT_M, BREAKPOINT_L, MAX_PAGE_WIDTH } from '../../constants'
import { Accordion } from '../common/Accordion'

const Wrapper = styled.div`
  padding: 80px 16px 0;
  box-sizing: border-box;

  @media (min-width: ${BREAKPOINT_M}px) {
    padding: 80px 60px;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    padding: 80px;
    max-width: calc(${MAX_PAGE_WIDTH}px + 160px);
    margin-left: auto;
    margin-right: auto;
  }
`

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -1px;
`

const Desktop = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINT_L}px) {
    display: block;
  }
`

const Mobile = styled.div`
  @media (min-width: ${BREAKPOINT_L}px) {
    display: none;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 200px;
  border-bottom: 1px solid ${COLORS.coolGray50};
  padding-bottom: 16px;
`

const Question = styled.p`
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.8px;
  width: 360px;
  margin-top: 24px;
  flex-shrink: 0;
`

const Answer = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 24px;
  width: 100%;
`

export const UltraFaq = ({ rate }) => {
  if (!rate) {
    return null
  }

  const faqData = [
    {
      title: `Is this ${rate}% for real?`,
      body: 'Yes, some outstanding mortgages are legally assumable by the buyer. The seller can transfer their existing mortgage to the buyer with their ultra low rate.'
    },
    {
      title: "Why haven't I heard about this before?",
      body: 'FHA and VA loans have been assumable for decades. With rates declining for the past several years, assuming of a seller’s loan almost never happened. Given the current interest rate environment, there are thousands of homes available in the market at ultra low rates.'
    },
    {
      title: 'Can anybody get this rate?',
      body: 'Any prospective buyer must qualify for the sellers existing mortgage just like they would qualify for their own.'
    },
    {
      title: 'How can I get in touch with HomeLight to learn more?',
      body: "Schedule a tour to get in touch directly about this listing and we'll answer any questions you have when we connect."
    }
  ]

  const renderFaqSection = (title, body) => {
    return (
      <Section>
        <Question>{title}</Question>
        <Answer>{body}</Answer>
      </Section>
    )
  }

  return (
    <Wrapper>
      <Title>FAQs</Title>
      <Desktop>{faqData.map(({ title, body }) => renderFaqSection(title, body))}</Desktop>
      <Mobile>
        {faqData.map(faq => (
          <Accordion key={faq.title} title={faq.title} body={faq.body} />
        ))}
      </Mobile>
    </Wrapper>
  )
}
