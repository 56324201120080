export const HomeLightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <g id="Frame">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16.5C32 25.3366 24.8366 32.5 16 32.5C7.16343 32.5 0 25.3366 0 16.5C0 7.66343 7.16343 0.5 16 0.5C24.8366 0.5 32 7.66343 32 16.5Z"
        fill="white"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.38281 23.0053C3.87607 28.5987 9.48087 32.4999 16 32.4999C22.5163 32.4999 28.1188 28.6021 30.6139 23.0128L16.0091 12.3806L1.38281 23.0053ZM16.6907 23.0158H19.7801V19.4622H16.6907V23.0158ZM16.6907 27.9427H19.7801V24.3891H16.6907V27.9427ZM12.2283 27.9427H15.3179V24.3891H12.2283V27.9427ZM12.2283 23.0158H15.3179V19.4622H12.2283V23.0158Z"
        fill="#273653"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6423 19.8662C31.875 18.7804 32 17.655 32 16.4998C32 7.66319 24.8368 0.499756 16 0.499756C7.16343 0.499756 0 7.66319 0 16.4998C0 17.652 0.124571 18.775 0.356114 19.8582L16.0101 8.48513L31.6423 19.8662Z"
        fill="#46B6FF"
      />
    </g>
  </svg>
)
