import { createContext, useContext, useEffect, useState } from 'react'
import { requestData } from '../../utils/requestData'
import { ISavedPropertySearch, ISavedPropertySearchesContext, ISavedPropertySearchesProvider } from './types'
import { ISort } from '../../components/Sort'
import { SAVED_SEARCHES_URL } from '../../constants'
import { IUserContext, UserContext } from '../UserContext'

export const SavedPropertySearchesContext = createContext<ISavedPropertySearchesContext | {}>({})

export const SavedPropertySearchesProvider: React.FC<ISavedPropertySearchesProvider> = ({children}) => {
    const {
        user: { currentUser, visitorId }
    } = useContext(UserContext) as IUserContext
    const [savedSearchesLoading, setSavedSearchesLoading] = useState<boolean>(true)
    const [savedSearches, setSavedSearches] = useState<ISavedPropertySearch[]>([])
    const getSavedPropertySearches = () => {
        const defaultSort: ISort = {
            field: 'created_at',
            operator: 'desc'
        }
        requestData({
            url: SAVED_SEARCHES_URL,
            method: 'get',
            filters: [],
            sorts: [defaultSort],
            headers: {'Cache-Control': 'no-cache'},
            includeBearerToken: false
        }).then(({data}) => {
            const searches = data.map((savedSearchResult: ISavedPropertySearch) => savedSearchResult)
            if (!savedSearches.length && !searches.length) {
                setSavedSearchesLoading(false)
                return
            }
            setSavedSearches(searches)
            setSavedSearchesLoading(false)
        }).catch(err => {
            setSavedSearchesLoading(false)
        })
    }

    useEffect(() => {
        if (!currentUser && !visitorId) {
            return
        }

        getSavedPropertySearches()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, visitorId])

    const contextVal: ISavedPropertySearchesContext = {
        savedSearches,
        savedSearchesLoading,
        refreshSavedSearches: getSavedPropertySearches
    }

    return (
        <SavedPropertySearchesContext.Provider value={contextVal}>
            {children}
        </SavedPropertySearchesContext.Provider>
    )
}

