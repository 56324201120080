import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FilterPopover } from '../FilterPopover'
import { PriceRangeSelect } from '../PriceRangeSelect'
import { IPriceProps } from './index'
import { formatPrice } from "../../../utils/formatPrice";
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import { BREAKPOINT_M } from '../../../constants'

const StyledPriceContainer = styled('div')`
  width: calc(100vw - 80px);

  @media (min-width: ${BREAKPOINT_M}px) {
    width: 480px;
  }
`

export const Price: React.FC<IPriceProps>= ({
  priceMin,
  selectPriceMin,
  priceMinOptions,
  priceMax,
  selectPriceMax,
  priceMaxOptions,
  clearPriceFilters,
  applyPriceFilters,
  mobile
}) => {
  const INITIAL_TRIGGER_LABEL = 'Price'
  const [hasFilters, setHasFilters] = useState(false)
  const [triggerLabel, setTriggerLabel] = useState<string>(INITIAL_TRIGGER_LABEL)
  const { desktop } = useBreakpoint()
  
  const getTriggerLabel = () => {
    const minValue = priceMin.value
    const maxValue = priceMax.value
    let separator = ''
    const low = !!minValue ? formatPrice(minValue) : ''
    const high = !!maxValue ? formatPrice(maxValue) : ''

    if (!(!!minValue && !!maxValue)) {
      separator = 'Price'
    }
    if (!!minValue) {
      separator = '+'
    }
    if (!!maxValue) {
      separator = 'Up to '
    }

    if (!!minValue && !!maxValue) {
      separator = '-'
    }

    return `${low}${separator}${high}`
  }

  useEffect(() => {
    const hasFilters = priceMin.value !== 0 || priceMax.value !== 0
    setHasFilters(hasFilters)

    const label = getTriggerLabel()
    setTriggerLabel(label)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceMin, priceMax])

  return (
    <FilterPopover
      triggerLabel={triggerLabel}
      testLabel="price-popover"
      hasSelectedFilters={hasFilters}
      clearFilters={clearPriceFilters}
      applyFilters={applyPriceFilters}
      popoverBodyWidth={mobile ? 'calc(100vw - 32px)' : ''}
      popoverBodyLeft={desktop ? '-175px' : '16px'}
      popoverBodyRight={'16px'}
      bodyMargin={'auto'}
      overflowEllipsis={true}
      popoverContainerMinWidth={'0'}
    >
      <StyledPriceContainer className="styled-price-container">
        <PriceRangeSelect
          priceMin={priceMin}
          priceMinOptions={priceMinOptions}
          priceMax={priceMax}
          priceMaxOptions={priceMaxOptions}
          selectPriceMin={selectPriceMin}
          selectPriceMax={selectPriceMax}
        />
      </StyledPriceContainer>
    </FilterPopover>
  )
}
