import { useState } from 'react'
import styled from 'styled-components'
import { StyledModalBackground, StyledModalContainer } from '../common/Modal'
import { ElectricBlueButton } from '../common/Button'
import { XIcon } from'../icons/XIcon'
import { COLORS } from '../../constants/colors'
import { WorkingWithAgentIcon } from '../icons/WorkingWithAgentIcon'
import { Flex } from '../common/Flex'
import { requestData } from '../../utils/requestData'
import { QUEUE_LEAD, BREAKPOINT_S } from '../../constants'
import { formatPhone } from '../../utils/formatPhone'

const ModalContainer = styled(StyledModalContainer)`
  position: absolute;
  bottom: 0;
  top: 10%;

  @media (min-width: ${BREAKPOINT_S}px) {
    position: relative;
    top: 0;
  }
`

const StyledContainer = styled(Flex)`
  width: 100%;
  padding: 0 32px 36px 32px;
  box-sizing: border-box;

  @media (min-width: ${BREAKPOINT_S}px) {
    width: 512px;
  }
`

const StyledHeader = styled.h2`
  color: ${COLORS.darkBlue};
  font-size: 32px;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 8px;
`

const StyledText = styled.p`
  color: ${COLORS.darkBlue}};
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 0;
`

const StyledWorkingWithAgentIcon = styled(WorkingWithAgentIcon)`
  margin: auto;
`

const StyledButton = styled(ElectricBlueButton.Large)`
  margin-top: 40px;
  width: 100%;
`

const StyledSubText = styled.p`
  color: ${COLORS.coolGray1};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`

const StyledBanner = styled.p`
  align-self: flex-end;
  margin-top: 24px;
`

const StyledCallingYouIcon = styled.img`
  width: 150px;
  height: 188px;
`

const FinishedButton = styled(ElectricBlueButton.Large)`
  background-color: white;
  color: ${COLORS.electricBlue};
  margin-top: 40px;
  position: absolute;
  bottom: 36px;
  width: calc(100% - 48px);

  @media (min-width: ${BREAKPOINT_S}px) {
    position: relative;
    margin: 0;
    margin-top: 40px;
    width: 100%;
  }
`

export const CallMeMaybeModal = ({
  callMeMaybeModal,
  setCallMeMaybeModal,
  callMeMaybeState,
  setCallMeMaybeState,
  cmmLeadId
}) => {
  const [salesNumber, setSalesNumber] = useState('18889981909')
  const handleClickDismiss = () => {
    setCallMeMaybeModal(false)
  }

  const handleButton = () => {
    queueLead()
    setCallMeMaybeState('confirm')
  }

  const queueLead = () => {
    const body = {
      lead_id: cmmLeadId
    }
  requestData({
      url: QUEUE_LEAD,
      method: 'post',
      body: body,
      ignoreJsonFormat: true,
      includeBearerToken: true,
    }).then(({ data, error }) => {
      if (!error) {
        setSalesNumber(data?.data?.sales_phone_number)
      }
    })
  }

  const renderInitialModal = () => {
    return (
      <StyledContainer direction="column">
        <StyledBanner onClick={handleClickDismiss}>
          <XIcon onClick={handleClickDismiss} />
        </StyledBanner>
        <StyledHeader>Your top agents are almost ready!</StyledHeader>
        <StyledText>
          We have a few more questions before introducing you to top agents in your area
        </StyledText>
        <StyledWorkingWithAgentIcon/>
        <StyledButton onClick={handleButton}>Call Me Now</StyledButton>
        <StyledSubText>
          Just click the button and your phone will ring. It's that simple!
        </StyledSubText>
      </StyledContainer>
    )
  }

  const renderConfirmModal = () => {
    return (
      <StyledContainer direction="column">
        <StyledBanner onClick={handleClickDismiss}>
          <XIcon onClick={handleClickDismiss} />
        </StyledBanner>
        <StyledCallingYouIcon
          src="https://hl-production-assets.s3.amazonaws.com/house-hunter/calling.png"
        />
        <StyledHeader>Calling you soon!</StyledHeader>
        <StyledText>
          Our next available representative will call you. You can expect a call within 10 minutes.
        </StyledText>
        <StyledText>
          We'll call from <b>{formatPhone(salesNumber)}</b>
        </StyledText>
        <FinishedButton onClick={handleClickDismiss}>Finished the call</FinishedButton>
      </StyledContainer>
    )
  }

  return (
    <>
      <StyledModalBackground>
        <ModalContainer>
          {callMeMaybeState === 'initial' ? renderInitialModal() : renderConfirmModal()}
        </ModalContainer>
      </StyledModalBackground>
    </>
  )
}
