import { ISort } from '../components/Sort'

export const SORT_OPTIONS: ISort[] = [
  {
    label: 'Ultra-Low Rates',
    field: 'featured',
    operator: 'asc'
  },
  {
    label: 'Newest',
    field: 'listing_hl_created_at',
    operator: 'desc'
  },
  {
    label: 'Oldest',
    field: 'listing_hl_created_at',
    operator: 'asc'
  },
  {
    label: 'Price: Low to High',
    field: 'listing_list_price',
    operator: 'asc'
  },
  {
    label: 'Price: High to Low',
    field: 'listing_list_price',
    operator: 'desc'
  },
  {
    label: 'Square Feet',
    field: 'listing_living_area',
    operator: 'desc'
  },
  {
    label: 'Lot Size',
    field: 'lot_size_acres',
    operator: 'desc'
  }
]

// "Date Saved" sorts cannot apply to property query (since sorting is on a different table)
// These will sort on front end from response - set blank fields and omit from property call
export const SAVED_HOME_SORT_OPTIONS: ISort[] = [
  {
    label: 'Date Saved: Most Recent',
    field: '',
    operator: 'desc'
  },
  {
    label: 'Date Saved: Least Recent',
    field: '',
    operator: 'asc'
  },
  {
    label: 'Price: Low to High',
    field: 'listing_list_price',
    operator: 'asc'
  },
  {
    label: 'Price: High to Low',
    field: 'listing_list_price',
    operator: 'desc'
  },
  {
    label: 'Newest',
    field: 'listing_hl_created_at',
    operator: 'desc'
  },
  {
    label: 'Oldest',
    field: 'listing_hl_created_at',
    operator: 'asc'
  }
]

export const DEFAULT_PROPERTY_SORT = SORT_OPTIONS[0]
export const NEWEST_PROPERTY_SORT = SORT_OPTIONS[1]
export const DEFAULT_SAVED_HOME_SORT = SAVED_HOME_SORT_OPTIONS[0]
