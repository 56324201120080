import { useState } from 'react'
import styled from 'styled-components'
import { Header, Body } from '../common/Modal'
import { CancelOrConfirm } from '../common/CancelOrConfirm'
import { Heading3, Text } from '../common/Text'
import { AlertFrequencyDropdown } from './AlertFrequencyDropdown'
import { requestData } from '../../utils/requestData'
import { cadmiumRed, SAVED_SEARCHES_URL, SEARCH_ALERT_FREQUENCY_OPTIONS } from '../../constants'
import { ACTION_STATE, trackEvent } from '../../utils/tracking'
import { IDropdownOption } from '../common/Dropdown'
import { ISaveSearchEditModal } from './types'
import { darkBlue } from '../../constants'

const StyledBody = styled(Body)`
  flex: 1;
  padding: 24px 16px;
`

const StyledHeading = styled(Heading3)`
  margin-bottom: 24px;
`

const StyledFooter = styled.div`
  .cancel-confirm {
    padding: 8px 16px;

    button {
    font-size: 16px;

      &:first-of-type {
        color: ${cadmiumRed};
      }
    }
  }
`

const StyledText = styled(Text)`
  padding-bottom: 24px;
`

const getSearchAlertFrequencyOption = (frequency: string): IDropdownOption => {
  const frequencyOption: IDropdownOption = SEARCH_ALERT_FREQUENCY_OPTIONS.find(option => option.label.toLowerCase() === frequency?.toLowerCase())
  const defaultFrequencyOption: IDropdownOption = SEARCH_ALERT_FREQUENCY_OPTIONS[0]
  return frequencyOption || defaultFrequencyOption
}

export const SaveSearchEditModal: React.FC<ISaveSearchEditModal> = ({
  searchToUpdate,
  closeModal,
  onUpdate,
  confirmDelete,
  setError,
  refreshSavedSearches
}) => {
  const initialSearchAlertFrequency = getSearchAlertFrequencyOption(searchToUpdate.frequency)
  const [selectedFrequency, setSelectedFrequency] = useState<IDropdownOption>(initialSearchAlertFrequency)

  const setFrequencyOption = (option: IDropdownOption, _id: undefined) => {
    setSelectedFrequency(option)
  }

  const updateSavedSearch = () => {
    requestData({
      url: `${SAVED_SEARCHES_URL}/${searchToUpdate.id}`,
      method: 'put',
      body: {
        search_url: searchToUpdate.searchUrlQuery,
        frequency: selectedFrequency.field
      },
      ignoreJsonFormat: true,
      includeBearerToken: false
    }).then(({ error }) => {
      if (error) {
        setError(error)
      } else {
        refreshSavedSearches()
        onUpdate()
        trackEvent(ACTION_STATE, 'edit_saved_search')
        closeModal()
      }
    })
  }
  const bodyText =
    searchToUpdate.areaName && searchToUpdate.stateCode
      ? `Edit your saved search in ${searchToUpdate.areaName}, ${searchToUpdate.stateCode}`
      : 'Edit your saved search.'

  return (
    <>
      <Header closeModal={closeModal} />
      <StyledBody>
        <StyledHeading as="h3">Edit search</StyledHeading>
        <StyledText as="p" color={darkBlue}>
          {bodyText }
        </StyledText>
        <AlertFrequencyDropdown
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={setFrequencyOption}
        />
      </StyledBody>
      <StyledFooter>
        <CancelOrConfirm
          confirmLabel="Save"
          confirm={updateSavedSearch}
          cancelLabel="Delete"
          cancel={() => confirmDelete(searchToUpdate)}
          testLabel="edit-search"
        />
      </StyledFooter>
    </>
  )
}
