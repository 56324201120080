import { ReactNode } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../../constants'

const StyledTooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  width: 160px;
  background-color: ${COLORS.darkBlue};
  color: white;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 12px 16px;
  line-height: 120%;
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 50%;
  margin-top: 5px;
  margin-left: -40px;
  font-weight: normal;
  transition: opacity 0.3s;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 16px 24px);
  overflow-wrap: break-word;
  white-space: normal;
  font-size: 13px;
`

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &: hover ${StyledTooltip} {
    visibility: visible;
    opacity: 1;
  }
`

export const Tooltip = ({ text, children }: { text: string, children?: ReactNode | string }) => {
  return (
    <TooltipContainer>
      {children}
      <StyledTooltip>{text}</StyledTooltip>
    </TooltipContainer>
  )
}
