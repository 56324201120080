import styled from 'styled-components'
import { CheckCircleIcon } from '../icons/CheckCircleIcon'
import { ScheduleTourButton } from './sharedStyles'
import {
  BREAKPOINT_M,
  BREAKPOINT_L,
  MAX_PAGE_WIDTH,
  white,
} from '../../constants'

const HeroWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  max-height: 720px;
  background-image: url("https://hl-production-assets.s3.amazonaws.com/house-hunter/hero-kitchen.jpg");
  background-repeat: no-repeat;
  background-size: cover;
`

const GradientOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.6;
  background: linear-gradient(96deg, rgba(0, 0, 0, 0.90) 47.04%, rgba(39, 54, 83, 0.36) 77.5%);
`

const HeroContent = styled.div`
  padding: 76px 20px 40px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  @media (min-width: ${BREAKPOINT_M}px) {
    padding: 140px 80px 40px;
    max-width: calc(${MAX_PAGE_WIDTH}px + 160px);
    margin-left: auto;
    margin-right: auto;
  }
`

const HeroTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  color: ${white};
  letter-spacing: -1px;
  margin-bottom: 16px;
  margin-top: 0;
  max-width: 1000px;

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 48px;
    margin-bottom: 40px;
  }
`

const ValueProps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 24px;

  @media (min-width: ${BREAKPOINT_M}px) {
    gap: 16px;
  }
`

const ValueItem = styled.div`
  display: flex;
  gap: 8px;
  
  svg {
    width: 28px;
    height: 28px;
    flex: 0 0 20px;
    
    @media (min-width: ${BREAKPOINT_M}px) {
      flex: 0 0 28px;
    }
  }
`

const ValueItemText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${white};

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 20px;
  }
`

const AgentInfoSection = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 40px;

  @media (min-width: ${BREAKPOINT_M}px) {
    margin-top: 48px;
  }
`

const AgentImage = styled.div`
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center top;

    @media (min-width: ${BREAKPOINT_M}px) {
      width: 100px;
      height: 100px;
    }
  }
`

const AgentDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const AgentName = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${white};

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 20px;
  }
`

const AgentBrokerage = styled.div`
  color: ${white};
  font-size: 14px;

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 16px;
  }
`

const AgentPhone = styled.div`
  color: ${white};
  font-size: 14px;

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 16px;
  }
`

export const UltraAgentHero = ({
  propertyListing,
  hlRate,
  rateEstimated,
  agentFirstName,
  goToForm,
  savingsSizeCopy,
  address,
  agentName,
  agentImage,
  agentPhone,
  officeName
}) => {
  const title = `Get ${rateEstimated ? 'as low as ' : ''}a ${hlRate}% mortgage rate for ${address}`

  return (
    <HeroWrapper>
      <GradientOverlay />
      <HeroContent>
        <HeroTitle>{title}</HeroTitle>
        <ValueProps>
          <ValueItem>
            <CheckCircleIcon />
            <ValueItemText>Assume the mortgage at the seller's current low rate.</ValueItemText>
          </ValueItem>
          <ValueItem>
            <CheckCircleIcon />
            <ValueItemText>Secure a mortgage that's found nowhere else.</ValueItemText>
          </ValueItem>
          <ValueItem>
            <CheckCircleIcon />
            <ValueItemText>
              {`Save ${savingsSizeCopy} of thousands of dollars over the lifetime of the loan.`}
            </ValueItemText>
          </ValueItem>
        </ValueProps>
        <ScheduleTourButton onClick={goToForm}>
          {`Schedule a Tour with ${agentFirstName}`}
        </ScheduleTourButton>
        <AgentInfoSection>
          {agentImage && (
            <AgentImage>
              <img src={agentImage} />
            </AgentImage>
          )}
          <AgentDetails>
            <AgentName>{agentName}</AgentName>
            {officeName && <AgentBrokerage>{officeName}</AgentBrokerage>}
            {agentPhone && <AgentPhone>{agentPhone}</AgentPhone>}
          </AgentDetails>
        </AgentInfoSection>
      </HeroContent>
    </HeroWrapper>
  )
}
