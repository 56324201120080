import * as Sentry from '@sentry/react'

const dsn = 'https://6916538315b0432ca1ace18d7cd9809f@o156072.ingest.sentry.io/4504203865030656'

export const initializeSentry = () => {
  Sentry.init({
    dsn: dsn,
    environment: process.env.REACT_APP_NODE_ENV
  })
}
