import { useContext, useCallback } from 'react'
import styled from 'styled-components'
import { CaretRight } from '../icons/CaretRight'
import { BreadCrumbsPropTypes } from './types'
import { soldHomesUrl } from '../../utils/soldHomesUrl'
import { BREAKPOINT_S, BREAKPOINT_M, coolGray30, coolGray40, darkBlue } from '../../constants'
import { LocationContext, ILocationContext } from '../../contexts/LocationContext'
import { stateCodeOf } from '../../utils/stateUtils'

const BreadCrumbWrapper = styled.div`
  height: 38px;
  padding-bottom: 42px;
  text-align: center;

  @media (max-width: ${BREAKPOINT_M}px) {
    text-align: center;
  }
`

const StyledBreadCrumbs = styled.div`
  padding: 0 40px 20px 0;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BREAKPOINT_S}px) {
    padding-left: 16px;
  }
`

const Crumb = styled.a`
  display: inline-block;
  padding: 0 10px;
  color: ${coolGray30};
  text-decoration: none;
  font-size: 14px;
  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0;
    color: ${darkBlue};
  }
`

export const BreadCrumbs = ({
  home = 'HomeLight',
  soldHomes = 'For Sale',
  showForSale = true
}: BreadCrumbsPropTypes) => {
  const { cityFromIP, county, stateFromIP } = useContext(LocationContext) as ILocationContext
  const stateCode = stateCodeOf(stateFromIP)
  const delimiter = <CaretRight fill={coolGray40} width="6px" height="10px" />

  const crumbWrapper = useCallback(
    node => {
      if (node !== null) {
        node.scrollLeft = window.innerWidth
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cityFromIP, county, stateFromIP]
  )

  return (
    <BreadCrumbWrapper className="breadcrumb-wrapper">
      <StyledBreadCrumbs ref={crumbWrapper}>
        <Crumb href={process.env.REACT_APP_HOMELIGHT_REPO_URL}>{home}</Crumb>
        {showForSale && (
          <>
            {delimiter}
            <Crumb href={soldHomesUrl()}>{soldHomes}</Crumb>
          </>
        )}
        {stateCode && (
          <>
            {delimiter}
            <Crumb href={soldHomesUrl(stateCode)}>{stateFromIP}</Crumb>
          </>
        )}
        {stateCode && county && (
          <>
            {delimiter}
            <Crumb href={soldHomesUrl(stateCode, county)}>{county}</Crumb>
          </>
        )}
        {stateCode && cityFromIP && (
          <>
            {delimiter}
            <Crumb href={soldHomesUrl(stateCode, null, cityFromIP)}>{cityFromIP}</Crumb>
          </>
        )}
      </StyledBreadCrumbs>
    </BreadCrumbWrapper>
  )
}
