import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LoadingSpinner } from '../common/LoadingSpinner'
import { TextSmall } from '../common/Text'
import { SecondaryButton } from '../common/Button'
import { Toast } from '../common/Toast'
import { CREATE_PENDING_AGENT } from '../../constants'
import { requestData } from '../../utils/requestData'
import { formatPhone } from '../../utils/formatPhone'
import { PiiForm, PiiInput, PiiStep } from '../MortgageDeals/styles'
import {
  actionOrange,
  BREAKPOINT_S,
  BREAKPOINT_M,
  coolGray30,
  coolGray50,
  electricBlue,
  white
} from '../../constants'

const AgentFormSection = styled.div`
  margin-top: 16px;
`

const SuccessMessage = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 16px;
`

const LineSeparator = styled.div`
  width: calc(100% - 32px);
  box-sizing: border-box;
  border-top: solid 1px ${coolGray50};
  margin: 0 16px 24px;
  @media (min-width: ${BREAKPOINT_S}px) {
    width: calc(100% - 48px);
    margin: 0 24px 24px;
  }
`

const AgentInfoTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 20px;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: ${BREAKPOINT_S}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const AgentPiiForm = styled(PiiForm)`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: ${BREAKPOINT_S}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (min-width: ${BREAKPOINT_M}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const AgentPiiStep = styled(PiiStep)`
  @media (min-width: ${BREAKPOINT_M}px) {
    flex: 0 0 calc(50% - 12px);
  }
`

const InputLabel = styled.div`
  margin-bottom: 6px;
`

const Optional = styled.span`
  font-style: italic;
  color: ${coolGray30};
`

const SubmitButtonWrapper = styled.div`
  padding: 0 16px 32px;
  @media (min-width: ${BREAKPOINT_S}px) {
    padding: 0 24px 48px;
  }
  @media (min-width: ${BREAKPOINT_M}px) {
    width: 100%;
    position: relative;
    border-top: solid 1px ${coolGray50};
    padding-top: 16px;
    box-sizing: border-box;
  }
`

const SubmitAgentInfoButton = styled(SecondaryButton)`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${actionOrange};
  color: ${white};
  border: none;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;
  padding: 4px 12px;
  cursor: pointer;

  &:hover {
    background-color: #f06c00;
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    position: absolute;
    right: 24px;
    width: unset;
    padding: 8px 24px;
  }
`

export const NewLeadAgentInfoForm = ({ userId }) => {
  const [nameVal, setNameVal] = useState('')
  const [emailVal, setEmailVal] = useState('')
  const [phoneVal, setPhoneVal] = useState('')
  const [nameIsValid, setNameIsValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = () => {
    const nameValIsPresent = nameVal && nameVal.length > 0
    if (!nameValIsPresent) {
      setNameIsValid(false)
      return null
    } else {
      setNameIsValid(true)
      setLoading(true)

      requestData({
        url: CREATE_PENDING_AGENT,
        method: 'post',
        body: {
          user_id: userId,
          name: nameVal,
          email: emailVal,
          phone: phoneVal
        },
        includeBearerToken: true
      }).then(({ data, error }) => {
        setLoading(false)
        if (error) {
          setError(true)
        } else {
          setSubmitted(true)
        }
      })
    }
  }

  return submitted ? (
    <AgentFormSection>
      <SuccessMessage>Your agent information has been submitted.</SuccessMessage>
    </AgentFormSection>
  ) : (
    <AgentFormSection>
      <LineSeparator />
      <AgentInfoTitle>Enter your agent information</AgentInfoTitle>
      <AgentPiiForm>
        <AgentPiiStep>
          <TextSmall as="label">
            <InputLabel>Agent full name</InputLabel>
            <PiiInput
              variation="large"
              type="text"
              inputState={nameIsValid ? 'valid' : 'error'}
              errorMessage={nameIsValid ? null : "Please enter your agent's full name"}
              value={nameVal}
              placeholder="Enter your agent full name"
              onChange={e => setNameVal(e.target.value)}
            />
          </TextSmall>
        </AgentPiiStep>
        <AgentPiiStep>
          <TextSmall as="label">
            <InputLabel>
              Agent email address <Optional>optional</Optional>
            </InputLabel>
            <PiiInput
              variation="large"
              type="email"
              value={emailVal}
              inputState="valid"
              placeholder="Enter your agent email address"
              onChange={e => setEmailVal(e.target.value)}
            />
          </TextSmall>
        </AgentPiiStep>
        <AgentPiiStep>
          <TextSmall as="label">
            <InputLabel>
              Agent phone number <Optional>optional</Optional>
            </InputLabel>
            <PiiInput
              variation="large"
              type="tel"
              value={phoneVal}
              inputState="valid"
              placeholder="Enter your agent phone number"
              onChange={e => setPhoneVal(formatPhone(e.target.value))}
            />
          </TextSmall>
        </AgentPiiStep>
      </AgentPiiForm>
      <SubmitButtonWrapper>
        <SubmitAgentInfoButton onClick={handleSubmit}>
          {loading ? <LoadingSpinner width="18px" color={electricBlue} /> : 'Submit'}
        </SubmitAgentInfoButton>
      </SubmitButtonWrapper>
      <Toast show={error} state="error">
        Sorry, something went wrong submitting
      </Toast>
    </AgentFormSection>
  )
}
