import { createContext, useContext, useEffect, useState } from "react"
import {
  IPropertyContext,
  IPropertyProvider,
  IPropertyListing
} from '.'
import { PROPERTIES_API_URL } from '../../constants'
import { requestData } from '../../utils/requestData'
import { buildPropertyInterface } from '../../utils/buildPropertyInterface'
import { PropertyType } from '../PropertiesContext'
import { ISavedHomesContext, SavedHomesContext } from "../SavedHomesContext"

export const PropertyContext = createContext<IPropertyContext | {}>({})

export const PropertyProvider: React.FC<IPropertyProvider> = ({
  propertyUuid,
  includeAgent,
  children
}) => {
  const {
    savedHomeUuids
  } = useContext(SavedHomesContext) as ISavedHomesContext

  const [propertyListing, setPropertyListing] = useState<IPropertyListing>()
  const [propertyListingLoading, setPropertyListingLoading] = useState<boolean>(true)
  const [propertyListingError, setPropertyListingError] = useState<boolean>(false)

  const getProperty = () => {
    if (!propertyUuid) { return }

    setPropertyListingLoading(true)
    const filters = [{
      field: 'uuid_formatted',
      operator: '=',
      value: propertyUuid
    }]
    if (includeAgent) {
      filters.push({
        field: 'agent_attr',
        operator: '=',
        value: 'true'
      })
    }

    requestData({
      url: PROPERTIES_API_URL,
      method: 'get',
      filters: filters
    }).then(({data, error}) => {
      const propertyData = data[0] as PropertyType
      const propertyInterface = buildPropertyInterface({property: propertyData, listing: null, savedHomes: savedHomeUuids})
      const propertyListing = propertyInterface as IPropertyListing

      setPropertyListing(propertyListing)
      setPropertyListingLoading(false)
      setPropertyListingError(error)
    })
  }

  useEffect(() => {
    if (!propertyUuid?.length) { return }

    getProperty()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyUuid])

  const contextVal = {
    propertyUuid,
    propertyListing,
    propertyListingLoading,
    propertyListingError
  }

  return (
    <PropertyContext.Provider value={contextVal}>
      {children}
    </PropertyContext.Provider>
  )
}
