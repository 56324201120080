import { useState } from 'react'
import styled from 'styled-components'
import {
  PopoverContainer,
  PopoverBody,
  PopoverContentContainer
} from '../../common/Popover'
import { Trigger } from './Trigger'
import { CancelOrConfirm } from '../../common/CancelOrConfirm'
import { IFilterPopoverProps } from './'

const InvisibleOverlay = styled.div<{ active: boolean }>`
  display: ${props => (props.active ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
`

export const FilterPopover: React.FC<IFilterPopoverProps> = ({
  triggerLabel,
  testLabel,
  hasSelectedFilters,
  applyFilters,
  clearFilters,
  contentPadding = '24px',
  bodyMargin = '0',
  children,
  popoverBodyWidth = '',
  popoverBodyLeft = '',
  popoverBodyRight = '',
  overflowEllipsis = false,
  popoverContainerMinWidth = ''
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const togglePopover = (e: any) => {
    e.preventDefault()
    if (isVisible) {
      applyFilters()
    }
    setIsVisible(!isVisible)
  }

  const handleApplyFilters = () => {
    setIsVisible(false)
    applyFilters()
  }

  const triggerActive = isVisible || hasSelectedFilters

  return (
    <>
      <InvisibleOverlay onClick={handleApplyFilters} active={isVisible} />
      {/* @ts-ignore */}
      <PopoverContainer mr="8px" minWidth={popoverContainerMinWidth} >
        <Trigger
          onClick={(e: any) => togglePopover(e)}
          active={triggerActive}
          data-testid={`${testLabel}-trigger`}
          className={`popover-trigger${triggerActive ? ' trigger-active' : ''}`}
          overflowEllipsis={overflowEllipsis}
        >
          {triggerLabel}
        </Trigger>
        <PopoverBody
          as="div"
          open={isVisible}
          pb="0px"
          margin={bodyMargin}
          width={popoverBodyWidth}
          left={popoverBodyLeft}
          right={popoverBodyRight}
        >
          <PopoverContentContainer padding={contentPadding}>{children}</PopoverContentContainer>
          <CancelOrConfirm
            confirm={handleApplyFilters}
            cancel={clearFilters}
            disableCancel={!hasSelectedFilters}
            testLabel={testLabel}
          />
        </PopoverBody>
      </PopoverContainer>
    </>
  )
}
