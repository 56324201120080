import { CSSProperties } from "react";
import styled from 'styled-components';
import { coolGray40, coolGray50 } from '../../../constants'

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${coolGray50};
  display: flex;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover, &:focus-visible {
    background-color: ${coolGray40};
  }
`

export const ClearIcon = () => {
  const SVGStyle: CSSProperties = {
    display: 'block',
    margin: 'auto'
  }

  return (
    <IconWrapper>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={SVGStyle} role="img">
          <title>Clear Search</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.23898 4.99998L9.04414 2.19482C9.20638 2.03258 9.20638 1.7693 9.04414 1.6068L8.39233 0.954991C8.23009 0.792754 7.96682 0.792754 7.80432 0.954991L4.99941 3.76042L2.19425 0.955251C2.03201 0.793014 1.76874 0.793014 1.60624 0.955251L0.954686 1.6068C0.792449 1.76904 0.792449 2.03232 0.954686 2.19482L3.75985 4.99998L0.954686 7.80514C0.792449 7.96738 0.792449 8.23066 0.954686 8.39316L1.6065 9.04497C1.76874 9.20721 2.03201 9.20721 2.19451 9.04497L4.99941 6.23954L7.80458 9.04471C7.96682 9.20695 8.23009 9.20695 8.39259 9.04471L9.0444 8.3929C9.20664 8.23066 9.20664 7.96738 9.0444 7.80488L6.23898 4.99998Z" fill="white"/>
      </svg>
    </IconWrapper>
  )
}
