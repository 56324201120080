import { createContext, useEffect, useState, useContext } from 'react'
import {
  PERMITTED_PROPERTY_TYPES,
  PROPERTY_TYPE_FILTER,
  HAS_LISTING_FILTER,
  LISTING_STATUS_FILTER,
  DATE_LISTED_FILTER,
  DEFAULT_DATE_LISTED_OPTION,
  CLOSED,
  DEFAULT_PROPERTY_SORT,
  NO_MIN_FILTER_OPTION,
  DEFAULT_LISTING_STATUS_FILTER,
  PRICE_MIN_FILTER,
  PRICE_MAX_FILTER,
  BEDS_MIN_FILTER,
  BEDS_MAX_FILTER,
  BEDS_NO_MIN_MAX_OPTION,
  BATHS_MIN_FILTER,
  NO_MAX_FILTER_OPTION,
  SQFT_MIN_FILTER,
  SQFT_MAX_FILTER,
  LOT_SIZE_MIN_FILTER,
  LOT_SIZE_MAX_FILTER,
  YEAR_BUILT_MIN_FILTER,
  YEAR_BUILT_MAX_FILTER,
  SALE_DATE_FILTER,
  DEFAULT_SALE_DATE,
  FAVORITE_PROPERTIES_FILTER,
  URL_ELIGIBLE,
  DEFAULT_FEATURED_OPTION,
  FEATURED_FILTER
} from '../../constants'
import { isState } from '../../utils/parseAreaSlug'
import { getAppliedPropertyFilter } from '../../utils/getAppliedPropertyFilter'
import { findObjectIndicesByKey } from '../../utils/findObjectIndicesByKey'
import { IDropdownOption } from '../../components/common/Dropdown'
import { IFilter } from '../../components/Filters'
import { ISort } from '../../components/Sort'
import { IFiltersContext, IFiltersProvider } from '.'
import { LocationContext, ILocationContext } from '../LocationContext'
import { UserContext, IUserContext } from '../UserContext'
import {
  ISavedPropertySearchesContext,
  SavedPropertySearchesContext
} from '../../contexts/SavedPropertySearchesContext'

export const PropertySearchFiltersContext = createContext<IFiltersContext | null>(null)

export const PropertySearchFiltersProvider: React.FC<IFiltersProvider> = ({
  areaSlug,
  searchParamFilters,
  searchParamSorts,
  children
}) => {
  const consumedLocationContext = useContext(LocationContext) as ILocationContext | null
  const { savedSearches } = useContext(
    SavedPropertySearchesContext
  ) as ISavedPropertySearchesContext
  areaSlug = areaSlug || consumedLocationContext?.currentSlug || null
  const {
    user: { currentUser, visitorId }
  } = useContext(UserContext) as IUserContext

  // Sort
  const defaultSort = searchParamSorts?.length ? searchParamSorts : [DEFAULT_PROPERTY_SORT]

  // Listing Status
  const defaultListingStatus =
    getAppliedPropertyFilter({ field: 'listingStatus', filters: searchParamFilters }) ||
    DEFAULT_LISTING_STATUS_FILTER
  const [listingStatus, setListingStatus] = useState<IDropdownOption>(defaultListingStatus)
  const [selectedListingStatus, setSelectedListingStatus] =
    useState<IDropdownOption>(defaultListingStatus)

  // Featured
  const defaultFeatured =
    getAppliedPropertyFilter({ field: 'featured', filters: searchParamFilters }) ||
    DEFAULT_FEATURED_OPTION
  const [featured, setFeatured] = useState(defaultFeatured)
  const [selectedFeatured, setSelectedFeatured] = useState(defaultFeatured)

  // Price
  const defaultPriceMin =
    getAppliedPropertyFilter({ field: 'priceMin', filters: searchParamFilters }) ||
    NO_MIN_FILTER_OPTION
  const defaultPriceMax =
    getAppliedPropertyFilter({ field: 'priceMax', filters: searchParamFilters }) ||
    NO_MAX_FILTER_OPTION
  const [priceMin, setPriceMin] = useState<IDropdownOption>(defaultPriceMin)
  const [selectedPriceMin, setSelectedPriceMin] = useState<IDropdownOption>(defaultPriceMin)
  const [priceMax, setPriceMax] = useState<IDropdownOption>(defaultPriceMax)
  const [selectedPriceMax, setSelectedPriceMax] = useState<IDropdownOption>(defaultPriceMax)

  // Beds Min/Max
  const defaultBedsMin =
    getAppliedPropertyFilter({ field: 'bedsMin', filters: searchParamFilters }) || BEDS_MIN_FILTER
  const defaultBedsMax =
    getAppliedPropertyFilter({ field: 'bedsMax', filters: searchParamFilters }) || BEDS_MAX_FILTER
  const [bedsMin, setBedsMin] = useState<IDropdownOption>(defaultBedsMin)
  const [selectedBedsMin, setSelectedBedsMin] = useState<IDropdownOption>(defaultBedsMin)
  const [bedsMax, setBedsMax] = useState<IDropdownOption>(defaultBedsMax)
  const [selectedBedsMax, setSelectedBedsMax] = useState<IDropdownOption>(defaultBedsMax)

  // Baths
  const defaultBathsMin =
    getAppliedPropertyFilter({ field: 'bathsMin', filters: searchParamFilters }) || BATHS_MIN_FILTER
  const [bathsMin, setBathsMin] = useState<IDropdownOption>(defaultBathsMin)
  const [selectedBathsMin, setSelectedBathsMin] = useState<IDropdownOption>(defaultBathsMin)

  // Property Types
  const defaultPropertyType =
    searchParamFilters[findObjectIndicesByKey(searchParamFilters, 'label', 'propertyType')[0]] ||
    PROPERTY_TYPE_FILTER
  const propertyTypeValueString = (defaultPropertyType.value as string) || ''
  let propertyTypeValue =
    propertyTypeValueString.split(',').length > 0 ? propertyTypeValueString.split(',') : []
  if (propertyTypeValueString === PERMITTED_PROPERTY_TYPES.join(',')) {
    propertyTypeValue = []
  }
  const filterEmptyValues = (types: string[]) => types.filter(Boolean)
  const filteredDefaultPropertyValues = filterEmptyValues(propertyTypeValue)
  const [propertyTypes, setPropertyTypes] = useState<string[]>(filteredDefaultPropertyValues)
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState<string[]>(
    filteredDefaultPropertyValues
  )
  const setFilteredPropertyTypes = (types: string[]) => setPropertyTypes(filterEmptyValues(types))

  // Sqft
  const defaultSqftMin =
    getAppliedPropertyFilter({ field: 'sqftMin', filters: searchParamFilters }) ||
    NO_MIN_FILTER_OPTION
  const defaultSqftMax =
    getAppliedPropertyFilter({ field: 'sqftMax', filters: searchParamFilters }) ||
    NO_MAX_FILTER_OPTION
  const [sqftMin, setSqftMin] = useState<IDropdownOption>(defaultSqftMin)
  const [selectedSqftMin, setSelectedSqftMin] = useState<IDropdownOption>(defaultSqftMin)
  const [sqftMax, setSqftMax] = useState<IDropdownOption>(defaultSqftMax)
  const [selectedSqftMax, setSelectedSqftMax] = useState<IDropdownOption>(defaultSqftMax)

  // Lot Size
  const defaultLotSizeMin =
    getAppliedPropertyFilter({ field: 'lotSizeMin', filters: searchParamFilters }) ||
    NO_MIN_FILTER_OPTION
  const defaultLotSizeMax =
    getAppliedPropertyFilter({ field: 'lotSizeMax', filters: searchParamFilters }) ||
    NO_MAX_FILTER_OPTION
  const [lotSizeMin, setLotSizeMin] = useState<IDropdownOption>(defaultLotSizeMin)
  const [selectedLotSizeMin, setSelectedLotSizeMin] = useState<IDropdownOption>(defaultLotSizeMin)
  const [lotSizeMax, setLotSizeMax] = useState<IDropdownOption>(defaultLotSizeMax)
  const [selectedLotSizeMax, setSelectedLotSizeMax] = useState<IDropdownOption>(defaultLotSizeMax)

  // Year Built
  const defaultYearBuiltMin =
    getAppliedPropertyFilter({ field: 'yearBuiltMin', filters: searchParamFilters }) ||
    NO_MIN_FILTER_OPTION
  const defaultYearBuiltMax =
    getAppliedPropertyFilter({ field: 'yearBuiltMax', filters: searchParamFilters }) ||
    NO_MAX_FILTER_OPTION
  const [yearBuiltMin, setYearBuiltMin] = useState<IDropdownOption>(defaultYearBuiltMin)
  const [selectedYearBuiltMin, setSelectedYearBuiltMin] =
    useState<IDropdownOption>(defaultYearBuiltMin)
  const [yearBuiltMax, setYearBuiltMax] = useState<IDropdownOption>(defaultYearBuiltMax)
  const [selectedYearBuiltMax, setSelectedYearBuiltMax] =
    useState<IDropdownOption>(defaultYearBuiltMax)

  // Sale Date
  const defaultSaleDate =
    getAppliedPropertyFilter({ field: 'saleDate', filters: searchParamFilters }) || SALE_DATE_FILTER
  const [saleDate, setSaleDate] = useState<IDropdownOption>(defaultSaleDate)
  const [selectedSaleDate, setSelectedSaleDate] = useState<IDropdownOption>(defaultSaleDate)

  // Date Listed
  const defaultDateListed =
    getAppliedPropertyFilter({ field: 'dateListed', filters: searchParamFilters }) ||
    DATE_LISTED_FILTER
  const [dateListed, setDateListed] = useState<IDropdownOption>(defaultDateListed)
  const [selectedDateListed, setSelectedDateListed] = useState<IDropdownOption>(defaultDateListed)

  // Favorites
  const fieldValue = currentUser
    ? { field: '_user_id', value: currentUser.id }
    : { field: '_visitor_id', value: visitorId }
  const userFilter = {
    ...fieldValue,
    label: 'savedHomesUserFilter',
    operator: '='
  }
  const favoritesFilters = [userFilter, FAVORITE_PROPERTIES_FILTER]

  // All Filters & Sorts State
  const [sorts, setSorts] = useState<ISort[]>(defaultSort)
  const [locationFilters, setLocationFilters] = useState<IFilter[]>([])
  const [filters, setFilters] = useState<IFilter[]>([])

  const resetPropertyDetailFilters = () => {
    setListingStatus(DEFAULT_LISTING_STATUS_FILTER)
    setSelectedListingStatus(DEFAULT_LISTING_STATUS_FILTER)
    setFeatured(DEFAULT_FEATURED_OPTION)
    setSelectedFeatured(DEFAULT_FEATURED_OPTION)
    setPriceMin(NO_MIN_FILTER_OPTION)
    setSelectedPriceMin(NO_MIN_FILTER_OPTION)
    setPriceMax(NO_MAX_FILTER_OPTION)
    setSelectedPriceMax(NO_MAX_FILTER_OPTION)
    setBedsMin(BEDS_NO_MIN_MAX_OPTION)
    setSelectedBedsMin(BEDS_NO_MIN_MAX_OPTION)
    setBedsMax(BEDS_NO_MIN_MAX_OPTION)
    setSelectedBedsMax(BEDS_NO_MIN_MAX_OPTION)
    setBathsMin(NO_MIN_FILTER_OPTION)
    setSelectedBathsMin(NO_MIN_FILTER_OPTION)
    setPropertyTypes([])
    setSelectedPropertyTypes([])
    setSqftMin(NO_MIN_FILTER_OPTION)
    setSelectedSqftMin(NO_MIN_FILTER_OPTION)
    setSqftMax(NO_MAX_FILTER_OPTION)
    setSelectedSqftMax(NO_MAX_FILTER_OPTION)
    setLotSizeMin(NO_MIN_FILTER_OPTION)
    setSelectedLotSizeMin(NO_MIN_FILTER_OPTION)
    setLotSizeMax(NO_MAX_FILTER_OPTION)
    setSelectedLotSizeMax(NO_MAX_FILTER_OPTION)
    setYearBuiltMin(NO_MIN_FILTER_OPTION)
    setSelectedYearBuiltMin(NO_MIN_FILTER_OPTION)
    setYearBuiltMax(NO_MAX_FILTER_OPTION)
    setSelectedYearBuiltMin(NO_MAX_FILTER_OPTION)
    setSaleDate(DEFAULT_SALE_DATE)
    setSelectedSaleDate(DEFAULT_SALE_DATE)
    setDateListed(DEFAULT_DATE_LISTED_OPTION)
    setSelectedDateListed(DEFAULT_DATE_LISTED_OPTION)
  }

  const savedSearchMatchesCurrentFilters = () => {
    return (
      Boolean(savedSearches) &&
      savedSearches.some(savedSearch => {
        const savedSearchPropertyTypes = savedSearch.propertyTypes?.split(',')?.sort()?.join()
        const filterPropertyTypes = propertyTypes.length
          ? propertyTypes.sort().join(',')
          : PERMITTED_PROPERTY_TYPES.sort().join(',')
        const propertyTypesMatch = savedSearchPropertyTypes === filterPropertyTypes

        return (
          savedSearch.areaSlug === areaSlug &&
          (savedSearch.bathsMin || 0) === bathsMin.value &&
          (savedSearch.bedsMin || 0) === bedsMin.value &&
          (savedSearch.bedsMax || 0) === bedsMax.value &&
          (savedSearch.lotSizeMax || 0) === lotSizeMax.value &&
          (savedSearch.lotSizeMin || 0) === lotSizeMin.value &&
          (savedSearch.priceMax || 0) === priceMax.value &&
          (savedSearch.priceMin || 0) === priceMin.value &&
          propertyTypesMatch &&
          (savedSearch.sqftMax || 0) === sqftMax.value &&
          (savedSearch.sqftMin || 0) === sqftMin.value &&
          savedSearch.status === listingStatus.value.toLowerCase() &&
          (savedSearch.yearBuiltMax || 0) === yearBuiltMax.value &&
          (savedSearch.yearBuiltMin || 0) === yearBuiltMin.value
        )
      })
    )
  }
  const [savedSearchMatchesFilters, setSavedSearchMatchesFilters] = useState(
    savedSearchMatchesCurrentFilters()
  )

  useEffect(() => {
    setSavedSearchMatchesFilters(savedSearchMatchesCurrentFilters())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    savedSearches,
    priceMin,
    priceMax,
    bedsMin,
    bedsMax,
    bathsMin,
    listingStatus,
    propertyTypes,
    sqftMin,
    sqftMax,
    lotSizeMin,
    lotSizeMax,
    yearBuiltMin,
    yearBuiltMax,
    locationFilters
  ])

  useEffect(() => {
    const validLocationFilters = locationFilters.filter(
      filter => filter.value !== '' && filter.value !== 0
    )

    if (!validLocationFilters || validLocationFilters.length === 0) {
      return
    }

    const propertyDetailFilters = [
      { ...PRICE_MIN_FILTER, value: priceMin.value },
      { ...PRICE_MAX_FILTER, value: priceMax.value },
      { ...BEDS_MIN_FILTER, value: bedsMin.value },
      { ...BEDS_MAX_FILTER, value: bedsMax.value },
      { ...BATHS_MIN_FILTER, value: bathsMin.value },
      { ...SQFT_MIN_FILTER, value: sqftMin.value },
      { ...SQFT_MAX_FILTER, value: sqftMax.value },
      { ...LOT_SIZE_MIN_FILTER, value: lotSizeMin.value },
      { ...LOT_SIZE_MAX_FILTER, value: lotSizeMax.value },
      { ...YEAR_BUILT_MIN_FILTER, value: yearBuiltMin.value },
      { ...YEAR_BUILT_MAX_FILTER, value: yearBuiltMax.value },
      { ...SALE_DATE_FILTER, value: saleDate.value },
      { ...DATE_LISTED_FILTER, value: dateListed.value },
      { ...FEATURED_FILTER, value: featured.value }
    ].filter(filter => filter.value !== '' && filter.value !== 0)

    const requestFilters = [
      HAS_LISTING_FILTER,
      URL_ELIGIBLE,
      { ...LISTING_STATUS_FILTER, value: listingStatus.value },
      {
        ...PROPERTY_TYPE_FILTER,
        value: propertyTypes.length ? propertyTypes.join(',') : PERMITTED_PROPERTY_TYPES.join(',')
      },
      ...propertyDetailFilters,
      ...validLocationFilters,
      ...favoritesFilters,
      { label: '_user_id', field: '_user_id', operator: '=', value: currentUser?.id || '' },
    ]

    setFilters(requestFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    priceMin,
    priceMax,
    bedsMin,
    bedsMax,
    bathsMin,
    listingStatus,
    propertyTypes,
    sqftMin,
    sqftMax,
    lotSizeMin,
    lotSizeMax,
    yearBuiltMin,
    yearBuiltMax,
    saleDate,
    dateListed,
    locationFilters,
    visitorId,
    currentUser,
    featured
  ])

  useEffect(() => {
    if (areaSlug?.length) {
      const area = {
        field: isState(areaSlug) ? 'state_or_province' : 'slug',
        operator: '',
        value: isState(areaSlug) ? areaSlug.toUpperCase() : areaSlug.toLowerCase()
      }
      setLocationFilters([area, ...locationFilters])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSlug])

  useEffect(() => {
    if (consumedLocationContext) {
      const setTitle = consumedLocationContext.setForSaleOrSoldTitle
      if (selectedListingStatus.value === CLOSED) {
        setTitle('Homes sold in')
      } else {
        setTitle('Homes for sale in')
      }
    }
  }, [selectedListingStatus, consumedLocationContext])

  const contextVal: IFiltersContext = {
    locationFilters,
    setLocationFilters,
    appliedFilters: {
      listingStatus: { setListingStatus, selectedListingStatus, setSelectedListingStatus },
      priceMin: { setPriceMin, selectedPriceMin, setSelectedPriceMin },
      priceMax: { setPriceMax, selectedPriceMax, setSelectedPriceMax },
      bedsMin: { setBedsMin, selectedBedsMin, setSelectedBedsMin },
      bedsMax: { setBedsMax, selectedBedsMax, setSelectedBedsMax },
      bathsMin: { setBathsMin, selectedBathsMin, setSelectedBathsMin },
      propertyType: {
        setPropertyTypes: setFilteredPropertyTypes,
        selectedPropertyTypes,
        setSelectedPropertyTypes
      },
      sqftMin: { setSqftMin, selectedSqftMin, setSelectedSqftMin },
      sqftMax: { setSqftMax, selectedSqftMax, setSelectedSqftMax },
      lotSizeMin: { setLotSizeMin, selectedLotSizeMin, setSelectedLotSizeMin },
      lotSizeMax: { setLotSizeMax, selectedLotSizeMax, setSelectedLotSizeMax },
      yearBuiltMin: { setYearBuiltMin, selectedYearBuiltMin, setSelectedYearBuiltMin },
      yearBuiltMax: { setYearBuiltMax, selectedYearBuiltMax, setSelectedYearBuiltMax },
      saleDate: { setSaleDate, selectedSaleDate, setSelectedSaleDate },
      dateListed: { setDateListed, selectedDateListed },
      featured: { setFeatured, selectedFeatured, setSelectedFeatured }
    },
    setFilters, // for tests only
    filters,
    resetPropertyDetailFilters,
    sorts,
    setSorts,
    savedSearchMatchesFilters
  }

  return (
    <PropertySearchFiltersContext.Provider value={contextVal}>
      {children}
    </PropertySearchFiltersContext.Provider>
  )
}
