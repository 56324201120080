import { stateNameOf } from '../../stateUtils'
import { isZipCode } from '../isZipCode'

export const parseZipCodeSlug = (slug: string) => {
  if (!slug || !isZipCode(slug)) {
    return {
      zip: '',
      state: ''
    }
  }
  const splitSlug: string[] = slug.split('-')
  let stateCode = splitSlug[1]
  let stateName = stateCode ? stateNameOf(stateCode) : ''
  let zipCode = splitSlug[0]

  return {
    zip: zipCode,
    state: stateName
  }
}
