import { toTitleCase } from '../../utils/toTitleCase'

export const buildPropertyStatus = (status?: string, date?: string): string => {
  if (!status) return ''
  if (!date) return toTitleCase(status)

  const formattedStatus = toTitleCase(status)
  const statusMap = {
    'pending': formattedStatus,
    'contingent': formattedStatus,
    'coming soon': formattedStatus,
    'closed': `Last sold ${new Date(date).toLocaleDateString('en-us', {
      year: "numeric", month: "long", day: "numeric"
    })}`,
    "active under contract": "Contingent"
  }

  let result = (statusMap as any)[status.toLowerCase()]

  if (!result) {
    let  time;

    if (daysFromNow(date) < 7) {
      time = timeFromNow(date, true)
      result = `New ${time}`
    } else {
      time = timeFromNow(date, false)
      result = `${time} on HomeLight`
    }
  }

  return result
}

export const secondsFromNow = (time: string): number => {
  let unixTime = new Date(time).getTime()
  if (!unixTime) return 0
  let now = Date.now()
  let difference = (unixTime / 1000) - (now / 1000)

  return Math.floor(Math.abs(difference));
}

const daysFromNow = (time: string): number => {
  let secFromNow = secondsFromNow(time)

  return Math.floor(secFromNow / (60 * 60 * 24))
}

export const roundUpForSingular = (num: number) => num === 1 ? 2 : num

const timeFromNow = (time: string, showAgo: boolean): string => {
  const seconds = secondsFromNow(time)
  const years = Math.floor(seconds / 31536000)
  const months = Math.floor(seconds / 2592000)
  const days = Math.floor(seconds / 86400)

  if (days > 548) {
    return roundUpForSingular(years) + ' years' + (showAgo ? ' ago' : '')
  }
  if (days >= 320 && days <= 547) {
    return '1 year' + (showAgo ? ' ago' : '')
  }
  if (days > 45 && days <= 319) {
    return roundUpForSingular(months) + ' months' + (showAgo ? ' ago' : '')
  }
  if (days >= 26 && days <= 45) {
    return '1 month' + (showAgo ? ' ago' : '')
  }

  const hours = Math.floor(seconds / 3600)

  if (hours >= 36 && days <= 25) {
    return roundUpForSingular(days) + ' days' + (showAgo ? ' ago' : '')
  }
  if (hours >= 22 && hours <= 35) {
    return '1 day' + (showAgo ? ' ago' : '')
  }

  const minutes = Math.floor(seconds / 60)

  if (minutes >= 90 && hours <= 21) {
    return roundUpForSingular(hours) + ' hours' + (showAgo ? ' ago' : '')
  }
  if (minutes >= 45 && minutes <= 89) {
    return '1 hour' + (showAgo ? ' ago' : '')
  }
  if (seconds >= 90 && minutes <= 44) {
    return roundUpForSingular(minutes) + ' minutes' + (showAgo ? ' ago' : '')
  }
  if (seconds >= 45 && seconds <= 89) {
    return '1 minute' + (showAgo ? ' ago' : '')
  }
  if (seconds >= 0) {
    return 'a few seconds' + (showAgo ? ' ago' : '')
  }
}
