import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { CREATE_LEAD_URL } from '../../constants'
import { requestData } from '../../utils/requestData'
import {
  COLORS,
  BREAKPOINT_S,
  BREAKPOINT_L,
  BREAKPOINT_XL,
  MAX_PAGE_WIDTH
} from '../../constants'
import { Flex } from '../common/Flex'
import { ScheduleTourButton } from './sharedStyles'
import { PiiForm, PiiStep, PiiInput, ErrorMessage } from '../MortgageDeals/styles'
import { TextSmall } from '../common/Text'
import { InfoIcon } from '../icons/InfoIcon'
import { LoadingSpinner } from '../common/LoadingSpinner'
import { formatCurrency } from '../../utils/formatCurrency'
import { formatPhone } from '../../utils/formatPhone'
import { VALIDATE_EMAIL_REGEX, VALIDATE_PHONE_REGEX } from '../../constants/validationRegex'

const SectionWrapper = styled.div`
  @media (min-width: ${BREAKPOINT_L}px) {
    padding: 40px 80px;
    max-width: ${MAX_PAGE_WIDTH}px;
    margin-left: auto;
    margin-right: auto;
  }
`

const ContentWrapper = styled.div`

  @media (min-width: ${BREAKPOINT_L}px) {
    display: flex;
    background-color: #E9F6FF;
    padding: 32px 40px 56px;
    gap: 48px;
    border-radius: 24px;
    box-shadow: 0 14px 48px 0 rgba(108, 129, 171, 0.20);
  }
  
  @media (min-width: ${BREAKPOINT_XL}px) {
    gap: 80px;
  }
`

const TextContent = styled.div`
  @media (min-width: ${BREAKPOINT_L}px) {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const UltraLowLabel = styled.div`
  font-size: 14px;

  @media (min-width: ${BREAKPOINT_L}px) {
    font-size: 20px;
  }
`

const BuyThisHome = styled.h2`
  margin: 16px 0;
  font-size: 24px;
  line-height: 120%;
  
  @media (min-width: ${BREAKPOINT_L}px) {
    margin: 40px 0;
    font-size: 48px;
  }
`

const MobileAddress = styled.div`
  display: block;
  font-weight: 600;

  @media (min-width: ${BREAKPOINT_L}px) {
    display: none;
  }
`

const Underline = styled.div`
  width: 126px;
  height: 2px;
  margin-top: 10px;
  border-radius: 3px;
  background-color: #8BD1FF;
`

const PaymentDetails = styled(Flex)`
  display: none;
  @media (min-width: ${BREAKPOINT_L}px) {
    display: block;
  }
`

const RateComparison = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

const HlRatePayment = styled.div`
  color: ${COLORS.electricBlue};
  font-size: 20px;
  font-weight: 700;
`

const MarketRatePayment = styled.div`
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-decoration: line-through;
`

const LeadForm = styled(PiiForm)`
  flex: 1;
  background-color: ${COLORS.white};

  @media (min-width: ${BREAKPOINT_L}px) {
    padding: 48px 32px;
    border-radius: 24px;
    box-shadow: 0px 4px 20px 0px rgba(108, 129, 171, 0.20);
  }
`

const CheckboxStep = styled(PiiStep)`
  padding-bottom: 84px;
  @media (min-width: ${BREAKPOINT_S}px) {
    padding-bottom: 0;
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`

const StyledTooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 30%;
  left: 16px;
  right: 16px;
  min-width: 300px;
  background-color: ${COLORS.white};
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 12px 16px;
  line-height: 150%;
  z-index: 9;
  transition: opacity 0.3s;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 16px 24px);

  @media (min-width: ${BREAKPOINT_L}px) {
    position: absolute;
    left: -200px;
    top: -324px;
    right: unset;
    bottom: unset;
  }
`

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;

  &: hover ${StyledTooltip} {
    visibility: visible;
    opacity: 1;
  }
`

const TermsErrorMessage = styled(ErrorMessage)`
  margin-left: unset;
  margin-top: 8px;
  margin-bottom: unset;
  text-align: left;
`

const SubmitButton = styled(ScheduleTourButton)<{disabled: boolean}>`
  font-size: 20px;
  width: 100%;
  ${props => props.disabled ? `
    background-color: ${COLORS.coolGray50};
    color: ${COLORS.coolGray30};
    cursor: initial;
    user-select: none;
    &:hover {
      background-color: ${COLORS.coolGray50};
    }
  ` : ''}
  
  @media (min-width: ${BREAKPOINT_L}px) {
    font-size: 24px;
  }
`

const SubmitButtonWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding: 16px;
  box-sizing: border-box;
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.coolGray60};
  box-shadow: 0 3px 16px 0 rgba(108, 129, 171, 0.20);

  @media (min-width: ${BREAKPOINT_S}px) {
    position: absolute;
    width: 100%;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    position: relative;
    left: unset;
    bottom: unset;
    padding: 0;
    border: none;
    box-shadow: none;
  }
`

export const UltraLeadForm = ({
  agentFirstName,
  hlRate,
  rateEstimated,
  propertyListing,
  closeModal,
  onSubmitSuccess,
}) => {
  const [loading, setLoading] = useState(false)
  const [nameVal, setNameVal] = useState('')
  const [emailVal, setEmailVal] = useState('')
  const [phoneVal, setPhoneVal] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const emailInputRef = useRef(null)

  if (!propertyListing) {
    return null;
  }

  const nameValIsPresent = nameVal && nameVal.length > 0
  const emailValIsPresent = emailVal && emailVal.length > 0
  const phoneValIsPresent = phoneVal && phoneVal.length > 0

  const nameIsValid = !nameVal || nameValIsPresent
  const emailIsValid = !emailVal || VALIDATE_EMAIL_REGEX.test(emailVal)
  const phoneIsValid = !phoneVal || VALIDATE_PHONE_REGEX.test(phoneVal)

  const nameError = nameIsValid ? null : 'Please enter your name.'
  const emailError = emailIsValid ? null : 'Please enter a valid email address.'
  const phoneError = phoneIsValid ? null : 'Please enter a valid phone number.'
  const allAreValid =
    nameIsValid && emailValIsPresent && emailIsValid && phoneValIsPresent && phoneIsValid

  const termsCopy = () => (
    <p>
      By clicking "Schedule a Tour", I consent to, and acknowledge that I have read,
      HomeLight’s{' '}
      <a href="https://www.homelight.com/terms" target="_blank" rel="noreferrer">
        Terms of Service
      </a>{' '}
      and{' '}
      <a href="https://www.homelight.com/privacy-policy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      , and I consent to have HomeLight or one of its partners to contact me via autodialed
      phone calls and text messages, and prerecorded messages, on the landline or cell number
      I provided even if I'm on any Do-Not-Call list and even if I am charged for the call or
      text. I understand that my consenting is not a condition of my receiving HomeLight’s
      services, and that I can call 866-627-3398 for services if I do not consent.
    </p>
  )

  const handleOnChangeTerms = () => {
    if (!termsAccepted) {
      setTermsError(false)
    }

    setTermsAccepted(!termsAccepted)
  }

  const submitForm = () => {
    if (!termsAccepted) {
      setTermsError(true)
      return
    }

    if (allAreValid) {
      setSubmitError(false)
      setLoading(true)

      const callReview = {
        agent_ultra_confirmed: propertyListing.agentUltraConfirmed,
        agent_name: propertyListing.agentName || propertyListing.listingListAgentFullName,
        agent_phone: propertyListing.agentPhone,
        agent_email: propertyListing.agentEmail,
      }

      const body = {
        user_type: 'buyer',
        source: 'web',
        source_page_type: 'ultra_listing_lead_capture',
        source_form: 'ultra_listing_lead_capture',
        referring_owner_type: 'Agent',
        referring_owner_id: propertyListing.agentId,
        full_name: nameVal,
        email: emailVal,
        phone: phoneVal,
        price: propertyListing.listingListPrice,
        timeline: '6_months',
        slug: propertyListing.slug,
        property_type: propertyListing.property_type || 'single_family_home',
        address: `${propertyListing.hlFullAddress}, ${propertyListing.formattedCityStateZip}`,
        city: propertyListing.formattedCity,
        state: propertyListing.formattedState,
        call_review: callReview,
        send_ultra_agent_event: propertyListing.agentUltraConfirmed,
        ultra_url: window.location.href,
        agent_id: propertyListing.agentId,
      }
      requestData({
        url: CREATE_LEAD_URL,
        method: 'post',
        body: body,
        ignoreJsonFormat: true,
        includeBearerToken: true
      }).then(({data, error}) => {
        setLoading(false)
        if (error) {
          setSubmitError(true)
          return
        }

        onSubmitSuccess()
        if (closeModal) {
          closeModal()
        }
      })
    }
  }

  return (
    <SectionWrapper id="lead-form-wrapper">
      <ContentWrapper>
        <TextContent>
          <UltraLowLabel>
            HomeLight’s Ultra-low rate
          </UltraLowLabel>
          <Underline />
          <BuyThisHome>
            {`Buy this home at ${rateEstimated ? 'as low as ' : ''}${hlRate}% today`}
          </BuyThisHome>
          <MobileAddress>
            {propertyListing.hlFullAddress}
          </MobileAddress>
          <PaymentDetails>
            <RateComparison>
              <HlRatePayment>
                {`${hlRate}% at ${formatCurrency(propertyListing.monthlyPayment)} / mo`}
              </HlRatePayment>
              <MarketRatePayment>
                {`${propertyListing.marketMortgageRate}% at ${formatCurrency(propertyListing.marketMonthlyMortgagePayment)} / mo`}
              </MarketRatePayment>
            </RateComparison>
          </PaymentDetails>
        </TextContent>
        <LeadForm>
          <PiiStep>
            <TextSmall as="label">
              Name
              <PiiInput
                variation="large"
                type="text"
                inputState={nameIsValid ? 'valid' : 'error'}
                errorMessage={nameError}
                value={nameVal}
                placeholder="Enter your full name"
                onChange={e => setNameVal(e.target.value)}
              />
            </TextSmall>
          </PiiStep>
          <PiiStep>
            <TextSmall as="label">
              Email Address
              <PiiInput
                variation="large"
                type="email"
                value={emailVal}
                inputRef={emailInputRef}
                inputState={emailIsValid ? 'valid' : 'error'}
                errorMessage={emailError}
                placeholder="Enter your email address"
                onChange={e => setEmailVal(e.target.value)}
              />
            </TextSmall>
          </PiiStep>
          <PiiStep>
            <TextSmall as="label">
              Phone Number
              <PiiInput
                variation="large"
                type="tel"
                value={phoneVal}
                inputState={phoneIsValid ? 'valid' : 'error'}
                errorMessage={phoneError}
                placeholder="Enter your phone number"
                onChange={e => setPhoneVal(formatPhone(e.target.value))}
              />
            </TextSmall>
          </PiiStep>
          <CheckboxStep>
            <CheckboxWrapper>
              <input type="checkbox" checked={termsAccepted} onChange={handleOnChangeTerms} />
              <TextSmall as="label">
                <span onClick={handleOnChangeTerms}>
                  I acknowledge that I have read and agree to the terms in HomeLight Ultra Program.{' '}
                </span>
                <TooltipContainer>
                  <InfoIcon />
                  <StyledTooltip>{termsCopy()}</StyledTooltip>
                </TooltipContainer>
              </TextSmall>
            </CheckboxWrapper>
            {termsError && <TermsErrorMessage>Please approve to continue.</TermsErrorMessage>}
            {submitError && <TermsErrorMessage>We're sorry, something went wrong.</TermsErrorMessage>}
          </CheckboxStep>
          <SubmitButtonWrapper>
            <SubmitButton onClick={submitForm} disabled={!allAreValid}>
              {loading ? (
                <LoadingSpinner width="24px" />
              ) : `Schedule a Tour with ${agentFirstName}`}
            </SubmitButton>
          </SubmitButtonWrapper>
        </LeadForm>
      </ContentWrapper>
    </SectionWrapper>
  )
}
