export const InfoIcon = ({ fill = "#273653" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="1 2 14 14" fill="none" className="info-icon">
    <g>
      <path
        d="M8 2.5C4.68594 2.5 2 5.18594 2 8.5C2 11.8141 4.68594 14.5 8 14.5C11.3141 14.5 14 11.8141 14 8.5C14 5.18594 11.3141 2.5 8 2.5ZM8 13.75C5.10547 13.75 2.75 11.3945 2.75 8.5C2.75 5.60547 5.10547 3.25 8 3.25C10.8945 3.25 13.25 5.60547 13.25 8.5C13.25 11.3945 10.8945 13.75 8 13.75ZM8 6.8125C8.31055 6.8125 8.5625 6.56078 8.5625 6.25C8.5625 5.93945 8.31055 5.6875 8 5.6875C7.68945 5.6875 7.4375 5.93828 7.4375 6.25C7.4375 6.56172 7.68828 6.8125 8 6.8125ZM9.125 10.75H8.375V8.125C8.375 7.91875 8.20625 7.75 8 7.75H7.25C7.04375 7.75 6.875 7.91875 6.875 8.125C6.875 8.33125 7.04375 8.5 7.25 8.5H7.625V10.75H6.875C6.66875 10.75 6.5 10.9187 6.5 11.125C6.5 11.3313 6.66875 11.5 6.875 11.5H9.125C9.33209 11.5 9.5 11.3321 9.5 11.125C9.5 10.9187 9.33125 10.75 9.125 10.75Z"
        fill={fill}
      />
    </g>
  </svg>
)
