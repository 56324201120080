import { useState } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import {
  darkBlue,
  FOR_SALE,
  LISTING_STATUS_OPTIONS,
  SOLD
} from '../../../constants'
import { Flex } from '../../common/Flex'
import { TextSmall } from '../../common/Text'
import { RadioButton } from '../../common/RadioButton'
import { Dropdown } from '../../common/Dropdown'
import { NativeDropdown } from '../../common/NativeDropdown'
import { IForSaleOrSold, ListingStatusType } from './'

const StyledForSaleFilterContainer = styled('div')`
  padding-top: 24px;
`

export const ForSaleOrSold: React.FC<IForSaleOrSold> = ({
  forSaleOrSold,
  selectForSaleOrSold,
  listingStatus,
  selectListingStatus,
  // Temporarily commenting this out until we get updated listing_close_date field from data team
  // saleDate,
  selectSaleDate,
  saleDateOptions
}) => {
  const [selected, setSelected] = useState(forSaleOrSold)
  const { mobile } = useBreakpoint()

  const forSaleSelected = selected === FOR_SALE
  const soldSelected = selected === SOLD
  const ResponsiveDropdown = mobile ? NativeDropdown : Dropdown
  const listingStatusLabel = listingStatus ? listingStatus.label : LISTING_STATUS_OPTIONS[0].label
  // Temporarily commenting this out until we get updated listing_close_date field from data team
  // const saleDateLabel = saleDate ? saleDate.label : saleDateOptions[0].label

  const handleChange = (value: ListingStatusType) => {
    if (value === SOLD) {
      selectListingStatus(LISTING_STATUS_OPTIONS[0])
    }
    if (value === FOR_SALE) {
      selectSaleDate(saleDateOptions[0])
    }

    setSelected(value)
    selectForSaleOrSold(value)
  }

  return (
    <div>
      <Flex
        direction='row'
        align='center'
      >
        <RadioButton
          label='For sale'
          name='for-sale-or-sold'
          value={FOR_SALE}
          selected={forSaleSelected}
          toggleSelected={(val) => handleChange(val)}
          marginRight='50px'
        />
        <RadioButton
          label='Sold'
          name='for-sale-or-sold'
          value={SOLD}
          selected={soldSelected}
          toggleSelected={(val) => handleChange(val)}
        />
      </Flex>
      {forSaleSelected && (
        <StyledForSaleFilterContainer>
          <TextSmall
            as="label"
            color={darkBlue}
          >
            Status
          </TextSmall>
          <ResponsiveDropdown
            options={LISTING_STATUS_OPTIONS}
            selectedLabel={listingStatusLabel}
            displayOptionState={true}
            setSelected={option => selectListingStatus(option)}
            triggerTestId='select-listing-status'
            optionsMaxHeight="250px"
            optionsOverflow="scroll"
            pt="5px"
          />
        </StyledForSaleFilterContainer>
      )}
      {/* Temporarily commenting this out until we get updated listing_close_date field from data team */}
      {/* {soldSelected && (
        <StyledForSaleFilterContainer>
          <TextSmall
            as="label"
            color={darkBlue}
          >
            Sale date
          </TextSmall>
          <ResponsiveDropdown
            options={saleDateOptions}
            selectedLabel={saleDateLabel}
            setSelected={option => selectSaleDate(option)}
            triggerTestId='select-sale-date'
            optionsMaxHeight="250px"
            optionsOverflow="scroll"
          />
        </StyledForSaleFilterContainer>
      )} */}
    </div>
  )
}
