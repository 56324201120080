import { IIconProps } from '../types'

export const MapMarkerIcon: React.FC<IIconProps> = ({ fill="#DBDFE6" }) => {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
      <title>Map Marker</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.61743 15.6772C0.879456 9.09472 0 8.41915 0 6C0 2.68628 2.80308 0 6.26087 0C9.71866 0 12.5217 2.68628 12.5217 6C12.5217 8.41915 11.6423 9.09472 6.9043 15.6772C6.59338 16.1076 5.92832 16.1076 5.61743 15.6772Z" fill={fill}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.26104 8.5C7.70179 8.5 8.86974 7.38072 8.86974 6C8.86974 4.61928 7.70179 3.5 6.26104 3.5C4.82029 3.5 3.65234 4.61928 3.65234 6C3.65234 7.38072 4.82029 8.5 6.26104 8.5Z" fill="white"/>
    </svg>
  )
}
