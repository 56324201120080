import styled from 'styled-components'

export const FullWidth = styled('div')<{
  margin?: string,
  mt?: string,
  mr?: string,
  mb?: string,
  ml?: string,
  padding?: string,
  pt?: string,
  pr?: string,
  pb?: string,
  pl?: string
}>`
  width: 100%;
  margin: ${props => props.margin ? props.margin : 0};
  margin-top: ${props => props.mt ? props.mt : 0};
  margin-right: ${props => props.mr ? props.mr : 0};
  margin-bottom: ${props => props.mb ? props.mb : 0};
  margin-left: ${props => props.ml ? props.ml : 0};
  padding: ${props => props.padding ? props.padding : 0};
  padding-top: ${props => props.pt ? props.pt : 0};
  padding-right: ${props => props.pr ? props.pr : 0};
  padding-bottom: ${props => props.pb ? props.pb : 0};
  padding-left: ${props => props.pl ? props.pl : 0};
`
