import styled from 'styled-components'
import { coolGray30, darkBlue, white } from '../../constants'
import { Flex } from '../common/Flex'
import { TextSmall } from '../common/Text'
import { DynamicPrice } from '../common/Price'
import { PropertyPriceDataTypes } from './types'
import  { formatPrice } from '../../utils/formatPrice'

const StyledText = styled(TextSmall)`
  line-height: 28px;
  margin-left: 6px;
`

export const PropertyPrice: React.FC<PropertyPriceDataTypes> = ({
  priceLabel,
  closePrice,
  variation,
  invertColors = false,
  flexStyle = {},
  downPayment,
  featuredAndGoldRushEnabled
}) => {
  const color = invertColors ? white : darkBlue

  return (
    <Flex align="start" direction="column" {...flexStyle}>
      {priceLabel && (
        <TextSmall as="label" color={coolGray30}>
          {priceLabel}
        </TextSmall>
      )}
      <DynamicPrice color={color} price={closePrice} variation={variation} />
      {featuredAndGoldRushEnabled && downPayment && (
        <StyledText as="label" color={coolGray30}>
          {`(${formatPrice(downPayment)}) down`}
        </StyledText>
      )}
    </Flex>
  )
}
