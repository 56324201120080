import { useState } from 'react'
import { PropertiesProvider } from '../../contexts/PropertiesContext'
import { LocationProvider } from '../../contexts/LocationContext'
import { SavedHomesProvider } from '../../contexts/SavedHomesContext'
import { SearchConsumerLayer } from '../../components/SearchConsumerLayer'
import { Layout } from '../../components/Layout'
import { PropertySearchFiltersProvider } from '../../contexts/PropertySearchFiltersContext'
import {
  getParamObjects,
  getSortsFromParams,
  getPaginationFromParams,
  getFiltersFromParams
} from '../../utils/parseParams'
import { SEVEN_DAYS_AGO } from '../../utils/getDateInPast'
import { LISTING_STATUS_SOLD_FILTER_VALUE } from '../../constants'
import { IPropertySearchProps } from './'
import { ISort } from '../../components/Sort'

import { buildAreaSlugFromFilterValues } from '../../utils/buildAreaSlugFromFilterValues'
import { SavedPropertySearchesProvider } from '../../contexts/SavedPropertySearchesContext'
import { useLocation, useParams } from 'react-router-dom'

declare interface IPageTypeFilter {
  filterKey: string
  filterValue: string
}

export const PropertySearch: React.FC<IPropertySearchProps> = ({ pageType, pageAreaType }) => {
  const { areaSlug, bedroomCount = 0 } = useParams()
  const { search } = useLocation()
  const [confirmAgent, setConfirmAgent] = useState<boolean>(false)

  const searchParams = getParamObjects(search)
  const pageTypeFilterMapping: { [type: string]: IPageTypeFilter[] } = {
    condo: [{ filterKey: 'filter[listing_property_sub_type]', filterValue: 'condo' }],
    sfh: [{ filterKey: 'filter[listing_property_sub_type]', filterValue: 'single_family_home' }],
    townhome: [{ filterKey: 'filter[listing_property_sub_type]', filterValue: 'townhome' }],
    mobile: [{ filterKey: 'filter[listing_property_sub_type]', filterValue: 'mobile_manufacture' }],
    bedroom: [
      { filterKey: 'filter[>=listing_bedrooms_total]', filterValue: bedroomCount },
      { filterKey: 'filter[<=listing_bedrooms_total]', filterValue: bedroomCount }
    ],
    newest: [{ filterKey: 'filter[>=listing_hl_created_at]', filterValue: SEVEN_DAYS_AGO }],
    sold: [
      { filterKey: 'filter[listing_mls_status]', filterValue: LISTING_STATUS_SOLD_FILTER_VALUE }
    ],
    featured: [{ filterKey: 'filter[featured]', filterValue: true }]
  }

  const pageTypeFilters = pageTypeFilterMapping[pageType]
  if (pageTypeFilters) {
    pageTypeFilters.forEach((filter: IPageTypeFilter) => {
      const key = filter['filterKey']
      const value = filter['filterValue']

      searchParams[key] = value
    })
  }

  const sorts = getSortsFromParams(searchParams) as ISort[]
  const initialPage = getPaginationFromParams(searchParams)
  const filters = getFiltersFromParams(searchParams)
  const areaSlugFromParams = buildAreaSlugFromFilterValues(filters)
  const slug = areaSlug || areaSlugFromParams

  return (
    <LocationProvider areaSlug={slug} pageType={pageType} bedroomCount={bedroomCount}>
      <Layout setConfirmAgent={setConfirmAgent}>
        <SavedPropertySearchesProvider>
          <SavedHomesProvider>
            <PropertySearchFiltersProvider
              areaSlug={slug}
              searchParamFilters={filters}
              searchParamSorts={sorts}
            >
              <PropertiesProvider initialPage={initialPage}>
                <SearchConsumerLayer
                  searchParams={searchParams}
                  pageType={pageType}
                  bedroomCount={bedroomCount}
                  pageAreaType={pageAreaType}
                  confirmAgent={confirmAgent}
                  setConfirmAgent={setConfirmAgent}
                />
              </PropertiesProvider>
            </PropertySearchFiltersProvider>
          </SavedHomesProvider>
        </SavedPropertySearchesProvider>
      </Layout>
    </LocationProvider>
  )
}
