import {
  SLUG_FILTER,
  COUNTY_OR_PARISH_FILTER,
  POSTAL_CODE_FILTER,
  STATE_OR_PROVINCE_FILTER,
  STREET_NUMBER_FILTER,
  STREET_NAME_FILTER,
  DIRECTIONAL_PREFIX
} from '../../constants'
import { IFilter } from "../buildRequestFilters"
import { stateCodeOf } from "../stateUtils"

export const getAreaFilters = ({
  context,
  text = '',
  placeType = '',
  address = '',
}): IFilter[] => {
  const formattedText = text.toLowerCase().replace('county', '').trim()
  const state = stateCodeOf(
    context?.find((option: any) => option.id.includes('region'))?.text || ''
  )
  const county = formattedText
  let city
  if (placeType === 'place') {
    city = text.toUpperCase()
  } else {
    city = context?.find((option: any) => option.id.includes('place'))?.text?.toUpperCase() || ''
  }
  const slug = `${city}-${state}`.toLowerCase().replaceAll(' ', '-').replaceAll("'", '-').replace(/st\./g, 'saint')
  const mapboxStreetName = text.toUpperCase()
  const mapboxStreetArr = mapboxStreetName.split(' ')
  const streetNameWithoutPostfix = mapboxStreetArr.slice(0, mapboxStreetArr.length - 1)
  const streetName = streetNameWithoutPostfix.filter(a => !DIRECTIONAL_PREFIX.includes(a.toLowerCase())).join(' ').trim()

  const unsupportedOption = {
    field: '',
    operator: '=',
    value: ''
  }

  const stateFilter =
    state && !['place', 'address', 'region'].includes(placeType)
      ? {
          ...STATE_OR_PROVINCE_FILTER,
          value: state
        }
      : null

  const areaFilterMap = {
    region: {
      ...STATE_OR_PROVINCE_FILTER,
      value: state
    },
    district: {
      ...COUNTY_OR_PARISH_FILTER,
      value: county
    },
    place: {
      ...SLUG_FILTER,
      value: slug
    },
    postcode: {
      ...POSTAL_CODE_FILTER,
      value: text
    },
    address: [
      {
        ...STREET_NUMBER_FILTER,
        value: address
      },
      {
        ...STREET_NAME_FILTER,
        value: streetName
      },
      {
        ...SLUG_FILTER,
        value: slug
      }
    ],
    // TODO: per Taylor this one needs special treatment: https://github.com/homelight/house-hunter/pull/24#discussion_r583246122
    neighborhood: unsupportedOption
  }

  let placeTypeFilter = (areaFilterMap as any)[placeType] || unsupportedOption

  if (Array.isArray(placeTypeFilter)) {
    return [stateFilter, ...placeTypeFilter].filter(f => Boolean(f))
  } else {
    return [stateFilter, placeTypeFilter].filter(f => Boolean(f))
  }
}