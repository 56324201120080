import styled from 'styled-components';
import { COLORS } from '../../../constants';

const { darkBlue } = COLORS;

const Line = styled.span`
  display: block;
  width: 20px;
  height: 2px;
  background-color: ${darkBlue};

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`

const IconWrapper = styled.div`
  &.close-mark {
    transform: rotate(45deg);

    span {
      transition-duration: .2s,.2s;
      transition-property: width,transform,-webkit-transform;
      transition-timing-function: ease-out,ease-out;
    }

    span:nth-child(1) {
      transform: translateY(7px);
    }

    span:nth-child(3) {
      transform: translateY(-7px) rotate(90deg);
    }
  }
`

export const HamburgerIcon = ({ closeMark }: { closeMark: boolean }) => (
  <IconWrapper className={closeMark ? "close-mark" : ""}>
    <Line />
    <Line />
    <Line />
  </IconWrapper>
);
