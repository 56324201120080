import { useContext, useState, Suspense, lazy, useEffect, ReactElement } from 'react'
import styled from 'styled-components'
import { ErrorBoundary } from '@sentry/react'
import { BREAKPOINT_S, BREAKPOINT_M } from '../../constants'
import { useHideOnClickElsewhere } from '../../hooks/useHideOnClickElsewhere'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { PropertiesContext, IPropertiesContext } from '../../contexts/PropertiesContext'
import { IPropertyListing } from '../../contexts/PropertyContext'
import { ImagesProvider } from '../../contexts/ImagesContext'
import { StyledModalBackground, StyledModalContainer } from '../common/Modal'
import { FallbackLoadingSpinner } from '../common/FallbackLoadingSpinner'
import { Pagination } from '../common/Pagination'
import { ConfirmationToast } from '../PropertyUserInteractions/ConfirmationToast'
import { PropertyListThumbnail } from '../PropertyListThumbnail'
import { NoResults } from '../NoResults'
import { PropertiesLoading } from '../PropertiesLoading'
import { IListView } from './index'
import { getParamObjects } from '../../utils/parseParams'
import { trackUserEvent } from '../../utils/trackUserEvent'
import { PropertyListThumbnailFeatured } from '../PropertyListThumbnail/PropertyListThumbnailFeatured'
import { UnlockFreeTrialContainer } from '../MortgageDeals'
import UltraBannerMobile from '../UltraBanner/UltraBannerMobile'

const SharePropertyModal = lazy(
  () => import('../PropertyUserInteractions/SharePropertyModal/SharePropertyModal')
)

const StyledPropertiesList = styled.div`
  margin-top: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;

  @media (min-width: ${BREAKPOINT_M}px) {
    margin-top: '24px';

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 24px;
    gap: 24px;
`

const StyledPropertyWrapper = styled.div`
  @media (min-width: ${BREAKPOINT_S}px) {
    width: calc(50% - 12px);
  }
  &:has(${UnlockFreeTrialContainer}) {
    display: none;
  }
`

export const ListView: React.FC<IListView> = ({
  favoriteProperty,
  NoResultsView = NoResults,
  listRef,
  activeTest,
  setUltraRateModal,
  hasFeatured
}) => {
  const paginationData = useContext(PropertiesContext) as IPropertiesContext
  const {
    properties: { propertiesData, propertiesLoading },
    listingImages,
    totalPropertiesCount
  } = paginationData
  const {
    ref: shareModalRef,
    isVisible: shareModalIsVisible,
    setIsVisible: setShareModalIsVisible
  } = useHideOnClickElsewhere(false)

  const { desktop } = useBreakpoint()

  const [propertiesList, setPropertiesList] = useState<Array<IPropertyListing | ReactElement>>([])
  const [selectedPropertyUuid, setSelectedPropertyUuid] = useState<string | undefined>()
  const [showToast, setShowToast] = useState<boolean>(false)
  const [url, setUrl] = useState('')
  const [address, setAddress] = useState('')
  const [firstListingPageView, setFirstListingPageView] = useState(true)
  const [quizTrackingSent, setQuizTrackingSent] = useState(false)

  const urlParams = getParamObjects(window.location.search)
  const { mobile: isMobile } = useBreakpoint()

  useEffect(() => {
    if (!quizTrackingSent && !propertiesLoading && Object.keys(urlParams).includes('cmm')) {
      setQuizTrackingSent(true)
      trackUserEvent({
        event_action: 'buyer_post_lead_search',
        result: {
          listing_count: totalPropertiesCount
        }
      })
    }
  }, [quizTrackingSent, urlParams, propertiesLoading, totalPropertiesCount])

  useEffect(() => {
    if (hasFeatured && isMobile) {
      const newArray: any[] = [...propertiesData]
      newArray.splice(2, 0, { type: 'element', render: <UltraBannerMobile /> })

      return setPropertiesList(newArray)
    }

    setPropertiesList(propertiesData)
  }, [propertiesData])

  const handleModalBlur = e => {
    if (!e.currentTarget.contains(e.target)) {
      setShareModalIsVisible(false)
    }
  }

  const shareProperty = (url: string, address: string) => {
    setUrl(url)
    setAddress(address)
    setShareModalIsVisible(true)
  }

  const getPropertyImages = (key: string): { urls: string[]; isPreview: boolean } => {
    if (!key || !listingImages[key]) {
      return { urls: [], isPreview: false }
    }
    return listingImages[key]
  }

  const setSelectedProperty = (uuid: string | undefined) => {
    if (!desktop) {
      return
    }

    if (selectedPropertyUuid) {
      const previousMarker = document.getElementsByClassName(selectedPropertyUuid)[0]

      previousMarker?.classList?.remove('hover')
      previousMarker?.classList?.remove('focus')
      previousMarker?.classList?.remove('map-marker-selected')
      previousMarker?.parentElement?.classList?.remove('bring-to-front')
    }

    if (uuid) {
      const marker = document.getElementsByClassName(uuid)[0]

      marker?.classList?.add('hover')
      marker?.classList?.add('focus')
      marker?.classList?.add('map-marker-selected')
      marker?.parentElement?.classList?.add('bring-to-front')
      setSelectedPropertyUuid(uuid)
    }
  }

  return propertiesLoading ? (
    <PropertiesLoading />
  ) : (
    <>
      <StyledPropertiesList className="property-list-view" ref={listRef}>
        {propertiesList.map((property: IPropertyListing | any, index) => {
          if (hasFeatured && property.type === 'element') {
            return property.render
          }

          return (
            <StyledPropertyWrapper key={`${property.hlFullAddress}-${property.propertyUuid}`}>
              <ErrorBoundary>
                <ImagesProvider listingKey={property.listingKey}>
                  {property?.featured ? (
                    <PropertyListThumbnailFeatured
                      property={property}
                      setSelectedPropertyUuid={setSelectedProperty}
                      favoriteProperty={favoriteProperty}
                      shareProperty={shareProperty}
                      images={getPropertyImages(property.listingKey)}
                      firstListingPageView={firstListingPageView}
                      setFirstListingPageView={setFirstListingPageView}
                      activeTest={activeTest}
                      setUltraRateModal={setUltraRateModal}
                    />
                  ) : (
                    <PropertyListThumbnail
                      property={property}
                      setSelectedPropertyUuid={setSelectedProperty}
                      favoriteProperty={favoriteProperty}
                      shareProperty={shareProperty}
                      images={getPropertyImages(property.listingKey)}
                      firstListingPageView={firstListingPageView}
                      setFirstListingPageView={setFirstListingPageView}
                      activeTest={activeTest}
                    />
                  )}
                </ImagesProvider>
              </ErrorBoundary>
            </StyledPropertyWrapper>
          )
        })}
      </StyledPropertiesList>
      {propertiesData.length ? <Pagination paginationData={paginationData} /> : <NoResultsView />}
      {shareModalIsVisible && (
        <StyledModalBackground>
          {/* @ts-ignore */}
          <StyledModalContainer ref={shareModalRef} onBlur={e => handleModalBlur(e)}>
            <Suspense fallback={<FallbackLoadingSpinner />}>
              <SharePropertyModal
                url={url}
                address={address}
                setShowToast={setShowToast}
                closeModal={() => setShareModalIsVisible(false)}
              />
            </Suspense>
          </StyledModalContainer>
        </StyledModalBackground>
      )}
      {showToast && <ConfirmationToast />}
    </>
  )
}
