import styled from 'styled-components'
import { COLORS } from '../../constants'
import { formatNumber } from '../../utils/formatNumber'
import { formatCurrency } from '../../utils/formatCurrency'
import { InfoIcon } from '../icons/InfoIcon'
import { Tooltip } from '../common/Tooltip'

const StyledContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: linear-gradient(
    359deg,
    rgba(255, 255, 255, 0.75) 90.82%,
    rgba(255, 255, 255, 0) 193.49%
  );
  padding: 4px 24px;
`

const HorizontalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const VerticalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const HeaderText = styled.h3`
  color: ${COLORS.darkBlue};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 8px 0;
`

const NumberText = styled.h3`
  color: ${COLORS.electricBlue};
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0;
`

const StyledInfoIcon = styled(InfoIcon)`
  margin-left: -2px;
  margin-bottom: -2px;
`

export const PropertyBanner = ({
  interestRate,
  monthlyPayment
}: {
  interestRate: number | undefined
  monthlyPayment: number | undefined
}) => {
  return (
    <StyledContainer>
      <HorizontalFlex>
        <VerticalFlex>
          <>
            <HeaderText>
              <Tooltip
                text={
                  'Rates are based on a 20% downpayment of the purchase price. You may adjust by clicking on the property'
                }
              >
                HOMELIGHT RATE &nbsp;
                <StyledInfoIcon />
              </Tooltip>
            </HeaderText>
          </>
          <NumberText>{formatNumber(interestRate, false, 2)}%</NumberText>
        </VerticalFlex>
        <VerticalFlex>
          <HeaderText>MONTHLY PAYMENTS</HeaderText>
          <NumberText>{formatCurrency(monthlyPayment)} / mo</NumberText>
        </VerticalFlex>
      </HorizontalFlex>
    </StyledContainer>
  )
}
