export const createCookie = (name: string, value: string, expire_days: number) =>{
    let date: Date
    let expires: string
    if (expire_days) {
        date = new Date()
        date.setDate(Number(date.getDate() + expire_days))
        expires = `;expires=${date.toUTCString()}`
    } else {
        expires = ''
    }
    document.cookie = `${name}=${encodeURIComponent(value)}${expires};domain=homelight.com;path=/`
}
