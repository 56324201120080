import { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Header, StyledModalBackground, StyledModalContainer } from '../common/Modal'
import { PropertyImg } from '../PropertyImg'
import {
  PropertyDetails,
  PropertyDetailsContainer as PropertyDetailsStyle
} from '../PropertyDetails'
import { PropertyDetailsContainer } from '../PropertyListThumbnail/PropertyListThumbnailFeatured'
import { PropertyStatus } from '../PropertyStatus'
import { PropertyPriceFeatured, NumberText } from '../PropertyPrice/PropertyPriceFeatured'
import { NewLeadAgentInfoForm } from './NewLeadAgentInfoForm'
import { Toast } from '../common/Toast'
import { LoadingSpinner } from '../common/LoadingSpinner'
import { InfoIcon } from '../icons/InfoIcon'
import { actionOrange, BREAKPOINT_S, electricBlue, white } from '../../constants'
import { ISavedHomesContext, SavedHomesContext } from '../../contexts/SavedHomesContext'
import { IPropertyListing } from '../../contexts/PropertyContext'
import { UserType } from '../../contexts/UserContext'
import { getRecommendedListings } from './getNewBuyerRecommendedListings'
import { trackUserEvent } from '../../utils/trackUserEvent'
import {
  HOME_RUN_IM_INTERESTED_CLICK,
  HOME_RUN_POST_BUYER_LEAD_MODAL_VIEW
} from '../../constants/events'
import { useSearchParams } from 'react-router-dom'
import { useBreakpoint } from '../../hooks/useBreakpoint'

interface INewBuyerLeadModal {
  onClose: () => void
  currentUser: UserType
  currentSlug?: string
  triggerAgentConfirmation: (uuid: string) => void
}

const NewBuyerLeadWrapper = styled(StyledModalContainer)`
  position: relative;
  height: 100%;
  border-radius: 0;
  @media (min-width: ${BREAKPOINT_S}px) {
    height: unset;
    width: 75vw;
    max-height: 620px;
    max-width: 1048px;
    border-radius: 8px;
  }
`

const ModalBody = styled.div`
  height: 100%;
  padding-top: 56px;
  overflow: auto;
  box-sizing: border-box;
  padding-bottom: 24px;
`

const TopText = styled.div`
  padding: 0 16px;
  @media (min-width: ${BREAKPOINT_S}px) {
    padding: 0 24px;
  }
`

const Title = styled.h2`
  font-size: 24px;
`

const Description = styled.p`
  font-size: 14px;
`

const RecommendedHomes = styled.div`
  margin-left: 16px;
  @media (min-width: ${BREAKPOINT_S}px) {
    margin-left: 24px;
  }
  margin-bottom: 24px;
`

const RecommendedForYou = styled.h3`
  font-size: 20px;
`

const PropertyCarousel = styled.div`
  display: flex;
  height: 325px;
  gap: 16px;
  overflow: auto;
  padding-right: 16px;
  margin-left: -24px;
  padding-left: 24px;
`

const PropertyCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 90%;
  height: 300px;
  margin-bottom: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(108, 129, 171, 0.2);
  transition: opacity 0.25s;
  min-width: 334px;

  @media (min-width: 940px) {
    flex-basis: 44%;
  }

  @media (min-width: 1260px) {
    flex-basis: 30%;
  }

  @media (max-width: calc(${BREAKPOINT_S}px - 1px)) {
    margin-bottom: 25px;
  }

  &.removing {
    opacity: 0;
  }

  ${PropertyDetailsStyle} {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    padding-left: 20px;
    padding-bottom: 12px;
    width: 100%;
    background: linear-gradient(0deg, rgba(18, 18, 18, 0.85) 15%, rgba(18, 18, 18, 0) 90.28%);

    span {
      color: white;
    }

    svg,
    path {
      fill: white;
    }
  }

  ${PropertyDetailsContainer} {
    display: flex;
    flex-direction: row;
    align-items: center;

    .property-price-featured {
      flex: 1;
    }

    ${NumberText} {
      font-size: 16px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .gray-market-rate {
    display: none;
  }
`

const InfoWrapper = styled.div`
  flex: 0 0 16px;
  margin-left: 6px;
  height: 16px;
  cursor: pointer;
`

const StyledInfoModal = styled(StyledModalContainer)`
  @media (max-width: calc(${BREAKPOINT_S}px - 1px)) {
    width: calc(100% - 32px);
    margin: 0 16px;
  }
`

const InfoModalBody = styled.div`
  padding: 0 32px 40px;
`

const PropertyImgContainer = styled.a`
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 204px;
`

const StyledPropertyDetailsContainer = styled(PropertyDetailsContainer)`
  height: 48px;
`

const StyledPropertyImg = styled(PropertyImg)`
  border-radius: 8px 8px 0 0;
`

const InterestedButton = styled.button`
  margin: 0 16px;
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${actionOrange};
  color: ${white};
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: #f06c00;
  }
`

export const NewBuyerLeadModal: React.FC<INewBuyerLeadModal> = ({
  onClose,
  currentUser,
  currentSlug,
  triggerAgentConfirmation
}) => {
  const [routerSearchParams] = useSearchParams()
  const { mobile: isMobile } = useBreakpoint()

  const leadId = routerSearchParams.get('lead_id')
  const sourceForm = routerSearchParams.get('source_form')
  const platform = isMobile ? 'web-mobile' : 'web'

  const { requestedTourUuids, addRequestedTour } = useContext(
    SavedHomesContext
  ) as ISavedHomesContext
  const [properties, setProperties] = useState<IPropertyListing[]>([])
  const [toast, setToast] = useState<string | null>(null)
  const [showRatesInfo, setShowRatesInfo] = useState<boolean>(false)
  const [ratesInfoMls, setRatesInfoMls] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const modalBodyRef = useRef(null)
  const leadLat = currentUser?.currentLeadLat
  const leadLong = currentUser?.currentLeadLong
  const propertiesToShow = properties.filter(property => {
    return !requestedTourUuids.includes(property.propertyUuidFormatted)
  })

  const headerStyles = {
    position: 'absolute',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    zIndex: 2,
    borderRadius: '8px 8px 0 0'
  }

  if (scrolled) {
    // @ts-ignore
    headerStyles.boxShadow = '0px 3px 16px 0px rgba(108, 129, 171, 0.20)'
  }

  useEffect(() => {
    const modalBody = modalBodyRef.current
    if (modalBody) {
      const handleScroll = e => {
        if (!scrolled && e.target.scrollTop > 24) {
          setScrolled(true)
        } else if (scrolled && e.target.scrollTop <= 24) {
          setScrolled(false)
        }
      }

      // @ts-ignore
      modalBody.addEventListener('scroll', handleScroll)
      return () => {
        // @ts-ignore
        modalBody.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrolled, modalBodyRef])

  useEffect(() => {
    const setRecommendedListings = async () => {
      const recommendedListings = await getRecommendedListings(currentSlug, leadLat, leadLong)
      setProperties(recommendedListings)
    }
    setRecommendedListings()
  }, [currentSlug, leadLat, leadLong])

  useEffect(() => {
    trackUserEvent({
      event_action: HOME_RUN_POST_BUYER_LEAD_MODAL_VIEW,
      result: {
        source_form: sourceForm,
        platform
      }
    })

    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    }
  }, [])

  const handleInterestedClick = (uuid: string) => {
    setLoading(true)

    try {
      const cardWrapper = document.querySelector(`#recommended-${uuid}`)
      if (cardWrapper) {
        cardWrapper.classList.add('removing')
      }
      const userParam = `user_id=${currentUser.id}`
      const requestParams = `?${userParam}&property_uuid=${uuid}`

      setTimeout(() => {
        addRequestedTour(requestParams, uuid)
          .then(({ error, data }) => {
            setLoading(false)
            if (error) {
              setToast('error')
              if (cardWrapper) {
                cardWrapper.classList.remove('removing')
              }

              trackUserEvent({
                event_action: HOME_RUN_IM_INTERESTED_CLICK,
                result: {
                  source_form: sourceForm,
                  platform,
                  lead_id: leadId,
                  user_properties_id: 'error'
                }
              })
            } else {
              setToast('success')

              trackUserEvent({
                event_action: HOME_RUN_IM_INTERESTED_CLICK,
                result: {
                  source_form: sourceForm,
                  platform,
                  lead_id: leadId,
                  user_properties_id: data?.data?.id
                }
              })
            }
          })
          .finally(() => {
            setTimeout(() => {
              setToast(null)
            }, 5000)
          })
      }, 300)
    } catch (e) {
      trackUserEvent({
        event_action: HOME_RUN_IM_INTERESTED_CLICK,
        result: {
          source_form: sourceForm,
          platform,
          lead_id: leadId,
          user_properties_id: 'error'
        }
      })
    }
  }

  const handleInfoClick = source => {
    setShowRatesInfo(true)
    setRatesInfoMls(source)
  }

  const buildPropertyCards = () => {
    return propertiesToShow.map(property => (
      <PropertyCardWrapper
        key={property.propertyUuidFormatted}
        id={`recommended-${property.propertyUuidFormatted}`}
      >
        <PropertyImgContainer href={property.propertyPageUrl} className="property-img-container">
          <StyledPropertyImg url={property.imagePreviews[0]} trackStockImage={() => {}} />
          {property.featured && (
            <PropertyStatus status="ULTRA-LOW RATE" featuredAndGoldRushEnabled={true} />
          )}
          <PropertyDetails
            bedroomsTotal={property.formattedBedrooms}
            bedroomsLabel={property.bedroomsLabel}
            bathroomsTotal={property.formattedBathrooms}
            bathroomsLabel={property.bathroomsLabel}
            livingArea={property.formattedLivingArea}
            formattedAddress={property.formattedAddress}
            locked={property.locked}
          />
        </PropertyImgContainer>
        <StyledPropertyDetailsContainer>
          <PropertyPriceFeatured
            closePrice={property.formattedPrice}
            variation="heading4"
            flexStyle={{ direction: 'row' }}
            interestRate={property.mortgageIntRate}
            monthlyPayment={property.monthlyPayment}
            marketMortgageRate={property.marketMortgageRate}
            marketMonthlyMortgagePayment={property.marketMonthlyMortgagePayment}
            featured={property.featured}
          />
          {property.featured && (
            <InfoWrapper onClick={() => handleInfoClick(property.listingHlMls)}>
              <InfoIcon fill={electricBlue} />
            </InfoWrapper>
          )}
        </StyledPropertyDetailsContainer>
        <InterestedButton onClick={() => handleInterestedClick(property.propertyUuidFormatted)}>
          {loading ? <LoadingSpinner width="18px" /> : `I'm interested`}
        </InterestedButton>
      </PropertyCardWrapper>
    ))
  }

  return (
    <StyledModalBackground>
      <NewBuyerLeadWrapper>
        <Header style={headerStyles} closeModal={onClose} hideBorder />
        <ModalBody ref={modalBodyRef}>
          <TopText>
            <Title>Thanks for applying!</Title>
            <Description>
              A Home Consultant from HomeLight will be reaching out to you shortly.
            </Description>
          </TopText>
          <RecommendedHomes>
            <RecommendedForYou>Recommended for you</RecommendedForYou>
            <PropertyCarousel>{buildPropertyCards()}</PropertyCarousel>
          </RecommendedHomes>
          {!!currentUser?.currentLeadHasAgent && <NewLeadAgentInfoForm userId={currentUser?.id} />}
        </ModalBody>
      </NewBuyerLeadWrapper>
      {showRatesInfo && (
        <StyledModalBackground>
          <StyledInfoModal>
            <Header closeModal={() => setShowRatesInfo(false)} hideBorder />
            <InfoModalBody>
              <h3>HomeLight Rate</h3>
              <p>
                All information presented here is for illustrative purposes only and is based on
                assumptions and market data provided by {ratesInfoMls}. The HomeLight Rate reflects
                an estimated blended rate based on assumptions and market data including, among
                others, an assumed down payment amount, the interest rate and amount of the
                assumable loan attached to this property, and market interest rates for second
                mortgage loans.
              </p>
            </InfoModalBody>
          </StyledInfoModal>
        </StyledModalBackground>
      )}
      <Toast show={!!toast} state={toast === 'success' ? 'default' : 'error'}>
        {toast === 'success'
          ? 'We added this property to your request.'
          : 'There was an error when trying to submit your interested property.'}
      </Toast>
    </StyledModalBackground>
  )
}
