import styled from 'styled-components'
import { COLORS } from '../../constants'
import { InfoIcon } from '../icons/InfoIcon'

const Footer = styled.a`
  width: 100%;
  height: 28px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${COLORS.electricBlue};
  cursor: pointer;
  box-sizing: border-box;

  span {
    color: white;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  svg {
    margin-left: 3px;
    height: 15px;
    width: 15px;

    g path {
      fill: white;
    }
  }
`

export const PropertyListThumbnailFooter = ({ onClick }) => {
  return (
    <Footer onClick={onClick}>
      <span>HOMELIGHT RATE</span>
      <InfoIcon />
    </Footer>
  )
}
