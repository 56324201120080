import { toTitleCase } from '../toTitleCase'
import { LISTING_STATUS_ACTIVE_FILTER_VALUE, LISTING_STATUS_SOLD_FILTER_VALUE } from '../../constants'

export const formatStatus = (status: string | null) =>  {
    if (!status || status === LISTING_STATUS_ACTIVE_FILTER_VALUE.toLowerCase()) { return '' }
    if (status === LISTING_STATUS_SOLD_FILTER_VALUE.toLowerCase() ) {
        return 'Sold'
    }
    let statusParts = status.split(',')
    statusParts.forEach((s, index) => statusParts[index] = toTitleCase(s));
    return statusParts.join(', ')
}
