import {
  PRICE_MIN_FILTER,
  PRICE_MAX_FILTER,
  BEDS_MIN_FILTER,
  BEDS_MAX_FILTER,
  BATHS_MIN_FILTER,
  PROPERTY_TYPE_FILTER,
  SQFT_MIN_FILTER,
  SQFT_MAX_FILTER,
  LOT_SIZE_MIN_FILTER,
  LOT_SIZE_MAX_FILTER,
  YEAR_BUILT_MIN_FILTER,
  YEAR_BUILT_MAX_FILTER,
  LISTING_STATUS_FILTER,
  DATE_LISTED_FILTER,
  FEATURED_FILTER
} from '../../../constants'

const PROPERTY_FILTER_OPTIONS = {
  '>=listing_list_price': PRICE_MIN_FILTER.label,
  '<=listing_list_price': PRICE_MAX_FILTER.label,
  '>=listing_bedrooms_total': BEDS_MIN_FILTER.label,
  '<=listing_bedrooms_total': BEDS_MAX_FILTER.label,
  '>=listing_bathrooms_full': BATHS_MIN_FILTER.label,
  listing_property_sub_type: PROPERTY_TYPE_FILTER.label,
  '>=living_area': SQFT_MIN_FILTER.label,
  '<=living_area': SQFT_MAX_FILTER.label,
  '>=lot_size_sqft': LOT_SIZE_MIN_FILTER.label,
  '<=lot_size_sqft': LOT_SIZE_MAX_FILTER.label,
  '>=year_built': YEAR_BUILT_MIN_FILTER.label,
  '<=year_built': YEAR_BUILT_MAX_FILTER.label,
  listing_mls_status: LISTING_STATUS_FILTER.label,
  '>=listing_hl_created_at': DATE_LISTED_FILTER.label,
  featured: "featured"
}

export const getFiltersFromParams = (searchParams: {}) => {
  return Object.keys(searchParams).map(key => {
    let filter = {
      field: '',
      operator: '',
      value: '',
      label: ''
    }
    if (key.startsWith('filter')) {
      const formattedKey = key.replace('filter[', '').replace(']', '')
      filter.label =  (PROPERTY_FILTER_OPTIONS as any)[formattedKey] || ''
      filter.field = formattedKey.replace(/>=|<=|=/, '')
      const op = formattedKey.match(/>=|<=|=/)
      filter.operator = op ? op[0] : '='
      filter.value = (searchParams as any)[key]
    }
    return filter
  }).filter(f => Boolean(f.field.length))
}
