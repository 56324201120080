import { IGetDateInPast } from './'

export const getDateInPast = ({
  startDate = new Date(), daysAgo = 0
} : IGetDateInPast) : Date  => {
  const pastDate = startDate.getDate() - daysAgo

  startDate.setDate(pastDate)

  return startDate
}

const buildDateString = (days: number) => {
  return getDateInPast({startDate: new Date(), daysAgo: days}).toISOString()
}

export const SEVEN_DAYS_AGO = buildDateString(7)
export const ONE_MONTH_AGO = buildDateString(30)
export const TWO_MONTHS_AGO = buildDateString(60)
export const THREE_MONTHS_AGO = buildDateString(90)
export const FOUR_MONTHS_AGO = buildDateString(120)
