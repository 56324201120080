import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { white, borderRadius6, BREAKPOINT_S, viridianGreen } from '../../../constants'
import { Flex } from '../Flex'
import { TextSmall } from '../Text'
import { IToast } from './types'
import { XCircleIcon } from '../../icons/XCircleIcon'
import { CheckCircleIcon } from '../../icons/CheckCircleIcon'

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 14px;
`

const ToastContainer = styled.div`
  position: fixed;
  bottom: 16px;
  z-index: 200;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: ${BREAKPOINT_S}) {
    bottom: 63px;
  }
`

const icons = {
  error: <XCircleIcon />,
  default: <CheckCircleIcon bgFill={viridianGreen} />
}

const ToastMessage = styled(TextSmall)`
  line-height: 18px;
  color: ${white};
  
  div, a, span {
    color: ${white};
  }
`

export const Toast: React.FC<IToast> = ({
  show=false,
  maxTimeout=5000,
  useIcon=true,
  state='default',
  children
}) => {
  const [showToast, setShowToast] = useState(false)
  const handler = useRef(null)
  const stateIcon = state

  useEffect(() => {
    if (show) {
      // @ts-ignore
      clearTimeout(handler)
      setShowToast(true)
      // @ts-ignore
      handler.current = window.setTimeout(() => {
        setShowToast(false)
      }, maxTimeout)
    }
  }, [handler, maxTimeout, show])

  return (
    <ToastContainer>
      {showToast && (
        <Flex
          width="352px"
          justify="start"
          align="start"
          pt="20px"
          pr="20px"
          pl="20px"
          pb="20px"
          borderRadius={`${borderRadius6}`}
          backgroundColor='#222222'
          shadow
          data-testid="toast"
        >
          {useIcon && <IconContainer>{icons[stateIcon]}</IconContainer>}
          <ToastMessage>{children}</ToastMessage>
        </Flex>
      )}
    </ToastContainer>
  )
}
