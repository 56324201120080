export const formatValueRange = (min: number | string | null, max: number | string | null) => {
  let separator = ''
  const hasMin = Boolean(min)
	const hasMax = Boolean(max)

  if (hasMin && hasMax) {
	  separator = '-'
  } else if (hasMin) {
	  separator = '+'
  } else if (hasMax) {
	  separator = 'Up to '
  }

  return `${min || ''}${separator}${max || ''}`
}
