import styled from 'styled-components'
import { PrimaryButton } from './'
import { cadmiumRed, alertRedHover, coolGray50, white } from '../../../constants'

export const CadmiumRedButton = styled(PrimaryButton)<{
  width: string
}>`
  justify-content: center;  
  width: ${props => props.width ? props.width : 'initial'};
  font-weight: 700;
  background-color: ${cadmiumRed};
  color: ${white};

  &:hover {
    background-color: ${alertRedHover}
  }
  &:focus-visible {
    border-color: ${alertRedHover};
  }
  &:focus {
    border-color: ${alertRedHover};
    outline: none;
  }
  &:disabled {
    background-color: ${coolGray50};
  }
`
