export const PROPERTY_TYPE_OPTIONS = [
  {
    label: 'Single family home',
    value: 'single_family_home',
    selected: false
  },
  {
    label: 'Condo',
    value: 'condo',
    selected: false
  },
  {
    label: 'Townhome',
    value: 'townhome',
    selected: false
  },
  {
    label: 'Mobile home',
    value: 'mobile_manufactured',
    selected: false
  }
]

export const PERMITTED_PROPERTY_TYPES = PROPERTY_TYPE_OPTIONS.map(option => option.value)
