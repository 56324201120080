import styled from 'styled-components'
import { coolGray30 } from "../../../../constants";

export const TransparentInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  box-shadow: none;

  &:focus-visible {
    outline: none;
  }

  &:focus {
    outline: none;
  }
  
  ::placeholder {
    font-size: 16px;
    color: ${coolGray30};
  }
`
