import { useLayoutEffect, useState } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export const useSize = (target: any): DOMRect => {
  const [size, setSize] = useState<DOMRect>({} as DOMRect)

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  // @ts-ignore
  useResizeObserver(target, (entry) => setSize(entry.contentRect))

  return size
}
