import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SparklesIcon } from '../icons/SparklesIcon'
import { XIcon } from '../icons/XIcon'
import { BREAKPOINT_S } from '../../constants'

type UltraRateToastProps = {
  show: boolean
  maxTimeout?: number
  onClose?: () => void
  totalFeatured: number
}

export default function UltraRateToast({
  show,
  maxTimeout = 15000,
  onClose,
  totalFeatured
}: UltraRateToastProps) {
  const [_show, setShow] = useState(show)

  useEffect(() => {
    setTimeout(() => {
      handleOnClose()
    }, maxTimeout)
  }, [])

  function handleOnClose() {
    setShow(false)
    onClose?.()
  }

  if (!_show) {
    return null
  }

  return (
    <Container>
      <SparklesIcon width={30} height={30} />
      <Text>
        Your search has {totalFeatured ? `${totalFeatured}+` : ''} ultra-low rate properties!
      </Text>
      <CloseIconContainer onClick={handleOnClose}>
        <XIcon color="white" />
      </CloseIconContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 24px;
  right: 16px;
  background-color: rgba(39, 54, 83, 0.85);
  padding: 16px;
  border-radius: 12px;
  max-width: 358px;

  @media (max-width: ${BREAKPOINT_S}px) {
    z-index: 4;
    right: 0;
    margin: 0 16px;
  }
`

const Text = styled.p`
  margin: 0 12px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
`

const CloseIconContainer = styled.div`
  cursor: pointer;
`
