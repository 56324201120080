import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import {
  HAPI_AUTH_URL,
  HAPI_LOGOUT_URL,
  FIND_OR_CREATE_VISITOR_URL,
  CREATE_OR_UPDATE_SESSION_URL,
  TRACK_SESSION_TEST_URL
} from '../../constants'
import { createCookie, readCookie } from '../../utils/cookies'
import { requestData } from '../../utils/requestData'
import { UserType, IExperiments } from './'
import { ACTIVE_EXPERIMENTS } from '../../constants/activeExperiments'
import { fetchAbTest } from '../../utils/fetchAbTests/fetchAbTest'
import flagsmith from 'flagsmith'

export const UserContext = createContext({})

const LOG_EXPERIMENT_NAMES = ['home-search-tile-redesign']

export const UserProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<UserType | null>(null)
  const [currentUserLoading, setCurrentUserLoading] = useState(false)
  const [currentUserError, setCurrentUserError] = useState(false)
  const [visitorId, setVisitorId] = useState<number | null>(null)
  const [sessionId, setSessionId] = useState<number | null>(null)
  const [experiments, setExperiments] = useState<IExperiments>({})

  const findOrCreateVisitor = () => {
    setCurrentUserLoading(true)
    return requestData({
      url: FIND_OR_CREATE_VISITOR_URL,
      method: 'get',
      includeBearerToken: true,
      ignoreJsonFormat: true
    })
  }

  const createOrUpdateSession = () => {
    if (!visitorId) {
      return new Promise(() => {})
    }

    const body = {
      visitor_id: visitorId.toString(),
      entry_path: readCookie('entry_path'),
      referrer: readCookie('original_referrer'),
      utm_campaign: readCookie('original_utm_campaign'),
      utm_content: readCookie('original_utm_content'),
      utm_medium: readCookie('original_utm_medium'),
      utm_source: readCookie('original_utm_source'),
      utm_term: readCookie('original_utm_term')
    }

    return requestData({
      url: CREATE_OR_UPDATE_SESSION_URL,
      method: 'post',
      body: body,
      includeBearerToken: true,
      ignoreJsonFormat: true
    })
  }

  const trackSessionTest = (name, value) => {
    if (!sessionId) {
      return
    }

    const body = {
      session_id: sessionId.toString(),
      name: name,
      value: value
    }

    return requestData({
      url: TRACK_SESSION_TEST_URL,
      method: 'post',
      body: body,
      includeBearerToken: true,
      ignoreJsonFormat: true
    })
  }

  const getCurrentUser = () => {
    setCurrentUserLoading(true)
    return requestData({
      url: HAPI_AUTH_URL,
      method: 'get',
      includeBearerToken: false,
      fields: [
        {
          user: [
            'email',
            'first_name',
            'id',
            'impersonated',
            'last_name',
            'type',
            'gold_rush_subscribed',
            'phone',
            'shared_subscription',
            'provider_name',
            'brokerage_name',
            'provider_image',
            'provider_id',
            'current_lead_id',
            'current_lead_lat',
            'current_lead_long',
            'current_lead_has_agent',
            'show_call_me_maybe',
            'in_contract'
          ]
        }
      ]
    })
  }

  const logoutCurrentUser = () => {
    requestData({
      url: HAPI_LOGOUT_URL,
      method: 'post',
      includeBearerToken: false
    }).then(() => {
      setCurrentUser(null)
      window.location.replace('/')
    })
  }

  const fetchUserData = useCallback(() => {
    Promise.all([findOrCreateVisitor(), getCurrentUser()]).then(
      ([{ data: visitorData }, { data: userData, error }]) => {
        const user = userData?.firstName ? userData : null
        unstable_batchedUpdates(() => {
          setCurrentUser(user)
          setVisitorId(visitorData?.visitor_id)
          flagsmith.identify(`visitor_${visitorData?.visitor_id}`)
          setCurrentUserError(error)
          setCurrentUserLoading(false)
        })
      }
    )
  }, [])

  useEffect(() => {
    fetchUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    createOrUpdateSession().then(({ data: sessionData }) => {
      if (sessionData?.session_id) {
        createCookie(
          `_hl_session_${process.env.REACT_APP_NODE_ENV}`,
          `${sessionData.session_id}`,
          1
        )
        setSessionId(sessionData.session_id)
        // @ts-ignore
        window.SESSION_ID = sessionData.session_id.toString()
      }
    })
  }, [visitorId])

  useEffect(() => {
    if (visitorId) {
      let experimentData = {}
      Promise.all(
        ACTIVE_EXPERIMENTS.map(testName => {
          return fetchAbTest(visitorId, testName).then(variant => {
            if (variant) {
              experimentData = { ...experimentData, [testName]: variant }
            }
          })
        })
      ).then(() => {
        setExperiments(experimentData)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorId, setExperiments])

  useEffect(() => {
    LOG_EXPERIMENT_NAMES.forEach(experimentName => {
      if (flagsmith.flags?.[experimentName]?.enabled) {
        const experimentValue = flagsmith.flags[experimentName].value
        trackSessionTest(experimentName, experimentValue)
      }
    })
  }, [sessionId, JSON.stringify(flagsmith.flags)])

  return (
    <UserContext.Provider
      value={{
        user: {
          currentUser,
          currentUserLoading,
          currentUserError,
          fetchUserData,
          logoutCurrentUser,
          visitorId,
          sessionId
        },
        experiments
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUser must be used within <UserProvider>')
  }

  return context
}
