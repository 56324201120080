import { pluralize } from '../pluralize'

export const formatPropertiesCount = (propertiesCount: number | 0) => {
  const homes = pluralize('home', propertiesCount)

  if (!propertiesCount || propertiesCount === 0) {
    return `0 ${homes}`
  } else if (propertiesCount === 1) {
    return `1 ${homes}`
  } else if (propertiesCount < 100) {
    return `${propertiesCount.toLocaleString()} ${homes}`
  } else if (propertiesCount < 1000) {
    return `${(Math.floor(propertiesCount/100)*100).toLocaleString()}+ ${homes}`
  } else if (propertiesCount < 10000) {
    return `${(Math.floor(propertiesCount/1000)*1000).toLocaleString()}+ ${homes}`
  } else if (propertiesCount < 100000) {
    // unlikely but maybe
    return `${(Math.floor(propertiesCount/10000)*10000).toLocaleString()}+ ${homes}`
  } else {
    // unlikely but maybe
    return `100,000+ ${homes}`
  }
}
