import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SparklesIcon } from '../icons/SparklesIcon'
import UltraBannerModal from './UltraBannerModal'
import { Description, Title } from './styles'
import { ACTION_TAP, ACTION_VIEW, trackEvent } from '../../utils/tracking'
import { ULTRA_BANNER_CLICK, ULTRA_BANNER_SHOW } from '../../constants/events'
import { XIcon } from '../icons/XIcon'

export default function UltraBanner() {
  const [showModal, setShowModal] = useState(false)
  const [show, setShow] = useState(true)

  useEffect(() => {
    trackEvent(ACTION_VIEW, ULTRA_BANNER_SHOW)
  }, [])

  function handleClickLearnMore() {
    setShowModal(true)

    trackEvent(ACTION_TAP, ULTRA_BANNER_CLICK)
  }

  if (!show) {
    return false
  }

  return (
    <Container>
      <XIcon className="icon-close" onClick={() => setShow(false)} />
      <Title>
        Rates as low as 3% are back
        <IconContainer>
          <SparklesIcon width={30} height={30}></SparklesIcon>
        </IconContainer>
      </Title>
      <Description>
        With HomeLight Ultra, you assume the current low-rate loan on our listings, so you can buy
        with peace of mind and save thousands over the lifetime of your loan.{' '}
        <LearnMore onClick={handleClickLearnMore}>Learn more</LearnMore>.
      </Description>
      {showModal && <UltraBannerModal onClose={setShowModal} />}
    </Container>
  )
}

const Container = styled.div`
  padding: 24px 32px;
  background-color: #f5f6f9;
  display: flex;
  flex-direction: column;
  width: calc(100% + 64px);
  margin-bottom: 24px;
  margin-left: -32px;
  box-sizing: border-box;
  position: relative;

  .icon-close {
    position: absolute;
    right: 32px;
    top: 40px;
    cursor: pointer;
  }
`

const IconContainer = styled.span`
  margin-left: 24px;

  svg {
    transform: translateY(7px);
  }
`

const LearnMore = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
