export const SQFT_OPTIONS = [
  { label: '500', value: 500 },
  { label: '1,000', value: 1000 },
  { label: '1,500', value: 1500 },
  { label: '2,000', value: 2000 },
  { label: '2,500', value: 2500 },
  { label: '3,000', value: 3000 },
  { label: '3,500', value: 3500 },
  { label: '4,000', value: 4000 },
  { label: '4,500', value: 4500 },
  { label: '5,000', value: 5000 },
  { label: '5,500', value: 5500 },
  { label: '6,000', value: 6000 },
  { label: '6,500', value: 6500 },
  { label: '7,000', value: 7000 },
  { label: '7,500', value: 7500 }
]
