import { useBreakpoint } from '../../hooks/useBreakpoint'
import { Flex } from '../common/Flex'
import { PropertyLoadingSilhouette } from './index'

export const PropertiesLoading = () => {
  const { mobile } = useBreakpoint()

  return (
    <Flex
      direction='column'
    >
      <PropertyLoadingSilhouette mobile={mobile} />
      <PropertyLoadingSilhouette mobile={mobile} />
      <PropertyLoadingSilhouette mobile={mobile} />
    </Flex>
  )
}