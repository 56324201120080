import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { HomeLightIcon } from '../../icons/HomeLightIcon'
import { IUserContext, IUserTypes, UserContext } from '../../../contexts/UserContext'
import { SearchBar } from '../../SearchBar'
import { AGENT_NAV_OPTIONS, USER_NAV_OPTIONS_MAP, NAV_OPTIONS } from '../../../constants'
import { INavOptions, UserLogin } from './UserLogin'
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import { MobileNavigation } from './MobileNavigation'
import { trackEvent } from '../../../utils/tracking'

export const getAgentNavOptions = (userType: IUserTypes): INavOptions[] | null => {
  return userType && userType === 'agent' ? AGENT_NAV_OPTIONS : null
}

export const getUserNavOptions = (userType: IUserTypes): INavOptions[] => {
  return userType ? USER_NAV_OPTIONS_MAP[userType] : []
}

export default function ApplicationNavigationSharedSub() {
  const {
    user: { currentUser, logoutCurrentUser }
  } = useContext(UserContext) as IUserContext
  const { mobile } = useBreakpoint()
  const signoutOption = currentUser
    ? {
        label: 'Sign out',
        action: logoutCurrentUser
      }
    : (null as INavOptions | null)

  const agentOptions = getAgentNavOptions(currentUser?.type)
  const userNavOptions = getUserNavOptions(currentUser?.type)
  const userOptions = userNavOptions.filter(o => Boolean(o)) as INavOptions[]
  const mobileNavOptions = [...NAV_OPTIONS, signoutOption].filter(o => Boolean(o)) as INavOptions[]

  useEffect(() => {
    trackEvent('View', 'shared_sub_nav_shown', currentUser?.providerId)
  }, [])

  return (
    <Container mobile={mobile}>
      {mobile ? (
        <>
          <div>
            {Boolean(currentUser?.providerImage) ? (
              <ProfilePicture src={currentUser?.providerImage} alt="" />
            ) : (
              <PicturePlaceholder>{currentUser?.providerName?.[0]}</PicturePlaceholder>
            )}
            <MobileAgentText>My Agent</MobileAgentText>
          </div>
          <SearchBar
            navigateOnSearch={true}
            mobileFilterPopoverOpen={true}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          />
          <MobileNavigation currentUser={currentUser} navOptions={mobileNavOptions} />
        </>
      ) : (
        <>
          <AgentContainer>
            <HomeLightIcon />
            <VerticalLine />
            {Boolean(currentUser?.providerImage) ? (
              <ProfilePicture src={currentUser?.providerImage} alt="" />
            ) : (
              <PicturePlaceholder>{currentUser?.providerName?.[0]}</PicturePlaceholder>
            )}
            <AgentInfo>
              <AgentName>{currentUser?.providerName}</AgentName>
              <AgentBrokerage>{currentUser?.brokerageName}</AgentBrokerage>
            </AgentInfo>
          </AgentContainer>
          <SearchBar
            navigateOnSearch={true}
            mobileFilterPopoverOpen={true}
            style={{ paddingTop: 0 }}
          />
          <UserLogin
            userOptions={userOptions}
            userFirstName={currentUser?.firstName || ''}
            displayFirstName={true}
            agentOptions={agentOptions}
            currentUser={currentUser}
          />
        </>
      )}
    </Container>
  )
}

const Container = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f2f9fe;
  height: ${props => (props.mobile ? '59px' : '73px')};
  padding: ${props => (props.mobile ? '12px 16px' : '12px 32px')};
  z-index: 5;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

const AgentContainer = styled.div`
  display: flex;
  align-items: center;
`

const VerticalLine = styled.div`
  height: 42px;
  border-right: 1px solid #8e929c;
  margin: 0 24px;
`
const ProfilePicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  object-fit: cover;
  margin-right: 8px;
`

const PicturePlaceholder = styled.span`
  overflow: hidden;
  align-items: center;
  justify-content: center;
  user-select: none;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 8px;
  display: flex;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  background-color: rgb(39, 54, 83);
`

const AgentInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const AgentName = styled.span`
  color: #273653;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
`

const AgentBrokerage = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
`

const MobileAgentText = styled.p`
  font-size: 8px;
  font-weight: 700;
  margin-top: 4px;
  margin-bottom: 0px;
`
