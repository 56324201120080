import { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { BREAKPOINT_S, fontSizeSm } from '../../constants'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { ACTION_ENTRY, ACTION_TAP, trackEvent, ACTION_VIEW } from '../../utils/tracking'
import { IImagesContext, ImagesContext } from '../../contexts/ImagesContext'
import { ImageCarousel } from '../PropertyImg'
import { PropertyPrice } from '../PropertyPrice'
import { PropertyDetails } from '../PropertyDetails'
import { PropertyStatus } from '../PropertyStatus'
import { PropertyUserInteractions } from '../PropertyUserInteractions'
import { PropertyListThumbnailDataTypes } from './types'
import flagsmith from 'flagsmith'
import { PropertyBanner } from '../PropertyBanner'

const PropertyThumbnailWrapper = styled.div`
  position: relative;
`

export const PropertyThumbnailContainer = styled.a`
  flex-direction: column;
  padding: 0;
  display: flex;
  position: relative;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  height: 245px;
  border: 1px solid rgb(0, 0, 0, 0.1);
  height: 300px;
  margin-bottom: 0;

  @media (max-width: ${BREAKPOINT_S}px) {
    margin-bottom: 25px;
    box-shadow: 0px 1px 5px rgba(29, 71, 113, 0.12), 0px 5px 10px rgba(29, 71, 123, 0.04);
  }

  &:hover {
    .property-image-overlay:after {
      opacity: 1;
      width: 100%;
    }

    .slideshow-arrow-wrapper {
      @media (min-width: ${BREAKPOINT_S}px) {
        opacity: 1;
      }
    }
  }
`

const PropertyImgContainer = styled.div`
  position: relative;
  margin-bottom: 12px;
`

const PropertyDetailsContainer = styled.div`
  width: calc(100% - 32px);
  margin: 0;
  margin-bottom: 25px;
  margin-left: 16px;
  position: relative;
  z-index: 1;
  bottom: 6px;
  display: flex;
  flex-direction: column;
`

const StyledImageCarousel = styled(ImageCarousel)`
  position: relative;
  min-height: 200px;
  min-width: unset;

  @media (min-width: ${BREAKPOINT_S}px) {
    width: 100%;
  }

  .property-image-overlay {
    height: 100%;
  }
`

const FullAddress = styled.p`
  margin: 0;
  font-size: ${fontSizeSm}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PropertyListThumbnail: React.FC<PropertyListThumbnailDataTypes> = ({
  property,
  setSelectedPropertyUuid,
  images,
  favoriteProperty,
  shareProperty,
  firstListingPageView,
  setFirstListingPageView,
  activeTest,
}) => {
  const {
    images: listingImages,
    imagesLoading,
    getImages
  } = useContext(ImagesContext) as IImagesContext

  const { mobile } = useBreakpoint()
  const [hasStockImage, setHasStockImage] = useState(false)
  const streetAddressVariation = mobile ? 'textSmall' : 'heading4'
  const [usingPreviewImages, setUsingPreviewImages] = useState(true)
  const firstViewTests = ['saved_search_modal', 'first_listing_page_view']
  const [url, setUrl] = useState(property.propertyPageUrl)
  const containerRef = useRef()
  const [resultHasBeenTracked, setResultHasBeenTracked] = useState(false)
  const stateRef = useRef(resultHasBeenTracked)
  const setTrackedResults = data => {
    stateRef.current = data
    setResultHasBeenTracked(data)
  }
  const featuredAndGoldRushEnabled = property?.featured
  const status = featuredAndGoldRushEnabled ? 'ULTRA-LOW RATE' : property?.propertyStatus
  const sendFavoriteProperty = (state: boolean) => {
    favoriteProperty(state, property)
  }
  const fullAddress = `${property.hlFullAddress}, ${property.formattedCityStateZip}`;

  const getListingImages = () => {
    getImages(property.listingKey)
  }

  useEffect(() => {
    setUsingPreviewImages(imagesLoading)
  }, [imagesLoading])

  useEffect(() => {
    if (hasStockImage) {
      trackEvent(ACTION_ENTRY, 'stock_image_shown', 0, true)
    }
  }, [hasStockImage])

  const trackOnClick = () => {
    trackEvent(ACTION_TAP, 'listing_viewed')

    if (firstListingPageView) {
      setFirstListingPageView(false)
    }
  }

  useEffect(() => {
    const newUrl =
      firstListingPageView && firstViewTests.includes(activeTest)
        ? `${property.propertyPageUrl}?first_listing_page_view=true`
        : property.propertyPageUrl

    setUrl(newUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstListingPageView, activeTest])

  const isInViewport = (element: HTMLElement) => {
    const elem = element?.current

    if (elem) {
      const rect = elem.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }
  }

  const trackVisibleProperties = () => {
    if (!stateRef.current && isInViewport(containerRef)) {
      setTrackedResults(true)

      if (flagsmith.hasFeature('home-search-tile-redesign')) {
        trackEvent(
          ACTION_VIEW,
          'results_in_page_view',
          0,
          true,
          'search_tile_redesign',
          flagsmith.getValue('home-search-tile-redesign')
        )
      } else {
        trackEvent(ACTION_VIEW, 'results_in_page_view', 0, true)
      }
    }
  }

  useEffect(() => {
    trackVisibleProperties()
    window.addEventListener('wheel', () => trackVisibleProperties())

    return () => window.removeEventListener('wheel', trackVisibleProperties)
  }, [])

  return (
    <PropertyThumbnailWrapper
      className="property-wrapper"
      ref={containerRef}
    >
      <PropertyThumbnailContainer
        target="_blank"
        href={url}
        data-testid={`property-thumbnail-container-${property.propertyUuid}`}
        onMouseEnter={() => setSelectedPropertyUuid(property.propertyUuid)}
        onMouseLeave={() => setSelectedPropertyUuid(undefined)}
        onClick={trackOnClick}
      >
        <PropertyImgContainer className="property-img-container">
          <StyledImageCarousel
            className="styled-image-carousel"
            images={images.urls}
            isPreview={usingPreviewImages}
            listingImages={listingImages}
            trackStockImage={() => setHasStockImage(true)}
            trackSlideChange={() => trackEvent(ACTION_TAP, 'list_thumbnail_viewed')}
            jsxKey={`list-thumb-${property.propertyUuid}`}
            getListingImages={getListingImages}
            listingContractDate={property.listingContractDate}
          />
          <PropertyStatus status={status} featuredAndGoldRushEnabled={featuredAndGoldRushEnabled} />
          {featuredAndGoldRushEnabled && (
            <PropertyBanner
              interestRate={property?.mortgageIntRate}
              monthlyPayment={property?.monthlyPayment}
            />
          )}
        </PropertyImgContainer>
        <PropertyDetailsContainer>
          <PropertyPrice
            closePrice={property.formattedPrice}
            downPayment={property.downPayment}
            featuredAndGoldRushEnabled={featuredAndGoldRushEnabled}
            variation="heading4"
            flexStyle={{ mt: '8px', direction: 'row' }}
          />
          <PropertyDetails
            bedroomsTotal={property.formattedBedrooms}
            bedroomsLabel={property.bedroomsLabel}
            bathroomsTotal={property.formattedBathrooms}
            bathroomsLabel={property.bathroomsLabel}
            livingArea={property.formattedLivingArea}
          />
          <FullAddress title={fullAddress}>{fullAddress}</FullAddress>
        </PropertyDetailsContainer>
      </PropertyThumbnailContainer>
      <PropertyUserInteractions
        url={property.propertyPageUrl}
        address={property.hlFullAddress}
        styles={{ topValue: '16px', rightValue: '16px' }}
        propertyIsFavorited={property.isFavorited}
        favoriteProperty={sendFavoriteProperty}
        shareProperty={shareProperty}
      />
    </PropertyThumbnailWrapper>
  )
}
