import { PRICE_OPTIONS } from '../../constants'

export const roundToIncrement = (num: number, increments: number[], roundTo: string = 'up') => {
  for (let i = 0; i < increments.length; i++) {
    const current = increments[i]
    const previous = increments[i - 1]

    if (num === current) {
      return num
    }

    if (num < current) {
      if (roundTo === 'down') {
        return previous === undefined ? current : previous
      }
      if (roundTo === 'up') {
        return current
      }
    }
  }

  return increments[increments.length - 1]
}

export const roundToPriceIncrement = (price: number, roundTo: string = 'up') => {
  return roundToIncrement(price, PRICE_OPTIONS.map(p => p.value), roundTo)
}
