export const findObjectIndicesByKey = (array: any[], key: string, value: string | number) => {
  const foundIndices: number[] = []

  if (!key || !value) {
    return foundIndices
  }

  (array || []).forEach((item, index) => {
    if (array[index][key] === value) {
      foundIndices.push(index)
    }
  })

  return foundIndices
}
