import styled from 'styled-components'
import { PrimaryButton } from '../common/Button'
import { actionOrange, BREAKPOINT_M, white } from '../../constants'

export const ScheduleTourButton = styled(PrimaryButton)`
  background-color: ${actionOrange};
  border: none;
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${white};
  font-size: 16px;
  font-weight: 700;
  padding: 16px 24px;
  cursor: pointer;
  
  &:hover {
    background-color: ${actionOrange};
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    font-size: 24px;
    width: unset;
  }
`
