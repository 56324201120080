import { createContext, useEffect, useState } from 'react'
import { ISort } from '../../components/Sort'
import { PROPERTIES_API_URL } from '../../constants'
import { getPageNumberFromParam } from '../../pages/HtmlSitemap/HtmlSitemap'
import { IFilter } from '../../utils/buildRequestFilters'
import { parseCountySlug } from '../../utils/parseAreaSlug'
import { requestData } from '../../utils/requestData'
import { buildPropertyInterface } from '../../utils/buildPropertyInterface'
import { buildPageNavigation, getCurrentPage, IPageNumbers } from '../../utils/buildPageNavigation'
import { stateCodeFromSlug } from '../../utils/stateUtils'
import {
  IPageState,
  IPropertiesContext,
  IPropertiesProvider,
  IPropertiesState,
  PropertyType
} from '../PropertiesContext'
import { shouldSortBySavedAt, sortBySavedAt } from '../SavedHomesContext'
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom'
import { IField } from '../../utils/buildRequestFields'

declare interface IPropertiesSitemapProvider extends IPropertiesProvider {
  areaSlug: string
  history: {}
  pageType: string | null
}

const initialPropertiesState: IPropertiesState = { data: [], loading: true, error: false, count: 0 }
const initialPageNumbers: IPageNumbers = {
  first: 1,
  next: undefined,
  last: undefined,
  prev: undefined
}

export const PropertiesSitemapContext = createContext<IPropertiesContext | null>(null)

export const PropertiesSitemapProvider: React.FC<IPropertiesSitemapProvider> = ({
  initialPage,
  children,
  areaSlug,
  history,
  pageType
}) => {
  const location = useLocation()
  const action = useNavigationType()
  const navigate = useNavigate()

  const [propertiesState, setPropertiesState] = useState<IPropertiesState>(initialPropertiesState)
  const [pageState, setPageState] = useState<IPageState>({
    pageNumbers: initialPageNumbers,
    currentPage: initialPage
  })
  const pageSize = 1000
  const totalPages = 10
  let stateCode: string = ''
  let pageAreaDisplayName: string = ''

  if (pageType === 'county') {
    stateCode = stateCodeFromSlug(areaSlug)
    pageAreaDisplayName = `${parseCountySlug(areaSlug).county} County`
  } else {
    stateCode = areaSlug.toUpperCase()
  }

  const getProperties = () => {
    const propertyFields: IField[] = [
      {
        reso_property: [
          'uuid',
          'uuid_latest',
          'postal_code',
          'display_address',
          'city',
          'county_or_parish',
          'state_or_province',
          'postal_code',
          'hl_full_address',
          'uuid_formatted'
        ]
      }
    ]

    const filters: IFilter[] = [
      {
        field: 'state_or_province',
        value: stateCode,
        operator: '='
      },
      {
        field: 'listing_mls_status',
        value: 'Active',
        operator: '='
      },
      {
        field: '_listing_link',
        value: true,
        operator: '='
      },
      {
        field: '_url_eligible',
        value: true,
        operator: '='
      },
      {
        field: 'listing_property_sub_type',
        value: 'single_family_home,condo,townhome,mobile_manufactured',
        operator: '='
      }
    ]
    if (pageType === 'county') {
      filters.push({
        field: 'county_or_parish',
        value: pageAreaDisplayName,
        operator: '='
      })
    }
    const sorts: ISort[] = [
      {
        field: 'listing_hl_created_at',
        operator: 'desc'
      }
    ]
    setPropertiesState({ ...propertiesState, loading: true })
    requestData({
      url: PROPERTIES_API_URL,
      method: 'get',
      filters: filters,
      fields: propertyFields,
      sorts: sorts,
      size: pageSize,
      page: pageState.currentPage,
      usePagination: true
    }).then(({ data, meta, links, error }) => {
      if (!error) {
        const dataAsArray = Array.isArray(data) ? data : [data]
        const pages = links ? buildPageNavigation(links, totalPages) : initialPageNumbers
        const propertiesCount = meta?.total_properties || 0
        let propertyListings = dataAsArray.map(prop => {
          const property = prop as PropertyType
          return buildPropertyInterface({ property: property, listing: null })
        })

        if (shouldSortBySavedAt(sorts[0])) {
          propertyListings = sortBySavedAt(sorts[0].operator, [], propertyListings)
        }

        setPropertiesState({
          data: propertyListings,
          error: error,
          loading: false,
          count: propertiesCount
        })

        if (pages) {
          const updatedCurrentPage = getCurrentPage(pages)
          setPageState({ ...pageState, pageNumbers: pages, currentPage: updatedCurrentPage })
        }
      }
    })
  }

  const navigateToPage = (number: number) => {
    const destinationPage =
      number === 1 ? `/homes/${areaSlug}` : `/homes/${areaSlug}/page-${number}`
    navigate(destinationPage)
  }

  useEffect(() => {
    getProperties()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSlug, pageState.currentPage])

  useEffect(() => {
    if (propertiesState.loading) {
      return
    }

    if (action === 'POP') {
      const destinationPage = getPageNumberFromParam(location.pathname)
      setPageState({ ...pageState, currentPage: destinationPage })
    }
  }, [location, pageState, pageState.currentPage, propertiesState.loading])

  const contextVal: IPropertiesContext = {
    properties: {
      propertiesData: propertiesState.data,
      propertiesLoading: propertiesState.loading,
      propertiesError: propertiesState.error
    },
    totalPropertiesCount: propertiesState.count,
    pageSize: pageSize,
    pageNumbers: pageState.pageNumbers,
    currentPage: pageState.currentPage,
    setCurrentPage: number => {
      setPageState({ ...pageState, currentPage: number })
      navigateToPage(number)
    },
    listingImages: {}
  }

  return (
    <PropertiesSitemapContext.Provider value={contextVal}>
      {children}
    </PropertiesSitemapContext.Provider>
  )
}
