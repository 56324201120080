import { ISuggestion } from "../../../utils/autocompleteRequest";
import { requestData } from "../../../utils/requestData";
import { PROPERTIES_API_URL, DIRECTIONAL_PREFIX } from "../../../constants";
import { mergeObjectArrays } from "../../../utils/mergeObjectArrays";
import { stateCodeOf } from '../../../utils/stateUtils'

export const hapiSearch = async (
  selectedOption: ISuggestion,
) => {
  try {
    const filters = [];
    let isAddress = true;

    filters.push({
      field: 'agent_attr',
      operator: '=',
      value: true,
    })

    filters.push({
      field: 'street_number',
      operator: '=',
      value: selectedOption.address,
    })

    const mapboxStreetName = selectedOption.text.toUpperCase().split(' ')
    const streetNameWithoutPostfix = mapboxStreetName.slice(0, mapboxStreetName.length - 1)
    const streetName = streetNameWithoutPostfix.filter(a => !DIRECTIONAL_PREFIX.includes(a.toLowerCase())).join(' ').trim()

    filters.push({
      field: 'street_name',
      operator: '=',
      value: streetName
    })

    const state = selectedOption.place_name.split(', ')[2].split(' ')[0]
    const stateCode = stateCodeOf(state)

    if (stateCode) {
      filters.push({
        field: 'state_or_province',
        operator: '=',
        value: stateCode
      })
    }

    let city = selectedOption.context.find((context) => {
      return context.id.includes('place')
    })?.text?.toUpperCase()

    if (city) {
      filters.push({
        field: 'city',
        value: city,
        operator: '='
      })
    }

    const { data, meta, error } = await requestData({
      url: PROPERTIES_API_URL,
      method: "get",
      filters: filters
    });
    if (error) throw error;

    const dataAsArray = Array.isArray(data) ? data : [data];
    const totalPropertiesCount = meta?.total_properties;
    const activeZones = meta?.active_zones;
    const mergedData = mergeObjectArrays({
      arr1: dataAsArray,
      arr2: [],
      priorityKey: "uuid",
      updateDupe: true,
    });

    isAddress = isAddress && mergedData.length > 0; // Make sure an address was found

    return {
      data: mergedData,
      totalPropertiesCount: totalPropertiesCount,
      activeZones: activeZones,
      error: false,
      isAddress,
    };
  } catch (error) {
    return { data: [], totalProperties: 0, error: true, isAddress: false };
  }
};
