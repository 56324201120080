import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IPropertyContext, PropertyContext } from '../../contexts/PropertyContext'
import { UltraAgentHero } from './UltraAgentHero'
import { ModernSolution } from './ModernSolution'
import { UltraDreamHome } from './UltraDreamHome'
import { UltraLeadForm } from './UltraLeadForm'
import { UltraConfimationModal } from './UltraConfimationModal'
import { Body, Header, StyledModalBackground, StyledModalContainer } from '../common/Modal'
import {
  BREAKPOINT_L,
  BREAKPOINT_M,
  coolGray60,
  MAX_PAGE_WIDTH,
  white,
  AGENT_LISTING_DETAILS_URL
} from '../../constants'
import { UltraFaq } from './UltraFaq'
import { UltraLogo, UltraLogoWhite } from '../icons/UltraLogo'
import { PropertyNotFound } from '../NoResults'
import { ScheduleTourButton } from './sharedStyles'
import { trackEvent } from '../../utils/tracking'
import { lifetimeSavings, tensOrHundreds } from '../../utils/mortgageSavings'
import { requestData } from '../../utils/requestData'

const PageWrapper = styled.div`
  position: relative;
`

const UltraHeader = styled.nav<{ scrolled: boolean }>`
  position: ${props => (props.scrolled ? 'fixed' : 'absolute')};
  left: 0;
  top: 0;
  width: 100vw;
  padding: 12px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  box-sizing: border-box;
  background-color: ${props => (props.scrolled ? 'white' : 'none')};
  
  svg {
    height: 22.5px;
  }
  
  @media (min-width: ${BREAKPOINT_L}px) {
    justify-content: flex-start;
    max-width: calc(${MAX_PAGE_WIDTH}px + 160px);
    left: 50%;
    transform: translateX(-50%);
    padding: ${props => (props.scrolled ? '12px 80px' : '24px 80px')};
    
    svg {
      height: unset;
      margin-left: 2px;
    }
  }
`

const StickyGoToFormWrapper = styled.div`
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding: 16px;
  box-sizing: border-box;
  background-color: ${white};
  border-top: 1px solid ${coolGray60};
  box-shadow: 0 3px 16px 0 rgba(108, 129, 171, 0.20);
  z-index: 4;

  @media (min-width: ${BREAKPOINT_M}px) {
    display: none;
  }
`

const StyledLeadFormContainer = styled(StyledModalContainer)`
  position: relative;
  height: calc(100vh - 45px);
  margin-top: 45px;
  overflow-y: auto;
`

const StyledConfirmationContainer = styled(StyledModalContainer)`
  position: absolute;
  bottom: -2px;

  @media (min-width: ${BREAKPOINT_M}px) {
    position: relative;
    bottom: unset;
  }
`

const ABBR_MAPPING = {
  "Rd": "Road",
  "Trl": "Trail",
  "St": "Street",
  "Cir": "Circle",
  "Dr": "Drive"
}

export const UltraAgentBody = ({ propertyUuid }) => {
  const [pageScrolled, setPageScrolled] = useState(false)
  const [leadFormModalVisible, setLeadFormModalVisible] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [show404, setShow404] = useState(false)
  const [address, setAddress] = useState('')
  const [agentData, setAgentData] = useState({})
  const {
    propertyListing,
    propertyListingLoading
  } = useContext(PropertyContext) as IPropertyContext

  useEffect(() => {
    if (!propertyListingLoading) {
      if (
        !propertyUuid ||
        !propertyListing ||
        !propertyListing.featured
      ) {
        setShow404(true)
      }
    }
  }, [propertyUuid, propertyListing, propertyListingLoading])

  useEffect(() => {
    if (propertyListing) {
      setAddress(propertyListing?.hlFullAddress)
      Object.entries(ABBR_MAPPING).forEach((abbr) => {
        let regex = new RegExp(abbr[0] + '$')
        if (regex.test(propertyListing?.hlFullAddress)) {
          setAddress(propertyListing?.hlFullAddress.replace(regex, abbr[1]))
        }
      })
    }
  }, [propertyListing])

  const isDesktop = window.innerWidth >= BREAKPOINT_L;
  const hlRate = propertyListing?.mortgageIntRate
    ? parseFloat(propertyListing.mortgageIntRate.toFixed(3))
    : 'an ultra-low rate'
  const agentName = agentData?.agent_name || propertyListing?.agentName || propertyListing?.listingListAgentFullName
  const agentFirstName = agentName ? agentName.split(' ')[0] : 'your agent'
  const agentImage = agentData?.agent_image || propertyListing?.agentImage
  const agentPhone = agentData?.agent_phone || propertyListing?.agentPhone
  const officeName = agentData?.agent_office || propertyListing?.agentOffice || propertyListing?.listingListOfficeName
  const rateEstimated = propertyListing?.isMortgageIntRateEstimated !== false

  const ultraLifetimeSavings = lifetimeSavings(propertyListing)
  const savingsSizeCopy = tensOrHundreds(ultraLifetimeSavings)

  const handleScroll = e => {
    const topFold = 640
    if (pageScrolled && window.pageYOffset < topFold) {
      setPageScrolled(false)
    } else if (!pageScrolled && window.pageYOffset >= topFold) {
      setPageScrolled(true)
    }
  }

  useEffect(() => {
    // @ts-ignore
    window.addEventListener('scroll', handleScroll)
    return () => {
      // @ts-ignore
      window.removeEventListener('scroll', handleScroll)
    }
  }, [pageScrolled])

  const goToForm = () => {
    if (isDesktop) {
      const formWrapper = document.querySelector('#lead-form-wrapper')
      if (formWrapper) {
        const pageY = formWrapper.getBoundingClientRect().top
        window.scrollTo({
          top: pageY,
          behavior: 'smooth',
        })
      }
    } else {
      setLeadFormModalVisible(true)
    }
  }

  const onSubmitSuccess = () => setShowConfirmation(true)

  useEffect(() => {
    trackEvent(
      'Page View',
      propertyUuid,
      0,
      false,
      null,
      null,
      'Ultra Listing Lead Capture',
    )
  }, [propertyUuid])

  useEffect(() => {
    if (propertyUuid) {
      requestData({
        url: `${AGENT_LISTING_DETAILS_URL}?property_uuid=${propertyUuid}&agent_attr=true`,
        method: 'get',
        body: {},
        includeBearerToken: true,
        ignoreJsonFormat: true
      }).then(({ data, error }) => {
        if (!error) {
          setAgentData(data?.data?.[0])
        }
      })
    }
  }, [propertyUuid])

  return show404 ? (
    <PropertyNotFound />
  ) : (
    <PageWrapper>
      <UltraHeader scrolled={pageScrolled}>
        {pageScrolled ? <UltraLogo /> : <UltraLogoWhite />}
      </UltraHeader>
      <UltraAgentHero
        agentFirstName={agentFirstName}
        hlRate={hlRate}
        rateEstimated={rateEstimated}
        propertyListing={propertyListing}
        goToForm={goToForm}
        savingsSizeCopy={savingsSizeCopy}
        address={address}
        agentName={agentName}
        agentImage={agentImage}
        agentPhone={agentPhone}
        officeName={officeName}
      />
      <ModernSolution
        agentFirstName={agentFirstName}
        propertyListing={propertyListing}
        hlRate={hlRate}
        rateEstimated={rateEstimated}
        goToForm={goToForm}
        savingsSizeCopy={savingsSizeCopy}
        address={address}
      />
      <UltraDreamHome propertyListing={propertyListing} agentFirstName={agentFirstName} />
      {isDesktop && (
        <UltraLeadForm
          agentFirstName={agentFirstName}
          hlRate={hlRate}
          rateEstimated={rateEstimated}
          propertyListing={propertyListing}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
      {pageScrolled && (
        <StickyGoToFormWrapper>
          <ScheduleTourButton onClick={goToForm}>
            {`Schedule a Tour with ${agentFirstName}`}
          </ScheduleTourButton>
        </StickyGoToFormWrapper>
      )}
      {leadFormModalVisible && !isDesktop && (
        <StyledModalBackground>
          {/* @ts-ignore */}
          <StyledLeadFormContainer>
            <Header closeModal={() => setLeadFormModalVisible(false)} hideBorder />
            <Body>
              <UltraLeadForm
                agentFirstName={agentFirstName}
                hlRate={hlRate}
                propertyListing={propertyListing}
                closeModal={() => setLeadFormModalVisible(false)}
                onSubmitSuccess={onSubmitSuccess}
              />
            </Body>
          </StyledLeadFormContainer>
        </StyledModalBackground>
      )}
      {showConfirmation && (
        <StyledModalBackground>
          {/* @ts-ignore */}
          <StyledConfirmationContainer>
            <Header closeModal={() => setShowConfirmation(false)} hideBorder />
            <UltraConfimationModal agentFirstName={agentFirstName} />
          </StyledConfirmationContainer>
        </StyledModalBackground>
      )}
      <UltraFaq rate={hlRate} />
    </PageWrapper>
  )
}
