import styled from 'styled-components'
import { Checkbox } from "../../common/Checkbox"
import { Heading5 } from '../../common/Text'
import { IPropertyTypesSelectProps } from './'

const StyledPropertyTypesSelect = styled('div')`
  width: 432px;
  max-width: 100%;
`

const StyledContainer = styled('div')`
  padding-top: 24px;
  overflow: auto;
  max-height: 42vh;
`

export const PropertyTypesSelect: React.FC<IPropertyTypesSelectProps> = ({
  propertyTypeOptions,
  selectedPropertyTypes,
  setPropertyTypes
}) => {
  const propertyTypeOptionsWithSelected = propertyTypeOptions.map(option => (
    selectedPropertyTypes.includes(option.value) ?
      {...option, selected: true} :
      {...option, selected: false}
    ))

  const toggleCheckedValue = (value: string) => {
    const valueWasSelected = selectedPropertyTypes.includes(value)
    const updatedSelectedTypes = !valueWasSelected ?
      [...selectedPropertyTypes, value ] :
      selectedPropertyTypes.filter(selectedType => selectedType !== value)
    setPropertyTypes(updatedSelectedTypes)
  }

  return (
    <StyledPropertyTypesSelect>
      <Heading5 as="label">Home type</Heading5>
      <StyledContainer>
        {propertyTypeOptionsWithSelected.map(option => (
          <Checkbox
            key={option.label}
            label={option.label}
            value={option.value}
            checked={option.selected}
            toggleChecked={toggleCheckedValue}
          />
        ))}
      </StyledContainer>
    </StyledPropertyTypesSelect>
  )
}
