export function formatBedsAndBaths(bedsMin?: number, bedsMax?: number, bathsMin?: number) {
  const hasBedsMin = Boolean(bedsMin)
  const hasBedsMax = Boolean(bedsMax)
  const hasBathsMin = Boolean(bathsMin)
  const separator = (hasBedsMin || hasBedsMax) && hasBathsMin ? ', ' : ''
  const bathsLabel = hasBathsMin ? '+ Baths' : ''

  let beds = ''
  if (hasBedsMin && !hasBedsMax) {
    beds = `${bedsMin}+ Beds`
  } else if (!hasBedsMin && hasBedsMax) {
    beds = `Up to ${bedsMax} Beds`
  } else if (hasBedsMin && hasBedsMax) {
    beds = `${bedsMin} - ${bedsMax} Beds`
  }

  return `${beds}${separator}${bathsMin  || ''}${bathsLabel}`
}
