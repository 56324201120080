import { FEET_IN_AN_ACRE } from "../../constants"

export const formatSqftOrAcres = (feet: number | null) => {
  if (!feet) { return '' }

  const quarterAcre = FEET_IN_AN_ACRE / 4

  if (feet < quarterAcre) {
    return `${feet.toLocaleString()} sq. ft.`
  } else {
    const acres = feet / FEET_IN_AN_ACRE
    const formattedAcres = acres.toFixed(2).toString().replace(/^[0.]+/, ".");
    return `${formattedAcres} acres`
  }
}
