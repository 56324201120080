export const NoFavoritesImage = () => (
  <svg width='643' height='152' viewBox='0 0 643 152' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M168.35 97.93C172.42 102.44 175.8 107.84 177.15 113.82C178.69 120.64 178.15 128.04 177.83 134.96C177.73 136.89 180.73 136.88 180.83 134.96C181.18 127.63 181.69 119.8 179.95 112.61C178.41 106.29 174.79 100.61 170.47 95.81C169.17 94.38 167.06 96.5 168.35 97.93Z' fill='#9BB0C9'/>
    <path d='M180.12 118.9C180.28 115.34 184.5 114.73 186.12 110.9C187.56 107.4 185.5 100.81 188.79 97.31' stroke='#9BB0C9' strokeWidth='2' strokeMiterlimit='10'/>
    <path d='M162.87 56.26C167.43 51.05 175.23 47.92 179.39 49.46C182.29 50.53 179.18 54.77 183.12 56.07C187.06 57.37 201.32 56.07 202.41 61.35C202.99 64.18 195.84 66.84 196.81 69.56C197.62 71.85 204.59 71.38 208.47 72.29C209.415 72.4057 210.3 72.8149 211 73.46C211.84 74.46 211.74 75.88 212.32 77.01C213.27 78.84 215.75 79.43 216.73 81.24C217.71 83.05 216.6 85.56 214.83 86.73C213.06 87.9 210.83 88.12 208.71 88.31C203.56 88.77 194.2 88.31 193.24 89.69C192.28 91.07 198.46 94.48 198.24 96.81C198.02 99.14 191.31 103.03 186.66 103.19C182.01 103.35 177.4 102.19 172.75 102.19C164.75 102.19 158.53 102.02 153.75 103.69C150.66 104.77 146.12 102.6 146.64 100.16C147.16 97.72 154.8 96.41 155.05 93.72C155.3 91.03 144.32 91.95 142.5 87.47C140.9 83.56 149.35 80.17 149.5 78.47C149.65 76.77 146.88 75.65 147.12 73.39C147.36 71.13 152.53 70.69 155.44 68.66C158.35 66.63 158.55 61.2 162.87 56.26Z' fill='#BBD3EA'/>
    <path d='M429.4 149.99H198.59V100.76H210.95V64.67H240.05L293.98 14.66L349.25 64.67H429.4V149.99Z' fill='#BBD3EA'/>
    <path d='M300.25 100.76H191.81L204.93 85.45H233.3V95.29H300.25V100.76Z' fill='#273653'/>
    <path d='M429.4 70.46H361.56V149.87H429.4V70.46Z' fill='white'/>
    <path d='M411.29 102.4H375.45V105.68H411.29V102.4Z' fill='#0C6EBA'/>
    <path d='M392.53 78.97H380.12V100.76H392.53V78.97Z' fill='#273653'/>
    <path d='M406.62 79.01H394.21V100.73H406.62V79.01Z' fill='#273653'/>
    <path d='M229.9 70.46H210.95V85.45H229.9V70.46Z' fill='white'/>
    <path d='M298.06 95.29H233.3V76.06L293.98 19.8L322.72 45.39L298.06 67.15V95.29Z' fill='white'/>
    <path d='M264.99 63.22H255.73V80.05H264.99V63.22Z' fill='#273653'/>
    <path d='M275.45 63.22H266.19V80.05H275.45V63.22Z' fill='#273653'/>
    <path d='M278.36 81.25H252.81V84.53H278.36V81.25Z' fill='#0C6EBA'/>
    <path d='M434.65 66.85L410.58 24.19H314.11L293.71 5.78003L273.73 24.19H245.41L240.69 32.94H221.28L204.88 66.85H222.44H227.43L219.86 73.83H230.16L293.98 14.66L325.36 43.06L290.5 73.83L300.21 73.81L329.88 47.15L359.37 73.83H369.13L361.4 66.85H434.65Z' fill='#273653'/>
    <path d='M359.37 150H300.25V78.75L329.9 52.16L359.37 78.75V150Z' fill='white'/>
    <path d='M340.96 107.19H318.66V149.88H340.96V107.19Z' fill='#273653'/>
    <path d='M338.7 128.42H337.1V133.54H338.7V128.42Z' fill='white'/>
    <path d='M486.59 149.88H155.46V151.73H486.59V149.88Z' fill='#BBD3EA'/>
    <path d='M486.56 150C487.19 145.77 476.86 144.4 477.38 140.16C477.51 139.07 478.26 138.16 478.54 137.08C479.34 133.96 475.89 131.34 472.73 130.74C469.57 130.14 466.08 130.51 463.34 128.82C462.758 128.371 462.115 128.007 461.43 127.74C460.382 127.571 459.307 127.728 458.35 128.19C455.28 129.32 453.15 122.82 449.44 121.37C445.73 119.92 443.44 122.07 441.6 120.79C439.76 119.51 441.12 113.54 440.21 111.26C439.762 110.348 439.076 109.574 438.224 109.022C437.372 108.469 436.385 108.157 435.37 108.12C433.326 108.08 431.291 108.419 429.37 109.12V150H486.56Z' fill='#BBD3EA'/>
    <path d='M198.59 129C198.209 128.388 197.633 127.921 196.956 127.672C196.278 127.424 195.537 127.409 194.85 127.63C193.489 128.099 192.208 128.773 191.05 129.63C189.484 130.599 187.782 131.33 186 131.8C185.005 132.12 183.935 132.12 182.94 131.8C181.94 131.41 181.29 130.53 180.34 130.13C179.644 129.856 178.871 129.846 178.167 130.102C177.464 130.358 176.878 130.862 176.52 131.52C175.8 132.94 176.16 134.98 174.85 135.88C174.242 136.221 173.547 136.378 172.85 136.33C170.62 136.4 168.93 135.6 166.71 135.43C164.71 135.28 161.71 135.9 160.95 137.8C160.693 138.798 160.666 139.84 160.87 140.85C161.1 142.71 157.65 143.36 158.17 145.85C158.55 147.69 156 147.5 155.44 149.85L198.57 149.99L198.59 129Z' fill='#BBD3EA'/>
    <path d='M213.58 149.88L198.59 149.99V104.81H298.2V149.88H282.54V115.34H213.58V149.88Z' fill='white'/>
    <path d='M279.93 117.94H216.17V124.94H279.93V117.94Z' fill='white'/>
    <path d='M279.93 126.25H216.17V133.25H279.93V126.25Z' fill='white'/>
    <path d='M279.93 134.59H216.17V141.59H279.93V134.59Z' fill='white'/>
    <path d='M279.93 142.92H216.17V149.92H279.93V142.92Z' fill='white'/>
    <path d='M329.21 76.58H319.95V93.41H329.21V76.58Z' fill='#273653'/>
    <path d='M339.67 76.58H330.41V93.41H339.67V76.58Z' fill='#273653'/>
    <path d='M342.58 94.6H317.03V97.88H342.58V94.6Z' fill='#0C6EBA'/>
    <path d='M411.29 138.5H375.45V141.78H411.29V138.5Z' fill='#0C6EBA'/>
    <path d='M392.53 115.06H380.12V136.85H392.53V115.06Z' fill='#273653'/>
    <path d='M406.62 115.1H394.21V136.82H406.62V115.1Z' fill='#273653'/>
    <path d='M411.29 47.44C424.391 47.44 435.01 36.8202 435.01 23.72C435.01 10.6198 424.391 0 411.29 0C398.19 0 387.57 10.6198 387.57 23.72C387.57 36.8202 398.19 47.44 411.29 47.44Z' fill='#46B6FF'/>
    <path d='M420.73 16.77C419.494 15.7693 417.924 15.2764 416.337 15.3916C414.751 15.5067 413.268 16.2212 412.19 17.39L411.29 18.32L410.39 17.39C409.312 16.2212 407.829 15.5067 406.243 15.3916C404.656 15.2764 403.086 15.7693 401.85 16.77C401.177 17.3525 400.629 18.0658 400.239 18.8667C399.85 19.6675 399.628 20.5391 399.585 21.4285C399.543 22.3179 399.683 23.2066 399.994 24.0406C400.306 24.8746 400.785 25.6365 401.4 26.28L410.25 35.42C410.383 35.5614 410.543 35.674 410.721 35.751C410.899 35.8281 411.091 35.8678 411.285 35.8678C411.479 35.8678 411.671 35.8281 411.849 35.751C412.027 35.674 412.187 35.5614 412.32 35.42L421.18 26.28C421.795 25.6365 422.274 24.8746 422.586 24.0406C422.898 23.2066 423.037 22.3179 422.995 21.4285C422.953 20.5391 422.73 19.6675 422.341 18.8667C421.951 18.0658 421.403 17.3525 420.73 16.77Z' fill='white'/>
    <path d='M112.28 149.89H24.5303V93.85L67.9503 58.03L112.28 93.55V149.89Z' fill='#E8EEF9'/>
    <path d='M112.28 100.67L67.9498 64.81L24.5298 100.67H16.2598L28.1298 90.88V71.4H41.7698V79.63L67.9498 58.03L121.16 100.67H112.28Z' fill='#BBD3EA'/>
    <path d='M112.28 150.23L24.5303 149.89V105.54L68.2603 69.69L112.28 105.54V150.23Z' fill='white'/>
    <path d='M78.6 110.87H58.21V149.89H78.6V110.87Z' fill='#BBD3EA'/>
    <path d='M76.5303 130.28H75.0703V134.96H76.5303V130.28Z' fill='white'/>
    <path d='M139.11 149.89H0V151.58H139.11V149.89Z' fill='#BBD3EA'/>
    <path d='M40.6797 110.87H32.2197V126.25H40.6797V110.87Z' fill='#E8EEF9'/>
    <path d='M50.2295 110.87H41.7695V126.25H50.2295V110.87Z' fill='#E8EEF9'/>
    <path d='M52.9098 127.35H29.5498V130.35H52.9098V127.35Z' fill='#BBD3EA'/>
    <path d='M94.8897 110.87H86.4297V126.25H94.8897V110.87Z' fill='#E8EEF9'/>
    <path d='M104.45 110.87H95.9902V126.25H104.45V110.87Z' fill='#E8EEF9'/>
    <path d='M107.12 127.35H83.7598V130.35H107.12V127.35Z' fill='#BBD3EA'/>
    <path d='M0 149.89C0.19 146.79 2.38 146.22 2.73 144.43C3.08 142.64 2.14 140.95 2.8 139.3C3.46 137.65 6.51 137.56 7.6 136.2C8.69 134.84 8.23 132.63 9.29 131.33C10.35 130.03 13.29 130.24 14.59 129.63C15.89 129.02 16.66 127.4 18.26 127.02C20.4752 126.771 22.705 127.34 24.53 128.62V149.89H0Z' fill='#BBD3EA'/>
    <path d='M139.12 149.89C136 147.73 137.6 145 136.75 143.3C135.81 141.42 133.53 141.78 132.52 140.48C131.51 139.18 129.86 134.29 127.15 133.8C124.07 133.24 122.44 135.64 119.91 135.92C117.91 136.13 115.2 134.18 112.28 136.98V149.89H139.12Z' fill='#BBD3EA'/>
    <path d='M615.89 150.04H528.14V93.99L571.57 58.18L615.89 93.7V150.04Z' fill='#E8EEF9'/>
    <path d='M615.89 100.81L571.57 64.96L528.14 100.81H519.87L531.74 91.03V71.54H545.39V79.77L571.57 58.18L624.78 100.81H615.89Z' fill='#BBD3EA'/>
    <path d='M615.89 150.38L528.14 150.04V105.69L571.87 69.83L615.89 105.69V150.38Z' fill='white'/>
    <path d='M582.21 111.01H561.82V150.03H582.21V111.01Z' fill='#BBD3EA'/>
    <path d='M580.14 130.42H578.68V135.1H580.14V130.42Z' fill='white'/>
    <path d='M642.72 150.04H503.61V151.73H642.72V150.04Z' fill='#BBD3EA'/>
    <path d='M544.29 111.01H535.83V126.39H544.29V111.01Z' fill='#E8EEF9'/>
    <path d='M553.85 111.01H545.39V126.39H553.85V111.01Z' fill='#E8EEF9'/>
    <path d='M556.52 127.49H533.16V130.49H556.52V127.49Z' fill='#BBD3EA'/>
    <path d='M598.5 111.01H590.04V126.39H598.5V111.01Z' fill='#E8EEF9'/>
    <path d='M608.06 111.01H599.6V126.39H608.06V111.01Z' fill='#E8EEF9'/>
    <path d='M610.73 127.49H587.37V130.49H610.73V127.49Z' fill='#BBD3EA'/>
    <path d='M503.61 150C503.8 146.89 505.99 146.32 506.34 144.54C506.69 142.76 505.75 141.06 506.41 139.41C507.07 137.76 510.12 137.67 511.21 136.3C512.3 134.93 511.84 132.73 512.91 131.43C513.98 130.13 516.91 130.35 518.2 129.74C519.49 129.13 520.27 127.5 521.87 127.13C524.085 126.871 526.317 127.437 528.14 128.72V150H503.61Z' fill='#BBD3EA'/>
    <path d='M642.73 150C639.61 147.83 641.21 145.1 640.37 143.41C639.37 141.53 637.15 141.88 636.13 140.59C635.11 139.3 633.47 134.4 630.77 133.9C627.69 133.34 626.05 135.75 623.52 136.02C621.52 136.23 618.81 134.28 615.89 137.08V150H642.73Z' fill='#BBD3EA'/>
  </svg>
)
