import { STATES } from '../../constants/states';

export const stateNameOf = (code: string) => {
  const match = STATES.find(state => state.code === code.toUpperCase())
  return( match ? match.name : null )
}

export const stateCodeOf = (name: string | null) => {
  const match = STATES.find(state => state.name === name)
  return( match ? match.code : null )
}

export const stateCodeFromSlug = (slug?: string) => {
  if (!slug) {
    return ''
  }
  const parts = slug.split('-')
  return parts[parts.length - 1].toUpperCase()
}
