import { CLOSED } from "../../constants"
import { IGetPropertyListingPriceToUse } from './'

export const getPropertyListingPriceToUse = ({
  property,
  listing
} : IGetPropertyListingPriceToUse) => (
  property.listingMlsStatus?.toLowerCase() === CLOSED && property.listingClosePrice ?
  property.listingClosePrice : (
    property.listingListPrice ||
    listing?.listingDetails.listPrice ||
    property.closePrice
  )
)
