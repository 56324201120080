export const FAVORITES_FILTER = {
  label: 'favoritesTypeFilter',
  field: 'mapping_type',
  operator: '=',
  value: 'favorites'
}

export const REQUESTED_TOUR_FILTER = {
  label: 'requestedTourTypeFilter',
  field: 'mapping_type',
  operator: '=',
  value: 'requested_tour'
}

export const RECENTLY_VIEWED_FILTER = {
  label: 'recentlyViewedTypeFilter',
  field: 'mapping_type',
  operator: '=',
  value: 'recently_viewed'
}

export const FAVORITE_PROPERTIES_FILTER = {
  label: 'favoritePropertiesFilter',
  field: '_favorite_properties',
  operator: '=',
  value: 'true'
}
