import TagManager from 'react-gtm-module'
import { postEvent } from '../postEvent';

export const CATEGORY = 'Property Search'
export const ACTION_ENTRY = 'Entry'
export const ACTION_TAP = 'Tap'
export const ACTION_VIEW = 'View'
export const ACTION_SEARCH = 'Search'
export const ACTION_SHARE = 'Share'
export const ACTION_STATE = 'State'
export const ACTION_CALL = 'Call'
export const DATA_LAYER_NAME = 'PropertySearchDataLayer'

export function initializeGTM() {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID || '',
    dataLayerName: DATA_LAYER_NAME
  }
  TagManager.initialize(tagManagerArgs)
}

export function trackEvent(
  action: string,
  label: string,
  value: number = 0,
  nonInteractionHit: boolean = false,
  experimentName: string | null = null,
  experimentValue: string | null = null,
  category: string = CATEGORY
) {
  const tagManagerArgs = {
    dataLayer: {
      event: 'track_event',
      category: category,
      action,
      label,
      value,
      non_interaction_hit: nonInteractionHit,
      experiment_name: experimentName,
      experiment_value: experimentValue
    },
    dataLayerName: DATA_LAYER_NAME
  }
  TagManager.dataLayer(tagManagerArgs)
  postEvent(tagManagerArgs.dataLayer)
}

export function sendToGtmDataLayer(
    dataLayerObject: any
) {
  const tagManagerArgs = {
    dataLayer: dataLayerObject,
    dataLayerName: DATA_LAYER_NAME
  }
  TagManager.dataLayer(tagManagerArgs)
}
