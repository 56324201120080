import styled from 'styled-components'
import { CheckIcon } from '../../icons/CheckIcon'
import { Paragraph } from '../../common/Text'
import { coolGray50, white, darkBlue, electricBlue } from '../../../constants'
import { ICheckboxProps } from './'

const HiddenCheckbox = styled('input')`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`

const StyledCheckbox = styled('label')<{
  margin?: string,
  mt?: string,
  mr?: string,
  mb?: string,
  ml?: string,
  padding?: string,
  pt?: string,
  pr?: string,
  pb?: string,
  pl?: string
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  cursor: pointer;
  margin: ${props => props.margin ? props.margin : 0};
  margin-top: ${props => props.mt ? props.mt : 0};
  margin-right: ${props => props.mr ? props.mr : 0};
  margin-bottom: ${props => props.mb ? props.mb : 0};
  margin-left: ${props => props.ml ? props.ml : 0};
  padding: ${props => props.padding ? props.padding : 0};
  padding-top: ${props => props.pt ? props.pt : 0};
  padding-right: ${props => props.pr ? props.pr : 0};
  padding-bottom: ${props => props.pb ? props.pb : 0};
  padding-left: ${props => props.pl ? props.pl : 0};

  input:focus-visible ~ .checkmark {
    outline: 2px solid ${darkBlue};
    outline-offset: 2px;
  }
`

const StyledUnchecked = styled('div')`
  width: 20px;
  height: 20px;
  border: 1px solid ${coolGray50};
  border-radius: 2.6px;
  background-color: ${white};
  margin-right: 16px;
  cursor: pointer;
`

const StyledChecked = styled(StyledUnchecked)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${electricBlue};
  background-color: ${electricBlue};
  margin-right: 16px;
  cursor: pointer;
`

export const Checkbox: React.FC<ICheckboxProps> = ({
  label,
  value,
  checked,
  toggleChecked
}) => {
  return (
    <StyledCheckbox pb='24px'>
      <HiddenCheckbox
        type='checkbox'
        checked={checked}
        onKeyDown={(e) => e.key === 'Enter' ? toggleChecked(value) : null}
        onChange={() => toggleChecked(value)}
      />
      {checked ? (
        <StyledChecked className="checkmark">
          <CheckIcon fill={white}/>
        </StyledChecked>
      ) :
        <StyledUnchecked className="checkmark" />
      }
      <Paragraph
        as='span'
        color={darkBlue}
      >
        {label}
      </Paragraph>
    </StyledCheckbox>
  )
}
