import styled from 'styled-components'
import {COLORS} from "../../../constants";

export const Button = styled.button`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  &:hover, &:focus-visible {
    text-decoration: none;
  }
  &:focus-visible {
    border-color: ${COLORS.darkBlue};
  }
   &:focus {
    outline: none;
    border-color: ${COLORS.electricBlue};
  }
`
