import styled from 'styled-components'
import { SALE_DATE_OPTIONS, coolGray50, PROPERTY_TYPE_OPTIONS } from '../../../../constants'
import { Heading5 } from '../../../common/Text'
import { Header, Body } from '../../../common/Modal'
import { CancelOrConfirm } from '../../../common/CancelOrConfirm'
import { RangeDropdowns } from '../../../common/RangeDropdowns'
import { ForSaleOrSold } from '../../ForSaleOrSold'
import { IMoreFiltersBody } from './types'
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions'
import { PropertyTypesSelect } from '../../PropertyTypesSelect'
import { FeaturedSelect } from '../../FeaturedSelect'

const StyledMoreFiltersModal = styled.div`
  .more-filters-body {
    overflow: auto;
    max-height: 75vh;
    padding-top: 0;
    
    h5 {
      padding-top: 24px;
    }
  }
`

const StyledHeader = styled(Heading5)`
  margin-bottom: 28px;
`
const StyledSection = styled('div')`
  padding-bottom: 30px;  
  border-bottom: 1px solid ${coolGray50};
  margin-bottom: 30px;
`

const MoreFiltersBody: React.FC<IMoreFiltersBody> = ({
  hasFilters,
  closeModal,
  forSaleOrSold,
  selectForSaleOrSold,
  listingStatus,
  selectListingStatus,
  saleDate,
  selectSaleDate,
  sqftMin,
  selectSqftMin,
  sqftMinOptions,
  sqftMax,
  selectSqftMax,
  sqftMaxOptions,
  lotSizeMin,
  selectLotSizeMin,
  lotSizeMinOptions,
  lotSizeMax,
  selectLotSizeMax,
  lotSizeMaxOptions,
  yearBuiltMin,
  selectYearBuiltMin,
  yearBuiltMinOptions,
  yearBuiltMax,
  selectYearBuiltMax,
  yearBuiltMaxOptions,
  clearMoreFilters,
  applyMoreFilters,
  selectedPropertyTypes,
  selectPropertyTypes,
  featured,
  selectFeatured,
  showGoldRush
}) => {
  const { height } = useWindowDimensions()
  const optionsPlacement = height < 879 ? 'bottom' : 'container'

  return (
    <StyledMoreFiltersModal>
      <Header closeModal={closeModal} />
      <Body className="more-filters-body">
        <StyledSection>
          {showGoldRush && <FeaturedSelect featured={featured} selectFeatured={selectFeatured} />}
          <StyledHeader>Listing status</StyledHeader>
          <ForSaleOrSold
            forSaleOrSold={forSaleOrSold}
            selectForSaleOrSold={selectForSaleOrSold}
            listingStatus={listingStatus}
            selectListingStatus={selectListingStatus}
            saleDate={saleDate}
            selectSaleDate={selectSaleDate}
            saleDateOptions={SALE_DATE_OPTIONS}
          />
        </StyledSection>
        <PropertyTypesSelect
          propertyTypeOptions={PROPERTY_TYPE_OPTIONS}
          selectedPropertyTypes={selectedPropertyTypes}
          setPropertyTypes={selectPropertyTypes}
        />
        <StyledHeader>Home details</StyledHeader>
        <RangeDropdowns
          label="Square feet"
          minOptions={sqftMinOptions}
          selectedMin={sqftMin}
          maxOptions={sqftMaxOptions}
          selectedMax={sqftMax}
          selectMin={selectSqftMin}
          selectMax={selectSqftMax}
          minTestId="square-feet-minimum"
          maxTestId="square-feet-maximum"
          padding="5px 0 28px"
          useDynamicOptionsPlacement={true}
          optionsPlacement={optionsPlacement}
        />
        <RangeDropdowns
          label="Lot size"
          minOptions={lotSizeMinOptions}
          selectedMin={lotSizeMin}
          maxOptions={lotSizeMaxOptions}
          selectedMax={lotSizeMax}
          selectMin={selectLotSizeMin}
          selectMax={selectLotSizeMax}
          minTestId="lot-size-minimum"
          maxTestId="lot-size-maximum"
          padding="5px 0 28px"
          useDynamicOptionsPlacement={true}
          optionsPlacement={optionsPlacement}
        />
        <RangeDropdowns
          label="Year built"
          minOptions={yearBuiltMinOptions}
          selectedMin={yearBuiltMin}
          maxOptions={yearBuiltMaxOptions}
          selectedMax={yearBuiltMax}
          selectMin={selectYearBuiltMin}
          selectMax={selectYearBuiltMax}
          minTestId="year-built-minimum"
          maxTestId="year-built-maximum"
          padding="5px 0 28px"
          useDynamicOptionsPlacement={true}
          optionsPlacement="bottom"
        />
      </Body>
      <CancelOrConfirm
        confirmLabel={'Save'}
        confirm={applyMoreFilters}
        cancelLabel="Clear"
        disableCancel={!hasFilters}
        cancel={clearMoreFilters}
        testLabel="more-filters"
      />
    </StyledMoreFiltersModal>
  )
}

export default MoreFiltersBody
