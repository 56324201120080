import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flex } from '../Flex'
import {
  BEDS_OPTIONS,
  BEDS_NO_MIN_MAX_OPTION,
  coolGray50,
  electricBlue,
  white,
  whiteHover,
  BREAKPOINT_M
} from '../../../constants'
import { IRangeButtonsProps } from './'
import { IDropdownOption } from '../Dropdown'

const StyledFlex = styled(Flex)`
  width: 100%;
  height: 48px;
  margin: 6px 0 8px 0;

  @media (min-width: ${BREAKPOINT_M}px) {
    width: 100%;
  };
`

const StyledOptionButton = styled.div<{selected: boolean}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => props.selected ? `solid 2px ${electricBlue}` : `solid 1px ${coolGray50}`};
  box-sizing: border-box;
  background-color: ${props => props.selected ? whiteHover : white};
  font-weight: bold;
  cursor: pointer;
  
  &:first-child {
    border-radius: 6px 0 0 6px;
  }
  &:last-child {
    border-radius: 0 6px 6px 0;
  }
  
  &:hover {
    background-color: ${whiteHover};
  }
`

export const RangeButtons: React.FC<IRangeButtonsProps> = ({
  rangeOptions,
  min,
  setMin,
  max = { value: 0 },
  setMax,
  applyFilters = () => {},
  singleSelectMode = false
}) => {
  const [currentMin, setCurrentMin] = useState(min.value)
  const [currentMax, setCurrentMax] = useState(max.value)

  const highPlusBeds = BEDS_OPTIONS[BEDS_OPTIONS.length - 1]
  const noMaxFilter = max.value === 0 || max.value === highPlusBeds.value

  useEffect(() => {
    const minChanged = min.value !== currentMin
    const maxChanged = max.value !== currentMax
    if (minChanged || maxChanged) {
      applyFilters()
    }
    if (minChanged) {
      setCurrentMin(min.value)
    }
    if (maxChanged) {
      setCurrentMax(noMaxFilter ? 0 : max.value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max, currentMin, currentMax])

  const setRange = (option: IDropdownOption) => {
    const isRange = min.value !== max.value
    const isClear = min.value === 0 && max.value === 0
    const maxFilterSelected = option.value !== 0 && option.value !== highPlusBeds.value

    if (isRange || isClear) {
      // Clicking again if range is currently selected sets new selection to single
      setMin(option)
      setMax(maxFilterSelected ? option : BEDS_NO_MIN_MAX_OPTION)
    } else {
      const currentValue = min.value
      if (option.value > currentValue) {
        setMax(maxFilterSelected ? option : BEDS_NO_MIN_MAX_OPTION)
      } else if (option.value < currentValue) {
        setMin(option)
      }
    }
  }

  const handleClick = (option: IDropdownOption) => {
    if (singleSelectMode) {
      setMin(option)
      return
    }

    // 'Any' - setting min and max to 0 effectively clears filter
    if (option.value === 0) {
      setMin(BEDS_NO_MIN_MAX_OPTION)
      setMax(BEDS_NO_MIN_MAX_OPTION)
    }

    setRange(option)
  }

  const isWithinRange = (value: number) => {
    if (singleSelectMode) {
      return value === min.value
    }

    if (min.value > 0 && noMaxFilter) {
      return value >= min.value
    }
    return value >= min.value && value <= max.value
  }

  return (
    <StyledFlex align='stretch' className='range-buttons'>
      {
        rangeOptions.map(option => (
          <StyledOptionButton
            key={option.label}
            selected={isWithinRange(option.value)}
            onClick={() => handleClick(option)}
            data-testid={`range-button-${option.label}`}
          >
            <div className='option-label'>{option.label}</div>
          </StyledOptionButton>
        ))
      }
    </StyledFlex>
  )
}
