import styled from 'styled-components'
import { CheckCircleIcon } from '../../icons/CheckCircleIcon';
import { COLORS } from '../../../constants/colors'

// TODO: use the Toast component for this
const StyledConfirmationToast = styled.div`
  position: fixed;
  width: 342px;
  bottom: 24px;
  right: calc(50% - 187px);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${COLORS.white};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  z-index: 101;

  svg {
    flex: 0 0 18px;
    height: 18px;
    margin-right: 13px;
    fill: ${COLORS.electricBlue};
  }
`;

const ConfirmationText = styled.div`
  font-size: 14px;
  white-space: nowrap;
`

export const ConfirmationToast = () => {
  return (
    <StyledConfirmationToast>
      <CheckCircleIcon />
      <ConfirmationText>Link Copied</ConfirmationText>
    </StyledConfirmationToast>
  )
}