export const DarkMapIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.714355 3.05929V15.4279C0.714355 15.8321 1.12257 16.1086 1.49793 15.9586L6.42864 13.7143V0L1.43293 1.99821C0.999023 2.17176 0.714459 2.59196 0.714355 3.05929ZM7.5715 13.7143L14.4286 16V2.28571L7.5715 0V13.7143ZM20.5022 0.0414287L15.5715 2.28571V16L20.5672 14.0018C21.0012 13.8283 21.2858 13.4081 21.2858 12.9407V0.572143C21.2858 0.167857 20.8776 -0.108571 20.5022 0.0414287Z"
        fill="#273653"
      />
    </svg>
  )
}
