import flagsmith from 'flagsmith'

export function initializeFlagsmith() {
  flagsmith.init({
    api: 'https://edge.api.flagsmith.com/api/v1/',
    environmentID: process.env.REACT_APP_FLAGSMITH_ENV_KEY,
    cacheFlags: true,
    enableAnalytics: true
  })
}
