import styled from 'styled-components'
import { Flex } from '../common/Flex'
import {
  aaBlue,
  actionOrange,
  BREAKPOINT_S,
  BREAKPOINT_M,
  BREAKPOINT_L,
  coolGray20,
  coolGray40,
  coolGray50,
  darkBlue,
  electricBlue,
  electricBlueHover,
  mistBlue,
  white, cadmiumRed,
} from '../../constants'
import { DynamicAlphaNumericInput } from '../common/Input'
import { PrimaryButton } from '../common/Button'
import { TransparentInput } from '../common/Input/TransparentInput'

export const UnlockTrialWrapper = styled.div`
  body {
    overflow: hidden;
  }

  max-width: 940px;
  max-height: 100vh;
  height: 100vh;
  color: ${darkBlue};
  overflow: auto;
  border-radius: 12px;
  
  @media (min-width: ${BREAKPOINT_L}px) {
    display: flex;
    align-items: stretch;
    height: unset;
  }
`

export const SpinnerModalWrapper = styled(Flex)`
  width: 100vw;
  min-height: 440px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${BREAKPOINT_M}px) {
    width: 680px;
    height: 520px;
  }
`

export const ModalLeft = styled.div`
  padding: 16px 24px;
  @media (min-width: ${BREAKPOINT_L}px) {
    flex: 1 1 50%;
    padding: 40px;
  }
`

export const ModalRight = styled.div`
  margin-bottom: 112px;
  padding: 0 24px 24px;
  @media (min-width: ${BREAKPOINT_L}px) {
    flex: 1 1 50%;
    background-color: #F8F8FB;
    margin-bottom: 0;
    padding: 0;
  }
`

export const Title = styled.h3`
  font-size: 24px;
  margin-bottom: 8px;
  margin-top: 0;
`

export const Subtitle = styled.p`
  margin-top: 24px;
  line-height: 24px;
  
  @media (min-width: ${BREAKPOINT_L}px) {
    margin: 0 0 16px;
  }
`

export const NoLineBreak = styled.span`
  white-space: nowrap;
`

export const ValueProps = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  margin-bottom: 48px;
  padding-top: 32px;
  border-top: solid 1px ${coolGray40};

  @media (min-width: ${BREAKPOINT_L}px) {
    display: none;
  }
`

export const ValueItem = styled(Flex)`
  line-height: 24px;
`

export const IconWrapper = styled(Flex)`
  flex: 0 0 40px;
  height: 40px;
  background-color: ${mistBlue};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
`

export const ImageWrapper = styled(Flex)`
  width: 100%;
  height: 120px;
  justify-content: center;
  align-items: stretch;
  
  img {
    flex: 0 0 120px;
  }
`

export const FeeAndTrial = styled.div`
  margin-bottom: 16px;
  @media (min-width: ${BREAKPOINT_L}px) {
    display: none;
  }
`

export const Fee = styled.span`
  text-decoration: line-through;
`

export const Trial = styled.span`
  color: ${aaBlue};
`

export const PiiForm = styled(Flex)`
  flex-direction: column;
  padding: 24px 0;
  gap: 24px;
  align-items: flex-start;

  @media (min-width: ${BREAKPOINT_L}px) {
    padding-right: 24px;
  }
`

export const PiiStep = styled.div`
  width: 100%;
`

export const PiiInput = styled(DynamicAlphaNumericInput)`
  width: 100%;
`

export const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${cadmiumRed};
  text-align: center;
  margin-top: -24px;
  margin-bottom: 24px;
`

export const ActionsWrapper = styled.div`
  @media (max-width: calc(${BREAKPOINT_L}px - 1px)) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 32px);
    background-color: ${white};
    padding: 16px 16px 32px;
    
    &.scrolled {
      box-shadow: 0px 3px 16px 0px rgba(108, 129, 171, 0.20);
    }
    
    button {
      margin-bottom: 16px;
    }
  }
`

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 20px;
  
  @media (min-width: ${BREAKPOINT_L}px) {
    margin-top: 0;
  }
`

export const SlideshowWrapper = styled.div`
  height: 204px;
  max-width: 520px;
  margin: 0 auto;
  .slideshow-wrapper > div {
    border-radius: 8px;
  }
  
  @media (min-width: ${BREAKPOINT_M}px) {
    height: 300px;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    margin: -55px 0 0;
    height: 380px;
    .slideshow-wrapper > div {
      border-radius: 0 8px 0 0;
    }
  }

  .image-container {
    height: 100%;
  }
`

export const MapImage = styled.div`
  height: 220px;
  margin-top: 0;
  background-image: url('https://hl-production-assets.s3.amazonaws.com/house-hunter/map-with-markers.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  @media (min-width: ${BREAKPOINT_S}px) {
    height: 340px;
    max-width: 570px;
    margin: 0 auto;
  }
  @media (min-width: ${BREAKPOINT_L}px) {
    height: 380px;
    background-image: url('https://hl-production-assets.s3.amazonaws.com/house-hunter/map-with-property-card.png');
    margin: -55px 0 0;
  }
`

export const ContactMessage = styled.div`
  color: ${coolGray20};
  font-size: 12px;
  margin-top: -16px;
`

export const ButtonGroup = styled(Flex)`
  margin-bottom: 12px;
  flex-direction: column;
  gap: 12px;
  align-items: stretch;
`

export const SubmitButton = styled(PrimaryButton)`
  height: 48px;
  background-color: ${electricBlue};
  color: ${white};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  display: block;
  cursor: pointer;
  &:hover {
    background-color: ${electricBlueHover};
  }
`

export const AgentLenderMessage = styled.div`
  width: 100%;
  text-align: center;
  
  @media (min-width: ${BREAKPOINT_L}px) {
    margin-top: 30px;
  }
`

export const AgentLenderButton = styled.a`
  cursor: pointer;
  color: ${aaBlue};
`


export const SubmitButtonOrange = styled(SubmitButton)`
  background-color: ${actionOrange};
  border: none;
  display: flex;
  justify-content: center;
  width: 100%;
  
  &:hover {
    background-color: ${actionOrange};
  }
  
  &:disabled {
    background-color: ${coolGray50};
    color: ${coolGray20};
    cursor: unset;
  }
  
  @media (min-width: ${BREAKPOINT_L}px) {
    height: 60px;
    line-height: 60px;
  }
`

export const RatesInfoSection = styled.div`
  margin-top: 24px;
`

export const UltraSavingsFeature = styled.div`
  width: 312px;
  margin: 0 auto;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0 14px 48px 0 rgba(108, 129, 171, 0.20);
  text-align: center;
`

export const UltraSavingsText = styled.div`
  font-size: 12px;
  font-weight: 600;
`

export const FeaturedPricing = styled(Flex)`
  width: 100%;
  margin-top: 10px;
  justify-content: space-around;
`

export const BigPrice = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: ${electricBlue};
`

export const BigPriceLabel = styled.div`
  font-size: 18px;
  color: ${electricBlue};
`

export const PricingDetails = styled(Flex)`
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  margin-top: 20px;
  padding: 24px 24px 32px;
`

export const PricingDetailItem = styled(Flex)`
  justify-content: space-between;
`

export const UltraLowRateDetail = styled(PricingDetailItem)`
  div {
    color: ${electricBlue};
    font-weight: 600;
  }
  span {
    color: ${electricBlue};
  }
`

export const StandardRate = styled.span`
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 16px;
`

export const CancelMessage = styled.div`
  margin: 10px auto 0;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${coolGray20};
  
  @media (max-width: calc(${BREAKPOINT_L}px - 1px)) {
    display: none;
  }
`

export const ConfirmWrapper = styled.div`
  @media (min-width: ${BREAKPOINT_M}px) {
    width: 580px;
  }
`

export const MailboxImageWrapper = styled(Flex)`
  width: 263px;
  height: 263px;
  align-items: center;
  justify-content: center;
  background-color: ${mistBlue};
  border-radius: 50%;
  margin-bottom: 48px;

  img {
    width: 166px;
    height: 193px;
  }
`

export const CodeInputGroup = styled(Flex)`
  height: 64px;
  gap: 16px;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 32px;
`

export const CodeInput = styled(TransparentInput)`
  flex: 0 0 64px;
  border: solid 2px ${coolGray50};
  border-radius: 12px;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }

  &:focus, &:active {
    border-color: ${electricBlue};
  }
`

export const ConfirmMessage = styled.p`
  text-align: center;
  @media (min-width: ${BREAKPOINT_M}px) {
    width: 384px;
  }
`

export const PaymentTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 8px;
  margin-top: 40px;
`

export const PaymentSubtitle = styled.p`
  line-height: 24px;
  font-size: 20px;

  @media (min-width: ${BREAKPOINT_L}px) {
    margin: 8px 0 40px;
  }
`

export const ListContainer = styled.ul`
  list-style-type: none;
  padding-left: 0;
`

export const ListItem = styled.li`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 26px;
  }
`
