import { DEFAULT_LISTING_STATUS_FILTER_VALUE } from "./listingOptions"

export const FOR_SALE = 'for_sale'
export const SOLD = 'sold'
export const CLOSED = 'closed'

export const HAS_LISTING_FILTER = {
  label: 'hasListing',
  field: '_listing_link',
  operator: '=',
  value: true
}

export const LISTING_STATUS_FILTER = {
  field: 'listing_mls_status',
  label: 'listingStatus',
  operator: '=',
  value: DEFAULT_LISTING_STATUS_FILTER_VALUE
}

export const URL_ELIGIBLE = {
  field: '_url_eligible',
  label: 'urlEligible',
  operator: '=',
  value: true
}

export const DATE_LISTED_FILTER = {
  label: 'dateListed',
  field: 'listing_hl_created_at',
  operator: '>=',
  value: ''
}
