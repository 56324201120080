import { captureException } from '@sentry/react'
import { TRACK_EVENT_URL } from '../../constants'
import { readCookie } from '../cookies';
import { requestData } from '../requestData'
import { IEvent } from "./types";

export const postEvent = ({category, label, value}: IEvent) => {
    requestData({
        url: TRACK_EVENT_URL,
        method: 'post',
        body: {
            session_id: window.SESSION_ID || readCookie(`_hl_session_${process.env.REACT_APP_NODE_ENV}`),
            category: category,
            label: label,
            value: `${value}`
        },
        includeBearerToken: true,
        ignoreJsonFormat: true
    }).then(({error}) => {
        if (error) {
            console.warn('error', error)
            captureException(error)
        }
    })
}
