import styled from 'styled-components'
import {
  NativeDropdownContainer,
  NativeDropdownPropType,
} from './'
import { CaretDown } from '../../icons/CaretDown';
import { coolGray30, coolGray50, whiteHover } from '../../../constants';

const Option = styled.option`
  min-width: 200px;
  padding: 100px 12px;
  font-size: 14px;
`

const SelectedOption = styled(Option)`
  background-color: ${whiteHover};
`

const SelectContainer = styled.div`
  position: relative;
`

const CaretDownContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 0;
  height: 100%;
  align-items: center;
  display: flex;
`

const SelectTrigger = styled.select`
  width: 100%;
  padding: 12px 20px 12px 12px;
  font-size: 16px;
  background-color: white;
  border: 1px solid ${coolGray50};
  border-radius: 4px;
  color: ${coolGray30};
  font-family: inherit;
  -webkit-appearance: none;
`

export const NativeDropdown: React.FC<NativeDropdownPropType> = ({
  options,
  selectedLabel,
  setSelected,
  triggerTestId,
  pt
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = options.find(option => option.label === event.target.value)

    if (selectedOption) {
      setSelected(selectedOption)
    }
  }

  return (
    <NativeDropdownContainer pt={pt}>
      <SelectContainer>
        <SelectTrigger value={selectedLabel} onChange={handleChange} data-testid={triggerTestId} role="select" >
          { options.map((option) => (
            selectedLabel === option.label ? (
              <SelectedOption key={ option.label } value={option.label} >
                { option.label }
              </SelectedOption>
            ) : (
              <Option key={ option.label } value={option.label} >
                { option.label }
              </Option>
            )
          )) }
        </SelectTrigger>
        <CaretDownContainer>
          <CaretDown/>
        </CaretDownContainer>
      </SelectContainer>
    </NativeDropdownContainer>
  )
}
