import styled from 'styled-components'
import { BREAKPOINT_M } from '../../../constants'
import { DropdownOptionsContainer } from '../Dropdown'

export const PopoverBody = styled(DropdownOptionsContainer)<{
  open: boolean
  left?: string
  right?: string
  margin?: string
  mt?: string
  mr?: string
  mb?: string
  ml?: string
  padding?: string
  pt?: string
  pr?: string
  pb?: string
  pl?: string
  width?: string
}>`
  display: ${props => (props.open ? 'block' : 'none')};
  position: fixed;
  z-index: 100;
  left: ${props => (props.left ? props.left : 0)};
  right: ${props => (props.right ? props.right : '')};
  margin-top: ${props => (props.mt ? props.mt : '8px')};
  margin-right: ${props => (props.mr ? props.mr : 0)};
  margin-bottom: ${props => (props.mb ? props.mb : 0)};
  margin-left: ${props => (props.ml ? props.ml : 0)};
  margin: ${props => (props.margin ? props.margin : 0)};
  padding: ${props => (props.padding ? props.padding : 0)};
  padding-top: ${props => (props.pt ? props.pt : 0)};
  padding-right: ${props => (props.pr ? props.pr : 0)};
  padding-bottom: ${props => (props.pb ? props.pb : 0)};
  padding-left: ${props => (props.pl ? props.pl : 0)};
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
  width: ${props => (props.width ? props.width : '')};

  @media (min-width: ${BREAKPOINT_M}px) {
    position: absolute;
    left: ${props => (props.left ? props.left : '0')};
    right: unset;
  } ;
`

export const PopoverContentContainer = styled('div')<{
  padding?: string,
}>`
  padding: ${props => props.padding ? props.padding : "16px"};

  @media (max-width: ${BREAKPOINT_M}px) {
    padding: ${props => props.padding ? props.padding : "16px 16px 72px"};
  }
`
