import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Header, Body } from '../common/Modal'
import { Heading3, Text } from '../common/Text'
import { Flex } from '../common/Flex'
import { AlertFrequencyDropdown } from './AlertFrequencyDropdown'
import {
  BREAKPOINT_S,
  coolGray50,
  darkBlue,
  SAVED_SEARCHES_URL,
  SEARCH_ALERT_FREQUENCY_OPTIONS,
  LISTING_STATUS_ACTIVE_COMING_SOON_FILTER_VALUE
} from '../../constants'
import { formatSavedSearch } from '../../utils/formatSavedSearch'
import { requestData } from '../../utils/requestData'
import { roundToPriceIncrement } from '../../utils/roundToIncrement'
import { hasFiltersSelected } from '../../utils/hasFiltersSelected'
import { ACTION_STATE, ACTION_TAP, ACTION_VIEW, trackEvent } from '../../utils/tracking'
import { buildRequestFilters } from '../../utils/buildRequestFilters'
import { IDropdownOption } from '../common/Dropdown'
import { ElectricBlueButton } from '../common/Button'
import { SearchIcon } from '../icons/SearchIcon'
import { PROPERTY_TYPE_MAPPING, PERMITTED_PROPERTY_TYPES } from '../../constants'
import { IPostFavoriteSearchModal } from './types'

const StyledBody = styled(Body)`
  padding-bottom: 24px;
  @media (min-width: ${BREAKPOINT_S}px) {
    flex: 1;
  }

  @media (max-width: ${BREAKPOINT_S}px) {
    padding: 24px 16px;
  }
`

const StyledHeading = styled(Heading3)`
  margin-bottom: 24px;

  @media (min-width: ${BREAKPOINT_S}px) {
    margin-bottom: 8px;
  }
`

const StyledText = styled(Text)`
  padding-bottom: 24px;
`

const StyledFooter = styled(Flex)`
  margin: 0 16px;
  button {
    width: 100%;
    height: 48px;
  }
  
  @media (min-width: ${BREAKPOINT_S}px) {
    margin: 0;
    padding: 16px;
    border-top: 1px solid ${coolGray50};
    justify-content: flex-end;
    
    button {
      width: unset;
      height: unset;
    }
  }
`

const SearchDisplay = styled.div`
  display: flex;
  margin-bottom: 24px;
`

const SearchDisplayText = styled.div`
  .search-area {
    font-weight: bold;
    margin-bottom: 4px;
  }
`

const IconSpace = styled.div`
  flex-basis: 32px;
  padding-left: 4px;
  padding-top: 4px;
`

declare interface ISearchParams {
  status: string,
  propertyTypes?: string | null,
  priceMin?: number | null,
  priceMax?: number | null,
  bedsMin?: number | null,
  bedsMax?: number | null,
  bathsMin?: number | null,
  sqftMin?: number | null,
  sqftMax?: number | null,
  lotSizeMin?: number | null,
  lotSizeMax?: number | null,
  yearBuiltMin?: number | null,
  yearBuiltMax?: number | null
}

const PRICE_MIN_MULTIPLIER = 0.7
const PRICE_MAX_MULTIPLIER = 1.3

export const PostFavoriteSearchModal: React.FC<IPostFavoriteSearchModal> = ({
  property,
  slug,
  locationTitle,
  filters,
  currentUser,
  visitorId,
  closeModal,
  setSavedSearchCreated,
  setShowToast,
  setToast,
  setError
}) => {
  const [searchDetails, setSearchDetails] = useState({})
  const [selectedFrequency, setSelectedFrequency] = useState<IDropdownOption>(
    SEARCH_ALERT_FREQUENCY_OPTIONS[0]
  )
  const useFilters = hasFiltersSelected(filters)

  useEffect(() => {
    trackEvent(ACTION_VIEW, 'PostFavoriteModal_save_search')
  }, [])

  const formattedSearchString = formatSavedSearch(searchDetails)
  const setFrequencyOption = (option: IDropdownOption, _id: undefined) => {
    setSelectedFrequency(option)
  }

  const getFilterValue = (label: string) => {
    return filters.find(filter => filter.label === label)?.value || null
  }

  useEffect(() => {
    const getPropertyType = () => {
      const resolvedType = PROPERTY_TYPE_MAPPING[property.propertySubType] ||
        PROPERTY_TYPE_MAPPING[property.propertyType]
      return resolvedType || PERMITTED_PROPERTY_TYPES.join(',')
    }

    let searchParams: ISearchParams = {
      status: LISTING_STATUS_ACTIVE_COMING_SOON_FILTER_VALUE
    }

    if (useFilters) {
      searchParams['propertyTypes'] = getFilterValue('propertyType') as string | null
      searchParams['priceMin'] = getFilterValue('priceMin') as number | null
      searchParams['priceMax'] = getFilterValue('priceMax') as number | null
      searchParams['bedsMin'] = getFilterValue('bedsMin') as number | null
      searchParams['bedsMax'] = getFilterValue('bedsMax') as number | null
      searchParams['bathsMin'] = getFilterValue('bathsMin') as number | null
      searchParams['sqftMin'] = getFilterValue('sqftMin') as number | null
      searchParams['sqftMax'] = getFilterValue('sqftMax') as number | null
      searchParams['lotSizeMin'] = getFilterValue('lotSizeMin') as number | null
      searchParams['lotSizeMax'] = getFilterValue('lotSizeMax') as number | null
      searchParams['yearBuiltMin'] = getFilterValue('yearBuiltMin') as number | null
      searchParams['yearBuiltMax'] = getFilterValue('yearBuiltMax') as number | null
    } else {
      searchParams['priceMin'] = roundToPriceIncrement(property.price * PRICE_MIN_MULTIPLIER, 'down')
      searchParams['priceMax'] = roundToPriceIncrement(property.price * PRICE_MAX_MULTIPLIER, 'up')
      searchParams['bedsMin'] = property.bedroomsCount && property.bedroomsCount > 1 ? property.bedroomsCount : null
      searchParams['propertyTypes'] = getPropertyType()
    }

    setSearchDetails(searchParams)
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, property, setSearchDetails, useFilters])

  const paramsToFilterString = () => {
    let searchUrl = '?save_search=true'
    if (useFilters) {
      searchUrl += buildRequestFilters(filters)
    } else {
      searchUrl += '&filter[_listing_link]=true&filter[_url_eligible]=true&filter[listing_mls_status]=Active,Coming Soon&filter[_favorite_properties]=true'
      const propertyFilters = [
        { field: 'listing_property_sub_type', operator: '=', value: searchDetails.propertyTypes },
        { field: 'listing_list_price', operator: '>=', value: searchDetails.priceMin },
        { field: 'listing_list_price', operator: '<=', value: searchDetails.priceMax },
        { field: 'listing_bedrooms_total', operator: '>=', value: searchDetails.bedsMin },
      ]
      searchUrl += buildRequestFilters(propertyFilters)
    }

    searchUrl += `&filter[slug]=${slug}`
    if (currentUser) {
      searchUrl += `&filter[user_id]=${currentUser.id}`
    } else if (visitorId) {
      searchUrl += `&filter[visitor_id]=${visitorId}`
    }

    return searchUrl
  }

  const createSavedSearch = () => {
    setShowToast(false)
    requestData({
      url: SAVED_SEARCHES_URL,
      method: 'post',
      body: {
        search_url: paramsToFilterString(),
        frequency: selectedFrequency.field,
        source: 'post_favorite'
      },
      ignoreJsonFormat: true,
      includeBearerToken: false
    }).then(({ error }) => {
      if (error) {
        setToast('requestError')
        setError(error)
      } else {
        trackEvent(ACTION_TAP, 'PostFavoriteModal_save_search')
        setToast('savedSearchCreated')
        setSavedSearchCreated(true)
        trackEvent(ACTION_STATE, 'save_search')
        closeModal()
      }
    })
  }

  return (
    <>
      <Header closeModal={closeModal} />
      <StyledBody>
        <StyledHeading as='h3'>Save a search for homes like this</StyledHeading>
        <StyledText as='p' color={darkBlue}>
          {`Get updated when similar homes in ${locationTitle} hit the market.`}
        </StyledText>
        <SearchDisplay>
          <IconSpace>
            <SearchIcon />
          </IconSpace>
          <SearchDisplayText>
            <div className='search-area'>{locationTitle}</div>
            <div className='search-details'>{formattedSearchString}</div>
          </SearchDisplayText>
        </SearchDisplay>
        <AlertFrequencyDropdown
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={setFrequencyOption}
        />
      </StyledBody>
      <StyledFooter>
        <ElectricBlueButton.Medium
          onClick={createSavedSearch}
          data-testid='post-favorite-search-save'
        >
          Save
        </ElectricBlueButton.Medium>
      </StyledFooter>
    </>
  )
}
