import axios from 'axios'
import { ISuggestion } from '.'

export const autocompleteRequest = (geography: string) => {
  const authToken = `${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
  // Removing neighborhood until it is supported
  // const types = 'region,district,place,postcode,address,neighborhood';
  const types = 'place,postcode,address';
  const url = `${process.env.REACT_APP_MAPBOX_URL}/${encodeURIComponent(geography)}.json?autocomplete=true&access_token=${encodeURIComponent(authToken)}&country=us&types=${encodeURIComponent(types)}`;
  let data: ISuggestion[] = [];
  let error = false;

  const makeRequest = async () => {
    try {
      const response = await axios.get(url);
      data = await response.data["features"];
    } catch (err) {
      data = []
      error = true
    } finally {
      return { data, error }
    }
  }

  return makeRequest().then(({ data, error }) => ({ data, error }));
}
